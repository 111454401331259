import Immutable from 'immutable';
import moment from 'moment';
import last from 'lodash/last';
import first from 'lodash/first';

export const getCurrentSchedule = (schedules) => {
  if (schedules) {
    const now = moment();
    let currentSchedule = schedules[0];
    schedules.forEach((schedule) => {
      const validFrom = moment(schedule.validFrom).startOf('day');
      const validUntil = schedule.validUntil && moment(schedule.validUntil).endOf('day');
      if (validFrom.isBefore(now) && (!validUntil || validUntil.isAfter(now))) {
        currentSchedule = schedule;
      }
      schedule.isPast = validUntil && validUntil.isBefore(now);
    });
    return currentSchedule;
  }
};

export function convertExceptions(exceptions) {
  const ff = Immutable.List(exceptions).sortBy(exception => exception.start);

  const grouped = ff.groupBy((e) => {
    return e.date;
  });
  const result = [];

  for (const k of grouped.keys()) {
    const values = grouped.get(k);
    const eg = {
      date: k,
      closed: []
    };
    let closed = {
      start: null,
      end: null
    };

    values.forEach((value, idx) => {
      if (closed.start) {
        closed.end = value?.start?.replace(/.*(\d{2}):(\d{2}):(\d{2}).*/, '$1:$2');
      } else {
        closed.start = value?.end?.replace(/.*(\d{2}):(\d{2}):(\d{2}).*/, '$1:$2');
      }

      if (idx == values.size - 1) {
        eg.end = value.end;
        eg.type = value.type;
      }

      if (closed.start != null && closed.end != null) {
        eg.closed.push(closed);
        closed = {
          start: value?.end?.replace(/.*(\d{2}):(\d{2}):(\d{2}).*/, '$1:$2'),
          end: null
        };
      }

      eg.start = eg.start || value.start;
    });
    eg.start = eg?.start?.replace(/.*(\d{2}):(\d{2}):(\d{2}).*/, '$1:$2');
    eg.end = eg?.end?.replace(/.*(\d{2}):(\d{2}):(\d{2}).*/, '$1:$2');
    result.push(eg);
  }
  return result;
}

function isZeroHour(time) {
  return time === '00:00:00' || time === '00:00';
}

export function ignoreClosedBlock(block) {
  return !(block.closed && isZeroHour(block.start) && isZeroHour(block.end));
}

function getScheduleArray(schedules, blockFilter = (block) => true) {
  let arr = [];
  schedules.forEach((schedule) => {
    arr = arr.concat(schedule.blocks.filter((block) => {
      return ignoreClosedBlock(block) && blockFilter(block);
    }));
  });
  return arr;
}

const scheduleFilter = (schedule) => {
  return schedule.start !== '00:00:00' || schedule.end !== '00:00:00';
};

export function getFirstSchedule(schedules, blockFilter) {
  const arr = getScheduleArray(schedules, blockFilter);
  return arr.length > 0
    ? moment(Immutable.List(arr).filter(scheduleFilter).sortBy(a => a.start).first().start, 'HH:mm')
    : null;
}

export function getLastSchedule(schedules, blockFilter) {
  const arr = getScheduleArray(schedules, blockFilter);
  return arr.length > 0
    ? moment(Immutable.List(arr).filter(scheduleFilter).sortBy(a => a.end).last().end, 'HH:mm')
    : null;
}

function getScheduleTime(schedules, date, getSchedule) {
  const blockFilter = (block) => block.day === date;
  const schedule = getSchedule(schedules, blockFilter);

  return schedule && moment(date).set({
    hour: schedule.hour(), minute: schedule.minute()
  });
}

export function getScheduleBounds(schedulesByResource, resourceId, date) {
  const schedules = schedulesByResource.filter(s => s.resourceId === resourceId);
  const dateString = moment.isMoment(date) ? date.format('YYYY-MM-DD') : date;

  return {
    startTime: getScheduleTime(schedules, dateString, getFirstSchedule),
    endTime: getScheduleTime(schedules, dateString, getLastSchedule)
  };
}

const isBlockCurrentDay = (week, day) => {
  return (block) => block.week === week && block.day === day;
};

const isBlockAnotherDay = (week, day) => {
  return (block) => block.week !== week || block.day !== day;
};

const getChangedBlocksForSave = (currentSchedule) => ({
  ...currentSchedule,
  blocks: currentSchedule.blocks.map((bl) => ({
    ...bl,
    start: moment(bl.start, 'HH:mm').format('HH:mm'),
    end: moment(bl.end, 'HH:mm').format('HH:mm')
  }))
});

export function getUpdatedSchedule(currentSchedule, update) {
  const { start, end, breaksData, day, week } = update;

  if (breaksData.length > 0) {
    const [, breakLastEnd] = last(breaksData);
    const [breakFirstStart, breakFirstEnd] = first(breaksData);

    // create first item block
    const newBlocks = [{
      start, end: breakFirstStart.time, day, week
    }];

    if (breaksData.length === 1) {
      // if just ine break need to push one element to create
      currentSchedule.blocks = [
        ...newBlocks,
        {
          start: breakFirstEnd.time, end, day, week
        },
        ...currentSchedule.blocks.filter(isBlockAnotherDay(week, day))
      ];
    } else {
      const newBlocksBreaks = [{
        start: breakLastEnd.time, end, day, week
      }];
      breaksData.forEach(([, itemBreakEnd], index, arr) => {
        // check the not last element
        if (index !== arr.length - 1) {
          const [nextBreakStart] = breaksData[index + 1];
          // push new obj block
          // start: current end time
          // end: nextBreakStart = breaksData[index + 1][0]
          newBlocksBreaks.push({
            start: itemBreakEnd.time, end: nextBreakStart.time, week, day
          });
        }
      });

      currentSchedule.blocks = [
        ...newBlocks,
        ...newBlocksBreaks,
        ...currentSchedule.blocks.filter(isBlockAnotherDay(week, day))
      ];
    }
  } else {
    let blocksDay = currentSchedule.blocks.filter(isBlockCurrentDay(week, day));
    const otherBlocks = currentSchedule.blocks.filter(isBlockAnotherDay(week, day));

    if (breaksData.length === 0 || blocksDay.length === 0) {
      blocksDay = [{
        start, end, day, week
      }];
    } else {
      blocksDay.forEach((bl, ind) => {
        if (bl.start <= end) {
          blocksDay.splice(ind, 1);
          blocksDay.push({
            start, end: bl.end, day, week
          });
        }
      });
    }
    currentSchedule.blocks = [...blocksDay, ...otherBlocks];
  }

  currentSchedule.blocks
    .filter(isBlockCurrentDay)
    .sort((left, right) => moment.utc(left.start, 'HH:mm').diff(moment.utc(right.start, 'HH:mm')));

  return getChangedBlocksForSave(currentSchedule);
}

export const isoWeekdays = [null, 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
const getDayFromWeekday = (weekday) => isoWeekdays.indexOf(weekday);
const getDayOfWeek = (day) => isoWeekdays[day];

export function scheduleToTimeRestrictions(schedule) {
  return schedule?.blocks.map((block) => {
    return {
      dayOfWeek: getDayOfWeek(block.day),
      start: block.start,
      end: block.end
    };
  });
}

export function timeRestrictionsToSchedule(timeRestrictions) {
  return {
    blocks: timeRestrictions.map((block) => {
      return {
        week: 1,
        day: getDayFromWeekday(block.dayOfWeek),
        start: block.start,
        end: block.end
      };
    })
  };
}
