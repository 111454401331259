import React from 'react';
import { Field } from 'redux-form';
import { web } from '@Utils/preference-keys';
import TimePickerField from '@Components/inputs/time-picker-field';

const PriceSettingsForm = () => {
  return (
    <>
      <div className="form-group">
        <label className="col-sm-3 control-label">Kvällspriser börjar gälla</label>
        <div className="col-sm-6">
          <Field name={web.eveningPriceStart} component={TimePickerField} required />
        </div>
      </div>

      <div className="form-group">
        <label className="col-sm-3 control-label">Helgpriser</label>
        <div className="col-sm-8">
          <div className="checkbox">
            <label>
              <Field name={web.holidayPriceOnSaturday} component="input" type="checkbox" />
              Helgpriser gäller på lördagar
            </label>
          </div>
          <div className="checkbox">
            <label>
              <Field name={web.holidayPriceOnSunday} component="input" type="checkbox" />
              Helgpriser gäller på söndagar
            </label>
          </div>
          <div className="checkbox">
            <label>
              <Field name={web.holidayPriceOnPublicHoliday} component="input" type="checkbox" />
              Helgpriser gäller på röda dagar
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceSettingsForm;
