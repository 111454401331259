import React, { useState, memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { getDayName, getShortDayName } from '@Utils/date-util';
import ConfirmPopover from '@Components/ui/confirm-popover';
import ScheduleItemTimePopover from '@Components/schedule/schedule-item-popover';
import { getTime, getMinutesDiff, getDiffString } from '@Utils/time-util';
import { DEFAULT_TIME_START, DEFAULT_TIME_END } from '@Utils/time-constants';
import { txt } from '@Utils/i18n-util';
import msg from './schedules.msg';

const ScheduleDay = ({ data: { day, blocks, week }, onSave, onDelete, compact }) => {
  const [showDayPopover, setShowDayPopover] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const onHideDayPopover = () => setShowDayPopover(false);
  const onShowDayPopover = () => setShowDayPopover(true);

  const onHideClosePopover = (ev) => {
    if (ev) {
      ev.stopPropagation();
    }
    setShowConfirmDelete(false);
  };

  const onShowClosePopover = (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    setShowConfirmDelete(true);
  };

  const onCloseDay = () => {
    onDelete(day, week);
    onHideClosePopover();
  };

  const renderBlocks = () => {
    if (blocks.length > 1) {
      blocks.sort(
        (left, right) => moment.utc(left.start, 'HH:mm').diff(moment.utc(right.start, 'HH:mm'))
      );
    }

    let height = 0;
    blocks.forEach((bl, index) => {
      const nextBlock = blocks[index + 1];
      let breakHeight = 0;
      if (nextBlock && nextBlock.start) {
        breakHeight = getMinutesDiff(bl.end, nextBlock.start) < 0 ? 31 : 0;
      }
      height += (getMinutesDiff(bl.end, bl.start) * 0.5) + breakHeight;
    });

    return (
      <>
        <ScheduleItemTimePopover
          onSave={onSave}
          onClose={onHideDayPopover}
          isOpen={showDayPopover}
          day={day}
          week={week}
          blocks={blocks}
        >
          <div onClick={onShowDayPopover}>
            {blocks.map(({ day, start, end }, index) => {
              const nextBlock = blocks[index + 1];

              let isRenderBreakBlock = false;
              if (nextBlock && nextBlock.start) {
                isRenderBreakBlock = getMinutesDiff(end, nextBlock.start) < 0 && true;
              }
              return (
                <Fragment key={day + start}>
                  {renderBlock(start, end, { height: `${getMinutesDiff(end, start) * 0.5}px` })}
                  {isRenderBreakBlock && renderBreakBlock(end, nextBlock.start)}
                </Fragment>
              );
            })}
          </div>
        </ScheduleItemTimePopover>
        <ConfirmPopover
          text={txt(msg.txtCloseDay)}
          confirmText={txt(msg.btnCloseDay)}
          onClose={onHideClosePopover}
          onConfirm={onCloseDay}
          isOpen={showConfirmDelete}
        >
          <button onClick={onShowClosePopover} className="btn btn-block btn-default">
            {compact ? txt(msg.btnClose) : txt(msg.btnCloseDay)}
          </button>
        </ConfirmPopover>
      </>
    );
  };

  const renderBreakBlock = (start, end) => (
    <div className="schedule-item schedule-item-break">
      <strong>{getDiffString(end, start)}</strong>
    </div>
  );

  const renderBlock = (start, end, styleItem) => (
    <div className="schedule-item" style={styleItem}>
      <span className="zero-height">
        <strong>
          {getTime(start)}
          {compact ? ' ' : ' - '}
          {getTime(end)}
        </strong>
        <br />
        <span className="small-muted-text">{getDiffString(end, start)}</span>
      </span>
    </div>
  );

  const renderEmptyItem = () => (
    <div className="schedule-item-empty">
      <ScheduleItemTimePopover
        onSave={onSave}
        onClose={onHideDayPopover}
        isOpen={showDayPopover}
        day={day}
        week={week}
        blocks={[{
          start: getTime(DEFAULT_TIME_START),
          end: getTime(DEFAULT_TIME_END)
        }]}
        isNew
      >
        <div className="schedule-item-closed">{txt(msg.lblClosed)}</div>
      </ScheduleItemTimePopover>
      <button onClick={onShowDayPopover} className="btn btn-block btn-default">
        {txt(msg.btnOpen)}
      </button>
    </div>
  );

  return (
    <div className="schedule-day">
      <h5 className="schedule-day-name">
        {compact ? getShortDayName(day) : getDayName(day)}
      </h5>
      {blocks.length > 0
        ? renderBlocks()
        : renderEmptyItem()}
    </div>
  );
};

ScheduleDay.propTypes = {
  data: PropTypes.object,
  onSave: PropTypes.func,
  onDelete: PropTypes.func
};

export default memo(ScheduleDay);
