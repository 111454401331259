import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import ModalSelector from '@Components/reports/data-export/data-export-modal-selector';

const formName = 'voucher-form';
const inputName = (name) => `voucherRules.${name}`;
const normalize = (value) => value.length > 0 ? value : null;
const formSelector = formValueSelector(formName);

const VoucherFormRules = () => {
  const dispatch = useDispatch();
  const services = useSelector(state => state.servicesById.valueSeq().toJS());
  const serviceGroups = useSelector(state => state.orderedServiceGroups.toJS());
  const resources = useSelector(state => state.resourcesById.valueSeq().toJS());
  const resourceGroups = useSelector(state => state.orderedGroups.toJS());
  const allowedServiceIds = useSelector(state => formSelector(state, inputName('allowedServiceIds')));
  const allowedResourceIds = useSelector(state => formSelector(state, inputName('allowedResourceIds')));

  const setFormValue = (name, value) => {
    dispatch(change(formName, inputName(name), normalize(value)));
  };

  const onUpdateService = (serviceIds) => setFormValue('allowedServiceIds', serviceIds);
  const onUpdateResource = (resourceIds) => setFormValue('allowedResourceIds', resourceIds);

  return (
    <>
      <ModalSelector
        title="Gäller för tjänster"
        items={services}
        values={allowedServiceIds || []}
        groups={serviceGroups}
        groupKey="serviceIds"
        onChange={onUpdateService}
        getItemTitle={(service) => service?.name}
        placeholder="Alla tjänster"
      />
      <ModalSelector
        title="Gäller för resurser"
        items={resources}
        values={allowedResourceIds || []}
        groups={resourceGroups}
        groupKey="resourceIds"
        onChange={onUpdateResource}
        getItemTitle={(resource) => resource?.name}
        placeholder="Alla resurser"
      />
    </>
  );
};

export default VoucherFormRules;
