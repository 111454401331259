import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import StaffEditEntryPopup from '@Components/staff/staff-edit-entry-popup';
import { Button, Text, TextEllipsis } from '@Components/ui/styled/main';
import { updateUserPno } from '@State/users-actions';
import { getDate } from '@Utils/time-util';
import PopoverPersonalNumber from '@Components/dialogs/popover-personal-number';
import { colors } from '@Components/ui/styled/variables';
import { txt } from '@Utils/i18n-util';
import msg from './staff-user-detail.msg';

const NumberStyle = styled.div`
  color: ${p => p.pno ? 'rgba(0, 0, 0, 0.7)' : colors.danger};
  padding-bottom: 20px;
  line-height: 21px;
  font-weight: 500;
`;

const TextEllipsisStyled = styled(TextEllipsis)`
  padding-top: 3px;
  font-weight: 600;
`;

const TextRow = styled(Text)`
 height: 30px;
 position: relative;
`;

const ButtonStyled = styled.div`
  position: absolute;
  top: -2px;
  right: 0;
`;

const StaffUserDetailHeader = ({ userId, pno, name }) => {
  const dispatch = useDispatch();
  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const togglePopover = () => setIsOpenPopover(!isOpenPopover);
  const hidePopup = () => setIsOpenPopover(false);

  const handlerSetPersonalNumber = async (pno) => {
    try {
      await dispatch(updateUserPno(userId, pno));
    } finally {
      hidePopup();
    }
  };
  const date = getDate();
  return (
    <>
      <TextRow bold fs={17}>
        <TextEllipsisStyled width={280}>
          {name}
        </TextEllipsisStyled>
        <ButtonStyled>
          {pno
            ? (
              <StaffEditEntryPopup
                preferPlace="below"
                date={date}
                isToday
                isNew
                userId={userId}
                isShowPopover={isOpenPopover}
                handleClose={hidePopup}
              >
                <Button small gray onClick={togglePopover}>
                  {txt(msg.btnManualEntry)}
                </Button>
              </StaffEditEntryPopup>
            )
            : (
              <PopoverPersonalNumber
                preferPlace="below"
                isOpenPopover={isOpenPopover}
                onHidePopup={hidePopup}
                onUpdate={handlerSetPersonalNumber}
              >
                <Button small gray onClick={togglePopover}>
                  {txt(msg.btnAddPno)}
                </Button>
              </PopoverPersonalNumber>
            )}
        </ButtonStyled>
      </TextRow>
      <NumberStyle pno={pno}>{pno || txt(msg.lblMissingPno)}</NumberStyle>
      <div className="border-bottom-gray" />
    </>
  );
};

StaffUserDetailHeader.propTypes = {
  userId: PropTypes.number,
  pho: PropTypes.string,
  name: PropTypes.string
};

export default memo(StaffUserDetailHeader);
