import Immutable from 'immutable';
import uniqBy from 'lodash/uniqBy';
import {
  GET_REPORT_DATA_FIELDS,
  GET_REPORT_DATA_OPTIONS,
  SET_DATA_EXPORT_REPORT_PREVIEW,
  SET_DATA_REPORT_VALUES,
  SET_DATA_REPORT_PREVIEW_STATUS,
  ABORT_DATA_REPORT_PREVIEW
} from '@State/report-actions';

function reportsData(state = Immutable.Map({ values: {} }), action = null) {
  switch (action.type) {
    case SET_DATA_REPORT_VALUES:
      const values = state.get('values');
      const { locationIds } = action.payload;
      if (locationIds && locationIds.length) {
        const { resources, services, customFields: customFieldsList } = state.get('reportOptions');
        const { serviceIds, resourceIds, customFields } = values;
        const filter = (arr, list) => arr?.filter(id => {
          const item = list.find(item => id === item.id);
          return item?.locationId && locationIds.includes(item.locationId);
        });
        return state.set('values', {
          ...values,
          serviceIds: filter(serviceIds, services),
          resourceIds: filter(resourceIds, resources),
          customFields: filter(customFields, customFieldsList),
          ...action.payload
        });
      }
      return state.set('values', { ...values, ...action.payload });
    case SET_DATA_EXPORT_REPORT_PREVIEW:
      return state.set('reportPreview', action.payload);
    case SET_DATA_REPORT_PREVIEW_STATUS:
      return state.set('reportPreviewStatus', action.payload);
    case ABORT_DATA_REPORT_PREVIEW:
      const status = state.get('reportPreviewStatus');
      if (status && status.source) {
        status.source.cancel('Operation canceled by the user.');
      }
      return state.set('reportPreviewStatus', { reportPreviewPending: false, controller: null });
    case GET_REPORT_DATA_FIELDS:
      return state.set('reportDataFields', action.payload);
    case GET_REPORT_DATA_OPTIONS:
      const sorter = (a, b) => a.name.localeCompare(b.name);
      const groupMap = (group) => ({ ...group, id: group.groupId });
      const groupFilter = (group) => group.entityIds && group.entityIds.length;
      const { locations, resources, services, resourceGroups, serviceGroups, customFields, ...rest } = action.payload;
      const data = {
        ...rest,
        customFields: customFields.reduce((prev, { fieldPairs = [], locationId }) => {
          return [
            ...prev,
            ...uniqBy(fieldPairs, 'field')
              .map(item => ({ ...item, id: item.field, locationId }))
          ];
        }, []).sort((a, b) => a.header.localeCompare(b.header)),
        resourceGroups: resourceGroups.filter(groupFilter).map(groupMap).sort(sorter),
        serviceGroups: serviceGroups.filter(groupFilter).map(groupMap).sort(sorter),
        resources: resources.sort(sorter),
        services: services.sort(sorter),
        locations: Object.keys(locations).map(key => ({ id: +key, name: locations[key] }))
      };
      return state.set('values', {}).set('reportCustomFields', []).set('reportOptions', data);
    default:
      return state;
  }
}

export default reportsData;
