import React, { useState } from 'react';
import { formatOrgNo, formatPno } from '@Utils/luhn-util';
import { formatPhoneNumber } from '@Utils/phone-util';
import { FormGroup, Column, Text, Button } from '@Components/ui/styled/main';
import {
  PanelWrapper, TitleBlock, StyledLink, RowFormGroups
} from '@Components/customers/customer-styles';
import { CustomerType, getGenderText } from '@Utils/customer-util';
import CustomerEditModal from '@Components/customers/customer-detail/overview/customer-edit-modal';
import { txt } from '@Utils/i18n-util';
import msg from './customer-information.msg';

const CustomerInformation = ({ hasChangePermission, selectedCustomer }) => {
  const {
    customerType, addressLine1, addressLine2, addressLine3, area, postCode, country,
    name, phoneNumber, otherPhoneNumber, email, officialIdNo, gender
  } = selectedCustomer || {};

  const [isOpenChangeModal, setOpenChangeModal] = useState(false);
  const openModalChangeInfo = () => setOpenChangeModal(true);
  const hideModalChangeInfo = () => setOpenChangeModal(false);

  const isEmptyContent = !name && !phoneNumber && !otherPhoneNumber && !email && !officialIdNo;
  const isShowAddress = addressLine1 || addressLine2 || addressLine3;
  const isShowFullAddress = (isShowAddress || postCode || country || area);
  const isPerson = customerType === CustomerType.Person;
  const isCompany = customerType === CustomerType.Company;
  const isVehicle = customerType === CustomerType.Vehicle;

  return (
    <>
      {isOpenChangeModal && (
        <CustomerEditModal
          customer={selectedCustomer}
          onClose={hideModalChangeInfo}
        />
      )}
      <TitleBlock>{txt(msg.lblCustomerDetails)}</TitleBlock>
      <PanelWrapper>
        <RowFormGroups>
          <Column>
            {isVehicle ? (
              <FormGroup
                labelText={txt(msg.lblVehicleRegNo)}
                text={officialIdNo || '-'}
              />
            ) : (
              <FormGroup
                labelText={isCompany ? txt(msg.lblCompanyName) : txt(msg.lblName)}
                text={name || '-'}
              />
            )}
          </Column>
          {hasChangePermission && (
            <Button white tiny onClick={openModalChangeInfo}>{txt(msg.btnEdit)}</Button>
          )}
        </RowFormGroups>
        {isCompany && officialIdNo && (
          <RowFormGroups>
            <Column>
              <FormGroup
                labelText={txt(msg.lblOrgNo)}
                text={formatOrgNo(officialIdNo)}
              />
            </Column>
          </RowFormGroups>
        )}
        {isVehicle && name && (
          <RowFormGroups>
            <Column>
              <FormGroup
                labelText={txt(msg.lblDescription)}
                text={name}
              />
            </Column>
          </RowFormGroups>
        )}
        {(phoneNumber || otherPhoneNumber) && (
        <RowFormGroups>
          {phoneNumber
            && (
            <Column>
              <FormGroup
                labelText={txt(msg.lblPhoneNumber)}
                text={formatPhoneNumber(phoneNumber)}
                link={`tel:${phoneNumber}`}
              />
            </Column>
            )}
          {otherPhoneNumber && (
          <Column>
            <FormGroup
              labelText={txt(msg.lblOtherPhoneNumber)}
              text={formatPhoneNumber(otherPhoneNumber)}
              link={`tel:${otherPhoneNumber}`}
            />
          </Column>
          )}
        </RowFormGroups>
        )}
        {email && (
        <RowFormGroups>
          <Column>
            <FormGroup
              labelText={txt(msg.lblEmail)}
              text={email}
              link={`mailto:${email}`}
            />
          </Column>
        </RowFormGroups>
        )}
        {isPerson && (!!officialIdNo || gender && gender !== 'None') && (
        <RowFormGroups>
          {officialIdNo && (
          <Column>
            <FormGroup
              labelText={txt(msg.lblPersonalIDNo)}
              text={formatPno(officialIdNo)}
            />
          </Column>
          )}
          {gender && gender !== 'None' && (
          <Column>
            <FormGroup
              labelText={txt(msg.lblGender)}
              text={getGenderText(gender)}
            />
          </Column>
          )}
        </RowFormGroups>
        )}
        {isShowFullAddress && (
        <RowFormGroups>
          <Column>
            <FormGroup
              labelText={txt(msg.lblAddress)}
              text={(
                <>
                  {addressLine1 && <Text fs={13}>{addressLine1}</Text>}
                  {addressLine2 && <Text fs={13}>{addressLine2}</Text>}
                  {addressLine3 && <Text fs={13}>{addressLine3}</Text>}
                  {postCode || area ? (
                    <Text fs={13}>
                      {`${postCode || ''} ${area || ''}`.trim()}
                    </Text>
                  ) : null}
                  {country && <Text fs={13}>{country}</Text>}
                </>
              )}
            />
          </Column>
        </RowFormGroups>
        )}
        {isEmptyContent && <StyledLink>{txt(msg.lblAddCustomerDetails)}</StyledLink>}
      </PanelWrapper>
    </>
  );
};

export default CustomerInformation;
