import { useEffect } from "react";
import { useSelector } from "react-redux";
import Swipe from "@Utils/swipe";
export default function useSwipeToDismiss(ref, onDismiss) {
    const phoneMode = useSelector(state => state.mainViewState.get('phoneMode'));
    useEffect(() => {
        const element = ref.current;
        if (!element) {
            console.error('useSwipeToDismiss hook requires a ref to an HTMLElement');
            return;
        }
        if (!phoneMode) {
            return;
        }
        const swipe = new Swipe(element, { onSwipeDown: onDismiss });
        swipe.enable();
        return () => {
            swipe.disable();
        };
    }, [ref, onDismiss, phoneMode]);
}
