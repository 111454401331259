import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';

import { DATE_PICKER_FORMAT, DEFAULT_TIME_START, DEFAULT_TIME_END } from '@Utils/time-constants';
import { GrayText } from '@Components/dialogs/dialog-styles';
import { classes } from '@Components/ui/utils';

import Flex from '@Components/ui/styled/flex-item';
import ModalDialog from '@Components/dialogs/modal-dialog';

import LabelSwitchInput from '@Components/ui/label-switch-input';
import DatePickerLabelInput from '@Components/ui/date-picker/date-picker-label-input';
import { styleLabelInputNoBorder } from '@Components/admin/pos-config/style';

const getMinimumDays = (weeks) => (weeks * 7) - 1;

const ScheduleCreateModal = ({ onClose, onSubmit, currentSchedule = { } }) => {
  const initialWeek = currentSchedule.weeks || 1;
  const validFrom = moment().startOf('week');
  const validUntil = moment().add(initialWeek - 1, 'week').endOf('week');

  const [saving, setSaving] = useState(false);
  const [timeStart, setTimeStart] = useState(validFrom);
  const [timeEnd, setTimeEnd] = useState(validUntil);
  const [activeWeek, setActiveWeek] = useState(initialWeek);

  const [validUntilFurtherNotice, setValidUntilFurtherNotice] = useState(!currentSchedule.validUntil);
  const [moreWeeksSchedule, setMoreWeeksSchedule] = useState(initialWeek > 1 || false);
  const weeks = moreWeeksSchedule ? activeWeek : 1;

  const handleToggleMoreWeeks = () => {
    const useMoreWeeks = !moreWeeksSchedule;
    setMoreWeeksSchedule(useMoreWeeks);

    if (useMoreWeeks) {
      handleChangeWeek(null, activeWeek < 2 ? 2 : activeWeek);
    }
  };

  const handleChangeWeek = (ev, week) => {
    if (ev) {
      ev.preventDefault();
    }
    setActiveWeek(week);

    const minimumDays = getMinimumDays(week);
    const earliestAllowedEnd = moment(timeStart).add(minimumDays, 'day');
    if (earliestAllowedEnd.isAfter(timeEnd)) {
      setTimeEnd(earliestAllowedEnd);
    }
  };

  const handleChangeDate = (type, value) => {
    const minimumDays = getMinimumDays(weeks);

    if (type === 'start') {
      const newStart = moment(value);
      const earliestAllowedEnd = moment(newStart).add(minimumDays, 'day');
      if (earliestAllowedEnd.isAfter(timeEnd)) {
        setTimeEnd(earliestAllowedEnd);
      }
      return setTimeStart(newStart);
    }

    const newEnd = moment(value);
    const earliestAllowedStart = moment(newEnd).subtract(minimumDays, 'day');
    if (earliestAllowedStart.isBefore(timeStart)) {
      setTimeStart(earliestAllowedStart);
    }
    return setTimeEnd(newEnd);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setSaving(true);
    return onSubmit({
      name: '',
      validFrom: timeStart.format(DATE_PICKER_FORMAT),
      validUntil: validUntilFurtherNotice ? null : timeEnd.format(DATE_PICKER_FORMAT),
      weeks,
      blocks: getBlocks()
    }).catch(() => setSaving(false));
  };

  const getDefaultBlock = (week) => Array.from({ length: 5 }, (x, i) => ({
    week, day: i + 1, start: DEFAULT_TIME_START, end: DEFAULT_TIME_END
  }));

  const getBlocks = () => {
    const blocks = [];
    for (let week = 1; week <= weeks; week++) {
      const blocksByWeek = currentSchedule.blocks?.filter(bl => bl.week === week);
      const block = blocksByWeek && blocksByWeek.length > 0 && blocksByWeek || getDefaultBlock(week);
      blocks.push(...block);
    }
    return blocks;
  };

  const getButtonClass = (week) => classes({ primary: week === activeWeek });

  const buttonsAction = [{
    primary: true,
    name: 'OK',
    loading: saving,
    onClick: handleSave
  }, {
    gray: true,
    name: 'Stäng',
    onClick: onClose
  }];

  const hasCurrentSchedule = currentSchedule && currentSchedule.validFrom;

  return (
    <ModalDialog
      hideCloseButton
      className="dialog"
      buttons={buttonsAction}
      closeButtonText="Stäng"
      title={hasCurrentSchedule ? 'Duplicera schema' : 'Lägg till schema'}
    >
      <form autoComplete="off" className="dialog">
        <Flex>
          <Field
            name="start"
            component={() => (
              <DatePickerLabelInput
                minDate={validFrom.toDate()}
                label="Startdatum"
                date={timeStart}
                handleChangeDate={(date) => handleChangeDate('start', date)}
              />
            )}
          />
        </Flex>
        {!validUntilFurtherNotice && (
          <Flex>
            <Field
              name="end"
              component={() => (
                <DatePickerLabelInput
                  minDate={moment(timeStart).add(getMinimumDays(weeks), 'day').toDate()}
                  label="Slutdatum"
                  date={timeEnd}
                  handleChangeDate={(date) => handleChangeDate('end', date)}
                />
              )}
            />
          </Flex>
        )}

        <Flex>
          <LabelSwitchInput
            label="Gäller tillsvidare"
            id="validUntilFurtherNotice"
            isOn={validUntilFurtherNotice}
            handleToggle={() => setValidUntilFurtherNotice(!validUntilFurtherNotice)}
            labelStyle={styleLabelInputNoBorder}
          />
        </Flex>
        <Flex>
          <LabelSwitchInput
            labelStyle={styleLabelInputNoBorder}
            label="Flerveckorsschema"
            id="moreWeeksSchedule"
            isOn={moreWeeksSchedule}
            handleToggle={handleToggleMoreWeeks}
          />
        </Flex>
        <Flex>
          {moreWeeksSchedule && (
            <div className="buttons-list">
              <button onClick={e => handleChangeWeek(e, 2)} className={getButtonClass(2)}>2v</button>
              <button onClick={e => handleChangeWeek(e, 3)} className={getButtonClass(3)}>3v</button>
              <button onClick={e => handleChangeWeek(e, 4)} className={getButtonClass(4)}>4v</button>
              <button onClick={e => handleChangeWeek(e, 5)} className={getButtonClass(5)}>5v</button>
            </div>
          )}
        </Flex>
        <Flex>
          <GrayText>Om ditt schema är olika på olika veckodagar. T.ex. vid jämna/ojämna veckor eller var tredje måndag.</GrayText>
        </Flex>
      </form>
    </ModalDialog>
  );
};

ScheduleCreateModal.propTypes = {
  onClose: PropTypes.func
};

export default reduxForm({
  form: 'pos-form-create-new'
})(ScheduleCreateModal);
