import { defineMessages } from 'react-intl';

export default defineMessages({
  txtTimePastWarning: {
    id: 'calendar.bkf.options.txtTimePastWarning',
    defaultMessage: 'The time of the booking has passed.'
  },
  txtCustomerDeleted: {
    id: 'calendar.bkf.options.txtCustomerDeleted',
    defaultMessage: 'Customer has been deleted.'
  },
  btnSendSms: {
    id: 'calendar.bkf.options.btnSendSms',
    defaultMessage: 'Send SMS'
  },
  btnSendConfirmation: {
    id: 'calendar.bkf.options.btnSendConfirmation',
    defaultMessage: 'Send confirmation'
  },
  btnSendReceipt: {
    id: 'calendar.bkf.options.btnSendReceipt',
    defaultMessage: 'Send receipt'
  },
  btnRefund: {
    id: 'calendar.bkf.options.btnRefund',
    defaultMessage: 'Refund'
  },
  btnPrintBooking: {
    id: 'calendar.bkf.options.btnPrintBooking',
    defaultMessage: 'Print'
  },
  btnCopyBooking: {
    id: 'calendar.bkf.options.btnCopyBooking',
    defaultMessage: 'Copy'
  },
  btnMoveBooking: {
    id: 'calendar.bkf.options.btnMoveBooking',
    defaultMessage: 'Move'
  },
  btnRemoveCustomer: {
    id: 'calendar.bkf.options.btnRemoveCustomer',
    defaultMessage: 'Remove customer'
  },
  btnCancelBooking: {
    id: 'calendar.bkf.options.btnCancelBooking',
    defaultMessage: 'Cancel'
  },
  btnDeleteBooking: {
    id: 'calendar.bkf.options.btnDeleteBooking',
    defaultMessage: 'Delete'
  },
  btnCancel: {
    id: 'calendar.bkf.options.btnCancel',
    defaultMessage: 'Cancel'
  },
  btnCall: {
    id: 'calendar.bkf.options.btnCall',
    defaultMessage: 'Call'
  },
  btnBack: {
    id: 'calendar.bkf.options.btnBack',
    defaultMessage: 'Back'
  },
  lblOptionslTitle: {
    id: 'calendar.bkf.options.lblOptionslTitle',
    defaultMessage: 'Options'
  },
  btnShowEvents: {
    id: 'calendar.bkf.options.btnShowEvents',
    defaultMessage: 'Show history'
  },
  btnRegisterPayment: {
    id: 'calendar.bkf.options.btnRegisterPayment',
    defaultMessage: 'Register payment'
  },
  btnSetMaxSlots: {
    id: 'calendar.bkf.options.btnSetMaxSlots',
    defaultMessage: 'Set max slots'
  },
  btnCloseClass: {
    id: 'calendar.bkf.options.btnCloseClass',
    defaultMessage: 'Close booking'
  },
  btnOpenClass: {
    id: 'calendar.bkf.options.btnOpenClass',
    defaultMessage: 'Open booking'
  }
});
