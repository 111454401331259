import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchBookingEvents } from '@State/bkf/actions';
import { fetchCustomerEvents } from '@State/customer-actions';
import { DialogTitleBtn } from '@Components/dialogs/dialog-styles';
import HistoryEvent from '@Components/dialogs/history-event';
import Button from '@Components/ui/button';
import Loader from '@Components/ui/loader';
import ModalDialog from './modal-dialog';

const EventsModal = ({ bookingId = null, customerId = null, onClose }) => {
  const dispatch = useDispatch();

  const [events, setEvents] = useState(null);
  const [loading, setLoading] = useState(true);
  const hasEvents = events?.length > 0;

  useEffect(() => {
    if (bookingId) {
      dispatch(fetchBookingEvents(bookingId))
        .then(events => setEvents(events))
        .finally(() => setLoading(false));
    }
    if (customerId) {
      dispatch(fetchCustomerEvents(customerId))
        .then(events => setEvents(events))
        .finally(() => setLoading(false));
    }
  }, [bookingId, customerId]);

  return (
    <ModalDialog
      onClose={onClose}
      contentSize="xl"
      mobileFullScreen
      alignTop
    >
      <div>
        <div className="pull-right">
          <DialogTitleBtn onClick={onClose}>Stäng</DialogTitleBtn>
        </div>
        {bookingId && (
          <>
            <h4 className="mt0 mb0">Bokningshistorik</h4>
            <p className="text-muted">Bokningsnr. {bookingId}</p>
          </>
        )}
        {customerId && (
          <>
            <h4 className="mt0 mb0">Kundhistorik</h4>
            <p className="text-muted">Kundnr. {customerId}</p>
          </>
        )}
      </div>
      <div className="modal-dialog-flex-overflow">
        {loading && <Loader />}
        {!loading && hasEvents && (
          events.map((item) => <HistoryEvent key={item.eventTs} {...item} />)
        )}
        {!loading && !hasEvents && (
          <p className="text-center mt4 mb4">
            Det finns ingen historik
          </p>
        )}
      </div>
      <div className="text-right">
        <Button gray small onClick={onClose}>Stäng</Button>
      </div>
    </ModalDialog>
  );
};

export default EventsModal;
