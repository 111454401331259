import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addResource } from '@State/resource-actions';
import { hasPermission, VIEW_ALL_BOOKINGS } from '@Utils/permissions';
import { logMeOut } from '@Utils/account';

const stylesPadding = { paddingRight: 0 };

class NoResourcePrompts extends Component {
  constructor(props) {
    super(props);

    this.state = this.makeInitialState(props);
  }

  makeInitialState(props) {
    return {
      person: ''
    };
  }

  render() {
    const content = this.contentOrNot();

    return (
      !content ? <div /> : <div className="dialog-container"><div className="dialog-content">{content}</div></div>
    );
  }

  contentOrNot() {
    const { resourceCount, showCalendar } = this.props;

    if (showCalendar && resourceCount === 0) {
      if (this.haveCreateAccess()) {
        return this.addResource();
      }
      return this.noAccess();
    }
    return false;
  }

  haveCreateAccess() {
    const { permissions } = this.props;
    return hasPermission(permissions, VIEW_ALL_BOOKINGS);
  }

  addResource() {
    const { person } = this.state;
    const buttonClass = person.length > 0 ? 'btn-save' : 'btn-save disabled';

    return (
      <div>
        <h2>Lägg till en resurs</h2>
        <hr />
        <p>
          För att använda Cliento behövs en eller flera resurser.
          En resurs är kopplad till ett inlogg och har en kalender,
          ett schema samt ett antal tjänster som kan bokas.
        </p>
        <br />
        <p>
          Skapa en resurs som vi kopplar till ditt inlogg:
        </p>
        <hr />
        <form autoComplete="off">
          <div className="row">
            <div className="col-xs-4" style={stylesPadding}>
              <input type="text" className="form-control" placeholder="Ditt namn" onChange={this.nameChanged} />
            </div>
            <div className="col-xs-4">
              <button onClick={this.addPerson} className={buttonClass}>Lägg till</button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  noAccess() {
    return (
      <div>
        <h2>Det finns ingen resurs kopplad till ditt inlogg</h2>
        <hr />
        <p>
          Det finns ingen resurs kopplad till ditt inlogg och du har inte tillräcklig behörighet för att skapa en.
          Vänligen kontakta administratören av kontot som kan hjälpa dig med detta.
        </p>
        <hr />
        <button onClick={this.logOut} className="button arrow">Logga ut</button>
      </div>
    );
  }

  addPerson = (e) => {
    e.preventDefault();
    const { person } = this.state;
    this.props.addResource(this.props.firstGroupId, person);
  };

  logOut = (e) => {
    e.preventDefault();
    logMeOut();
  };

  nameChanged = (event) => {
    this.setState({ person: event.target.value });
  };
}

NoResourcePrompts.propTypes = {
  permissions: PropTypes.array.isRequired,
  phoneMode: PropTypes.bool.isRequired,
  resourceCount: PropTypes.number.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const {
    mainViewState, resourcesById, orderedGroups, locationOptions
  } = state;
  const { org, loc, section } = ownProps.match.params;
  const resourceCount = resourcesById != null ? resourcesById.count() : 0;
  const firstGroupId = orderedGroups?.first(g => g.get('id') !== 0)?.get('id');
  const permissions = locationOptions.size > 0 ? locationOptions.get(`${org}/${loc}`).permissions : [];

  return {
    permissions,
    phoneMode: mainViewState.get('phoneMode'),
    resourceCount,
    showCalendar: section === 'calendar',
    firstGroupId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addResource: (groupId, name) => {
      dispatch(addResource({
        name,
        groupId,
        resourceType: 'Person'
      }));
    }
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(NoResourcePrompts));
