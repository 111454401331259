import React, { Component } from 'react';
import { connect } from 'react-redux';
import { txt } from '@Utils/i18n-util';
import { getColorForItem, getSaleSectionLabel, saleItemSort } from '@Utils/pos-utils';
import CurrencyUtil from '@Utils/currency-util';
import { pos } from '@Utils/preference-keys';
import { updateSaleItem, removeSaleItem, voidSale } from '@State/pos-actions';
import {
  getSalePartialPaid, getSaleItems, getSaleGrossAmount, getSaleTotalAmount,
  getSalePaidAmount, getSaleRemainingAmount, hasPrinterError, getSaleTransactions,
  hasSaleVatError, getSaleUnpaid, getSalePrePayments, hasSaleLowStock,
  getSaleCustomer, getPosUnitPrefs
} from '@State/pos-selectors';
import { getServiceColors } from '@State/calendar-selectors';
import { getProductGroups } from '@State/products-selectors';
import SaleToolbar from '@Components/pos/sale/sale-toolbar';
import SaleItem from '@Components/pos/sale/sale-item';
import EditSaleItemModal from '@Components/pos/sale/edit-sale-item-modal';
import SaleFooter from '@Components/pos/sale/pos-sale-total-footer';
import PosPayment from '@Components/pos/payment/pos-payment';
import NotificationBanner from '@Components/ui/notification-banner';
import ConfirmAddBooking from '@Components/pos/dialogs/confirm-add-booking';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import PosSaleCustomerGiftCard from './pos-sale-customer-gift-card';
import msg from './pos-sale.msg';

class PosSale extends Component {
  state = {
    showItemModal: null
  };

  showItemModal = (item) => {
    this.setState({ showItemModal: item });
  };

  hideItemModal = () => {
    this.setState({ showItemModal: null });
  };

  saveItem = (item) => {
    this.hideItemModal();
    return this.props.updateSaleItem(item);
  };

  onRemoveItem = (id) => {
    const { customer, items } = this.props;
    if (!customer && items.size === 1) {
      return this.props.voidSale();
    }
    return this.props.removeSaleItem(id);
  };

  render() {
    const { showItemModal } = this.state;
    const {
      routeParams, partialPaid, items, posUnit, grossAmount, totalAmount, paidAmount,
      remainingAmount, vatError, printerError, saleId, queuedBooking, transactions,
      products, productGroups, serviceColors, prePayments, lowStock, posUnitPrefs, customer
    } = this.props;
    const totalValue = CurrencyUtil.accountCurrency(totalAmount, 2);
    const paidAmountValue = CurrencyUtil.accountCurrency(paidAmount, 2);
    const remainingAmountValue = CurrencyUtil.accountCurrency(remainingAmount, 2);
    const showLowStock = posUnitPrefs[pos.lowStockQuantityWarning];
    const showBookingNote = posUnitPrefs[pos.showBookingNoteInSale];
    const isSaleEmpty = items.isEmpty();
    const groupedItems = items.groupBy(i => i.get('bookingId') ?? null)
      .toOrderedMap().sortBy((v, bookingId) => -bookingId);
    const showLabels = groupedItems.keySeq().some(key => key);

    return (
      <div className="pos-sale">
        <SaleToolbar
          routeParams={routeParams}
          partialPaid={partialPaid}
          isSaleEmpty={isSaleEmpty}
        />
        {showLowStock && lowStock && (
          <NotificationBanner warning text={txt(msg.txtLowStock)} />
        )}
        <div className="pos__selected-products">
          <PosSaleCustomerGiftCard />
          {customer && items.isEmpty() && (
            <AlertWithIcon warning center>
              <strong>{txt(msg.lblNoArticles)}</strong>
            </AlertWithIcon>
          )}
          {groupedItems.entrySeq().map(([group, children]) => {
            const booking = group ? children.first() : null;
            return (
              <section key={group || '0'}>
                {showLabels && (
                  <label className="section-label">
                    {booking ? getSaleSectionLabel(booking) : txt(msg.lblOther)}
                  </label>
                )}
                {showBookingNote && booking?.get('bookingNote') && (
                  <AlertWithIcon warning icon="far fa-exclamation-circle">
                    {booking.get('bookingNote')}
                  </AlertWithIcon>
                )}
                {children.sort(saleItemSort).map(item => (
                  <SaleItem
                    key={item.get('id') || item.get('idLocal')}
                    item={item.toJS()}
                    products={products}
                    partialPaid={partialPaid}
                    showLowStock={showLowStock}
                    color={getColorForItem(item, products, productGroups, serviceColors)}
                    grossAmount={grossAmount}
                    onClick={this.showItemModal}
                    onRemove={this.onRemoveItem}
                  />
                ))}
              </section>
            );
          })}
        </div>
        <div>
          <SaleFooter
            totalValue={totalValue}
            paidAmountValue={paidAmountValue}
            remainingAmountValue={remainingAmountValue}
            prePayments={prePayments}
            transactions={transactions}
          />
          {vatError && (
            <NotificationBanner danger text={txt(msg.txtVatError)} />
          )}
          {printerError && (
            <NotificationBanner warning text={txt(msg.txtPrinterError)} />
          )}
          {posUnit.get('demo') && (
            <NotificationBanner text={txt(msg.txtDemoMode)} />
          )}
          <PosPayment disabled={isSaleEmpty || vatError} />
        </div>
        {saleId && queuedBooking && (
          <ConfirmAddBooking />
        )}
        {showItemModal && (
          <EditSaleItemModal
            item={showItemModal}
            onSave={this.saveItem}
            onClose={this.hideItemModal}
            grossAmount={grossAmount}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  products: state.products,
  productGroups: getProductGroups(state),
  serviceColors: getServiceColors(state),
  posUnit: state.pos.get('posUnit'),
  saleId: state.posSale.get('id'),
  queuedBooking: state.pos.get('queuedBooking'),
  partialPaid: getSalePartialPaid(state),
  items: getSaleItems(state),
  grossAmount: getSaleGrossAmount(state),
  totalAmount: getSaleTotalAmount(state),
  paidAmount: getSalePaidAmount(state),
  remainingAmount: getSaleRemainingAmount(state),
  printerError: hasPrinterError(state),
  vatError: hasSaleVatError(state),
  lowStock: hasSaleLowStock(state),
  transactions: getSaleTransactions(state),
  prePayments: getSalePrePayments(state),
  saleUnpaid: getSaleUnpaid(state),
  posUnitPrefs: getPosUnitPrefs(state),
  customer: getSaleCustomer(state)
});

const mapDispatchToProps = dispatch => ({
  updateSaleItem: item => dispatch(updateSaleItem(item)),
  removeSaleItem: itemId => dispatch(removeSaleItem(itemId)),
  voidSale: () => dispatch(voidSale())
});

export default connect(mapStateToProps, mapDispatchToProps)(PosSale);
