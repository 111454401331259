import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setBKFProp } from '@State/bkf/actions';
import { txt } from '@Utils/i18n-util';
import LabelSwitchInput from '@Components/ui/label-switch-input';
import ScheduleSegment from './schedule-segment';
import BookingAllDay from './booking-all-day';
import msg from './booking-form.msg';

class ScheduleException extends Component {
  handleAllDayClick = () => {
    this.props.onAllDay();
  };

  handleTypeChange = (value) => {
    this.props.setExceptionType(value);
  };

  handleSwitchToggle = () => {
    const { openType } = this.props;
    const newValue = openType !== 'Overtime' ? 'Overtime' : null;
    this.props.setOpenType(newValue);
  };

  render() {
    const { exceptionType, openType, isAllDay } = this.props;
    return (
      <>
        <ScheduleSegment currentType={exceptionType} onTypeChange={this.handleTypeChange} />
        {exceptionType === 'open' ? (
          <div className="booking-form-switch">
            <LabelSwitchInput
              noBorder
              label={txt(msg.lblOvertime)}
              id="Overtime"
              isOn={openType === 'Overtime'}
              handleToggle={this.handleSwitchToggle}
            />
          </div>
        ) : (
          <div className="booking-form-switch">
            <BookingAllDay isSchedule isAllDay={isAllDay} onAllDay={this.handleAllDayClick} />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf, schedulesByResource } = state;

  return {
    schedulesByResource,
    startTime: bkf.get('startTime'),
    resourceId: bkf.get('resourceId'),
    exceptionType: bkf.get('exceptionType'),
    openType: bkf.get('openType')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setExceptionType: (exceptionType) => {
      dispatch(setBKFProp('exceptionType', exceptionType));
    },
    setOpenType: (openType) => {
      dispatch(setBKFProp('openType', openType));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleException);
