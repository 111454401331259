import React, { PureComponent, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { TitleEmptyDataStyle } from '@Components/pos/style';
import { Redirect } from 'react-router';

import {
  fetchReceipts, fetchReceipt, printReceiptCopy, sendReceiptCopy
} from '@State/pos-actions';
import CurrencyUtil from '@Utils/currency-util';
import Loader from '@Components/ui/loader';
import SendReceiptForm from '@Components/pos/receipts/send-receipt-form';
import Receipt from '@Components/pos/receipts/receipt';
import PosPaymentMethod from '@Components/pos/pos-payment-method';
import DateRangePicker from '@Components/reports/date-range-picker';
import { navigate, getSectionUrl } from '@Utils/navigate';
import ColumnListItem from '@Components/ui/column/column-list-item';

const FORMAT_DATE = 'D MMMM YYYY';

class Receipts extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      modal: null,
      loading: true,
      openedItems: [],
      startDate: moment().subtract(1, 'month').startOf('month'),
      endDate: moment().endOf('month')
    };
  }

  UNSAFE_componentWillReceiveProps({ id, receipts, selectedReceipt }) {
    if (id && (id !== this.props.id || selectedReceipt.size === 0)) {
      this.props.fetchReceipt(id);
    }

    if (selectedReceipt.size > 0) {
      const selectedItem = receipts && receipts.find(r => r.get('receiptId') === this.props.id);
      if (selectedItem) {
        const openedItems = [...this.state.openedItems, this.formatDate(selectedItem.get('createdTs'))];
        this.setState({ openedItems });
      }
    }
  }

  componentDidMount() {
    const { id, fetchReceipt } = this.props;
    Promise.all([
      id && fetchReceipt(id),
      this.fetchReceipts()
    ]).then(() => this.setState({ loading: false }));
  }

  formatDate = (value) => moment(value).format(FORMAT_DATE);

  showModal = (modal) => {
    this.setState({ modal });
  };

  hideModal = () => {
    this.setState({ modal: null });
  };

  itemClick = id => {
    navigate(getSectionUrl(this.props.routeParams, 'pos/receipts', id));
  };

  fetchReceipts = () => {
    const { startDate, endDate } = this.state;
    this.props.fetchReceipts(startDate, endDate);
  };

  printReceipt = () => {
    const { selectedReceipt } = this.props;
    const receiptId = selectedReceipt.get('receiptId');
    return this.props.printReceiptCopy(receiptId);
  };

  sendReceipt = ({ email }) => {
    const { selectedReceipt } = this.props;
    const receiptId = selectedReceipt.get('receiptId');
    return this.props.sendReceiptCopy(receiptId, email);
  };

  handleDatesChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate }, this.fetchReceipts);
  };

  handleClickHeader = (group) => {
    const { openedItems } = this.state;
    let newItems = [];
    if (openedItems.includes(group)) {
      newItems = openedItems.filter((item) => item !== group);
    } else {
      newItems = [...openedItems, group];
    }
    this.setState({
      openedItems: newItems
    });
  };

  render() {
    const { modal, loading, openedItems, startDate, endDate } = this.state;
    const {
      receipts, selectedReceipt, id, routeParams
    } = this.props;
    const grouped = receipts.groupBy(r => this.formatDate(r.get('createdTs')));

    if (loading) {
      return (
        <div className="pos-receipts">
          <Loader />
        </div>
      );
    }

    if (!grouped.isEmpty() && (!id || (id && !selectedReceipt))) {
      const idSeq = grouped.first().first().get('receiptId');
      return (
        <Redirect
          to={getSectionUrl(
            routeParams,
            'pos/receipts',
            idSeq
          )}
        />
      );
    }

    return (
      <div className="pos-receipts">
        <div className="columns-container">
          <div className="columns-sidebar">
            <div className="columns-header">
              <h1>Kvitton</h1>
            </div>
            <div className="columns-date-picker">
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onDatesChange={this.handleDatesChange}
              />
            </div>
            <div className="columns-list">
              {grouped && grouped.size === 0
                ? <TitleEmptyDataStyle>Det finns inga kvitton att visa</TitleEmptyDataStyle>
                : grouped.entrySeq().map(([group, results]) => {
                  const isSelectedGroup = openedItems.includes(group);
                  const classListIcon = isSelectedGroup ? 'fa fa-fw fa-chevron-down' : 'fa fa-fw fa-chevron-right';
                  const handleOnClick = () => this.handleClickHeader(group);
                  return (
                    <Fragment key={group}>
                      <div className="columns-list-header" onClick={handleOnClick}>
                        <div>
                          <i className={classListIcon} />
                          <span>{group}</span>
                        </div>
                      </div>
                      {isSelectedGroup && results && results.map(receipt => {
                        const receiptId = receipt.get('receiptId');
                        const amount = receipt.get('amount');
                        const paymentMethods = receipt.get('paymentMethods');
                        const isSelected = receiptId === id;

                        return receipt && (
                          <ColumnListItem
                            key={receiptId}
                            selected={isSelected}
                            onClick={() => this.itemClick(receiptId)}
                            icon={(
                              <PosPaymentMethod isSelected={isSelected} methods={paymentMethods} />
                            )}
                            header={CurrencyUtil.accountCurrency(amount, 2)}
                            subHeader={`kl ${moment(receipt.get('createdTs')).format('LT')}`}
                          />
                        );
                      })}
                    </Fragment>
                  );
                })}
            </div>
          </div>
          <div className="columns-content">
            <div className="columns-content-container width-large">
              <div className="columns-content-body with-padding select-text">
                <Receipt
                  receiptId={id}
                  receipt={selectedReceipt}
                  routeParams={routeParams}
                  handleSelectReceipt={this.itemClick}
                  showSendReceipt={() => this.showModal('sendReceipt')}
                  printReceipt={this.printReceipt}
                />
              </div>
            </div>
          </div>
        </div>
        {modal === 'sendReceipt' && (
          <SendReceiptForm
            onClose={this.hideModal}
            onSubmit={this.sendReceipt}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  id: parseInt(props.routeParams.id),
  receipts: state.posReceipts,
  selectedReceipt: state.posReceipt
});

const mapDispatchToProps = dispatch => ({
  fetchReceipts: (startDate, endDate) => dispatch(fetchReceipts(startDate, endDate)),
  fetchReceipt: (receiptId) => dispatch(fetchReceipt(receiptId)),
  printReceiptCopy: (receiptId) => dispatch(printReceiptCopy(receiptId)),
  sendReceiptCopy: (receiptId, email) => dispatch(sendReceiptCopy(receiptId, email))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Receipts);
