import React from 'react';
import { connect } from 'react-redux';
import { routeParams, getTrialStatus, getAccountStatus } from '@State/selectors';
import { getOrganisationsUrl } from '@Utils/navigate';
import { actionRequired } from '@Utils/account';

const Subscription = ({ actionRequired, routeParams }) => {
  return (
    <div className="subnav-container center">
      <div>
        <img src="/subscription.svg" width={300} />
        <p className="lead">
          På vår abonnemangssida kan du hämta kvitton, se information om <br />
          ditt abonnemang samt ändra betalsätt.
          <i className="fas chevron-right" />
        </p>
        {actionRequired && (
          <p className="text-danger">
            <strong>Ett fel har uppstått vid betalning, vänligen kontrollera era betalningsuppgifter.</strong>
          </p>
        )}
        <br />
        <a href={getOrganisationsUrl(routeParams)} className="btn btn-modern btn-xl">
          Gå till kvitton, betalning, m.m. <i className="fa fa-chevron-right" style={{ marginLeft: 10 }} />
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state, { match }) => {
  const trialStatus = getTrialStatus(state);
  const accountStatus = getAccountStatus(state);

  return {
    actionRequired: actionRequired(trialStatus, accountStatus),
    routeParams: routeParams(match.params)
  };
};

export default connect(mapStateToProps)(Subscription);
