import moment from 'moment';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import CurrencyUtil from '@Utils/currency-util';
import { fetchCustomerGiftCards } from '@State/customer-actions';
import { updateGiftCard, voidGiftCard } from '@State/gift-card-actions';
import { PanelWrapper, RowFormGroups } from '@Components/customers/customer-styles';
import { Button, Column, FormGroup } from '@Components/ui/styled/main';
import GiftCardEditModal from '@Components/admin/pos-config/gift-cards/gift-card-edit-modal';

const CustomerGiftCardDetails = ({ hasPosPermission, giftCard }) => {
  const dispatch = useDispatch();
  const [showEditGiftCard, setShowEditGiftCard] = useState(false);
  const {
    code, soldTs, noRedeemAfterTs, initialValue, remainingValue,
    description, beneficiary, comment, customerId
  } = giftCard || {};

  const handleVoidGiftCard = (id) => {
    return dispatch(voidGiftCard(id))
      .then(() => dispatch(fetchCustomerGiftCards(customerId)))
      .then(() => setShowEditGiftCard(false));
  };

  const handleEditGiftCard = (id, values) => {
    return dispatch(updateGiftCard(id, values))
      .then(() => dispatch(fetchCustomerGiftCards(customerId)))
      .then(() => setShowEditGiftCard(false));
  };

  return (
    <PanelWrapper defaultCursor>
      <RowFormGroups>
        <Column>
          <FormGroup labelText="Kod" text={code} />
        </Column>
        {hasPosPermission && (
          <Button tiny white onClick={() => setShowEditGiftCard(true)}>Ändra</Button>
        )}
      </RowFormGroups>
      <RowFormGroups>
        <Column>
          <FormGroup labelText="Värde" text={CurrencyUtil.accountCurrency(initialValue)} />
        </Column>
        <Column>
          <FormGroup labelText="Värde kvar" text={CurrencyUtil.accountCurrency(remainingValue)} />
        </Column>
      </RowFormGroups>
      <RowFormGroups>
        <Column>
          <FormGroup
            labelText="Sålt"
            text={soldTs ? moment(soldTs).format('ll') : '-'}
          />
        </Column>
        <Column>
          <FormGroup
            labelText="Giltigt t.o.m."
            text={noRedeemAfterTs ? moment(noRedeemAfterTs).subtract(1, 'day').format('ll') : '-'}
          />
        </Column>
      </RowFormGroups>
      <FormGroup labelText="Beskrivning" text={description || '-'} />
      <FormGroup labelText="Förmånstagare" text={beneficiary || '-'} />
      <FormGroup labelText="Kommentar" text={comment || '-'} />

      {showEditGiftCard && (
        <GiftCardEditModal
          giftCard={giftCard}
          onVoid={handleVoidGiftCard}
          onSubmit={handleEditGiftCard}
          onClose={() => setShowEditGiftCard(false)}
        />
      )}
    </PanelWrapper>
  );
};

export default CustomerGiftCardDetails;
