import { defineMessages } from 'react-intl';

export default defineMessages({
  btnLock: {
    id: 'pos.header.btnLock',
    defaultMessage: 'Lock'
  },
  btnUnlock: {
    id: 'pos.header.btnUnlock',
    defaultMessage: 'Unlock'
  },
  lblLoggedOut: {
    id: 'pos.header.lblLoggedOut',
    defaultMessage: 'Logged out'
  },
  lblCashier: {
    id: 'pos.header.lblCashier',
    defaultMessage: 'Cashier: {cashier}'
  },
  lblPayment: {
    id: 'pos.header.lblPayment',
    defaultMessage: 'Payment'
  },
  lblReceipts: {
    id: 'pos.header.lblReceipts',
    defaultMessage: 'Receipts'
  },
  lblReports: {
    id: 'pos.header.lblReports',
    defaultMessage: 'Reports'
  },
  btnCashInOut: {
    id: 'pos.header.btnExchange',
    defaultMessage: 'Cash in/out'
  },
  btnOpenDrawer: {
    id: 'pos.header.btnOpenDrawer',
    defaultMessage: 'Open drawer'
  },
  btnClose: {
    id: 'pos.header.btnClose',
    defaultMessage: 'Close POS'
  },
  btnFixReceipt: {
    id: 'pos.header.btnFixReceipt',
    defaultMessage: 'Fix receipt'
  }
});
