import { defineMessages } from 'react-intl';

export default defineMessages({
  lblSchedules: {
    id: 'admin.schedules.lblSchedules',
    defaultMessage: 'Schedules'
  },
  lblMainSchedule: {
    id: 'admin.schedules.lblMainSchedule',
    defaultMessage: 'Main schedule'
  },
  lblSharedSchedule: {
    id: 'admin.schedules.lblSharedSchedule',
    defaultMessage: 'Shared schedule'
  },
  lblIndividual: {
    id: 'admin.schedules.lblIndividual',
    defaultMessage: 'Individual'
  },
  btnUseLocationSchedule: {
    id: 'admin.schedules.btnUseLocationSchedule',
    defaultMessage: 'Turn off individual schedule'
  },
  txtUseLocationSchedule: {
    id: 'admin.schedules.txtUseLocationSchedule',
    defaultMessage: 'All your schedules will be deleted. Do you want to continue?'
  },
  btnTurnOff: {
    id: 'admin.schedules.btnTurnOff',
    defaultMessage: 'Turn off'
  },
  lblExceptions: {
    id: 'admin.schedules.lblExceptions',
    defaultMessage: 'Exceptions'
  },
  lblHolidays: {
    id: 'admin.schedules.lblHolidays',
    defaultMessage: 'Holidays'
  },
  txtSharedSchedule1: {
    id: 'admin.schedules.txtSharedSchedule1',
    defaultMessage: 'The resource is using the shared schedule.'
  },
  txtSharedSchedule2: {
    id: 'admin.schedules.txtSharedSchedule2',
    defaultMessage: 'If you want to use an individual schedule instead, you can activate it here:'
  },
  btnUseIndividualSchedule: {
    id: 'admin.schedules.btnUseIndividualSchedule',
    defaultMessage: 'Use individual schedule'
  },
  txtAllIndividual1: {
    id: 'admin.schedules.txtAllIndividual1',
    defaultMessage: 'All resources are using individual schedules.'
  },
  txtAllIndividual2: {
    id: 'admin.schedules.txtAllIndividual2',
    defaultMessage: 'Select a resource in the list to change the schedule for that resource.'
  },
  txtNoExceptions1: {
    id: 'admin.schedules.txtNoExceptions1',
    defaultMessage: 'There are no exceptions'
  },
  txtNoExceptions2: {
    id: 'admin.schedules.txtNoExceptions2',
    defaultMessage: 'To add exceptions, click on the "Schedule" button in the calendar.'
  },
  txtOneOrMoreIndividual: {
    id: 'admin.schedules.txtOneOrMoreIndividual',
    defaultMessage: 'One or more resources are using individual schedules. Select a resource in the list to change the schedule for that resource.'
  },
  btnClose: {
    id: 'admin.schedules.btnClose',
    defaultMessage: 'Close'
  },
  btnCloseDay: {
    id: 'admin.schedules.btnCloseDay',
    defaultMessage: 'Close day'
  },
  txtCloseDay: {
    id: 'admin.schedules.txtCloseDay',
    defaultMessage: 'Confirm that you want to close the day'
  },
  lblClosed: {
    id: 'admin.schedules.lblClosed',
    defaultMessage: 'Closed'
  },
  btnOpen: {
    id: 'admin.schedules.btnOpen',
    defaultMessage: 'Open'
  },
  btnAddSchedule: {
    id: 'admin.schedules.btnAddSchedule',
    defaultMessage: 'Add schedule'
  }
});
