import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useCustomerSelect from '@Components/customers/useCustomerSelect';
import { addCustomerAssociation, fetchCustomerAssociations, removeCustomerAssociation } from '@State/customer-actions';
import { PanelWrapper, StyledLink, TitleBlock } from '@Components/customers/customer-styles';
import { txt } from '@Utils/i18n-util';
import ColumnListItem from '@Components/ui/column/column-list-item';
import { CustomerType, getCustomerTypeIcon } from '@Utils/customer-util';
import SearchCustomerModal from '@Components/customers/search-customer-modal';
import CustomerListSubtext from '@Components/customers/customer-list-subtext';
import PopoverDeleteButton from '@Components/ui/popover-delete-button';
import msg from './customer-information.msg';

const CustomerContact = ({ hasChangePermission, routeParams, selectedCustomer }) => {
  const dispatch = useDispatch();
  const onCustomerSelect = useCustomerSelect(routeParams);
  const { id, customerType } = selectedCustomer || {};
  const customerAssociations = useSelector((state) => state.customerAssociations);
  const loading = customerAssociations.get('loading');
  const associations = customerAssociations.get('associations');
  const associationType = customerType === CustomerType.Vehicle ? 'VehicleContact' : 'CompanyContact';
  const contact = associations.find((a) => a.associationType === associationType);

  useEffect(() => {
    if (id) {
      dispatch(fetchCustomerAssociations(id));
    }
  }, [id]);

  const [showCustomerContactModal, setShowCustomerContactModal] = useState(false);
  const hideCustomerContactModal = () => setShowCustomerContactModal(false);

  const handleSetCustomerContact = ({ customerId }) => {
    return dispatch(addCustomerAssociation(id, customerId, associationType))
      .then(hideCustomerContactModal);
  };

  const handleRemoveCustomerContact = () => {
    return dispatch(removeCustomerAssociation(id, contact.otherCustomerId));
  };

  if (!contact && !hasChangePermission) {
    return null;
  }

  return (
    <>
      <TitleBlock>{txt(msg.lblContactPerson)}</TitleBlock>
      {contact ? (
        <ColumnListItem
          key={contact.otherCustomerId}
          onClick={() => onCustomerSelect(contact)}
          icon={getCustomerTypeIcon(CustomerType.Person)}
          header={contact.name}
          subText={<CustomerListSubtext customer={contact} />}
          buttons={hasChangePermission && (
            <PopoverDeleteButton
              onDelete={handleRemoveCustomerContact}
              buttonText={<i className="fa fa-trash" />}
              confirmText={`Ta bort ${contact.name} som kontaktperson?`}
              deleteText="Ta bort"
              white
            />
          )}
        />
      ) : (
        <PanelWrapper onClick={() => setShowCustomerContactModal(true)}>
          <StyledLink>{txt(msg.lblAddContact)}</StyledLink>
        </PanelWrapper>
      )}
      {showCustomerContactModal && (
        <SearchCustomerModal
          routeParams={routeParams}
          onSelected={handleSetCustomerContact}
          onCancel={hideCustomerContactModal}
          scope={[CustomerType.Person]}
        />
      )}
    </>
  );
};

export default CustomerContact;
