import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import Loader from '@Components/ui/loader';

import { fetchStaff } from '@State/staff-actions';
import { getStaffUrl, navigate, getSectionUrl } from '@Utils/navigate';
import StaffContext from '@Components/staff/staff-context';
import StaffList from '@Components/staff/staff-list';
import StaffUserDetail from '@Components/staff/staff-user-detail/staff-user-detail';
import StaffReport from '@Components/staff/staff-report';
import { txt } from '@Utils/i18n-util';
import msg from './staff.msg';

const Staff = ({ match }) => {
  const dispatch = useDispatch();
  const routeParams = match.params;
  const context = useMemo(() => ({ routeParams }), [routeParams]);

  const staffState = useSelector(({ staffState }) => staffState.filter(s => {
    return s.get('showInStaffJournal') && !s.get('deleted');
  }));
  const [loading, setLoading] = useState(true);

  const getUserFromState = () => staffState.find((user) => user.get('id') === parseInt(routeParams.id));
  const [user, setUser] = useState(getUserFromState());

  const fetchStaffAsync = async () => {
    try {
      await dispatch(fetchStaff());
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading) {
      fetchStaffAsync();
    }
  }, []);

  useEffect(() => {
    setUser(getUserFromState());
  }, [staffState, routeParams.id]);

  const onRedirect = useCallback(() => navigate(getSectionUrl(routeParams, 'admin', 'users')), []);

  if (loading) {
    return (
      <div className="section-content">
        <div className="columns-container">
          <div className="columns-content">
            <div className="columns-content-container width-large">
              <Loader />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (routeParams.id === 'report') {
    return (
      <div className="section-content">
        <div className="columns-container">
          <div className="columns-content">
            <div className="columns-content-container width-large">
              <div className="columns-content-body with-padding">
                <StaffReport />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (staffState && staffState.size > 0 && !routeParams.id) {
    return (
      <Redirect to={getStaffUrl(routeParams, staffState.first().get('id'))} />
    );
  }

  return (
    <div className="columns-wrapper">
      <StaffContext.Provider value={context}>
        <div className="columns-container">
          <div className="columns-sidebar">
            <div className="columns-header">
              <h1>{txt(msg.lblListTitle)}</h1>
            </div>
            <StaffList onRedirect={onRedirect} list={staffState} />
          </div>
          <div className="columns-content">
            <div className="columns-content-container width-small">
              <div className="columns-content-body with-padding">
                {user && <StaffUserDetail user={user} />}
              </div>
            </div>
          </div>
        </div>
      </StaffContext.Provider>
    </div>
  );
};

export default Staff;
