import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import Button from '@Components/ui/button';
import SubmitButton from '@Components/ui/submit-button';
import { useSelector } from 'react-redux';
import { txt } from '@Utils/i18n-util';
import msg from './campaign-form.msg';

const CampaignButtonFooter = ({ isNew, step, setActiveStep, onClose }) => {
  const { watch, handleSubmit, formState: { isSubmitting } } = useFormContext();
  const [sendAtTime, id, accepted] = watch(['sendAtTime', 'id', 'accepted']);
  const campaign = useSelector(state => state?.campaignViewState?.get('campaigns')?.find(it => +it.get('id') === +id));
  const hasRecipients = campaign && campaign.get('recipientCount') > 0;

  const submitDisabled = step === 4 && (!hasRecipients || !accepted);
  const sendBtnText = sendAtTime !== 'now' ? txt(msg.btnSchedule) : txt(msg.btnSend);

  const handleBack = useCallback(() => {
    setActiveStep(s => s - 1);
  }, [step]);

  return (
    <div className="campaign-modal-footer">
      <div>
        {(isNew || step > 1) && (
          <Button gray small marginRight onClick={step === 0 ? onClose : handleBack}>
            {step === 0 ? txt(msg.btnCancel) : txt(msg.btnBack)}
          </Button>
        )}
      </div>
      <div>
        {step > 0 && (
          <Button gray small marginRight onClick={onClose} type="button">
            {txt(msg.btnCancel)}
          </Button>
        )}
        <SubmitButton primary small type="submit" disabled={submitDisabled} onClick={handleSubmit} submitting={isSubmitting}>
          {step === 4 ? sendBtnText : txt(msg.btnContinue)}
        </SubmitButton>
      </div>
    </div>
  );
};

export default CampaignButtonFooter;
