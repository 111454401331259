import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyUtil from '@Utils/currency-util';
import { getPosOrgPrefs } from '@State/pos-selectors';
import { getPaymentMethodName } from '@Utils/pos-utils';
import { fetchState, revertGiftCardPayment } from '@State/pos-actions';
import ConfirmPopover from '@Components/ui/confirm-popover';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import ModalDialog from '@Components/dialogs/modal-dialog';
import PosPaymentMethod from '../pos-payment-method';

const PosSaleTransactionsModal = ({ transactions, prePayments, onClose }) => {
  const dispatch = useDispatch();
  const posOrgPrefs = useSelector(getPosOrgPrefs);
  const saleId = useSelector(state => state.posSale.get('id'));
  const [showConfirmReverse, setShowConfirmReverse] = useState(null);

  const hasTransactions = !transactions?.isEmpty();
  const hasPrePayments = prePayments?.size > 0;

  const onShowConfirmReverse = (ev, id) => {
    ev.preventDefault();
    setShowConfirmReverse(id);
  };

  const onReverseTransaction = (id) => {
    return dispatch(revertGiftCardPayment(saleId, id))
      .then(() => dispatch(fetchState()))
      .then(() => onClose());
  };

  return (
    <ModalDialog
      contentSize="medium"
      title="Transaktioner"
      closeButtonText="Stäng"
      onClose={onClose}
      closeOnClickOutside={false}
      focusTrapPaused
    >
      <AlertWithIcon>
        Visar alla transaktioner för pågående försäljning.
      </AlertWithIcon>
      <div className="pos-transactions">
        {hasPrePayments && prePayments.map((payment) => (
          <div className="pos-transaction" key={payment.get('transactionId')}>
            <p>
              <PosPaymentMethod method="PrepaidBankPayment" />
              {getPaymentMethodName('PrepaidBankPayment')}
            </p>
            <p>{CurrencyUtil.accountCurrency(payment.get('amount'), 2)}</p>
          </div>
        ))}
        {hasTransactions && transactions.map((transaction) => {
          const id = transaction.get('transactionId');
          const isGiftCard = transaction.get('paymentMethod') === 'Voucher';
          const paymentMethod = transaction.get('paymentMethodName') || transaction.get('paymentMethod');
          return (
            <div className="pos-transaction" key={id}>
              <p>
                <PosPaymentMethod method={paymentMethod} />
                {getPaymentMethodName(paymentMethod, posOrgPrefs)}
              </p>
              <p>
                {CurrencyUtil.accountCurrency(transaction.get('amount'), 2)}
                {isGiftCard && (
                  <ConfirmPopover
                    text="Ta bort betalning med presentkort?"
                    confirmText="Ta bort"
                    onClose={() => setShowConfirmReverse(null)}
                    onConfirm={() => onReverseTransaction(id)}
                    isOpen={showConfirmReverse === id}
                  >
                    <a href="#" onClick={(ev) => onShowConfirmReverse(ev, id)}>
                      <i className="fa fa-trash" />
                    </a>
                  </ConfirmPopover>
                )}
              </p>
            </div>
          );
        })}
      </div>
    </ModalDialog>
  );
};

export default PosSaleTransactionsModal;
