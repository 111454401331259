import React from 'react';
import { CustomerType } from '@Utils/customer-util';
import { formatPhoneNumber } from '@Utils/phone-util';
import { formatOrgNo } from '@Utils/luhn-util';

const CustomerListSubtext = ({ customer }) => {
  const {
    customerType, phoneNumber, otherPhoneNumber, email, name, officialIdNo
  } = customer;
  const phoneNumbers = [phoneNumber, otherPhoneNumber]
    .filter(Boolean).map(formatPhoneNumber).join('/');

  return (
    <>
      {customerType === CustomerType.Person && phoneNumbers && <div>{phoneNumbers}</div>}
      {customerType === CustomerType.Person && email && <div>{email}</div>}
      {customerType === CustomerType.Vehicle && officialIdNo && name && <div>{name}</div>}
      {customerType === CustomerType.Company && officialIdNo && <div>{formatOrgNo(officialIdNo)}</div>}
    </>
  );
};

export default CustomerListSubtext;
