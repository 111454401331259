import React, { Component } from 'react';
import ModalDialog from '@Components/dialogs/modal-dialog';
import CurrencyUtil from '@Utils/currency-util';
import {
  maxLength, convertToFloat, getChange, isValidInput
} from '@Utils/numpad-util';
import Numpad from '@Components/inputs/numpad';
import CalcDisplay from '@Components/ui/calc-display';
import { txt } from '@Utils/i18n-util';
import msg from './pos-payment.msg';

class CashPayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cash: '',
      change: 0,
      isValid: false
    };
  }

  componentDidMount() {
    this.handleNumpadUpdate(this.state.cash);
  }

  handleNumpadUpdate = value => {
    if (isValidInput(value, maxLength)) {
      const { totalAmount } = this.props;
      const isValid = CurrencyUtil.isValidCashAmount(value) && (value > 0 || totalAmount === 0);
      const change = getChange(value, totalAmount);
      this.setState({ cash: value, change, isValid });
    }
  };

  onSubmit = () => {
    return this.props.onSubmit(convertToFloat(this.state.cash));
  };

  render() {
    const { totalAmount, onClose } = this.props;
    const { change, isValid } = this.state;
    const TotalAmount = CurrencyUtil.accountCurrency(totalAmount, 2);
    const AmountReceived = CurrencyUtil.accountCurrency(convertToFloat(this.state.cash), 2);
    const ChangeDue = CurrencyUtil.accountCurrency(change > 0 ? change : 0, 2);

    return (
      <ModalDialog
        title={txt(msg.lblToPay, { amount: TotalAmount })}
        alignTitle="left"
        onClose={onClose}
        closeOnClickOutside={false}
        buttons={[
          {
            name: 'OK',
            primary: true,
            onClick: this.onSubmit,
            disabled: !isValid
          }
        ]}
      >
        <CalcDisplay
          large
          align="center"
          label={txt(msg.lblChange, { amount: ChangeDue })}
          labelPosition="below"
          labelHighlight={change > 0}
        >
          {AmountReceived}
        </CalcDisplay>
        <Numpad
          onChange={this.handleNumpadUpdate}
          onEnter={isValid ? this.onSubmit : null}
          input={this.state.cash}
        />
      </ModalDialog>
    );
  }
}

export default CashPayment;
