import { defineMessages } from 'react-intl';

export default defineMessages({
  lblSearch: {
    id: 'pos.products.lblSearch',
    defaultMessage: 'Search...'
  },
  lblReset: {
    id: 'pos.products.lblReset',
    defaultMessage: 'Clear'
  },
  lblFilterResults: {
    id: 'pos.products.lblFilterResults',
    defaultMessage: 'Showing results for "{filter}"'
  },
  lblProducts: {
    id: 'pos.products.lblProducts',
    defaultMessage: 'Products'
  },
  lblServices: {
    id: 'pos.products.lblServices',
    defaultMessage: 'Services'
  },
  lblVouchers: {
    id: 'pos.products.lblVouchers',
    defaultMessage: 'Vouchers'
  }
});
