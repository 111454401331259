import { defineMessages } from 'react-intl';

export default defineMessages({
  btnNewCampaign: {
    id: 'campaign.header.btnNewCampaign',
    defaultMessage: 'New campaign'
  },
  lblListTitle: {
    id: 'campaign.list.lblListTitle',
    defaultMessage: 'Previous campaigns'
  },
  lblNoCampaigns: {
    id: 'campaign.list.lblNoCampaigns',
    defaultMessage: 'There are no previous campaigns'
  },
  lblDraft: {
    id: 'campaign.status.lblDraft',
    defaultMessage: 'Draft'
  },
  lblSent: {
    id: 'campaign.status.lblSent',
    defaultMessage: 'Sent'
  },
  lblNotSent: {
    id: 'campaign.status.lblNotSent',
    defaultMessage: 'Not sent'
  },
  lblDelivered: {
    id: 'campaign.status.lblDelivered',
    defaultMessage: 'Delivered'
  },
  lblPending: {
    id: 'campaign.status.lblPending',
    defaultMessage: 'Pending'
  },
  lblProcessing: {
    id: 'campaign.status.lblProcessing',
    defaultMessage: 'Processing'
  },
  lblCancelled: {
    id: 'campaign.status.lblCancelled',
    defaultMessage: 'Cancelled'
  },
  lblSystemCancelled: {
    id: 'campaign.status.lblSystemCancelled',
    defaultMessage: 'Cancelled by system'
  },
  lblFailed: {
    id: 'campaign.status.lblFailed',
    defaultMessage: 'Failed'
  },
  lblNewCampaign: {
    id: 'campaign.content.lblNewCampaign',
    defaultMessage: 'New campaign'
  },
  lblEmail: {
    id: 'campaign.content.lblEmail',
    defaultMessage: 'Email'
  },
  lblSms: {
    id: 'campaign.content.lblSms',
    defaultMessage: 'SMS'
  },
  lblAllCustomers: {
    id: 'campaign.content.lblAllCustomers',
    defaultMessage: 'All customers'
  },
  lblBookedCustomers: {
    id: 'campaign.content.lblBookedCustomers',
    defaultMessage: 'Booked customers'
  },
  lblNewCustomers: {
    id: 'campaign.content.lblNewCustomers',
    defaultMessage: 'New customers'
  },
  lblTopCustomers: {
    id: 'campaign.content.lblTopCustomers',
    defaultMessage: 'Top customers'
  },
  lblCustomerCreated: {
    id: 'campaign.content.lblCustomerCreated',
    defaultMessage: 'Customer created'
  },
  lblBookingCreated: {
    id: 'campaign.content.lblBookingCreated',
    defaultMessage: 'Booking created'
  },
  lblPurchaseDate: {
    id: 'campaign.content.lblPurchaseDate',
    defaultMessage: 'Purchase date'
  },
  lblBookingDate: {
    id: 'campaign.content.lblBookingDate',
    defaultMessage: 'Booking date'
  },
  lblOwnedByResource: {
    id: 'campaign.content.lblOwnedByResource',
    defaultMessage: 'Belongs to resource'
  },
  lblBookedAtResource: {
    id: 'campaign.content.lblBookedAtResource',
    defaultMessage: 'Booked at resource'
  },
  lblSender: {
    id: 'campaign.content.lblSender',
    defaultMessage: 'Sender'
  },
  lblSubject: {
    id: 'campaign.content.lblSubject',
    defaultMessage: 'Subject'
  },
  lblMessage: {
    id: 'campaign.content.lblMessage',
    defaultMessage: 'Message'
  },
  lblContent: {
    id: 'campaign.content.lblContent',
    defaultMessage: 'Content'
  },
  lblRecipients: {
    id: 'campaign.content.lblRecipients',
    defaultMessage: 'Recipients'
  },
  btnShowRecipients: {
    id: 'campaign.content.btnShowRecipients',
    defaultMessage: 'Show recipients'
  },
  btnClose: {
    id: 'campaign.content.btnClose',
    defaultMessage: 'Close'
  },
  btnEdit: {
    id: 'campaign.content.btnEdit',
    defaultMessage: 'Edit'
  },
  lblName: {
    id: 'campaign.content.lblName',
    defaultMessage: 'Name'
  },
  lblPhone: {
    id: 'campaign.content.lblPhone',
    defaultMessage: 'Phone'
  },
  lblStatus: {
    id: 'campaign.content.lblStatus',
    defaultMessage: 'Status'
  },
  lblSpentMore: {
    id: 'campaign.content.lblSpentMore',
    defaultMessage: 'Spent more than'
  },
  lblBookedService: {
    id: 'campaign.content.lblBookedService',
    defaultMessage: 'Booked service'
  },
  btnAddContent: {
    id: 'campaign.content.btnAddContent',
    defaultMessage: 'Add content'
  },
  lblAddContentWarning: {
    id: 'campaign.content.lblAddContentWarning',
    defaultMessage: 'You must add content before sending the message.'
  },
  btnCancel: {
    id: 'campaign.content.btnCancel',
    defaultMessage: 'Cancel'
  },
  lblConfirmCancel: {
    id: 'campaign.content.lblConfirmCancel',
    defaultMessage: 'Do you want to cancel the campaign?'
  },
  btnCancelNo: {
    id: 'campaign.content.btnCancelNo',
    defaultMessage: 'No'
  },
  btnSendSchedule: {
    id: 'campaign.content.btnSendSchedule',
    defaultMessage: 'Send/schedule'
  },
  lblCampaignScheduled: {
    id: 'campaign.content.lblCampaignScheduled',
    defaultMessage: 'Your campaign has been scheduled'
  },
  lblNoCampaignsText1: {
    id: 'campaign.content.lblNoCampaignsText1',
    defaultMessage: 'Get more bookings, create your first campaign today!'
  },
  lblNoCampaignsText2: {
    id: 'campaign.content.lblNoCampaignsText2',
    defaultMessage: 'Press New campaign to get started.'
  }
});
