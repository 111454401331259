import React, { Component } from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import CurrencyUtil from '@Utils/currency-util';
import { required } from '@Utils/validation';
import { getVatRates } from '@State/selectors';
import TextInput from '../inputs/text-input';
import SelectField from '../inputs/select-field';

class MultiVatForm extends Component {
  onAddRow = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    this.props.fields.push();
  };

  onDeleteRow = (ev, index) => {
    ev.preventDefault();
    this.props.fields.remove(index);
  };

  render() {
    const { vatRates } = this.props;
    return (
      <>
        <div className="horizontal-scroll-container">
          <table className="table-form tight">
            <tbody>
              {this.props.fields.map((member, index) => (
                <tr key={index}>
                  <td className="width-xl">
                    <Field
                      name={`${member}.description`}
                      component={TextInput}
                      label={index === 0 ? 'Beskrivning' : null}
                      validate={required}
                    />
                  </td>
                  <td className="width-md">
                    <Field
                      name={`${member}.price`}
                      component={TextInput}
                      label={index === 0 ? 'Pris' : null}
                      unitLabel={CurrencyUtil.currencySymbol()}
                      validate={required}
                    />
                  </td>
                  <td className="width-sm">
                    <Field
                      name={`${member}.vatPct`}
                      component={SelectField}
                      label={index === 0 ? 'Moms' : null}
                      validate={required}
                    >
                      <option value="" />
                      {vatRates.map(vat => <option key={vat} value={vat}>{`${vat}%`}</option>)}
                    </Field>
                  </td>
                  <td className="width-xs">
                    <Field
                      name={`${member}.COACode`}
                      component={TextInput}
                      label={index === 0 ? 'Konto' : null}
                    />
                  </td>
                  <td className="width-sm">
                    <Field
                      name={`${member}.costCenter`}
                      component={TextInput}
                      label={index === 0 ? 'KS' : null}
                    />
                  </td>
                  <td className="width-xs">
                    <Field
                      name={`${member}.reportingQuantity`}
                      component={TextInput}
                      label={index === 0 ? 'Enheter' : null}
                    />
                  </td>
                  <td>
                    {index !== 0 && (
                      <p className="form-control-static">
                        <a href="#" className="text-muted" onClick={(ev) => this.onDeleteRow(ev, index)}>
                          <i className="fa fa-times-circle" />
                        </a>
                      </p>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <a href="#" onClick={this.onAddRow}>
          + Lägg till rad
        </a>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    vatRates: getVatRates(state)
  };
};

export default connect(mapStateToProps)(MultiVatForm);
