import { defineMessages } from 'react-intl';

export default defineMessages({
  noPaymentsTitle: {
    id: 'customers.sales.noPaymentsTitle',
    defaultMessage: 'No payments'
  },
  noPaymentsText: {
    id: 'customers.sales.noPaymentsText',
    defaultMessage: 'There are no payments for this customer'
  },
  lblDetails: {
    id: 'customers.sales.lblDetails',
    defaultMessage: 'Details'
  },
  lblService: {
    id: 'customers.sales.lblService',
    defaultMessage: '{count} service'
  },
  lblServices: {
    id: 'customers.sales.lblServices',
    defaultMessage: '{count} services'
  },
  lblProduct: {
    id: 'customers.sales.lblProduct',
    defaultMessage: '{count} product'
  },
  lblProducts: {
    id: 'customers.sales.lblProducts',
    defaultMessage: '{count} products'
  },
  lblOnlinePayment: {
    id: 'customers.sales.lblOnlinePayment',
    defaultMessage: 'Online payment'
  },
  lblPosReceipt: {
    id: 'customers.sales.lblPosReceipt',
    defaultMessage: 'Receipt'
  },
  lblLocation: {
    id: 'customers.sales.lblLocation',
    defaultMessage: 'Location'
  },
  lblCashier: {
    id: 'customers.sales.lblCashier',
    defaultMessage: 'Cashier'
  },
  lblReceiptNo: {
    id: 'customers.sales.lblReceiptNo',
    defaultMessage: 'Receipt #'
  },
  lblDate: {
    id: 'customers.sales.lblDate',
    defaultMessage: 'Date'
  },
  lblSpecification: {
    id: 'customers.sales.lblSpecification',
    defaultMessage: 'Specification'
  },
  lblTransactions: {
    id: 'customers.sales.lblTransactions',
    defaultMessage: 'Transactions'
  },
  lblInvoice: {
    id: 'customers.sales.lblInvoice',
    defaultMessage: 'Invoice'
  },
  lblInvoiceNo: {
    id: 'customers.sales.lblInvoiceNo',
    defaultMessage: 'Invoice number'
  },
  lblStatus: {
    id: 'customers.sales.lblStatus',
    defaultMessage: 'Status'
  },
  lblInvoiceDate: {
    id: 'customers.sales.lblInvoiceDate',
    defaultMessage: 'Invoice date'
  },
  lblDueDate: {
    id: 'customers.sales.lblDueDate',
    defaultMessage: 'Due date'
  },
  btnDownloadInvoice: {
    id: 'customers.sales.btnDownloadInvoice',
    defaultMessage: 'Download invoice'
  },
  lblTotal: {
    id: 'customers.sales.lblTotal',
    defaultMessage: 'Total'
  },
  btnClose: {
    id: 'customers.sales.btnClose',
    defaultMessage: 'Close'
  }
});
