import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFilteredResourceGroups, getFilteredResources } from '@Utils/filter-selectors';
import { getGridProps } from '@Components/calendar/grid/grid-selectors';
import GridScroller from '@Components/calendar/grid/grid-scroller';
import { getPermissions } from '@State/selectors';
import { getResourcesInView } from '@State/calendar-selectors';
import { getResourceEntityType, navigate } from '@Utils/navigate';
import { fetchGroupsAndResources } from '@State/resource-actions';
import ResourceList from './resource-list';

class ResourceSelector extends Component {
  static propTypes = {
    permissions: PropTypes.array.isRequired,
    orderedGroups: PropTypes.object.isRequired,
    resourcesById: PropTypes.object.isRequired,
    entityType: PropTypes.oneOf(['my-resource', 'resource', 'group']),
    entityId: PropTypes.number.isRequired,
    resourceTargetUrlResolver: PropTypes.func.isRequired,
    groupTargetUrlResolver: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.loadData().then(() => this.loadingCompleted());
  }

  loadingCompleted = () => {
    const {
      section, entityType, entityId, resourcesById, orderedGroups
    } = this.props;

    if (section !== 'calendar') {
      return;
    }

    if (entityType === 'group') {
      const group = orderedGroups.find(g => g.get('id') === parseInt(entityId));

      if (group == null) {
        if (orderedGroups.first()) {
          this.changeGroup(orderedGroups.first().get('id'));
        }
      }
    } else {
      if (!resourcesById.has(parseInt(entityId))) {
        let resId = null;
        let groupId = null;
        orderedGroups.find((g) => {
          if (g.get('resourceIds').size > 0) {
            resId = g.get('resourceIds').first();
            groupId = g.get('id');
            return true;
          }
          return false;
        });
        const resourceEntityType = getResourceEntityType(groupId);
        this.changeResource(resId, resourceEntityType);
      }
    }
  };

  render() {
    const { orderedGroups, resourcesById, resourcesInView } = this.props;

    return (
      <div className="resource-selector" style={this.props.customStyle}>
        <ResourceList
          {...this.props}
          resources={resourcesById.toJS()}
          groups={orderedGroups.toJS()}
          scrollToFirstElement={this.scrollToFirstElement}
          onToggleCollapse={this.onToggleCollapse}
          resourcesInView={resourcesInView}
        />
      </div>
    );
  }

  onToggleCollapse = (id, collapse) => {
    const { collapsedIds = [] } = this.props;
    if (collapse) {
      const newCollapsedIds = [...collapsedIds, id];
      this.props.onToggleCollapse(newCollapsedIds);
    } else {
      const newCollapsedIds = [...collapsedIds];
      newCollapsedIds.splice(collapsedIds.indexOf(id), 1);
      this.props.onToggleCollapse(newCollapsedIds);
    }
  };

  scrollToFirstElement = (resourceId) => {
    const {
      gridProps, schedulesByResource, bookingsById, routeParams
    } = this.props;

    if (this.lastId === resourceId) {
      new GridScroller(gridProps, schedulesByResource, bookingsById, routeParams).scrollToFirstElement(true);
    }

    this.lastId = resourceId;
  };

  changeGroup = (groupId) => {
    navigate(this.props.groupTargetUrlResolver(groupId));
  };

  changeResource = (resId, resourceEntityType) => {
    navigate(this.props.resourceTargetUrlResolver(resId, resourceEntityType));
  };
}

const mapStateToProps = (state, ownProps) => {
  const { locationFeatures, calendarViewState } = state;
  const { section, entityType, entityId } = ownProps.routeParams;

  return {
    permissions: getPermissions(state, ownProps),
    locationFeatures,
    resourcesById: getFilteredResources(state, ownProps),
    orderedGroups: getFilteredResourceGroups(state, ownProps),
    section,
    entityType,
    entityId,
    gridProps: getGridProps(state, ownProps),
    schedulesByResource: state.schedulesByResource,
    bookingsById: state.bookingsById,
    collapsedIds: calendarViewState.get('resourceListCollapsedIds'),
    resourcesInView: getResourcesInView(state, ownProps)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadData: () => {
      return dispatch(fetchGroupsAndResources());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceSelector);
