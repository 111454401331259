import React from 'react';
import { connect } from 'react-redux';

import TextEllipsis from '@Components/ui/styled/text-ellipsis';
import HeaderSubText from '@Components/ui/styled/header-sub-text';

import { getPermissions, routeParams, getCalendarTitle } from '@State/selectors';
import { viewDateToStartEnd } from '@Utils/time-util';
import DateNavigator from './date-navigator';
import ViewModeSwitcher from './viewmode-switcher';
import CurrentDateLabel from './current-date-label';
import CalendarHeaderPhone from './calendar-header-phone';
import CalendarHeaderTable from './calendar-header-tablet';
import CalendarHeaderUserBlock from './calendar-header-user-block';
import CalendarHeaderToolbarButtons from './calendar-header-toolbar-buttons';

const CalendarHeader = ({
  routeParams, calendarTitle, gridClientWidth, phoneMode, tabletMode
}) => {
  const { viewMode, viewDate } = routeParams;
  const viewDateStartEnd = viewDateToStartEnd(viewMode, viewDate);

  const dateLabel = (
    <CurrentDateLabel
      viewMode={viewMode}
      phoneMode={phoneMode}
      tabletMode={tabletMode}
      viewDateStart={viewDateStartEnd.start}
      gridClientWidth={gridClientWidth}
    />
  );

  if (phoneMode) {
    return (
      <CalendarHeaderPhone
        dateLabel={dateLabel}
        routeParams={routeParams}
        viewDateStartEnd={viewDateStartEnd}
      />
    );
  }

  if (tabletMode) {
    return (
      <CalendarHeaderTable
        dateLabel={dateLabel}
        routeParams={routeParams}
        viewDateStartEnd={viewDateStartEnd}
        calendarTitle={calendarTitle}
      />
    );
  }

  return (
    <header className="section-header calendar-header">
      <div className="section-header-title title-block">
        <h1><TextEllipsis width={185}>{calendarTitle}</TextEllipsis></h1>
        <HeaderSubText>{dateLabel}</HeaderSubText>
      </div>
      <div className="section-header-content ui-btn-block">
        <div className="section-header-toolbar">
          <div className="ui-mar-right-auto ui-mar-left-sm">
            <DateNavigator routeParams={routeParams} viewDateStartEnd={viewDateStartEnd} />
          </div>
          <div className="ui-mar-right-auto">
            <ViewModeSwitcher routeParams={routeParams} />
          </div>
          <div className="ui-mar-right-auto ui-mar-top-toolbar">
            <CalendarHeaderToolbarButtons routeParams={routeParams} />
          </div>
        </div>
      </div>
      <CalendarHeaderUserBlock routeParams={routeParams} />
    </header>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { match, location } = ownProps;
  const { mainViewState, gridViewState } = state;

  const rp = routeParams(match.params, location);
  const permissions = getPermissions(state, { routeParams: rp });

  return {
    routeParams: rp,
    permissions,
    calendarTitle: getCalendarTitle(state, ownProps),
    phoneMode: mainViewState.get('phoneMode'),
    tabletMode: mainViewState.get('tabletMode'),
    gridClientWidth: gridViewState.get('gridClientWidth')
  };
};

export default connect(mapStateToProps)(CalendarHeader);
