import { defineMessages } from 'react-intl';

export default defineMessages({
  lblCashAmount: {
    id: 'pos.dialog.lblCashAmount',
    defaultMessage: 'Cash amount'
  },
  lblOpeningPos: {
    id: 'pos.dialog.lblOpeningPos',
    defaultMessage: 'Opening POS...'
  },
  btnOpenPos: {
    id: 'pos.dialog.btnOpenPos',
    defaultMessage: 'Open POS'
  },
  txtCashAmount: {
    id: 'pos.dialog.txtCashAmount',
    defaultMessage: 'Enter the amount in the cash drawer'
  }
});
