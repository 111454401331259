import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomerIdContext from '@Components/customers/customer-id-context';
import { saveCustomerComment, fetchCustomerLogEntries, deleteCustomerComment, updateCustomerComment } from '@State/customer-actions';
import CommentBox from '@Components/customers/customer-detail/logs/comment-box';
import CustomerDetailLogActions from '@Components/customers/customer-detail/logs/customer-detail-log-actions';
import CustomerEmptyTab from '@Components/customers/customer-detail/customer-empty-tab';
import Loader from '@Components/ui/loader';
import { sortEventByTimeAndReverse } from '@Utils/customer-util';
import { Row } from '@Components/ui/styled/main';
import { ScrollContent, ScrollWrapper, LogWrapper } from '@Components/customers/customer-styles';
import { txt } from '@Utils/i18n-util';
import msg from './customer-detail-logs.msg';

const CustomerDetailLogs = () => {
  const dispatch = useDispatch();
  const customerId = +useContext(CustomerIdContext);
  const state = useSelector((state) => state.customerLogEntries);

  const logs = useMemo(() => sortEventByTimeAndReverse(state.get('logs').toJS()), [state]);
  const loading = useMemo(() => state.get('loading'), [state]);

  const [selectedLog, setSelectedLog] = useState(null);

  const onSaveComment = (comment, files) => dispatch(saveCustomerComment(customerId, comment.trim(), files));
  const handleSelect = (id) => setSelectedLog(logs?.find((log) => log.id === id));

  useEffect(() => {
    dispatch(fetchCustomerLogEntries(customerId));
  }, [customerId]);

  return (
    <Row scrollContainer>
      <LogWrapper>
        <CommentBox
          newComment
          allowFiles
          comment=""
          onSave={onSaveComment}
        />
        {loading && <Loader topPosition />}
        {!loading && logs.length > 0 && (
          <ScrollWrapper>
            <ScrollContent>
              {logs.map(({ id, ...log }) => {
                return (
                  <CustomerDetailLogActions
                    key={id}
                    isSelected={id === selectedLog?.id}
                    onClick={() => handleSelect(id)}
                    onUpdate={updateCustomerComment}
                    onDelete={deleteCustomerComment}
                    customerId={customerId}
                    allowFiles
                    id={id}
                    {...log}
                  />
                );
              })}
            </ScrollContent>
          </ScrollWrapper>
        )}
        {!loading && logs.length === 0 && (
          <CustomerEmptyTab
            title={txt(msg.noLogsTitle)}
            text={txt(msg.noLogsText)}
          />
        )}
      </LogWrapper>
    </Row>
  );
};

export default CustomerDetailLogs;
