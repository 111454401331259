import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Route, Switch, Redirect } from 'react-router-dom';

import { routeParams } from '@State/selectors';
import { getSectionUrl } from '@Utils/navigate';
import { getAdminNavSections } from '@Components/admin/nav-selectors';

import SubNav from '@Components/nav/sub-nav';
import Services from '@Components/services/services';
import Resources from '@Components/resources/resources';
import Schedules from '@Components/schedule/schedules';
import Products from '@Components/inventory/products/products';
import StockTaking from '@Components/inventory/stock-taking/stock-taking';
import Delivery from '@Components/inventory/delivery/delivery';
import WebBookingInfo from '@Components/admin/web-booking/web-booking-info';
import WebBooking from '@Components/admin/web-booking';
import CompanyInfo from '@Components/admin/company-info';
import GoogleBusiness from '@Components/admin/google-business';
import SmsConfig from '@Components/admin/sms-config';
import EmailConfig from '@Components/admin/email-config';
import Notifications from '@Components/admin/notifications';
import FeaturesConfig from '@Components/admin/features-config';
import PaymentConfig from '@Components/admin/payment-config';
import StorageConfig from '@Components/admin/storage-config';
import ContactsConfig from '@Components/admin/contacts-config';
import PosOrgs from '@Components/admin/pos-config/pos-orgs';
import Terminals from '@Components/admin/pos-config/terminals';
import Printers from '@Components/admin/pos-config/printers';
import GiftCards from '@Components/admin/pos-config/gift-cards/gift-cards';
import Vouchers from '@Components/admin/pos-config/vouchers';
import VoucherTemplates from '@Components/admin/voucher-templates/voucher-templates';
import Invoices from '@Components/admin/pos-config/invoices';
import PosConfig from '@Components/admin/pos-config/pos-config';
import Subscription from '@Components/admin/subscription';
import StaffConfig from '@Components/admin/staff-config/staff-config';
import JsonPrefs from '@Components/admin/json-prefs/json-prefs';
import PartnerConfig from '@Components/admin/partner-config/partner-config';
import PosOrgRoute from './pos-org-route';

class Admin extends PureComponent {
  state = {
    collapsed: false
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { subsection, tabletMode } = this.props;
    const { subsection: nextSubsection } = nextProps;

    if (subsection !== nextSubsection && tabletMode) {
      this.setState({ collapsed: true });
    }
  }

  toggleCollapse = (ev) => {
    ev.target.blur();
    this.setState(({ collapsed }) => ({ collapsed: !collapsed }));
  };

  render() {
    const { collapsed } = this.state;
    const { routeParams, sections } = this.props;
    const defaultUrl = getSectionUrl(routeParams, 'admin', sections[0]?.id || 'services');

    return (
      <div className="subnav admin">
        <div className={collapsed ? 'subnav-sidebar collapsed' : 'subnav-sidebar'}>
          <div className="nav-toggle-collapse">
            <button className="btn btn-default" onClick={this.toggleCollapse}>
              <i className={collapsed ? 'fa fa-chevron-right' : 'fa fa-chevron-left'} />
            </button>
          </div>
          <SubNav routeParams={routeParams} sections={sections} />
          {collapsed && <ReactTooltip id="sub-nav" effect="solid" place="right" />}
        </div>
        <div className="subnav-content">
          <Switch>
            <Route exact path="/:org/:loc/admin/" render={() => <Redirect to={defaultUrl} />} />
            <Route path="/:org/:loc/:section(admin)/:subsection(services)/:id?/:tab?" component={Services} />
            <Route path="/:org/:loc/:section(admin)/:subsection(addon-services)/:id?/:tab?" component={Services} />
            <Route path="/:org/:loc/:section(admin)/:subsection(resources)/:id?/:tab?" component={Resources} />
            <Route path="/:org/:loc/:section(admin)/:subsection(schedules)/:id?/:tab?" component={Schedules} />
            <Route path="/:org/:loc/:section(admin)/:subsection(users)/:id?" component={StaffConfig} />
            <Route path="/:org/:loc/:section(admin)/:subsection(booking-info)" component={WebBookingInfo} />
            <Route path="/:org/:loc/:section(admin)/:subsection(company-info)" component={CompanyInfo} />
            <Route path="/:org/:loc/:section(admin)/:subsection(google-business)" component={GoogleBusiness} />
            <Route path="/:org/:loc/:section(admin)/:subsection(web-booking)" component={WebBooking} />
            <Route path="/:org/:loc/:section(admin)/:subsection(payment)" component={PaymentConfig} />
            <Route path="/:org/:loc/:section(admin)/:subsection(vouchers)/:tab?" component={Vouchers} />
            <Route path="/:org/:loc/:section(admin)/:subsection(sms)" component={SmsConfig} />
            <Route path="/:org/:loc/:section(admin)/:subsection(email)" component={EmailConfig} />
            <Route path="/:org/:loc/:section(admin)/:subsection(notifications)" component={Notifications} />
            <PosOrgRoute path="/:org/:loc/:section(admin)/:subsection(pos)/:id?/:tab?" component={PosOrgs} />
            <PosOrgRoute path="/:org/:loc/:section(admin)/:subsection(products)/:id?" component={Products} />
            <PosOrgRoute path="/:org/:loc/:section(admin)/:subsection(stock-taking)/:id?" component={StockTaking} />
            <PosOrgRoute path="/:org/:loc/:section(admin)/:subsection(delivery)/:id?" component={Delivery} />
            <PosOrgRoute path="/:org/:loc/:section(admin)/:subsection(gift-cards)/:id?/:tab?" component={GiftCards} />
            <PosOrgRoute path="/:org/:loc/:section(admin)/:subsection(pos-config)/" component={PosConfig} />
            <PosOrgRoute path="/:org/:loc/:section(admin)/:subsection(voucher-templates)/:id?/:tab?" component={VoucherTemplates} />
            <PosOrgRoute path="/:org/:loc/:section(admin)/:subsection(invoices)/:id?/:tab?" component={Invoices} />
            <Route path="/:org/:loc/:section(admin)/:subsection(terminals)/:id?" component={Terminals} />
            <Route path="/:org/:loc/:section(admin)/:subsection(printers)/" component={Printers} />
            <Route path="/:org/:loc/:section(admin)/:subsection(features)" component={FeaturesConfig} />
            <Route path="/:org/:loc/:section(admin)/:subsection(storage)" component={StorageConfig} />
            <Route path="/:org/:loc/:section(admin)/:subsection(contacts)" component={ContactsConfig} />
            <Route path="/:org/:loc/:section(admin)/:subsection(subscription)" component={Subscription} />
            <Route path="/:org/:loc/:section(admin)/:subsection(json-prefs)/:tab?" component={JsonPrefs} />
            <Route path="/:org/:loc/:section(admin)/:subsection(partners)/:id?/:tab?" component={PartnerConfig} />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { mainViewState } = state;
  return {
    routeParams: routeParams(ownProps.match.params),
    subsection: ownProps.match.params.subsection,
    tab: ownProps.match.params.tab,
    tabletMode: mainViewState.get('tabletMode'),
    sections: getAdminNavSections(state, ownProps)
  };
};

export default connect(mapStateToProps)(Admin);
