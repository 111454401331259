import React, { useState, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import Autosuggest from 'react-autosuggest';
import { CustomerType } from '@Utils/customer-util';
import { fetchSuggestions } from '@State/bkf/actions';
import { escapeRegexCharacters } from '@Components/ui/utils';
import { formatPhoneNumber } from '@Utils/phone-util';
import { CUSTOMER_SEARCH_DEBOUNCE } from '@Utils/constants';
import { DialogTitle } from '@Components/dialogs/dialog-styles';
import Text from '@Components/ui/styled/text-element';
import { txt } from '@Utils/i18n-util';
import msg from './pos-payment.msg';

const RecipientAutosuggestion = ({
  deliveryMethod, inputRef, onChange, onSelect, value
}) => {
  const dispatch = useDispatch();
  const highlightedSuggestion = useRef(null);
  const [suggestions, setSuggestions] = useState([]);

  const onFetchSuggestions = (value) => {
    return dispatch(fetchSuggestions(value, [CustomerType.Person], true))
      .then((suggestions) => setSuggestions(suggestions.filter(filterSuggestions)));
  };

  const debouncedFetchSuggestions = useCallback(debounce(onFetchSuggestions, CUSTOMER_SEARCH_DEBOUNCE), []);

  const onSuggestionsFetchRequested = ({ value, reason }) => {
    if (value.length <= 2) {
      onSuggestionsClearRequested();
      return;
    }
    if (!value || reason === 'suggestion-selected') {
      return;
    }
    return debouncedFetchSuggestions(value);
  };

  const getSuggestions = (value) => {
    const escapedValue = escapeRegexCharacters(value.trim());
    if (escapedValue === '') {
      return [];
    }
    return suggestions;
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions(getSuggestions('', suggestions));
  };

  const filterSuggestions = ({ email, phoneNumber }) => {
    if (deliveryMethod === 'Email') {
      return !!email;
    }
    if (deliveryMethod === 'SMS' || deliveryMethod === 'Swish') {
      return !!phoneNumber;
    }
    return false;
  };

  const renderSuggestion = ({ email, name, phoneNumber }) => {
    if (deliveryMethod === 'Email') {
      return email ? (
        <div className="form-suggestion">
          <Text fontWeight={600} fs="13">{name}</Text>
          <Text muted fs="13">{email}</Text>
        </div>
      ) : null;
    }
    if (deliveryMethod === 'SMS' || deliveryMethod === 'Swish') {
      return phoneNumber ? (
        <div className="form-suggestion">
          <Text fontWeight={600} fs="13">{name}</Text>
          <Text muted fs="13">{formatPhoneNumber(phoneNumber)}</Text>
        </div>
      ) : null;
    }
    return null;
  };

  const getRecipient = (suggestion) => {
    switch (deliveryMethod) {
      case 'Email':
        return suggestion?.email;
      case 'SMS':
      case 'Swish':
        return formatPhoneNumber(suggestion?.phoneNumber);
      default:
        return null;
    }
  };

  const getPlaceholder = () => {
    switch (deliveryMethod) {
      case 'Email':
        return txt(msg.lblEmailAddress);
      case 'SMS':
      case 'Swish':
        return txt(msg.lblMobilePhone);
      default:
        return '';
    }
  };

  const onSuggestionHighlighted = ({ suggestion }) => {
    highlightedSuggestion.current = suggestion;
  };

  const onKeyDown = (ev) => {
    if (ev.keyCode === 9 || ev.keyCode === 13) {
      if (highlightedSuggestion.current) {
        onSuggestionSelected(ev, {
          suggestion: highlightedSuggestion.current
        });
      }
    }
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    event.preventDefault();
    event.stopPropagation();

    onSelect(getRecipient(suggestion));
    setSuggestions([]);
  };

  const inputProps = {
    value,
    ref: inputRef,
    onChange,
    onKeyDown,
    placeholder: getPlaceholder(),
    className: 'form-control'
  };

  return (
    <>
      {deliveryMethod === 'Email' && <DialogTitle>{txt(msg.lblEmailReceipt)}</DialogTitle>}
      {deliveryMethod === 'SMS' && <DialogTitle>{txt(msg.lblSmsReceipt)}</DialogTitle>}
      <Autosuggest
        inputProps={inputProps}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        renderSuggestion={renderSuggestion}
        onSuggestionHighlighted={onSuggestionHighlighted}
        shouldRenderSuggestions={() => true}
        getSuggestionValue={() => value}
        focusInputOnSuggestionClick
        alwaysRenderSuggestions
      />
    </>
  );
};

RecipientAutosuggestion.propTypes = {
  deliveryMethod: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  inputRef: PropTypes.object,
  value: PropTypes.string
};

export default RecipientAutosuggestion;
