import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import Loader from '@Components/ui/loader';
import {
  fetchVoucherTemplates,
  updateVoucherTemplate,
  deleteVoucherTemplate
} from '@State/voucher-template-actions';
import { getVoucherTemplatesUrl, navigate } from '@Utils/navigate';
import NoContent from '@Components/ui/no-content';
import VoucherTemplateList from '@Components/admin/voucher-templates/voucher-template-list';
import VoucherTemplateForm from '@Components/admin/voucher-templates/voucher-template-form';

class VoucherTemplateConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filter: ''
    };
  }

  componentDidMount() {
    this.props.loadData().then(() => this.setState({ loading: false }));
  }

  onItemClick = (id) => {
    const { routeParams } = this.props;
    navigate(getVoucherTemplatesUrl(routeParams, id));
  };

  render() {
    const { loading, filter } = this.state;
    const {
      voucherTemplates,
      voucherTemplateId,
      voucherTemplate,
      routeParams
    } = this.props;

    if (loading && voucherTemplates.isEmpty()) {
      return <Loader />;
    }

    if (!voucherTemplates.isEmpty() && (!voucherTemplateId || (voucherTemplateId && !voucherTemplate))) {
      return (
        <Redirect
          to={getVoucherTemplatesUrl(
            routeParams,
            voucherTemplates.first().get('id')
          )}
        />
      );
    }

    return (
      <div className="columns-container">
        <div className="columns-sidebar">
          <div className="columns-header">
            <h1>Presentkortsmallar</h1>
          </div>
          <div className="columns-list">
            <VoucherTemplateList
              routeParams={routeParams}
              voucherTemplates={voucherTemplates}
              selectedId={voucherTemplateId}
              onItemClick={this.onItemClick}
              filter={filter}
            />
          </div>
        </div>
        <div className="columns-content">
          <div className="columns-content-container width-small">
            <div className="columns-content-body">
              {this.renderSubsection()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSubsection() {
    const {
      voucherTemplate, voucherTemplateId, updateVoucherTemplate,
      deleteVoucherTemplate
    } = this.props;

    if (!voucherTemplate) {
      return (
        <NoContent icon="fa fa-gifts">
          Det finns inga presentkortsmallar.<br />
          Klicka på "Lägg till mall" för att skapa en mall.
        </NoContent>
      );
    }

    return (
      <VoucherTemplateForm
        onSubmit={updateVoucherTemplate}
        onDelete={deleteVoucherTemplate}
        voucherTemplateId={voucherTemplateId}
        initialValues={voucherTemplate?.toJS()}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    voucherTemplates
  } = state;
  const routeParams = ownProps.match.params;
  const voucherTemplateId = parseInt(ownProps.match.params.id);
  const voucherTemplate = voucherTemplates?.find(t => t.get('id') === voucherTemplateId);

  return {
    voucherTemplateId,
    routeParams,
    voucherTemplates,
    voucherTemplate
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadData: () => {
      return dispatch(fetchVoucherTemplates());
    },
    updateVoucherTemplate: voucherTemplate => {
      return dispatch(updateVoucherTemplate(voucherTemplate));
    },
    deleteVoucherTemplate: voucherTemplateId => {
      return dispatch(deleteVoucherTemplate(voucherTemplateId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VoucherTemplateConfig);
