import React, { Component } from 'react';
import { connect } from 'react-redux';
import { exportCustomers } from '@State/customer-actions';
import { fetchGroupsAndResources } from '@State/resource-actions';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { ModalContent } from '@Components/dialogs/dialog-styles';
import { txt } from '@Utils/i18n-util';
import msg from './customer-export-dialog.msg';

class CustomerExportDialog extends Component {
  state = {
    resourceId: null,
    includeHistory: false,
    progress: false
  };

  componentDidMount() {
    if (this.props.resourcesById.isEmpty()) {
      this.props.fetchGroupsAndResourcesDialog();
    }
  }

  onClose = (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    this.props.onClose();
  };

  resourceChanged = (resourceId) => {
    this.setState({ resourceId });
  };

  includeHistoryChanged = (ev) => {
    this.setState({ includeHistory: ev.target.checked });
  };

  exportCustomers = (ev) => {
    ev.preventDefault();
    const { resourceId, includeHistory } = this.state;
    this.setState({ progress: true });
    this.props.exportCustomers(resourceId, includeHistory)
      .then(() => this.setState({ progress: false }));
  };

  render() {
    const { resourcesById } = this.props;
    const { includeHistory, progress } = this.state;

    return (
      <ModalDialog
        contentSize="medium"
        title={txt(msg.title)}
        onClose={this.onClose}
        buttons={[{
          name: txt(msg.btnDownload),
          primary: true,
          disabled: progress,
          onClick: this.exportCustomers
        }]}
      >
        <ModalContent>
          <p>{txt(msg.txtInfo)}</p>
          <br />
          <form autoComplete="off" className={progress ? 'form-horizontal disabled' : 'form-horizontal'}>
            <div className="form-group">
              <label className="control-label col-xs-4">{txt(msg.lblCustomers)}</label>
              <div className="col-xs-8">
                <div className="radio">
                  <label>
                    <input type="radio" name="resourceId" defaultChecked onChange={() => this.resourceChanged()} /> {txt(msg.lblAllCustomers)}
                  </label>
                </div>
                <br />
                {resourcesById.valueSeq().map(({ id, name, hashId }) => (
                  <div className="radio" key={id}>
                    <label>
                      <input type="radio" name="resourceId" onChange={() => this.resourceChanged(hashId)} /> {name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            {/*
            <div className="form-group">
              <label className="control-label col-xs-4">{txt(msg.lblHistory)}</label>
              <div className="col-xs-8">
                <div className="checkbox">
                  <label>
                    <input type="checkbox" checked={includeHistory} onChange={this.includeHistoryChanged} /> {txt(msg.lblIncludeHistory)}
                  </label>
                </div>
              </div>
            </div>
            */}
          </form>
        </ModalContent>
      </ModalDialog>
    );
  }
}

const mapStateToProps = (state) => {
  const { resourcesById } = state;

  return {
    resourcesById
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGroupsAndResourcesDialog: () => {
      dispatch(fetchGroupsAndResources());
    },
    exportCustomers: (resourceId, includeHistory) => {
      return dispatch(exportCustomers(resourceId, includeHistory));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerExportDialog);
