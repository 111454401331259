import { customerClearForm } from './customer-actions';

export const SET_CF_STATE = 'SET_CF_STATE';
export const OPEN_TAB = 'OPEN_TAB';

export function openCustomerTab(state) {
  return {
    type: OPEN_TAB,
    ...state
  };
}
export function setCustomerFormState(state) {
  return {
    type: SET_CF_STATE,
    ...state
  };
}

export function closeCustomerForm() {
  return dispatch => {
    dispatch(setCustomerFormState({ formVisible: false }));
    dispatch(customerClearForm());
    dispatch(openCustomerTab({ tab: 'overview' }));
  };
}
