import { defineMessages } from 'react-intl';

export default defineMessages({
  lblNotes: {
    id: 'calendar.bkf.notes.lblNotes',
    defaultMessage: 'Notes'
  },
  btnCancel: {
    id: 'calendar.bkf.notes.btnCancel',
    defaultMessage: 'Cancel'
  },
  btnSave: {
    id: 'calendar.bkf.notes.btnSave',
    defaultMessage: 'Save'
  },
  btnClose: {
    id: 'calendar.bkf.notes.btnClose',
    defaultMessage: 'Close'
  },
  btnAddNotes: {
    id: 'calendar.bkf.notes.btnAddNotes',
    defaultMessage: 'Add note'
  }
});
