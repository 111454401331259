import React, { useState } from 'react';
import moment from 'moment';
import { Field, reduxForm } from 'redux-form';
import CurrencyUtil from '@Utils/currency-util';
import { getSaleItemsWithVatPctDiff } from '@Utils/pos-utils';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { Flex } from '@Components/ui/styled/main';
import TextInput from '@Components/inputs/text-input';
import GiftCardEditModal from '@Components/admin/pos-config/gift-cards/gift-card-edit-modal';
import ColumnListItem from '@Components/ui/column/column-list-item';
import AlertWithIcon from '@Components/ui/alert-with-icon';

const GiftCardSummaryModal = ({
  title, submitText, giftCard, saleItems, totalAmount, onSubmit, onBack, onClose,
  submitting, handleSubmit, onEditGiftCard
}) => {
  const [showEditGiftCard, setShowEditGiftCard] = useState(false);
  const {
    code, beneficiary, description, comment, initialValue, remainingValue,
    noRedeemAfterTs, voucherStatus, vatPct
  } = giftCard;
  const isVoucherSale = !saleItems;
  const isRedeemedFully = voucherStatus === 'RedeemedFully';
  const isExpired = moment().isAfter(noRedeemAfterTs);
  const saleItemsWithVatPctDiff = getSaleItemsWithVatPctDiff(saleItems, giftCard);
  const hasVatPctDiff = saleItemsWithVatPctDiff && !saleItemsWithVatPctDiff.isEmpty();
  const remainingAfter = Math.max(remainingValue - totalAmount, 0);
  const submitDisabled = isRedeemedFully || isExpired || hasVatPctDiff;

  const handleCreateGiftCard = (values) => onSubmit({ ...giftCard, ...values });

  const handleEditGiftCard = (id, values) => {
    return onEditGiftCard(id, values)
      .then(() => setShowEditGiftCard(false));
  };

  return (
    <ModalDialog
      onBack={onBack}
      onClose={onClose}
      closeOnClickOutside={false}
      hideCloseButton={!isVoucherSale}
      title={title}
      buttons={[{
        name: submitText,
        primary: true,
        onClick: isVoucherSale ? handleSubmit(handleCreateGiftCard) : onSubmit,
        disabled: submitDisabled,
        loading: submitting
      }, isExpired && {
        name: 'Ändra giltighetstid',
        gray: true,
        onClick: () => setShowEditGiftCard(true)
      }, !isVoucherSale && {
        name: 'Avbryt',
        gray: true,
        onClick: onClose
      }]}
    >
      <ColumnListItem
        icon="fa fa-gift-card text-success"
        subHeader={CurrencyUtil.accountCurrency(isVoucherSale ? initialValue : remainingValue, 2)}
        header={code}
      >
        <p>
          {description}<br />
          {comment}
        </p>
        {beneficiary && (
          <p>
            Förmånstagare:<br />
            {beneficiary}
          </p>
        )}
        <p>Giltigt t.o.m: {noRedeemAfterTs ? moment(noRedeemAfterTs).subtract(1, 'day').format('L') : '-'}</p>
      </ColumnListItem>
      {!isVoucherSale && !submitDisabled && (
        <div className="gift-card-summary">
          <Flex>
            <Flex>Värde</Flex>
            <Flex right>{CurrencyUtil.accountCurrency(initialValue)}</Flex>
          </Flex>
          {remainingValue && remainingValue !== 0 ? (
            <Flex>
              <Flex>Kvar att använda</Flex>
              <Flex right>{CurrencyUtil.accountCurrency(remainingValue)}</Flex>
            </Flex>
          ) : null}
          {totalAmount ? (
            <Flex>
              <Flex><strong>Värde kvar efter köp</strong></Flex>
              <Flex right><strong>{CurrencyUtil.accountCurrency(remainingAfter)}</strong></Flex>
            </Flex>
          ) : null}
        </div>
      )}
      {isRedeemedFully && (
        <AlertWithIcon small icon="fa fa-exclamation-circle" error>
          Presentkortet är redan förbrukat!
        </AlertWithIcon>
      )}
      {isExpired && (
        <AlertWithIcon small icon="fa fa-exclamation-circle" error>
          Presentkortets giltighetstid har passerat!
        </AlertWithIcon>
      )}
      {hasVatPctDiff && (
        <AlertWithIcon small icon="fa fa-exclamation-circle" error>
          Presentkortet får endast användas för {vatPct}% moms!<br /><br />
          Artiklar med fel moms:{' '}
          {[...new Set(saleItemsWithVatPctDiff.map(item => item.get('description')))].join(', ')}
        </AlertWithIcon>
      )}
      {isVoucherSale && (
        <form onSubmit={handleSubmit(handleCreateGiftCard)} autoComplete="off" className="dialog mt2">
          <Field
            type="text"
            name="beneficiary"
            component={TextInput}
            label="Förmånstagare"
            labelHint="(valfritt)"
            placeholder="Namn och/eller personnummer"
            autoFocus
          />
        </form>
      )}
      {showEditGiftCard && (
        <GiftCardEditModal
          giftCard={giftCard}
          onSubmit={handleEditGiftCard}
          onClose={() => setShowEditGiftCard(false)}
        />
      )}
    </ModalDialog>
  );
};

export default reduxForm({
  form: 'gift-card-summary-form'
})(GiftCardSummaryModal);
