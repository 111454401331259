import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigateToBooking } from '@Utils/navigate';
import { getOrgWideCustomerDb } from '@State/selectors';
import { getCompanyCustomer, getDescription, getPrimaryCustomer, getVehicleCustomer } from '@Utils/booking-util';
import { closeCustomerForm } from '@State/cf-actions';
import { getTimeByFormat } from '@Utils/time-util';
import { formatOrgNo } from '@Utils/luhn-util';
import {
  getSmsStatus, getChannel, getSmsSent, getSmsType
} from '@Utils/customer-util';
import { PanelWrapper, UpperText } from '@Components/customers/customer-styles';
import { Button, FormGroup } from '@Components/ui/styled/main';
import HistoryButton from '@Components/customers/history-button';
import BookingLabelsPanel from '@Components/customers/customer-detail/bookings/booking-labels-panel';
import { txt } from '@Utils/i18n-util';
import msg from './customer-booking.msg';

const CustomerBooking = (props) => {
  const dispatch = useDispatch();
  const {
    customerId, resources, description, services, id, createdTime, note,
    locationId, lastUpdateTime, reminders, status, attributes, routeParams
  } = props;
  const orgWideCustomerDb = useSelector(getOrgWideCustomerDb);
  const locationNames = useSelector(state => state.locationNames);

  const customer = getPrimaryCustomer(props);
  const company = getCompanyCustomer(props);
  const vehicle = getVehicleCustomer(props);

  const { bookedAs, name, channel, cancelledTs } = customer || {};
  const { name: orgName, officialIdNo: orgNo } = company || {};
  const { officialIdNo: vehicleRegNo, name: vehicleDescription } = vehicle || {};

  const onNavigateToBooking = () => {
    dispatch(closeCustomerForm());
    navigateToBooking(routeParams, props);
  };

  return (
    <PanelWrapper defaultCursor>
      <BookingLabelsPanel
        id={id}
        customerId={customerId}
        status={status}
        attributes={attributes}
      />

      {orgWideCustomerDb && <FormGroup labelText="Plats" text={locationNames.get(locationId)} />}
      {orgNo && <FormGroup labelText={txt(msg.lblOrgNo)} text={formatOrgNo(orgNo)} />}
      {orgName && <FormGroup labelText={txt(msg.lblOrgName)} text={orgName} />}
      {vehicleRegNo && <FormGroup labelText={txt(msg.lblVehicleRegNo)} text={vehicleRegNo} />}
      {vehicleDescription && <FormGroup labelText={txt(msg.lblVehicleDesc)} text={vehicleDescription} />}

      <FormGroup
        labelText={txt(msg.lblResourceName)}
        text={resources.map(r => r.name).join(', ') || '-'}
      />
      {bookedAs && bookedAs !== name && (
        <FormGroup
          labelText={txt(msg.lblBookedAs)}
          text={bookedAs}
        />
      )}
      <FormGroup
        labelText={txt(msg.lblService)}
        text={getDescription(description, services) || '-'}
      />
      <FormGroup
        labelText={txt(msg.lblBookingNo)}
        text={id || '-'}
      />
      <FormGroup
        labelText={txt(msg.lblCreated)}
        text={createdTime ? getTimeByFormat(createdTime, 'LLL') : '-'}
      />
      {channel && (
        <FormGroup
          labelText={txt(msg.lblChannel)}
          text={getChannel(channel) || '-'}
        />
      )}
      <FormGroup
        labelText={txt(msg.lblUpdated)}
        text={lastUpdateTime ? getTimeByFormat(lastUpdateTime, 'LLL') : '-'}
      />
      {reminders && reminders.filter(({ status }) => getSmsSent(status)).map(({ type, status, statusTs }) => (
        <FormGroup
          key={type}
          labelText={getSmsType(type)}
          text={<UpperText>{`${getSmsStatus(status)} ${getTimeByFormat(statusTs, 'LLL')}`}</UpperText>}
        />
      ))}
      {cancelledTs && (
        <FormGroup
          labelText={txt(msg.lblCancelled)}
          text={cancelledTs ? getTimeByFormat(cancelledTs, 'LLL') : '-'}
        />
      )}
      <FormGroup
        labelText={txt(msg.lblNote)}
        text={note || '-'}
      />
      <div className="mt2">
        <Button small white marginRight onClick={onNavigateToBooking}>
          {txt(msg.btnShowInCalendar)}
        </Button>
        <HistoryButton white bookingId={id} />
      </div>
    </PanelWrapper>
  );
};

export default CustomerBooking;
