import { defineMessages } from 'react-intl';

export default defineMessages({
  noBookingsTitle: {
    id: 'customers.bookings.noBookingsTitle',
    defaultMessage: 'No bookings'
  },
  noBookingsText: {
    id: 'customers.bookings.noBookingsText',
    defaultMessage: 'There are no bookings for this customer'
  },
  lblDetails: {
    id: 'customers.bookings.lblDetails',
    defaultMessage: 'Details'
  },
  btnClose: {
    id: 'customers.bookings.btnClose',
    defaultMessage: 'Close'
  }
});
