import { defineMessages } from 'react-intl';

export default defineMessages({
  saveText: {
    id: 'calendar.bkf.form.saveText',
    defaultMessage: 'Save'
  },
  savingText: {
    id: 'calendar.bkf.form.savingText',
    defaultMessage: 'Saving...'
  },
  titleTextNew: {
    id: 'calendar.bkf.form.titleTextNew',
    defaultMessage: 'New booking'
  },
  titleTextChange: {
    id: 'calendar.bkf.form.titleTextChange',
    defaultMessage: 'Change booking'
  },
  titleBlockTime: {
    id: 'calendar.bkf.form.titleBlockTime',
    defaultMessage: 'Block time'
  },
  titleSchedule: {
    id: 'calendar.bkf.form.titleSchedule',
    defaultMessage: 'Edit schedule'
  },
  blockTime: {
    id: 'calendar.bkf.form.blockTime',
    defaultMessage: 'Block time'
  },
  createBooking: {
    id: 'calendar.bkf.form.createBooking',
    defaultMessage: 'Create booking'
  },
  backBtnLabel: {
    id: 'calendar.bkf.form.backBtnLabel',
    defaultMessage: 'Back'
  },
  optionsBtnLabel: {
    id: 'calendar.bkf.form.optionsBtnLabel',
    defaultMessage: 'Options'
  },
  recurringBtnLabel: {
    id: 'calendar.bkf.form.recurringBtnLabel',
    defaultMessage: 'Recurring'
  },
  recurringLabel: {
    id: 'calendar.bkf.form.recurringLabel',
    defaultMessage: 'Rules for recurring booking'
  },
  cancelBtnLabel: {
    id: 'calendar.bkf.form.cancelBtnLabel',
    defaultMessage: 'Cancel'
  },
  takePaymentBtnLabel: {
    id: 'calendar.bkf.form.takePaymentBtnLabel',
    defaultMessage: 'Payment'
  },
  openSchedule: {
    id: 'calendar.bkf.form.openSchedule',
    defaultMessage: 'Open schedule'
  },
  closeSchedule: {
    id: 'calendar.bkf.form.closeSchedule',
    defaultMessage: 'Close schedule'
  },
  lblOvertime: {
    id: 'calendar.bkf.form.lblOvertime',
    defaultMessage: 'Mark as overtime'
  }
});
