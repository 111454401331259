import React, { lazy, Suspense } from 'react';

const RichTextContentDiv = lazy(() => import('./rich-text-content-div'));

const RichTextContent = (props) => {
  return (
    <Suspense fallback={null}>
      <RichTextContentDiv {...props} />
    </Suspense>
  );
};

export default RichTextContent;
