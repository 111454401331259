import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getSaleCustomer } from '@State/pos-selectors';
import { setCustomerFormState } from '@State/cf-actions';
import SearchCustomerModal from '@Components/customers/search-customer-modal';
import { addCustomerToSale } from '@State/pos-actions';
import { CustomerType } from '@Utils/customer-util';
import BookingModals from '@Components/calendar/booking/booking-modals';
import { txt } from '@Utils/i18n-util';
import msg from './pos-sale.msg';

const Icon = styled.i`
  margin: -1px 6px 0 -3px;
  font-size: 12px;
`;

const Remove = styled.i`
  margin-left: 5px;
  margin-right: -5px;
  color: #999;
  padding: 5px 0 5px 5px;
`;

const SaleToolbarCustomerButton = ({
  routeParams, isSaleDisabled, showModal
}) => {
  const dispatch = useDispatch();
  const [showSearchCustomer, setShowSearchCustomer] = useState(false);

  const customer = useSelector(state => getSaleCustomer(state));

  const onRemoveCustomer = (ev) => {
    ev.stopPropagation();
    showModal('removeCustomer');
  };
  const onSearchCustomer = () => setShowSearchCustomer(true);
  const onHideSearchCustomer = () => setShowSearchCustomer(false);

  const onCustomerSelected = (customer) => {
    dispatch(addCustomerToSale(customer));
    onHideSearchCustomer();
  };

  const onOpenCustomer = () => dispatch(setCustomerFormState({
    formVisible: true,
    customer
  }));

  return (
    <>
      {customer ? (
        <button className="btn btn-default" onClick={onOpenCustomer} disabled={false}>
          <Icon className="fad fa-user" />
          <div>{customer.name}</div>
          <Remove className="fal fa-times-circle" onClick={!isSaleDisabled ? onRemoveCustomer : null} />
        </button>
      ) : (
        <button className="btn btn-default" onClick={onSearchCustomer} disabled={false}>
          <Icon className="fad fa-user" /> {txt(msg.btnAddCustomer)}
        </button>
      )}
      {showSearchCustomer && (
        <SearchCustomerModal
          routeParams={routeParams}
          onSelected={onCustomerSelected}
          onCancel={onHideSearchCustomer}
          scope={[CustomerType.Person]}
        />
      )}
      <BookingModals routeParams={routeParams} />
    </>
  );
};

export default SaleToolbarCustomerButton;
