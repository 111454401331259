import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { classes } from '@Components/ui/utils';

const SectionItem = ({
  id, name, iconBody, icon, notification, org, loc, section, news
}) => {
  const isActive = section === id;
  const iconClasses = `fa-lg ${icon}`;
  const baseUrl = id ? `/${org}/${loc}/${id}/` : `/${org}/${loc}/`;

  const className = classes({
    active: isActive
  });
  return (
    <li className={className}>
      <Link to={baseUrl}>
        {notification && <span className="notification">{notification}</span>}
        {iconBody || <i className={iconClasses} />}
        {news ? <span className="label-news">NY</span> : null}
        <small>{name}</small>
      </Link>
    </li>
  );
};

SectionItem.propTypes = {
  id: PropTypes.string.isRequired,
  org: PropTypes.string.isRequired,
  loc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  icon: PropTypes.string,
  notification: PropTypes.string
};

export default SectionItem;
