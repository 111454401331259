import { axiosPost, prefixUrl } from '@Utils/ajax-util';
import { isClassBooking, confirmMoveBooking } from '@Utils/booking-util';
import { postWebkitMessage } from '@Utils/wk-embed-bridges';
import { resourceFromColIdx } from '@Components/calendar/grid/grid-state-helper';
import { bookingDeleted, bookingAdded, setUndoableBooking, getUndoState } from './booking-actions';
import { getResourcesInView } from './calendar-selectors';
import { addBooking } from './bkf/actions';

export const ADD_BOOKING_TO_CLIPBOARD = 'ADD_BOOKING_TO_CLIPBOARD';
export const REMOVE_BOOKING_FROM_CLIPBOARD = 'REMOVE_BOOKING_FROM_CLIPBOARD';
export const PASTE_BOOKING = 'PASTE_BOOKING';
export const ADD_CLIPBOARD_DRAGGER = 'ADD_CLIPBOARD_DRAGGER';
export const REMOVE_CLIPBOARD_DRAGGER = 'REMOVE_CLIPBOARD_DRAGGER';

export function addClipboardDragger(dragger) {
  return (dispatch) => {
    dispatch({
      type: ADD_CLIPBOARD_DRAGGER,
      dragger
    });
  };
}

export function removeClipboardDragger() {
  return {
    type: REMOVE_CLIPBOARD_DRAGGER
  };
}

export function addToClipboard(id, copy, sourceResourceId) {
  return (dispatch, getState) => {
    const booking = getState().bookingsById.get(id);
    const isClass = isClassBooking(booking);
    const { customers } = booking || {};

    // If we're making a copy, then we reset a couple of fields that are unlikely to make sense to copy across
    //
    const changes = copy ? {
      bookedAs: null,
      copyOnPaste: true,
      channel: 'Cal',
      status: 'Booked',
      payment: null,
      sales: null,
      reminders: null,
      review: null,
      saleId: null,
      viaClipboard: true,
      sourceResourceId,
      customers: isClass ? [] : customers?.map(customer => ({
        ...customer, status: 'Booked'
      })),
      attributes: {
        ...booking.attributes,
        askedForPerson: false,
        dropIn: false
      },
      bookedSlots: 0
    } : {
      copyOnPaste: false,
      viaClipboard: true,
      sourceResourceId
    };

    dispatch({
      type: ADD_BOOKING_TO_CLIPBOARD,
      booking: { ...booking, ...changes }
    });
  };
}

export function pasteBooking(bk, routeParams) {
  return (dispatch, getState) => {
    const state = getState();
    const { bookingsById } = state;

    const resource = resourceFromColIdx(state, routeParams, bk.colIdx);

    const booking = {
      ...bk,
      resourceId: resource?.id || bk.sourceResourceId,
      sourceResourceId: bk.sourceResourceId,
      pendingMove: false
    };

    dispatch(bookingDeleted('DRAGGER'));

    if (booking.copyOnPaste) {
      dispatch(copyPastedBooking(booking));
    } else {
      const undoState = getUndoState(bookingsById.get(bk.id), {
        sourceResourceId: booking.sourceResourceId,
        targetResourceId: booking.resourceId
      });
      dispatch(movePastedBooking(booking, undoState));
    }
  };
}

export function addPasteDragger(dragger, routeParams) {
  return (dispatch, getState) => {
    const state = getState();

    // Add the first resourceId from current view to booking if dragger doesn't have
    // any resources in current view, otherwise it will be filtered out.
    const resourceIdsInView = getResourcesInView(state, { routeParams }).map(r => r.id);

    const booking = {
      ...dragger,
      id: 'DRAGGER',
      resourceId: resourceIdsInView[0],
      pasteDragger: true
    };

    dispatch(bookingDeleted('DRAGGER'));
    dispatch(bookingAdded(booking));
  };
}

export function removePasteDragger() {
  return bookingDeleted('DRAGGER');
}

export function cancelCopyPaste(bookingId) {
  return (dispatch) => {
    dispatch(bookingDeleted('DRAGGER'));
    dispatch(removeFromClipboard(bookingId));
  };
}

function notifyEmptyClipboard() {
  postWebkitMessage('clipboardEmpty');
}

export function removeFromClipboard(bookingId) {
  return (dispatch, getState) => {
    const { bookingsClipboard } = getState();

    if (bookingsClipboard.size <= 1) {
      notifyEmptyClipboard();
    }

    dispatch({
      type: REMOVE_BOOKING_FROM_CLIPBOARD,
      bookingId
    });
  };
}

function movePastedBooking(booking, undoState) {
  const url = prefixUrl(`/bookings/${booking.id}/move`);

  return (dispatch, getState) => {
    const { locationConfig } = getState();
    const isUndoable = !confirmMoveBooking(locationConfig, booking);

    dispatch(bookingAdded(booking));
    dispatch(removeFromClipboard(booking.id));

    const confirmations = isUndoable ? {} : {
      sendSmsConfirmation: booking.sendSmsConfirmation,
      sendEmailConfirmation: booking.sendEmailConfirmation
    };
    const body = {
      startTime: booking.startTime.toISOString(),
      endTime: booking.endTime.toISOString(),
      sourceResourceId: booking.sourceResourceId,
      targetResourceId: booking.resourceId,
      ...confirmations
    };

    return dispatch(axiosPost(url, body, {
      onSuccess: ({ data }) => {
        dispatch(bookingPasted({ ...booking, ...data }));
        dispatch(bookingDeleted('DRAGGER'));
        dispatch(setUndoableBooking(isUndoable ? undoState : null));
      }
    }));
  };
}

export function getBookingToCopy(booking) {
  return {
    ...booking,
    secondaryResources: booking.resources
      .filter(r => !r.primary && r.id !== booking.resourceId).map(r => r.id)
  };
}

function copyPastedBooking(booking) {
  return (dispatch) => {
    dispatch(removeFromClipboard(booking.id));
    const add = getBookingToCopy(booking);
    const confirmations = {
      sms: booking.sendSmsConfirmation,
      email: booking.sendEmailConfirmation
    };
    return dispatch(addBooking(add, confirmations));
  };
}

export function bookingPasted(booking) {
  return {
    type: PASTE_BOOKING,
    booking
  };
}
