import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { txt } from '@Utils/i18n-util';
import CurrencyUtil from '@Utils/currency-util';
import { fetchCampaignEstimate } from '@State/campaign-actions';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import Loader from '@Components/ui/loader';
import CheckBoxEl from '@Components/inputs/checkbox-styled';
import { Panel } from '@Components/ui/styled/main';
import { colors } from '@Components/ui/styled/variables';
import Label from '@Components/ui/label';
import CampaignContentPanel from '../campaign-content-panel';
import LabelTextGroup from '../label-text-group';
import msgForm from './campaign-form.msg';
import msg from '../campaign.msg';

const CampaignConfirm = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { register, watch } = useFormContext();
  const [sendAtTime, sendDate, sendTime] = watch(['sendAtTime', 'sendDate', 'sendTime']);
  const campaign = useSelector(state => state?.campaignViewState?.get('campaigns')?.find(it => +it.get('id') === +id));
  const deliveryTime = moment(`${sendDate?.format('YYYY-MM-DD')}T${sendTime}`);

  const dispatch = useDispatch();
  const [estimate, setEstimate] = useState(null);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchCampaignEstimate(id))
      .then((res) => setEstimate(res))
      .finally(() => setLoading(false));
  }, [id]);

  const { recipientCount, costEstimate, limitExceeded } = estimate ?? {};
  const formattedCost = CurrencyUtil.accountCurrency(costEstimate || 0, 2);
  const isSms = campaign.get('campaignType') === 'Sms';

  return (
    <>
      <h4>{txt(msgForm.lblConfirmCampaign)}</h4>
      {loading && <Loader />}
      {!loading && recipientCount === 0 ? (
        <AlertWithIcon warning icon="fa fa-exclamation-triangle">
          {txt(msgForm.txtNoRecipients)}
        </AlertWithIcon>
      ) : (
        <>
          <Panel>
            <LabelTextGroup label={txt(msgForm.lblTime)}>
              {sendAtTime === 'now' ? txt(msgForm.lblSendNow) : deliveryTime.format('LLLL')}
            </LabelTextGroup>
            <LabelTextGroup label={txt(msg.lblRecipients)}>
              {recipientCount || campaign.get('recipientCount')}
            </LabelTextGroup>
            {campaign.get('campaignType') === 'Sms' && (
              <LabelTextGroup label={txt(msgForm.lblCost)}>
                <Label bgColor={colors.labelGreen}>
                  {formattedCost}
                </Label>
              </LabelTextGroup>
            )}
          </Panel>
          <CampaignContentPanel campaign={campaign} />
          {limitExceeded && (
            <AlertWithIcon warning icon="fa fa-exclamation-triangle">
              {txt(msgForm.txtLimitExceeded)}
            </AlertWithIcon>
          )}
          {!limitExceeded && (
            <CheckBoxEl
              disabled={loading}
              label={isSms
                ? txt(msgForm.txtConfirmSms, { cost: formattedCost })
                : txt(msgForm.txtConfirmEmail)}
              input={{ ...register('accepted') }}
            />
          )}
        </>
      )}
    </>
  );
};

export default CampaignConfirm;
