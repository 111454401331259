import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactDataSheet from 'react-datasheet';
import { txt } from '@Utils/i18n-util';
import { fetchCampaignRecipients, fetchCampaignRecipientsForSearch } from '@State/campaign-actions';
import Button from '@Components/ui/button';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { formatPhoneNumber } from '@Utils/phone-util';
import { ModalContent } from '@Components/dialogs/dialog-styles';
import Loader from '@Components/ui/loader';
import { getCampaignRecipientStatus } from './campaign-helpers';
import msg from './campaign.msg';

function formatValue(id, value) {
  switch (id) {
    case 'msisdn':
      return formatPhoneNumber(value);
    case 'status':
      return getCampaignRecipientStatus(value);
    default:
      return value;
  }
}

function getGrid(rows, type, showStatus) {
  const grid = [];
  if (!rows || rows.length === 0) {
    return grid;
  }

  const columns = [
    { id: 'name', width: 200, name: txt(msg.lblName), itemClass: 'text-left' },
    type === 'Email'
      ? { id: 'email', width: 200, name: txt(msg.lblEmail), itemClass: 'text-left' }
      : { id: 'msisdn', width: 200, name: txt(msg.lblPhone), itemClass: 'text-left' },
    showStatus
      ? { id: 'status', width: 100, name: txt(msg.lblStatus), itemClass: 'text-left' }
      : null
  ].filter(c => c);

  const header = [];
  columns.forEach(({ name, width }) => {
    header.push({
      readOnly: true,
      value: name,
      overflow: 'clip',
      className: 'col-header',
      width
    });
  });
  grid.push(header);

  rows.forEach(item => {
    const row = [];
    columns.forEach(({ id, itemClass }) => {
      row.push({
        readOnly: true,
        value: formatValue(id, item[id]),
        overflow: 'clip',
        className: itemClass
      });
    });
    grid.push(row);
  });

  return grid;
}

const CampaignRecipientsModal = ({
  campaignId = null, searchParams = null, type, showStatus = false, onClose
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [grid, setGrid] = useState([]);

  useEffect(() => {
    dispatch(campaignId
      ? fetchCampaignRecipients(campaignId)
      : fetchCampaignRecipientsForSearch({ type, searchParams }))
      .then(({ recipients }) => setGrid(getGrid(recipients, type, showStatus)))
      .finally(() => setLoading(false));
  }, [campaignId, searchParams]);

  return (
    <ModalDialog
      onClose={onClose}
      focusTrapPaused
      contentSize="xl"
      closeOnClickOutside={false}
      alignTop
    >
      <div>
        <div className="pull-right">
          <Button gray small onClick={onClose}>{txt(msg.btnClose)}</Button>
        </div>
        <h3 className="mt0 mb2">
          {txt(msg.lblRecipients)}<br />
          {grid.length > 1 && <small>{grid.length - 1}</small>}
        </h3>
      </div>
      <ModalContent>
        <div className="data-report-preview-table">
          {loading && <Loader />}
          <div className="data-table long">
            <ReactDataSheet
              overflow="clip"
              data={grid}
              valueRenderer={cell => cell.value}
              isCellNavigable={(cell) => cell.className !== 'header'}
            />
          </div>
        </div>
      </ModalContent>
    </ModalDialog>
  );
};

export default CampaignRecipientsModal;
