import React from 'react';
import ModalDialog from '@Components/dialogs/modal-dialog';
import JsonEditor from './jsoneditor';

const JsonEditorModal = ({ title, json, onSave, onClose }) => {
  return (
    <ModalDialog
      focusTrapPaused
      contentSize="full"
      title="Redigera JSON"
      closeButtonText="Stäng"
      closeOnClickOutside={false}
      onClose={onClose}
    >
      <JsonEditor title={title} json={json} onSubmit={onSave} />
    </ModalDialog>
  );
};

export default JsonEditorModal;
