import React, { Component } from 'react';
import { connect } from 'react-redux';
import { classes } from '@Components/ui/utils';
import ProductListItem from '@Components/pos/products/product-list-item';
import ProductGroupsItem from '@Components/pos/products/product-groups-item';
import ProductHeader from '@Components/pos/products/product-header';
import Loader from '@Components/ui/loader';
import { pos } from '@Utils/preference-keys';
import { getPermissions } from '@State/selectors';
import { getArticleName } from '@Utils/pos-utils';
import { itemFilter } from '@Utils/filter-selectors';
import { hasPermission, POS_ADMIN } from '@Utils/permissions';
import { setUserPreference } from '@State/user-actions';
import { fetchServices } from '@State/services-actions';
import { fetchProductGroups, fetchProducts } from '@State/products-actions';
import { mergePosUnitPrefs } from '@State/pos-config-actions';
import { getProductGroups, getAllProducts, getVisibleProducts } from '@State/products-selectors';
import { getDefaultGroupType, getPosServiceGroups, getPosServices } from '@State/pos-selectors';
import ProductDelivery from '@Components/inventory/products/product-delivery';
import { fetchVoucherTemplates } from '@State/voucher-template-actions';
import { triggerScan } from '@Utils/barcode';
import VoucherProducts from './voucher-products';

class Products extends Component {
  constructor(props) {
    super(props);
    const { defaultGroupType, posUnitPrefs } = props;
    const hideServices = posUnitPrefs[pos.hideServices];
    const hideProducts = posUnitPrefs[pos.hideProducts];
    const selectedType = this.getInitialType(defaultGroupType, hideServices, hideProducts);
    const selectedGroup = this.getFirstGroup(selectedType, props);

    this.state = {
      hideServices,
      hideProducts,
      selectedType,
      selectedGroup,
      loading: true,
      filter: '',
      productScanned: null
    };
  }

  componentDidMount() {
    Promise.all([
      this.props.fetchServices(),
      this.props.fetchProducts(),
      this.props.fetchProductGroups(),
      this.props.fetchVoucherTemplates()
    ]).then(() => this.setState({ loading: false }));

    document.addEventListener('barcode:scan', this.handleBarcodeScan);
  }

  componentWillUnmount() {
    document.removeEventListener('barcode:scan', this.handleBarcodeScan);
  }

  componentDidUpdate(prevProps) {
    const { selectedType } = this.state;
    const firstGroup = this.getFirstGroup(selectedType, this.props);
    const prevFirstGroup = this.getFirstGroup(selectedType, prevProps);

    if (!this.state.selectedGroup && firstGroup || prevFirstGroup !== firstGroup) {
      this.selectGroup(firstGroup);
    }
  }

  handleBarcodeScan = (bcEvent) => {
    const barcode = bcEvent.detail.barcode;
    console.log('handleBarcodeScan', barcode);
    const { allProducts, isPosAdmin } = this.props;
    const { filter } = this.state;

    if (barcode.indexOf('CIA') === 0) {
      // Ignore, this is scan for a receipt
    } else {
      const product = allProducts.find(p => p && p.EAN.indexOf(barcode) !== -1);
      if (product) {
        this.props.onProductClick(product);
      } else if (isPosAdmin) {
        this.showProductDelivery(barcode);
      }
      if (filter === barcode) {
        this.handleFilterReset();
      }
    }
  };

  selectType = selectedType => {
    this.setState({
      selectedType,
      selectedGroup: this.getFirstGroup(selectedType, this.props)
    });
  };

  getInitialType = (defaultGroupType, hideServices, hideProducts) => {
    if (hideProducts) {
      return 'Services';
    }
    return defaultGroupType && !hideServices ? defaultGroupType : 'Products';
  };

  selectInitialType = () => {
    const { defaultGroupType } = this.props;
    const { hideServices, hideProducts } = this.state;
    const initialType = this.getInitialType(defaultGroupType, hideServices, hideProducts);
    this.selectType(initialType);
  };

  getFirstGroup = (selectedType, props) => {
    const { productGroups, serviceGroups } = props;
    return selectedType === 'Products'
      ? productGroups.first()
      : serviceGroups.first();
  };

  setDefaultType = groupType => {
    const { selectedType } = this.state;
    this.selectType(selectedType === 'Products' ? 'Services' : 'Products');
    this.props.setUserPreference({ [pos.defaultGroupType]: groupType });
  };

  onHideServices = hideServices => {
    const { selectedType } = this.state;
    const { posUnit, mergePosUnitPrefs } = this.props;
    this.setState({ hideServices });
    if (hideServices && selectedType === 'Services') {
      this.selectType('Products');
    }
    if (!hideServices && selectedType === 'Products') {
      this.selectType('Services');
    }
    return mergePosUnitPrefs(posUnit.get('vunitId'), { [pos.hideServices]: hideServices });
  };

  onHideProducts = hideProducts => {
    const { selectedType } = this.state;
    const { posUnit, mergePosUnitPrefs } = this.props;
    this.setState({ hideProducts });
    if (hideProducts && selectedType === 'Products') {
      this.selectType('Services');
    }
    if (!hideProducts && selectedType === 'Services') {
      this.selectType('Products');
    }
    return mergePosUnitPrefs(posUnit.get('vunitId'), { [pos.hideProducts]: hideProducts });
  };

  togglePosUnitPref = (key) => {
    const { posUnit, posUnitPrefs, mergePosUnitPrefs } = this.props;
    return mergePosUnitPrefs(posUnit.get('vunitId'), { [key]: !posUnitPrefs[key] });
  };

  selectGroup = selectedGroup => {
    this.setState({ selectedGroup });
  };

  filterMethod = (item) => {
    const { filter, hideServices, hideProducts } = this.state;
    if (item.type === 'Service' && hideServices) {
      return false;
    }
    if (item.type !== 'Service' && hideProducts) {
      return false;
    }
    return itemFilter(getArticleName(item), filter)
      || item.EAN && item.EAN.indexOf(filter) !== -1;
  };

  getGroupItems = () => {
    const { allProducts, visibleProducts, services } = this.props;
    const { selectedGroup, filter } = this.state;

    if (filter !== '') {
      return allProducts.valueSeq().filter(this.filterMethod)
        .concat(services.valueSeq().filter(this.filterMethod));
    }
    if (selectedGroup.has('articleIds')) {
      return selectedGroup.get('articleIds').map(id => visibleProducts.get(id)).filter(p => p);
    }
    if (selectedGroup.has('serviceIds')) {
      return selectedGroup.get('serviceIds').map(id => services.get(id)).filter(s => s);
    }
    return null;
  };

  getItemGroups = () => {
    const { serviceGroups, productGroups } = this.props;
    const { selectedType } = this.state;

    switch (selectedType) {
      case 'Services':
        return serviceGroups;
      case 'Products':
        return productGroups;
    }
    return null;
  };

  getIsDisabledProduct = (id) => {
    // return false because have saleId from server
    if (typeof saleId === 'number') return false;

    // disable all buttons if have localSaleId and slow speed inet.
    // except current item
    let disableItem = false;
    const { saleItems, saleId } = this.props;
    if (typeof saleId === 'string') {
      disableItem = !saleItems.find((i) => i.get('articleId') === id);
    }
    return disableItem;
  };

  handleFilterChange = (ev) => {
    this.setState({ filter: ev.target.value });
  };

  handleFilterReset = (ev) => {
    this.setState({ filter: '' });
  };

  handleFilterKeyPress = (ev) => {
    if (ev.shiftKey && ev.ctrlKey && ev.key === 'Enter') {
      triggerScan(this.state.filter);
    }
  };

  showProductDelivery = (barcode) => {
    this.setState({ productScanned: barcode });
  };

  hideProductDelivery = () => {
    this.setState({ productScanned: null });
  };

  render() {
    const {
      disabled, onProductClick, onServiceClick, onGiftCardClick,
      defaultGroupType, posUnitPrefs
    } = this.props;
    const {
      hideServices, hideProducts, selectedType, selectedGroup, loading, filter,
      productScanned
    } = this.state;
    const itemGroups = this.getItemGroups();
    const groupItems = selectedGroup ? this.getGroupItems() : null;
    const selectedId = selectedGroup && selectedGroup.get('id');
    const hasFilter = filter !== '';

    if (loading) {
      return <Loader />;
    }

    const classList = classes({
      pos__list: true,
      pos__list__disabled: disabled
    });

    return (
      <div className={classList}>
        <ProductHeader
          filter={filter}
          posUnitPrefs={posUnitPrefs}
          togglePosUnitPref={this.togglePosUnitPref}
          onFilterChange={this.handleFilterChange}
          onFilterReset={this.handleFilterReset}
          onFilterKeyPress={this.handleFilterKeyPress}
          onSelectType={this.selectType}
          onSetDefaultType={this.setDefaultType}
          defaultGroupType={defaultGroupType}
          onHideServices={this.onHideServices}
          hideServices={hideServices}
          onHideProducts={this.onHideProducts}
          hideProducts={hideProducts}
          selectedType={selectedType}
          isDisabled={disabled}
        />
        <div className="pos__list__products">
          <div className="pos__list__products__grid">
            {selectedType !== 'Vouchers' && groupItems && groupItems.map((item) => (
              <ProductListItem
                key={item.id}
                item={item}
                posUnitPrefs={posUnitPrefs}
                isDisabled={disabled || this.getIsDisabledProduct(item.id)}
                onProductClick={item.type === 'Service' ? onServiceClick : onProductClick}
                groupColor={selectedGroup?.get('color')}
              />
            ))}
            {selectedType === 'Vouchers' && (
              <VoucherProducts
                disabled={disabled}
                onGiftCardClick={onGiftCardClick}
                onCancel={this.selectInitialType}
              />
            )}
          </div>
        </div>
        {itemGroups && (
          <div className="pos__list__groups">
            <div className="pos__list__groups__grid">
              {itemGroups.map(group => (
                <ProductGroupsItem
                  key={group.get('id')}
                  group={group}
                  isSelected={group.get('id') === selectedId}
                  onSelectGroup={this.selectGroup}
                  isDisabled={disabled || hasFilter}
                />
              ))}
            </div>
          </div>
        )}
        {productScanned && (
          <ProductDelivery
            barcode={productScanned}
            onClose={this.hideProductDelivery}
            onProductAdded={this.props.onProductClick}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const permissions = getPermissions(state, ownProps);

  return {
    services: getPosServices(state),
    serviceGroups: getPosServiceGroups(state),
    allProducts: getAllProducts(state),
    visibleProducts: getVisibleProducts(state),
    productGroups: getProductGroups(state),
    defaultGroupType: getDefaultGroupType(state),
    isPosAdmin: hasPermission(permissions, POS_ADMIN)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchServices: () => dispatch(fetchServices()),
  fetchProducts: () => dispatch(fetchProducts()),
  fetchProductGroups: () => dispatch(fetchProductGroups()),
  fetchVoucherTemplates: () => dispatch(fetchVoucherTemplates()),
  setUserPreference: prefs => dispatch(setUserPreference(false, prefs)),
  mergePosUnitPrefs: (posUnitId, prefs) => dispatch(mergePosUnitPrefs(posUnitId, prefs))
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);
