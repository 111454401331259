import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Popover from '@Components/ui/popover';
import { resourceTypes } from '@Utils/resource-util';
import { getChangeJsonPrefsPermission, getFeatures, getPermissions } from '@State/selectors';
import { hasPermission, CHANGE_GROUPS, CHANGE_RESOURCES } from '@Utils/permissions';
import {
  addResourceGroup, addResource, duplicateResource, saveCopyToGroup, saveCopyToResource
} from '@State/resource-actions';
import { getAdminResourceGroups } from '@Utils/filter-selectors';
import { getResourceConfigUrl, navigate } from '@Utils/navigate';
import { DropDownItem, DropdownHeader } from '@Components/ui/drop-down/dropdown';
import AddGroupForm from '../sortable-list/add-group-form';
import AddItemForm from '../sortable-list/add-item-form';
import TBDropdown from '../ui/tb-dropdown';
import DialogCopy from './dialog-copy';

class ResourceActionButtons extends Component {
  static propTypes = {
    onAddGroup: PropTypes.func.isRequired,
    onAddResource: PropTypes.func.isRequired,
    permissions: PropTypes.array.isRequired,
    features: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isShowCopyDialog: false,
      addGroup: false,
      addResource: false
    };

    this.handleToggleDialogCopy = this.handleToggleDialogCopy.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
  }

  render() {
    return (
      <>
        {this.renderAddResourceButton()}
        {this.renderAddGroupButton()}

        {this.renderAdminDropdown()}
        {this.renderDialogCopyResource()}
      </>
    );
  }

  renderDialogCopyResource() {
    const { isShowCopyDialog } = this.state;
    const { resourcesById, orderedGroups, routeParams } = this.props;
    if (isShowCopyDialog) {
      return (
        <DialogCopy
          listResources={resourcesById.toList().toJS()}
          listGroups={orderedGroups.toList().toJS()}
          routeParams={routeParams}
          title="Kopiera inställningar"
          onCancel={this.handleToggleDialogCopy}
          onConfirm={this.handleCopy}
        />
      );
    }
  }

  handleCopy(data) {
    this.setState({
      isShowCopyDialog: false
    });
    this.props.doCopy(data);
  }

  handleToggleDialogCopy() {
    this.setState({ isShowCopyDialog: !this.state.isShowCopyDialog });
  }

  renderAdminDropdown() {
    const { canChangeJsonPrefs } = this.props;

    if (!canChangeJsonPrefs) {
      return null;
    }

    return (
      <TBDropdown icon="fa-tools">
        <DropdownHeader>Verktyg</DropdownHeader>
        <DropDownItem onClick={this.handleDuplicateResource} icon="fa-copy">Duplicera resurs</DropDownItem>
        <DropDownItem onClick={this.handleToggleDialogCopy} icon="fa-people-arrows">Kopiera inställningar</DropDownItem>
      </TBDropdown>
    );
  }

  renderAddResourceButton() {
    const { permissions, features, orderedGroups } = this.props;
    const canChangeResource = hasPermission(permissions, CHANGE_RESOURCES);
    const { ShowEditGroupResource } = features;
    const disabled = orderedGroups.isEmpty();

    if (!canChangeResource || !ShowEditGroupResource) {
      return null;
    }

    return (
      <Popover
        isOpen={this.state.addResource}
        body={this.addResourceContent()}
        preferPlace="below"
        className="Popover-big-radius"
        onOuterAction={this.handleCloseResourcePop}
      >
        <button type="button" className="btn btn-cta" disabled={disabled} onClick={this.handleOpenResourcePop}>Lägg till resurs</button>
      </Popover>
    );
  }

  renderAddGroupButton() {
    const { permissions, features } = this.props;
    const canChangeGroups = hasPermission(permissions, CHANGE_GROUPS);
    const { ShowEditGroupResource } = features;

    if (!canChangeGroups || !ShowEditGroupResource) {
      return null;
    }

    return (
      <Popover
        isOpen={this.state.addGroup}
        body={this.addGroupContent()}
        preferPlace="below"
        className="Popover-big-radius"
        onOuterAction={this.handleCloseGroupPop}
      >
        <button type="button" className="btn btn-cta-secondary" onClick={this.handleOpenGroupPop}>Lägg till grupp</button>
      </Popover>
    );
  }

  handleOpenGroupPop = () => {
    this.setState({ addGroup: true });
  };

  handleCloseGroupPop = () => {
    this.setState({ addGroup: false });
  };

  handleOpenResourcePop = () => {
    this.setState({ addResource: true });
  };

  handleCloseResourcePop = () => {
    this.setState({ addResource: false });
  };

  handleAddGroup = group => {
    return this.props
      .onAddGroup(group)
      .then(() => this.handleCloseGroupPop());
  };

  handleAddResource = ({ groupId, name, type, prepend }) => {
    return this.props
      .onAddResource({ groupId, name, prepend, resourceType: type, webName: name })
      .then(() => this.handleCloseResourcePop());
  };

  handleDuplicateResource = () => {
    const { resourceId, resource } = this.props;

    return this.props.onDuplicateResource(resourceId, resource.name);
  };

  addResourceContent() {
    return (
      <AddItemForm
        onClosePopover={this.handleCloseResourcePop}
        onAddItem={this.handleAddResource}
        placeholder="Ange namn på resursen"
        groups={this.props.orderedGroups}
        types={resourceTypes}
      />
    );
  }

  addGroupContent() {
    return (
      <AddGroupForm
        onClosePopover={this.handleCloseGroupPop}
        onAddGroup={this.handleAddGroup}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const resourceId = parseInt(ownProps.id);
  const { adminViewState, resourcesById } = state;
  const resource = resourcesById && resourceId && resourcesById.get(resourceId);

  return {
    resourcesById,
    sortable: adminViewState.get('resourcesSortable'),
    collapsedIds: adminViewState.get('resourcesCollapsedIds'),
    orderedGroups: getAdminResourceGroups(state),
    permissions: getPermissions(state, ownProps),
    features: getFeatures(state),
    canChangeJsonPrefs: getChangeJsonPrefsPermission(state, ownProps),
    resourceId,
    resource
  };
};

const mapDispatchToProps = (dispatch, { routeParams, id }) => {
  return {
    onAddGroup: (group) => {
      return dispatch(addResourceGroup(group));
    },
    onAddResource: (resource) => {
      return dispatch(addResource(resource))
        .then(res => navigate(getResourceConfigUrl(routeParams, res.id)));
    },
    onDuplicateResource: (resourceId, name) => {
      return dispatch(duplicateResource(resourceId, name))
        .then(res => navigate(getResourceConfigUrl(routeParams, res.id)));
    },
    doCopy: ({ isCallCopyResource, id: idTarget, ...data }) => {
      if (isCallCopyResource) {
        return dispatch(saveCopyToResource(id, idTarget, data));
      }
      return dispatch(saveCopyToGroup(id, idTarget, data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourceActionButtons);
