import axios from 'axios';
import { pick } from 'ramda';
import { destroy, initialize } from 'redux-form';
import {
  prefixUrl, axiosDefault, checkStatusAxios, axiosErrorHandler,
  axiosDelete,
  axiosPost,
  axiosPut
} from '@Utils/ajax-util';
import { firstLetterUpperCase } from '@Utils/customer-util';
import { resetHighlightedBookings } from '@State/view-actions';
import { resourceFromColIdx } from '@Components/calendar/grid/grid-state-helper';
import { addBookingSuccess, bookingChanged, bookingDeleted, removeTempBooking, sendBookingConfirmation } from '@State/booking-actions';
import PhoneUtil from '@Utils/phone-util';

import {
  RESET_BKF,
  SET_BKF_PROP,
  SET_BKF_VEHICLE,
  SET_BKF_COMPANY,
  SET_BKF_CUSTOMER,
  SET_BKF_CUSTOMER_STATUS,
  SET_BKF_BOOKING,
  SET_BKF_SERVICE,
  ADD_BKF_SERVICE,
  REMOVE_BKF_SERVICE,
  UPDATE_BKF_SERVICE,
  ADD_BKF_RESOURCE,
  REMOVE_BKF_RESOURCE,
  RESET_BKF_NEW_RESOURCE,
  SET_BKF_TIME,
  SET_BKF_RES_TIME,
  SET_BKF_ATTRIBUTES,
  TOGGLE_BKF_MODAL,
  TOGGLE_BKF_VIEW,
  RES_SRV_LOADED,
  CLEAR_RES_SRV,
  BOOKING_SALE_LOADED,
  BOOKING_REMINDERS_LOADED,
  BOOKING_CUSTOMER_ADDED,
  BOOKING_CUSTOMER_REMOVED
} from './constants';

export function clearFormsBKF() {
  return (dispatch) => {
    dispatch(destroy(
      'bkf-company', 'bkf-customer', 'bkf-custom-fields', 'bkf-service', 'bkf-address', 'bkf-notes', 'bkf-confirmation', 'bkf-place', 'bkf-vehicle', 'bkf-price', 'bkf-time', 'bkf-class-info', 'bkf-recurring'
    ));
  };
}

export function clearAndCloseBKF() {
  return (dispatch, getState) => {
    dispatch({ type: RESET_BKF });
    dispatch(clearFormsBKF());

    setTimeout(() => {
      // Don't reset highlight if booking form was closed because of a click on another chip
      const { bkf } = getState();
      if (!bkf.get('id')) {
        dispatch(resetHighlightedBookings());
      }
    }, 100);
  };
}

export function setBKFBooking(booking, colIdx, sourceResourceId) {
  return {
    type: SET_BKF_BOOKING,
    booking,
    colIdx,
    sourceResourceId
  };
}

export function setBKFProp(key, value) {
  return {
    type: SET_BKF_PROP,
    key,
    value
  };
}

export function setBKFAttributes(attributes) {
  return {
    type: SET_BKF_ATTRIBUTES,
    attributes
  };
}

export function updateBKFCoords(coords, routeParams) {
  return (dispatch, getState) => {
    const state = getState();
    const { id, startTime, endTime, colIdx } = coords;
    const resource = resourceFromColIdx(state, routeParams, colIdx);

    dispatch(destroy('bkf-time'));
    dispatch({
      type: SET_BKF_RES_TIME,
      id,
      startTime,
      endTime,
      resId: id === 'DRAGGER' && resource
        ? resource.id
        : state.bkf.get('resourceId')
    });
  };
}

export function setBKFBookingTime(newTime) {
  return {
    type: SET_BKF_TIME,
    ...newTime
  };
}

export function resetBKFNotes() {
  return (dispatch) => {
    dispatch(destroy('bkf-notes'));
  };
}

export function loadBKFBooking(bkId, colIdx, sourceResourceId) {
  return (dispatch, getState) => {
    const booking = getState().bookingsById.get(bkId);
    dispatch(clearFormsBKF());
    dispatch(setBKFBooking(booking, colIdx, sourceResourceId));
  };
}

export function setBKFVehicle(vehicle) {
  return (dispatch) => {
    dispatch(initialize('bkf-vehicle', vehicle));
    dispatch({ type: SET_BKF_VEHICLE, vehicle });
  };
}

export function addBKFService(service) {
  return (dispatch) => {
    dispatch(destroy('bkf-service', 'bkf-price', 'bkf-time', 'bkf-class-info'));
    dispatch({ type: ADD_BKF_SERVICE, service });
  };
}

export function removeBKFService(service) {
  return (dispatch) => {
    dispatch(destroy('bkf-service', 'bkf-price', 'bkf-time', 'bkf-class-info'));
    dispatch({ type: REMOVE_BKF_SERVICE, service });
  };
}

export function updateBKFService(service) {
  return (dispatch) => {
    dispatch(destroy('bkf-service', 'bkf-price', 'bkf-class-info'));
    dispatch({ type: UPDATE_BKF_SERVICE, service });
  };
}

export function addBKFResource(resource) {
  return (dispatch) => {
    dispatch({ type: ADD_BKF_RESOURCE, resource });
  };
}

export function resetBKFNewResource(resource) {
  return (dispatch) => {
    dispatch({ type: RESET_BKF_NEW_RESOURCE, resource });
  };
}

export function removeBKFResource(resource) {
  return (dispatch) => {
    dispatch({ type: REMOVE_BKF_RESOURCE, resource });
  };
}

export function setBKFService(service, resourceServices) {
  return (dispatch) => {
    dispatch(destroy('bkf-service', 'bkf-price', 'bkf-time', 'bkf-class-info'));
    dispatch({ type: SET_BKF_SERVICE, service, resourceServices });
  };
}

export function setBKFCompany(company) {
  return (dispatch) => {
    dispatch(destroy('bkf-company'));
    dispatch({ type: SET_BKF_COMPANY, company });
  };
}

export function setBKFCustomer(customer) {
  return (dispatch) => {
    const initialValues = customer && {
      ...customer,
      phoneNumber: PhoneUtil.formatPhoneNumber(customer.phoneNumber),
      otherPhoneNumber: PhoneUtil.formatPhoneNumber(customer.otherPhoneNumber)
    };
    dispatch(initialize('bkf-customer', initialValues));
    dispatch({ type: SET_BKF_CUSTOMER, customer });
  };
}

export function setBKFCustomerStatus(customerId, status) {
  return (dispatch) => {
    dispatch({ type: SET_BKF_CUSTOMER_STATUS, customerId, status });
  };
}

export function updateBKFCustomer(id, customer) {
  return (dispatch, getState) => {
    const { bkf } = getState();
    const bkfCustomer = bkf.get('customer');

    if (bkfCustomer?.customerId === id) {
      const fields = ['name', 'officialIdNo', 'email', 'phoneNumber', 'otherPhoneNumber'];
      const updatedCustomer = pick(fields, customer);
      dispatch(setBKFCustomer({
        ...bkfCustomer,
        ...updatedCustomer
      }));
    }
  };
}

export function toggleBKFView(view, props) {
  return {
    type: TOGGLE_BKF_VIEW,
    view,
    props
  };
}

export function toggleBKFModal(modal, show, props) {
  return {
    type: TOGGLE_BKF_MODAL,
    modal,
    show,
    props
  };
}

function resourceServicesLoaded(resId, services) {
  return {
    type: RES_SRV_LOADED,
    resId,
    services
  };
}

export function clearResourceServices() {
  return {
    type: CLEAR_RES_SRV
  };
}

export function addBooking(booking, confirmations) {
  const url = prefixUrl(`/bookings/resource/${booking.resourceId}/`);
  const config = axiosDefault();
  return (dispatch) => {
    return axios.post(url, booking, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch(addBookingSuccess(booking, res)))
      .then((newBooking) => {
        if (confirmations.sms || confirmations.email) {
          dispatch(sendBookingConfirmation({
            bookingId: newBooking.id,
            customerIds: newBooking.customers?.map(c => c.customerId),
            options: confirmations
          }));
        }
        dispatch(removeTempBooking());
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateBooking(booking) {
  const { id, resourceId, customFields, ...data } = booking;
  const url = prefixUrl(`/bookings/resource/${resourceId}/${id}`);

  return dispatch => dispatch(axiosPut(url, data, {
    onSuccess: res => dispatch(bookingChanged(id, { ...data, ...res.data }))
  }));
}

export function deleteBooking(id) {
  const url = prefixUrl(`/bookings/${id}`);
  return dispatch => dispatch(axiosDelete(url, {
    onSuccess: () => dispatch(bookingDeleted(id))
  }));
}

export function addBookingCustomer(bookingId, customer) {
  const url = prefixUrl(`/bookings/${bookingId}/customers`);
  return dispatch => dispatch(axiosPost(url, customer, {
    onSuccess: () => dispatch(bookingCustomerAdded(bookingId, customer))
  }));
}

export function removeBookingCustomer(bookingId, customerId) {
  const url = prefixUrl(`/bookings/${bookingId}/customers/${customerId}`);
  return dispatch => dispatch(axiosDelete(url, {
    onSuccess: () => dispatch(bookingCustomerRemoved(bookingId, customerId))
  }));
}

export function bookingCustomerAdded(bookingId, customer) {
  return {
    type: BOOKING_CUSTOMER_ADDED,
    bookingId,
    customer
  };
}

export function bookingCustomerRemoved(bookingId, customerId) {
  return {
    type: BOOKING_CUSTOMER_REMOVED,
    bookingId,
    customerId
  };
}

export function fetchResourceServices(resId, clearCache) {
  const url = prefixUrl(`/services/resource-services/${resId}`);
  const config = axiosDefault();

  return (dispatch) => {
    if (clearCache) {
      dispatch(clearResourceServices());
    }
    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((res) => {
        dispatch(resourceServicesLoaded(resId, res.services));
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

function mapVehicleSearchResult(data) {
  const { ownerType, ownerOrgOrPno, ownerName } = data;
  const customerType = firstLetterUpperCase(ownerType);
  const hasRelated = customerType && (ownerOrgOrPno || ownerName);

  return {
    customerType: 'VehicleRegistry',
    name: data.summary,
    officialIdNo: data.regNo,
    attributes: data.attribs,
    related: hasRelated ? [{
      customerType,
      officialIdNo: ownerOrgOrPno,
      name: ownerName
    }] : null
  };
}

function mapSuggestions(data) {
  return data.vehicleSearchResult
    ? data.customerEntries.concat(mapVehicleSearchResult(data.vehicleSearchResult))
    : data.customerEntries;
}

let currentSearch = null;
export function fetchSuggestions(query, scope, distinct) {
  if (currentSearch) {
    currentSearch.cancel();
  }
  if (!query || !scope || query.length <= 2) {
    return Promise.resolve([]);
  }

  const queryParam = `?query=${encodeURIComponent(query)}`;
  const scopeParam = `&scope=${scope.join(',')}`;
  const distinctParam = distinct ? '&distinct=true' : '';

  currentSearch = axios.CancelToken.source();
  const config = axiosDefault({ cancelToken: currentSearch.token });
  const url = prefixUrl(`/search${queryParam}${scopeParam}${distinctParam}`);

  return dispatch => axios.get(url, config)
    .then(({ data }) => mapSuggestions(data))
    .catch((error) => {
      axiosErrorHandler(error, dispatch);
      return [];
    }).finally(() => {
      currentSearch = null;
    });
}

export function fetchVehicleInfo(regNo, customerId) {
  const url = prefixUrl('/lookup-vehicle-data');
  const config = axiosDefault();

  return (dispatch) => {
    return axios.post(url, { regNo, customerId }, config)
      .then(({ data }) => {
        if (data?.result) {
          dispatch(setBKFVehicle({
            officialIdNo: regNo,
            name: data.result.summary,
            attributes: data.result.attribs
          }));
        } else {
          throw new Error('No vehicle found');
        }
      });
  };
}

export function fetchBookingSale(bookingId) {
  return (dispatch) => {
    const url = prefixUrl(`/bookings/${bookingId}/sale`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch({ type: BOOKING_SALE_LOADED, sale: res.data }))
      .catch((error) => {
        console.error('Failed to fetch booking sale', { bookingId });
        Sentry.captureException(error);
      });
  };
}

export function fetchBookingReminders(bookingId) {
  return (dispatch) => {
    const url = prefixUrl(`/reminders/${bookingId}/`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch({ type: BOOKING_REMINDERS_LOADED, reminders: res.data.reminders }))
      .catch(() => {});
  };
}

export function fetchBookingEvents(bookingId) {
  return (dispatch) => {
    const url = prefixUrl(`/bookings/${bookingId}/history`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data.events)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}
