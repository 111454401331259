export const DATE_PICKER_FORMAT = 'YYYY-MM-DD'; // "2021-01-13"
export const SERVER_FORMAT = 'YYYY-MM-DDTHH:mm:ss'; // "2021-01-13"
export const DATE_CALENDAR_PICKER_FORMAT = 'yyyy-MM-dd'; // "2021-01-We"
export const DAY_DATE_FULL = 'dddd D MMM YYYY'; // "Wednesday 13 Jan 2021"
export const DAY_WEEK_DATE_MONTH_YEAR = 'ddd DD MMM YYYY'; // "Wed 07 Oct 2020"
export const DAY_WEEK_DATE_MONTH = 'ddd DD MMM'; // "Wed 07 Oct"
export const HOURS_MINUTES = 'HH:mm'; // "09:00"
export const HOURS_MINUTES_SECONDS = 'HH:mm:ss'; // "09:00:00"
export const YEAR_MONTH_DAY_H_M_S = 'YYYY-MM-DD HH:mm:ss'; // 2019-02-09 18:52:01
export const MONTH_YEAR = 'MMMM YYYY';
export const DEFAULT_TIME_START = '09:00';
export const DEFAULT_TIME_END = '18:00';
export const DEFAULT_BREAK_TIME_START = '12:00:00';
export const DEFAULT_BREAK_TIME_END = '13:00:00';
