import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import CurrencyUtil from '@Utils/currency-util';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { Flex, Panel } from '@Components/ui/styled/main';
import GiftCardEditForm from './gift-card-edit-form';

const SummaryPanel = styled(Panel)`
  line-height: 24px;
`;

const GiftCardEditModal = ({ giftCard, onSubmit, onClose, onVoid }) => {
  const { id, code, initialValue, remainingValue, noRedeemAfterTs } = giftCard;

  const initialValues = {
    remaining: remainingValue,
    noRedeemAfter: moment(noRedeemAfterTs).subtract(1, 'day').format('L')
  };

  const handleSubmit = (values) => {
    const data = {
      ...values,
      noRedeemAfter: moment(values.noRedeemAfter).add(1, 'day').format('L')
    };
    return onSubmit(id, data);
  };

  return (
    <ModalDialog
      title="Ändra presentkort"
      contentSize="small"
      hideCloseButton
      closeOnClickOutside={false}
      onClose={onClose}
      focusTrapPaused
    >
      <SummaryPanel>
        <Flex>
          <Flex>Kod</Flex>
          <Flex right>{code}</Flex>
        </Flex>
        <Flex>
          <Flex>Ursprungligt värde</Flex>
          <Flex right>{CurrencyUtil.accountCurrency(initialValue)}</Flex>
        </Flex>
      </SummaryPanel>
      <GiftCardEditForm
        giftCard={giftCard}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onClose={onClose}
        onVoid={onVoid}
      />
    </ModalDialog>
  );
};

export default GiftCardEditModal;
