import { defineMessages } from 'react-intl';

export default defineMessages({
  lblContactPerson: {
    id: 'calendar.bkf.customer.lblContactPerson',
    defaultMessage: 'Contact person'
  },
  lblName: {
    id: 'calendar.bkf.customer.lblName',
    defaultMessage: 'Name'
  },
  lblMobilePhone: {
    id: 'calendar.bkf.customer.lblMobilePhone',
    defaultMessage: 'Mobile phone'
  },
  lblEmail: {
    id: 'calendar.bkf.customer.lblEmail',
    defaultMessage: 'Email'
  },
  lblPersonalNo: {
    id: 'calendar.bkf.customer.lblPersonalNo',
    defaultMessage: 'Personal number'
  },
  lblOtherPhone: {
    id: 'calendar.bkf.customer.lblOtherPhone',
    defaultMessage: 'Other phone'
  },
  btnShowAllFields: {
    id: 'calendar.bkf.customer.btnShowAllFields',
    defaultMessage: 'Show all fields'
  },
  btnAddContactPerson: {
    id: 'calendar.bkf.customer.btnAddContactPerson',
    defaultMessage: 'Add contact person...'
  },
  btnAddCustomer: {
    id: 'calendar.bkf.customer.btnAddCustomer',
    defaultMessage: 'Add customer...'
  },
  lblCustomer: {
    id: 'calendar.bkf.customer.lblCustomer',
    defaultMessage: 'Customer'
  },
  searchPlaceholderText: {
    id: 'calendar.bkf.customer.searchPlaceholderText',
    defaultMessage: 'Search or add new customer'
  },
  btnOpen: {
    id: 'calendar.bkf.customer.btnOpen',
    defaultMessage: 'Open'
  },
  btnClear: {
    id: 'calendar.bkf.customer.btnClear',
    defaultMessage: 'Clear'
  },
  btnClose: {
    id: 'calendar.bkf.customer.btnClose',
    defaultMessage: 'Close'
  }
});
