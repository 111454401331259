import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { fetchVehicleInfo } from '@State/customer-actions';
import { formatVehicleRegNo } from '@Utils/vehicle-util';
import { required, vehicleValid } from '@Utils/validation';
import TextInput from '@Components/inputs/text-input';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import SelectField from '@Components/inputs/select-field';
import Loader from '@Components/ui/loader';
import { txt } from '@Utils/i18n-util';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import msg from './customer-information.msg';

const selector = formValueSelector('customer-vehicle-info');

const VehicleInformationForm = ({
  submitting, submitSucceeded, handleSubmit, onClose
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const enableVehicleSearch = useSelector(state => state.locationFeatures.get('EnableVehicleRegistrySearch'));
  const officialIdType = useSelector(state => selector(state, 'officialIdType'));
  const officialIdNo = useSelector(state => selector(state, 'officialIdNo'));
  const validateIdNo = officialIdType === 'SE_VEHICLE';

  const handleSearchVehicle = () => {
    setLoading(true);
    return dispatch(fetchVehicleInfo(officialIdNo))
      .then((vehicleData) => {
        setError(null);
        dispatch(change('customer-vehicle-info', 'name', vehicleData.name));
        dispatch(change('customer-vehicle-info', 'attributes', vehicleData.attributes));
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  };

  return (
    <form autoComplete="off" className="dialog" onSubmit={handleSubmit}>
      {loading && <Loader />}
      {error && (
        <AlertWithIcon icon="fa fa-exclamation-circle" error>
          Kunde inte hitta en bil med detta registreringsnummer.
        </AlertWithIcon>
      )}
      <Field
        type="text"
        label={txt(msg.lblVehicleRegNo)}
        name="officialIdNo"
        component={TextInput}
        normalize={formatVehicleRegNo}
        validate={validateIdNo ? [required, vehicleValid] : required}
        onBlur={enableVehicleSearch ? handleSearchVehicle : null}
      />
      <Field
        name="officialIdType"
        component={SelectField}
      >
        <option value="SE_VEHICLE">Svenskt registreringsnummer</option>
        <option value="">Annan typ av registreringsnummer</option>
      </Field>
      <Field
        type="text"
        label={txt(msg.lblDescription)}
        name="name"
        component={TextInput}
      />
      <FormButtonsFooter
        onClose={onClose}
        submitting={submitting}
        submitSucceeded={submitSucceeded}
        handleSubmit={handleSubmit}
      />
    </form>
  );
};

export default reduxForm({
  form: 'customer-vehicle-info'
})(VehicleInformationForm);
