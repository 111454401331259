import React from 'react';
import { useSelector } from 'react-redux';
import { getOrgWideCustomerDb } from '@State/selectors';
import { colors } from '@Components/ui/styled/variables';
import { getDescription } from '@Utils/booking-util';
import EventCard from '@Components/ui/event-card';
import BookingLabelsList from '@Components/customers/customer-detail/bookings/booking-labels-list';
import { getTimeHours, getShortDate } from '@Utils/time-util';
import {
  getCardIcon, CustomerBookingsTimeStyle,
  CustomerBookingsResource, CustomerBookingsNote, CustomerBookingsWrapper
} from '@Components/customers/customer-styles';

const CustomerBookingEvent = ({
  onSelectEvent, isSelectable = false, selectedEventId, ...item
}) => {
  const orgWideCustomerDb = useSelector(getOrgWideCustomerDb);
  const locationNames = useSelector(state => state.locationNames);

  const {
    id, locationId, channel, status, startTime, endTime, description,
    services, resources, note, attributes
  } = item;
  const isSelected = selectedEventId === id;
  const icon = getCardIcon(channel);
  const isCancelled = status === 'Cancelled';
  const handleSelectEvent = () => onSelectEvent && onSelectEvent(item);
  const serviceDescription = getDescription(description, services);
  const { dropIn, askedForPerson } = attributes || {};
  const time = `${getShortDate(startTime)} kl. ${getTimeHours(startTime)} - ${getTimeHours(endTime)}`;
  const showLabels = status === 'Show' || status === 'NoShow' || dropIn || askedForPerson;

  return (
    <EventCard
      isSelectable={isSelectable}
      isSelected={isSelected}
      borderColor={!isCancelled ? colors.customerBookingBg : undefined}
      handler={handleSelectEvent}
      icon={icon}
    >
      <CustomerBookingsWrapper>
        <CustomerBookingsTimeStyle isCancelled={isCancelled}>
          <strong>{serviceDescription || time}</strong><br />
          {serviceDescription && <p>{time}</p>}
          {orgWideCustomerDb && (
            <CustomerBookingsResource>
              <i className="far fa-location-dot mr1" />
              {locationNames.get(locationId)}
            </CustomerBookingsResource>
          )}
          <CustomerBookingsResource>{resources.map(r => r.name).join(', ')}</CustomerBookingsResource>
        </CustomerBookingsTimeStyle>

        {note && <CustomerBookingsNote>{note}</CustomerBookingsNote>}

        {showLabels && (
          <BookingLabelsList
            status={status}
            dropIn={dropIn}
            askedForPerson={askedForPerson}
          />
        )}
      </CustomerBookingsWrapper>
    </EventCard>
  );
};

export default CustomerBookingEvent;
