import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import TextEllipsis from '@Components/ui/styled/text-ellipsis';
import { colors, fsw } from '@Components/ui/styled/variables';
import { getRouteParams, getLocationName, getShowReleaseNotesNotification } from '@State/selectors';
import UserMenu from '@Components/nav/user-menu';
import ReleaseNotesModal from '@Components/dialogs/release-notes-modal';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Img = styled.img`
  margin-left: 8px;
  width: ${props => props.phoneMode ? '26px' : '30px'};
  height: ${props => props.phoneMode ? '26px' : '30px'};
`;

const Notification = styled.div`
  position: absolute;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid white;
  background: ${colors.labelNews};
  width: 12px;
  height: 12px;
  top: 0px;
  right: -4px;
  padding-top: 0.2em;
`;

const A = styled.a`
  display: flex;
  align-items: center;
  touch-action: manipulation;
  color: ${colors.textColor};
  font-weight: ${fsw.medium};

  :active, :hover, :focus {
    color: ${colors.textColor};
    text-decoration: none;
  }
`;

const UserMenuButton = ({ className, ...props }) => {
  const [open, setOpen] = useState(false);
  const [showReleaseNotesModal, setShowReleaseNotes] = useState(false);
  const routeParams = useSelector(state => getRouteParams(state, props));
  const phoneMode = useSelector(state => state.mainViewState.get('phoneMode'));
  const tabletMode = useSelector(state => state.mainViewState.get('tabletMode'));
  const locationName = useSelector(state => getLocationName(state));
  const showNotification = useSelector(getShowReleaseNotesNotification);
  const showName = !phoneMode && !tabletMode;

  const handleReleaseNotes = () => {
    setShowReleaseNotes(true);
    setOpen(false);
  };

  const handleClick = (e) => {
    e && e.preventDefault();
    setOpen(!open);
  };

  return (
    <>
      <Wrapper className={`${className} dropdown`}>
        <A href="#" onClick={handleClick}>
          {showName && (
            <TextEllipsis width={125} align="right">{locationName}</TextEllipsis>
          )}
          <Img className="ui-avatar" phoneMode={phoneMode} src="/user.svg" />
          {showNotification && <Notification />}
        </A>
        {open && (
          <UserMenu
            onClose={handleClick}
            onShowReleaseNotes={handleReleaseNotes}
            showNotification={showNotification}
            routeParams={routeParams}
            locationName={locationName}
          />
        )}
      </Wrapper>

      {showReleaseNotesModal && (
        <ReleaseNotesModal onClose={() => setShowReleaseNotes(false)} />
      )}
    </>
  );
};

export default memo(UserMenuButton);
