import React, { lazy, PureComponent, Suspense } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { required } from '@Utils/validation';
import TextInput from '@Components/inputs/text-input';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import AlertWithIcon from '@Components/ui/alert-with-icon';

const PasswordStrength = lazy(() => import('@Components/user-config/password-strength'));

class SetPasswordForm extends PureComponent {
  render() {
    const {
      handleSubmit, submitting, submitSucceeded, onClose, password, passwordError
    } = this.props;

    return (
      <div>
        <form autoComplete="off" className="dialog" onSubmit={handleSubmit}>
          <Field
            name="password"
            label="Nytt lösenord"
            component={TextInput}
            validate={required}
            className="form-control-password"
          />
          <Suspense fallback={null}>
            <PasswordStrength newPassword={password || ''} />
          </Suspense>
          {passwordError && (
            <AlertWithIcon error>
              {passwordError.message}
            </AlertWithIcon>
          )}
          <FormButtonsFooter
            submitting={submitting}
            submitSucceeded={submitSucceeded}
            onClose={onClose}
            handleSubmit={handleSubmit}
            submitText="Byt lösenord"
          />
        </form>
      </div>
    );
  }
}

const selector = formValueSelector('set-password-form');

const mapStateToProps = (state) => {
  return {
    password: selector(state, 'password')
  };
};

export default reduxForm({
  form: 'set-password-form'
})(connect(mapStateToProps)(SetPasswordForm));
