import { defineMessages } from 'react-intl';

export default defineMessages({
  emailNotifications: {
    id: 'calendar.userMenu.emailNotifications',
    defaultMessage: 'Email notifications...'
  },
  changePassword: {
    id: 'calendar.userMenu.changePassword',
    defaultMessage: 'Change password...'
  },
  subscription: {
    id: 'calendar.userMenu.subscription',
    defaultMessage: 'Your subscription'
  },
  accounts: {
    id: 'calendar.userMenu.accounts',
    defaultMessage: 'Accounts'
  },
  accounts10plus: {
    id: 'calendar.userMenu.accounts10plus',
    defaultMessage: 'More than 10 accounts...'
  },
  searchAccountsPlaceholder: {
    id: 'calendar.userMenu.searchAccountsPlaceholder',
    defaultMessage: 'Search accounts...'
  },
  logOut: {
    id: 'calendar.userMenu.logOut',
    defaultMessage: 'Log out'
  },
  releaseNotes: {
    id: 'calendar.userMenu.releaseNotes',
    defaultMessage: 'Release notes'
  },
  news: {
    id: 'calendar.userMenu.news',
    defaultMessage: 'NEWS'
  }
});
