import React from 'react';
import PropTypes from 'prop-types';
import { DialogButtonsHorizontal, DialogButton } from '@Components/dialogs/dialog-styles';

export default class AddItemForm extends React.Component {
  static propTypes = {
    onClosePopover: PropTypes.func.isRequired,
    onAddItem: PropTypes.func.isRequired,
    groups: PropTypes.object,
    placeholder: PropTypes.string.isRequired,
    infoText: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      name: props.name || '',
      groupId: props.groups?.first().get('id'),
      type: props.types ? Object.keys(props.types)[0] : null,
      prepend: false,
      progress: false
    };
  }

  componentDidMount() {
    this.input.focus();
  }

  handleAdd = ev => {
    ev.preventDefault();
    this.setState({ progress: true });
    const { name, groupId, type, prepend } = this.state;
    this.props
      .onAddItem({ groupId, name, type, prepend })
      .catch(() => this.setState({ progress: false }));
  };

  prependChange = ev => this.setState({ prepend: ev.target.checked });

  render() {
    const { name, groupId, type, prepend, progress } = this.state;
    const {
      onClosePopover, types, groups, placeholder,
      submitText, infoText, typesNews
    } = this.props;
    const disabled = !name || progress;

    return (
      <div className="Popover-dialog-sm">
        <form autoComplete="off" onSubmit={this.handleAdd}>
          {infoText && (
            <div className="alert alert-sm alert-info text-center">
              {infoText}
            </div>
          )}
          <div className="form-group">
            <label className="control-label">Namn</label>
            <input
              type="text"
              className="form-control"
              placeholder={placeholder}
              ref={ref => {
                this.input = ref;
              }}
              onChange={ev => this.setState({ name: ev.target.value })}
              value={name}
            />
          </div>
          {types && (
            <div className="form-group">
              <label className="control-label">
                Typ
                {typesNews && <span className="label-news">NY</span>}
              </label>
              <div className="select-container">
                <select
                  name="group"
                  className="form-control"
                  value={type}
                  onChange={ev => this.setState({ type: ev.target.value })}
                >
                  {Object.keys(types).map(type => (
                    <option key={type} value={type}>
                      {types[type]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
          {groups && (
            <div className="form-group">
              <label className="control-label">Grupp</label>
              <div className="select-container">
                <select
                  name="group"
                  className="form-control"
                  value={groupId}
                  onChange={ev => this.setState({ groupId: ev.target.value })}
                >
                  {groups.map(group => (
                    <option key={group.get('id')} value={group.get('id')}>
                      {group.get('name')}
                    </option>
                  ))}
                </select>
              </div>
              <div className="checkbox popover-checkbox">
                <label>
                  <input type="checkbox" checked={prepend} onChange={this.prependChange} />
                  Lägg till överst i gruppen
                </label>
              </div>
            </div>
          )}
          <DialogButtonsHorizontal>
            <DialogButton small gray onClick={onClosePopover}>Avbryt</DialogButton>
            <DialogButton small primary type="submit" disabled={disabled}>{submitText || 'Lägg till'}</DialogButton>
          </DialogButtonsHorizontal>
        </form>
      </div>
    );
  }
}
