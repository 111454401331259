import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetSearch, saveSearchScrollPos } from '@State/booking-actions';
import { getCalendarUrl, navigate } from '@Utils/navigate';
import { viewDateFromDate } from '@Utils/time-util';
import { visualStylesHC, visualStylesNonHC } from '@Components/calendar/grid/chip-styles';
import { classes } from '@Components/ui/utils';
import { getCurrentLocation, getOrgWideCustomerDb } from '@State/selectors';
import SearchResultItem from './search-result-item';

class SearchResults extends Component {
  componentDidMount() {
    const { scrollPos } = this.props;
    this.resutlListEl.scrollTo({
      top: scrollPos,
      behavior: 'auto'
    });
  }

  bookingClick = (ev, booking) => {
    const { routeParams, locationOptions, orderedGroups } = this.props;
    const location = locationOptions.valueSeq().find(l => l.locationId === booking.get('locationId'));
    const resourceId = booking.get('resources').find(r => r.get('primary')).get('id');
    const myResources = orderedGroups.find(g => g.get('id') === 0);
    const isInMyResources = myResources?.get('resourceIds').includes(resourceId);
    const startTime = moment(booking.get('startTime'));
    const viewDate = viewDateFromDate('week', startTime);
    const newRouteParams = {
      ...routeParams,
      viewMode: 'week',
      entityType: isInMyResources ? 'my-resource' : 'resource',
      entityId: resourceId,
      org: location ? location.orgCtxName : routeParams.org,
      loc: location ? location.locCtxName : routeParams.loc
    };
    const calendarUrl = getCalendarUrl(viewDate, newRouteParams);
    this.props.setScrollPos(this.resutlListEl.scrollTop);
    navigate(`${calendarUrl}#${booking.get('id')}`);
  };

  resetSearch = (ev) => {
    ev.preventDefault();
    this.props.resetSearch();
  };

  render() {
    const {
      showSearch, query, isSearching, bookings, highContrast, selectedBookingId,
      locationOptions, currentLocation, tabletMode, orgWideCustomerDb
    } = this.props;
    const search = query && query.split(' ');
    const matches = (bookings && bookings.size) || 0;
    const visualStyles = highContrast ? visualStylesHC : visualStylesNonHC;
    const grouped = bookings && bookings.groupBy(b => moment(b.get('startTime')).format('MMMM YYYY'));

    const classList = classes({
      'search-results': true,
      ' show': showSearch
    });

    const style = tabletMode ? { paddingTop: '40px' } : {};
    return (
      <div className={classList} style={style} ref={(ref) => { this.resutlListEl = ref; }}>
        <div className="search-results-container">
          {query && !tabletMode && <a href="#" onClick={this.resetSearch} className="reset-search"><i className="fa fa-times-circle" /></a>}
          {query && (
          <p className="search-info">
            {matches} träffar på '
            {query}
            '
          </p>
          )}
          {isSearching && <p className="search-loading">Söker...</p>}
          {grouped && grouped.entrySeq().map(([group, results]) => (
            <div key={group} className="search-group">
              <h5>{group}</h5>
              {results && results.map(result => (
                <SearchResultItem
                  key={result.get('bookingId')}
                  booking={result.toJS()}
                  onClick={ev => this.bookingClick(ev, result)}
                  locationOptions={locationOptions}
                  currentLocation={currentLocation}
                  selectedBookingId={selectedBookingId}
                  visualStyles={visualStyles}
                  search={search}
                  orgWideCustomerDb={orgWideCustomerDb}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, { routeParams }) => {
  const {
    locationOptions, bookingSearchResults, gridViewState, mainViewState, orderedGroups
  } = state;

  return {
    routeParams,
    locationOptions,
    currentLocation: getCurrentLocation(state, { routeParams }),
    orgWideCustomerDb: getOrgWideCustomerDb(state),
    orderedGroups,
    query: bookingSearchResults.get('query'),
    showSearch: bookingSearchResults.get('showSearch'),
    isSearching: bookingSearchResults.get('isSearching'),
    bookings: bookingSearchResults.get('bookings'),
    scrollPos: bookingSearchResults.get('scrollPos'),
    tabletMode: mainViewState.get('tabletMode'),
    highContrast: gridViewState.get('highContrast'),
    selectedBookingId: routeParams.bookingId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetSearch: () => {
      dispatch(resetSearch());
    },
    setScrollPos: (scrollPos) => {
      dispatch(saveSearchScrollPos(scrollPos));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
