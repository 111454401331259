import React from 'react';
import PropTypes from 'prop-types';
import { DialogButtonsHorizontal, DialogButton } from '@Components/dialogs/dialog-styles';
import ColorPicker from '../inputs/color-picker';

export default class AddGroupForm extends React.Component {
  static propTypes = {
    onClosePopover: PropTypes.func.isRequired,
    onAddGroup: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      color: '',
      progress: false,
      checkboxes: props.checkboxes,
      prepend: false
    };
  }

  componentDidMount() {
    this.input.focus();
  }

  handleAdd = (ev) => {
    ev.preventDefault();
    this.setState({ progress: true });
    const { name, color, checkboxes, prepend } = this.state;
    const checkboxValues = {};
    checkboxes?.forEach(({ name, checked }) => {
      checkboxValues[name] = !!checked;
    });
    this.props.onAddGroup({ name, color, ...checkboxValues, prepend })
      .catch(() => this.setState({ progress: false }));
  };

  colorChange = color => this.setState({ color });

  prependChange = ev => this.setState({ prepend: ev.target.checked });

  checkboxChange = ev => {
    const { name, checked } = ev.target;
    this.setState(({ checkboxes }) => ({
      checkboxes: checkboxes.map(checkbox => ({
        ...checkbox,
        checked: checkbox.name === name ? checked : checkbox.checked
      }))
    }));
  };

  render() {
    const { name, color, checkboxes, progress, prepend } = this.state;
    const {
      onClosePopover, showColorPicker, colorPickerState,
      handleColorPickerOpen, handleColorPickerClose
    } = this.props;
    const disabled = !name || progress;

    return (
      <div className="Popover-dialog-sm">
        <form autoComplete="off" onSubmit={this.handleAdd}>
          <div className="form-group">
            <label className="control-label">Namn</label>
            <input
              type="text"
              className="form-control"
              placeholder="Ange namn på gruppen"
              ref={(ref) => { this.input = ref; }}
              onChange={ev => this.setState({ name: ev.target.value })}
              value={name}
            />
            <div className="checkbox popover-checkbox">
              <label>
                <input type="checkbox" checked={prepend} onChange={this.prependChange} />
                Lägg till överst i listan
              </label>
            </div>
          </div>
          {checkboxes?.map(({ name, label, checked = false }) => (
            <div key={name} className="form-group">
              <div className="checkbox popover-checkbox">
                <label>
                  <input type="checkbox" name={name} checked={checked} onChange={this.checkboxChange} />
                  {label}
                </label>
              </div>
            </div>
          ))}
          {showColorPicker && (
            <ColorPicker
              input={{ value: color, onChange: this.colorChange }}
              colorPickerState={colorPickerState}
              handleOpenPopover={handleColorPickerOpen}
              handleClosePopover={handleColorPickerClose}
            />
          )}
          <DialogButtonsHorizontal>
            <DialogButton small gray onClick={onClosePopover}>Avbryt</DialogButton>
            <DialogButton small primary type="submit" disabled={disabled}>Lägg till</DialogButton>
          </DialogButtonsHorizontal>
        </form>
      </div>
    );
  }
}
