import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Popover from '@Components/ui/popover';
import { Button, Column } from '@Components/ui/styled/main';
import useIsMounted from '@Hooks/useIsMounted';
import { txt } from '@Utils/i18n-util';
import { cancelBooking, sendBookingConfirmation } from '@State/booking-actions';
import SendConfirmation from './send-confirmation';
import ConfirmCancel from './confirm-cancel';
import msg from './booking-options.msg';
import form from './booking-form.msg';

const BookingClassOptions = ({ bookingId, customerIds, disabled, onSetCustomerIds, onShowSendSms }) => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();

  const [progress, setProgress] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const [showSendConfirmation, setShowSendConfirmation] = useState(false);
  const showButtons = !showConfirmCancel && !showSendConfirmation;

  const handleClose = () => {
    if (isMounted()) {
      onShowSendSms(false);
      setShowConfirmCancel(false);
      setShowMenu(false);
    }
  };

  const onSendConfirmation = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();

    const form = ev.target;
    const options = {
      sms: form.sms && form.sms.checked,
      email: form.email && form.email.checked
    };

    if (!options.sms && !options.email) return;

    setProgress(true);
    dispatch(sendBookingConfirmation({ bookingId, customerIds, options }))
      .then(handleClose)
      .finally(() => setProgress(false));
  };

  const onCancel = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();

    const form = ev.target;
    const options = {
      notifyBySms: form.notifyBySms?.checked,
      notifyByEmail: form.notifyByEmail?.checked,
      deleteBooking: form.deleteBooking?.checked,
      overrideCancellationPolicy: form.overrideCancellationPolicy?.checked
    };

    setProgress(true);
    return dispatch(cancelBooking({ bookingId, customerIds, options }))
      .then(() => {
        onSetCustomerIds([]);
        handleClose();
      })
      .finally(() => setProgress(false));
  };

  const renderPopupBody = (
    <div>
      {showConfirmCancel && (
        <div className="Popover-dialog-sm">
          <ConfirmCancel
            onCancel={() => setShowConfirmCancel(false)}
            onConfirm={onCancel}
            customerIds={customerIds}
            progress={progress}
          />
        </div>
      )}
      {showSendConfirmation && (
        <div className="Popover-dialog-sm">
          <SendConfirmation
            onCancel={() => setShowSendConfirmation(false)}
            onConfirm={onSendConfirmation}
            customerIds={customerIds}
            progress={progress}
          />
        </div>
      )}
      {showButtons && (
        <div className="Popover-dialog-sm button-wrapper">
          <Column className="menu-button-wrapper">
            <Button small gray block onClick={() => setShowSendConfirmation(true)}>
              <i className="far fa-paper-plane mr1" /> {txt(msg.btnSendConfirmation)}
            </Button>
            <Button small gray block onClick={onShowSendSms}>
              <i className="far fa-mobile mr1" />{txt(msg.btnSendSms)}
            </Button>
            <Button small danger block onClick={() => setShowConfirmCancel(true)}>
              <i className="fa fa-user-times mr1" /> {txt(msg.btnCancelBooking)}
            </Button>
          </Column>
        </div>
      )}
    </div>
  );

  return (
    <Popover
      preferPlace="below"
      isOpen={showMenu}
      onOuterAction={handleClose}
      body={renderPopupBody}
    >
      <button type="button" className="btn btn-default" disabled={disabled} onClick={() => setShowMenu(!showMenu)}>
        {txt(form.optionsBtnLabel)}
        <i className="far fa-chevron-down ml1" />
      </button>
    </Popover>
  );
};

export default BookingClassOptions;
