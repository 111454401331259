import moment from 'moment';
import PT from 'prop-types';
import s from 'underscore.string';
import { createSelector } from 'reselect';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, Field, getFormValues, reduxForm } from 'redux-form';
import ReactDOM from 'react-dom';
import { sms } from '@Utils/preference-keys';
import { setTime } from '@Utils/time-util';
import { changeBookingAttribute } from '@State/booking-actions';
import { isBookingCancelled, isBookingNew, isClassBooking } from '@Utils/booking-util';
import { setBKFAttributes, toggleBKFModal } from '@State/bkf/actions';
import { bkfModals } from '@State/bkf/constants';
import { txt } from '@Utils/i18n-util';
import { getStartTime } from './booking-form-util';
import BookingDetails from './booking-details';
import msg from './booking-info.msg';

class BookingInfo extends Component {
  static propTypes = {
    id: PT.oneOfType([PT.number, PT.string]).isRequired,
    status: PT.string.isRequired,
    bookedAs: PT.string,
    createdTime: PT.object,
    cancelledTs: PT.object,
    lastUpdateTime: PT.object,
    type: PT.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
      editTime: false
    };
  }

  focusHiddenField = () => {
    if (this.hidden) {
      const hidden = ReactDOM.findDOMNode(this.hidden);
      hidden.style.visibility = 'visible';
      hidden.focus();
      hidden.style.visibility = 'hidden';
    }
  };

  handleEdit = (ev) => {
    ev.preventDefault();
    this.focusHiddenField();
    this.setState({ editTime: true });
  };

  handleClose = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({ editTime: false });
  };

  showDetails = (ev) => {
    ev.target.blur();
    ev.preventDefault();
    this.setState({ showDetails: true });

    if (!this.props.allReminders) {
      this.props.onFetchReminders(this.props.id);
    }
  };

  hideDetails = (ev) => {
    ev.preventDefault();
    this.setState({ showDetails: false });
  };

  showEvents = (ev) => {
    ev.preventDefault();
    this.props.onShowEvents();
  };

  toggleSms = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const { id, customer, attributes } = this.props;
    this.props.onChangeAttributes(id, [customer.customerId], {
      [sms.reminderDisabled]: !attributes[sms.reminderDisabled]
    });
  };

  getTotalDuration = () => {
    const { serviceDuration, afterTime } = this.props.timeForm || {};
    return parseInt(serviceDuration || 0) + parseInt(afterTime || 0);
  };

  onChangeStart = () => {
    const duration = this.getTotalDuration();
    const startTime = getStartTime(this.props);
    const endTime = startTime.clone().add(duration, 'minutes');
    this.props.setFormValue('endTime', endTime.format('HH:mm'));
  };

  onChangeEnd = () => {
    const { endTime, timeForm } = this.props;
    const startTime = getStartTime(this.props);
    const newEndTime = setTime(endTime, timeForm.endTime);

    if (newEndTime.isSameOrBefore(startTime)) {
      const duration = this.getTotalDuration();
      const newStartTime = newEndTime.clone().subtract(duration, 'minutes');
      this.props.setFormValue('startTime', newStartTime.format('HH:mm'));
    } else {
      const afterTime = parseInt(timeForm.afterTime || 0);
      const duration = newEndTime.diff(startTime, 'minutes') - afterTime;
      this.props.setFormValue('serviceDuration', duration);
    }
  };

  onChangeDuration = () => {
    this.onChangeStart();
  };

  onChangeAfterTime = (ev) => {
    this.onChangeStart();
  };

  render() {
    const { showDetails, editTime } = this.state;
    const { startTime, endTime, timeForm, allReminders, attributes } = this.props;
    const { afterTime } = timeForm || {};

    const isNew = isBookingNew(this.props);
    const isClass = isClassBooking(this.props);
    const isCancelled = isBookingCancelled(this.props);

    const hasAfterTime = afterTime > 0;
    const displayEndTime = hasAfterTime ? moment(endTime).subtract(afterTime, 'm') : endTime;
    const hasUnsentReminders = allReminders?.some(r => r.smsStatus === 'PENDING');
    const reminderDisabled = attributes && attributes[sms.reminderDisabled];

    if (editTime) {
      return (
        <div className="booking-form-block form">
          <div className="row tight">
            <div className="form-group col-xs-6">
              <label className="control-label">{txt(msg.startTime)}</label>
              <Field name="startTime" component="input" type="time" className="form-control" onBlur={this.onChangeStart} />
            </div>
            <div className="form-group col-xs-6">
              <label className="control-label">{txt(msg.endTime)}</label>
              <Field name="endTime" component="input" type="time" className="form-control" onBlur={this.onChangeEnd} />
            </div>
          </div>
          <div className="row tight">
            <div className="form-group col-xs-6">
              <label className="control-label">{txt(msg.duration)}</label>
              <label className="form-control-label">{txt(msg.unitMinutes)}</label>
              <Field name="serviceDuration" component="input" type="number" className="form-control" ref={(ref) => { this.duration = ref; }} onBlur={this.onChangeDuration} />
            </div>
            <div className="form-group col-xs-6">
              <label className="control-label">{txt(msg.aftertime)}</label>
              <label className="form-control-label">{txt(msg.unitMinutes)}</label>
              <Field name="afterTime" component="input" type="number" className="form-control" onBlur={this.onChangeAfterTime} />
            </div>
          </div>
          <div className="text-right">
            <button className="btn-label" tabIndex={-1} onClick={this.handleClose}>{txt(msg.closeBtnLabel)}</button>
          </div>
        </div>
      );
    }

    return (
      <div className={isNew ? 'booking-form-block' : 'booking-form-block nopointer'} onClick={isNew ? this.handleEdit : null}>
        {startTime && endTime && (
          <div>
            <div className="block-buttons">
              {!isCancelled && <button className="btn-label" tabIndex={-1} onClick={this.handleEdit}>{txt(msg.editBtnLabel)}</button>}
            </div>
            <h4>
              {startTime.format('LT')} - {displayEndTime.format('LT')} {hasAfterTime && (
              <small>+ {afterTime} {txt(msg.unitMinutes)}</small>
              )}
            </h4>
            {!isNew && (
              <div className="pull-right">
                {showDetails
                  ? (
                    <button type="button" className="btn-link" tabIndex={-1} onClick={this.hideDetails}>
                      <i className="fa fa-chevron-circle-down" /> {txt(msg.hideDetailsBtnLabel)}
                    </button>
                  )
                  : (
                    <button type="button" className="btn-link" tabIndex={-1} onClick={this.showDetails}>
                      <i className="fa fa-chevron-circle-right" /> {txt(msg.showDetailsBtnLabel)}
                    </button>
                  )}
              </div>
            )}
            <small>
              {s.capitalize(startTime.format('dddd LL'))}
            </small>
          </div>
        )}
        {showDetails && (
          <>
            <BookingDetails />
            {hasUnsentReminders && !isClass && (
              <div className="pull-right">
                <button type="button" className="btn-label" tabIndex={-1} onClick={this.toggleSms}>
                  {txt(msg.toggleSmsBtnLabel)}
                  {reminderDisabled ? (
                    <i className="fa fa-toggle-off ml1" />
                  ) : (
                    <i className="fa fa-toggle-on text-success ml1" />
                  )}
                </button>
              </div>
            )}
            <div className="text-left">
              <button type="button" className="btn-label" tabIndex={-1} onClick={this.showEvents}>
                {txt(msg.showHistoryBtnLabel)}
              </button>
            </div>
          </>
        )}
      </div>
    );
  }
}

const initialValues = createSelector(
  state => state.bkf.get('startTime'),
  state => state.bkf.get('endTime'),
  state => state.bkf.get('service'),
  (startTime, endTime, service) => {
    return {
      startTime: moment(startTime).format('HH:mm'),
      endTime: moment(endTime).format('HH:mm'),
      ...service
    };
  }
);

const mapStateToProps = (state) => {
  const { bkf } = state;

  return {
    id: bkf.get('id'),
    type: bkf.get('type'),
    status: bkf.get('status'),
    initialValues: initialValues(state),
    allReminders: bkf.get('allReminders'),
    review: bkf.get('review'),
    customer: bkf.get('customer'),
    attributes: bkf.get('attributes'),
    startTime: bkf.get('startTime'),
    endTime: bkf.get('endTime'),
    timeForm: getFormValues('bkf-time')(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowEvents: () => dispatch(toggleBKFModal(bkfModals.events, true)),
    onChangeAttributes: (id, customerIds, attributes) => {
      dispatch(setBKFAttributes({ ...attributes }));
      dispatch(changeBookingAttribute({
        bookingId: id, customerIds, attributes
      }));
    },
    setFormValue: (field, value) => dispatch(change('bkf-time', field, value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'bkf-time',
  destroyOnUnmount: false
})(BookingInfo));
