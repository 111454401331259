import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleBKFModal } from '@State/bkf/actions';
import { bkfModals } from '@State/bkf/constants';
import { addExternalPayment, completePrePayment } from '@State/booking-actions';
import ReceiptModal from '@Components/pos/payment/receipt-modal';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import ModalDialog from '@Components/dialogs/modal-dialog';
import BookingPaymentForm from './booking-payment-form';

const BookingPaymentModal = () => {
  const dispatch = useDispatch();
  const posOrgs = useSelector(state => state.posOrgs);
  const bookingId = useSelector(({ bkf }) => bkf.get('id'));
  const customer = useSelector(({ bkf }) => bkf.get('customer'));
  const { price } = useSelector(({ bkf }) => bkf.get('service') || {});
  const payments = useSelector(({ bkf }) => bkf.get('payments'));

  const amountPaid = parseFloat(payments?.reduce((a, b) => a + b.amount, 0) || 0);
  const amountRemaining = Math.max(0, price - amountPaid);

  const [posOrgId, setPosOrgId] = useState(null);
  const [payment, setPayment] = useState(null);

  useEffect(() => {
    if (posOrgs.size === 1) {
      setPosOrgId(posOrgs.first().get('id'));
    }
  }, [posOrgs]);

  const initialValues = useMemo(() => ({
    bookingId,
    customerBookingId: customer?.customerBookingId,
    posPaymentMethod: 'PrepaidBankPayment',
    paymentDate: moment().format('L')
  }), [posOrgs, bookingId, customer]);

  const onSubmit = (data) => {
    return dispatch(addExternalPayment({ posOrgId, ...data }))
      .then((payment) => setPayment(payment));
  };

  const onClose = () => {
    dispatch(toggleBKFModal(bkfModals.payment, false));
    setPosOrgId(null);
    setPayment(null);
  };

  const onComplete = (receiptData) => {
    const { paymentRef } = payment;
    return dispatch(completePrePayment({ paymentRef, ...receiptData }))
      .then(onClose);
  };

  if (posOrgs.isEmpty()) {
    return null;
  }

  if (posOrgs.size > 1 && !posOrgId) {
    return (
      <ModalDialog
        onClose={onClose}
        contentSize="small"
        title="Välj företag"
        closeOnClickOutside={false}
        buttons={[
          ...posOrgs.map((posOrg) => ({
            gray: true,
            name: posOrg.get('companyName'),
            onClick: () => setPosOrgId(posOrg.get('id'))
          }))
        ]}
      />
    );
  }

  if (payment) {
    return (
      <ReceiptModal
        hidePrint
        customer={customer}
        bookingId={bookingId}
        totalAmount={payment.amount}
        onSubmit={onComplete}
      />
    );
  }

  return (
    <ModalDialog
      onClose={onClose}
      contentSize="small"
      title="Registrera betalning"
      closeOnClickOutside={false}
    >
      <AlertWithIcon icon="fa fa-info-circle">
        Om beloppet motsvarar bokningens totala pris så blir bokningen betald.
        Annars visas beloppet som en förbetalning och räknas av från belopp att betala i kassan.
      </AlertWithIcon>
      <BookingPaymentForm
        posOrgs={posOrgs}
        onClose={onClose}
        onSubmit={onSubmit}
        amountRemaining={amountRemaining}
        initialValues={initialValues}
      />
    </ModalDialog>
  );
};

export default BookingPaymentModal;
