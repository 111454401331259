import React from 'react';
import { useSelector } from 'react-redux';
import { getRouteParams, getFeatures } from '@State/selectors';
import Header, { Toolbar } from '@Components/nav/header';
import StaffReportToolbar from './staff/staff-report-toolbar';
import StockReportToolbar from './stock/stock-report-toolbar';

const ReportsToolbar = ({ routeParams }) => {
  const { subsection } = routeParams;
  switch (subsection) {
    case 'staff':
      return <StaffReportToolbar routeParams={routeParams} />;
    case 'stock':
      return <StockReportToolbar routeParams={routeParams} />;
    default:
      return null;
  }
};

const ReportsHeader = (props) => {
  const { EnableReports } = useSelector(getFeatures);
  const routeParams = useSelector(state => getRouteParams(state, props));

  if (!EnableReports) {
    return <Header routeParams={routeParams} />;
  }

  return (
    <Header className="thin-header with-subnav" routeParams={routeParams}>
      <Toolbar>
        <ReportsToolbar routeParams={routeParams} />
      </Toolbar>
    </Header>
  );
};

export default ReportsHeader;
