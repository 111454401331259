import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomerAssociation, fetchCustomerAssociations, removeCustomerAssociation } from '@State/customer-actions';
import { PanelWrapper, StyledLink, TitleBlock } from '@Components/customers/customer-styles';
import useCustomerSelect from '@Components/customers/useCustomerSelect';
import PopoverDeleteButton from '@Components/ui/popover-delete-button';
import ColumnListItem from '@Components/ui/column/column-list-item';
import { CustomerType, getCustomerTypeIcon } from '@Utils/customer-util';
import SearchCustomerModal from '@Components/customers/search-customer-modal';
import CustomerListSubtext from '@Components/customers/customer-list-subtext';
import { txt } from '@Utils/i18n-util';
import msg from './customer-information.msg';

const CustomerCompany = ({ hasChangePermission, routeParams, selectedCustomer }) => {
  const dispatch = useDispatch();
  const onCustomerSelect = useCustomerSelect(routeParams);
  const { id, customerType } = selectedCustomer || {};
  const customerAssociations = useSelector((state) => state.customerAssociations);
  const loading = customerAssociations.get('loading');
  const associations = customerAssociations.get('associations');
  const associationType = customerType === CustomerType.Vehicle ? 'VehicleOfCompany' : 'EmployeeOfCompany';
  const company = associations.find((a) => a.associationType === associationType);

  useEffect(() => {
    if (id) {
      dispatch(fetchCustomerAssociations(id));
    }
  }, [id]);

  const [showCustomerCompanyModal, setShowCustomerCompanyModal] = useState(false);
  const hideCustomerCompanyModal = () => setShowCustomerCompanyModal(false);

  const handleSetCustomerCompany = ({ customerId }) => {
    return dispatch(addCustomerAssociation(id, customerId, associationType))
      .then(hideCustomerCompanyModal);
  };

  const handleRemoveCustomerCompany = () => {
    return dispatch(removeCustomerAssociation(id, company.otherCustomerId));
  };

  if (!company && !hasChangePermission) {
    return null;
  }

  return (
    <>
      <TitleBlock>{txt(msg.lblCompany)}</TitleBlock>
      {company ? (
        <ColumnListItem
          key={company.otherCustomerId}
          onClick={() => onCustomerSelect(company)}
          icon={getCustomerTypeIcon(CustomerType.Company)}
          header={company.name}
          subText={<CustomerListSubtext customer={company} />}
          buttons={hasChangePermission && (
            <PopoverDeleteButton
              onDelete={handleRemoveCustomerCompany}
              buttonText={<i className="fa fa-trash" />}
              confirmText={`Ta bort koppling till ${company.name}?`}
              deleteText="Ta bort"
              white
            />
          )}
        />
      ) : (
        <PanelWrapper onClick={() => setShowCustomerCompanyModal(true)}>
          <StyledLink>{txt(msg.lblAddToCompany)}</StyledLink>
        </PanelWrapper>
      )}
      {showCustomerCompanyModal && (
        <SearchCustomerModal
          routeParams={routeParams}
          onSelected={handleSetCustomerCompany}
          onCancel={hideCustomerCompanyModal}
          scope={[CustomerType.Company]}
        />
      )}
    </>
  );
};

export default CustomerCompany;
