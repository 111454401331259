import { globals } from '@Utils/globals';
import { isYabieUrl } from './url-util';

export function clientBuildNumber() {
  if (globals.CLIENT_BUILD_NUMBER.includes('PLACEHOLDER')) {
    return 'LOCAL';
  }
  return globals.CLIENT_BUILD_NUMBER;
}

export function isDevelopment() {
  return process.env.NODE_ENV === 'development';
}

export function isRunningLocal() {
  return clientBuildNumber() === 'LOCAL';
}

export function sentryDSN() {
  if (globals.SENTRY_DSN.includes('PLACEHOLDER')) {
    return 'LOCAL_DEV';
  }
  return globals.SENTRY_DSN;
}

export function apiUrl() {
  const { hostname } = window.location;
  if (hostname === 'localhost' || hostname === '0.0.0.0') {
    return 'https://pre-app.cliento.com';
  }
  return `https://${hostname}`;
}

export function bookingApiUrl() {
  const { hostname } = window.location;
  if (hostname === 'localhost' || hostname === '0.0.0.0') {
    return 'https://preview.cliento.com';
  }
  const { origin } = new URL(webBookingUrl());
  return origin;
}

export function loginUrl(suffix) {
  if (globals.LOGIN_URL.includes('PLACEHOLDER') || isYabieUrl()) {
    return '/login';
  }
  return globals.LOGIN_URL + (suffix || '');
}

export function businessUrl() {
  if (globals.BUSINESS_URL.includes('PLACEHOLDER')) {
    return 'https://cliento.com/business/';
  }
  return globals.BUSINESS_URL;
}

export function webBookingUrl() {
  if (globals.WEB_BOOKING_URL.includes('PLACEHOLDER')) {
    return 'https://cliento.com/bookingapp/';
  }
  return globals.WEB_BOOKING_URL;
}

export function vipBookingUrl() {
  if (globals.VIP_BOOKING_URL.includes('PLACEHOLDER')) {
    return 'https://cliento.com/vip/';
  }
  return globals.VIP_BOOKING_URL;
}

export function widgetBaseUrl() {
  if (globals.WIDGET_BASE_URL.includes('PLACEHOLDER')) {
    return 'https://cliento.com/widget/';
  }
  return globals.WIDGET_BASE_URL;
}

function isUsingRemoteApi() {
  return apiUrl().indexOf('cliento.com') !== -1;
}

export function pusherKey() {
  if (globals.PUSHER_KEY.includes('PLACEHOLDER')) {
    if (isUsingRemoteApi()) {
      return '394a62f0ce0f35877acc'; // Use "Cliento Preview" pusher
    }
    return '891be3f163031c7c79cc'; //  Use "Cliento Local Dev" pusher
  }
  return globals.PUSHER_KEY;
}

export function pusherCluster() {
  if (globals.PUSHER_CLUSTER.includes('PLACEHOLDER')) {
    if (isUsingRemoteApi()) {
      return 'eu'; // Use "Cliento Preview" pusher
    }
    return 'mt1'; //  Use "Cliento Local Dev" pusher
  }
  return globals.PUSHER_CLUSTER;
}

export function stripeKey() {
  if (globals.STRIPE_KEY.includes('PLACEHOLDER')) {
    return 'pk_test_8RCjP6aLCQRK9Dzpu1lY3B2U';
  }
  return globals.STRIPE_KEY;
}

export function authCookieDomain() {
  if (globals.AUTH_COOKIE_DOMAIN.includes('PLACEHOLDER')) {
    return null;
  }
  return globals.AUTH_COOKIE_DOMAIN;
}

export function authCookieName() {
  if (globals.AUTH_COOKIE_NAME.includes('PLACEHOLDER')) {
    return '_ct_local';
  }
  if (window.location.hostname.startsWith('embed-app')) {
    // This is required when using preview web for live app
    return '_ct';
  }
  return globals.AUTH_COOKIE_NAME;
}

export function deployEnvironment() {
  if (globals.DEPLOY_ENVIRONMENT.includes('PLACEHOLDER')) {
    return 'Local';
  }
  return globals.DEPLOY_ENVIRONMENT;
}
