import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { ChipDragHandler } from '../grid/paste-chip-draghandler';
import { styles, chipStatusStyle } from '../grid/chip-styles';

class ClipboardItem extends Component {
  constructor(props) {
    super(props);
    this.state = this.makeInitialState();
  }

  makeInitialState() {
    return {
      isDragging: false
    };
  }

  render() {
    const { booking } = this.props;
    const iconClass = booking.copyOnPaste ? 'far fa-copy' : 'fa fa-cut';
    const closeClass = this.state.isDragging ? 'fa fa-times invisible' : 'fa fa-times tap-close';
    const chipStyles = this.styles();

    return (
      <div className="btn btn-sm animated pulse" style={chipStyles}>
        {this.state.isDragging ? '' : (
          <a
            href="#"
            className="tap-close"
            style={{
              float: 'right', padding: '6px 10px 3px', margin: '-5px -10px -5px 0', color: chipStyles.color
            }}
            onClick={this.cancelPasteBooking}
          >
            <i className={closeClass} />
          </a>
        ) }

        <span>
          { this.cramped() ? '' : <i className={iconClass} /> } {this.label()}
        </span>
      </div>
    );
  }

  cramped() {
    return this.props.averageClipSize < 150;
  }

  label() {
    const { booking } = this.props;
    const { customers, service, startTime } = booking;
    const customer = customers?.length === 1 ? customers[0] : null;
    const { name, bookedAs } = customer || {};
    const time = startTime.format('DD/MM LT');

    return `${bookedAs || name || service || time} (${this.duration()} min)`;
  }

  duration() {
    return this.props.booking.endTime.diff(this.props.booking.startTime, 'minutes');
  }

  styles() {
    const { type, status } = this.props.booking;
    return {
      ...(this.state.isDragging ? styles.chips.placeholder : styles.chips.base),
      ...(this.state.isDragging ? { width: this.width } : chipStatusStyle(type, status, this.props.highContrast)),
      paddingLeft: this.cramped() ? 4 : 8
    };
  }

  cancelPasteBooking = (ev) => {
    ev.preventDefault();
    this.props.onCancelItem(this.props.booking.id);
    ev.target.blur();
  };

  draggable() {
    if (!this.chipDragHandler) {
      this.chipDragHandler = new ChipDragHandler(this);
    }
  }

  undraggable() {
    if (this.chipDragHandler) {
      this.chipDragHandler.dispose();
      this.chipDragHandler = undefined;
    }
  }

  componentDidMount() {
    this.draggable(this);
    const chipEl = ReactDOM.findDOMNode(this);
    const rect = chipEl.getBoundingClientRect();
    this.width = rect.width;
  }

  componentDidUpdate(prevProps, prevState) {
    const chipEl = ReactDOM.findDOMNode(this);
    const rect = chipEl.getBoundingClientRect();

    if (!this.state.isDragging) {
      this.width = rect.width;
    }
  }

  componentWillUnmount() {
    this.undraggable();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.chipDragHandler) {
      this.chipDragHandler.UNSAFE_componentWillReceiveProps(nextProps);
    }

    this.setState({ isDragging: nextProps.draggerVisible });
  }
}

ClipboardItem.propTypes = {
  highContrast: PropTypes.bool.isRequired,
  averageClipSize: PropTypes.number.isRequired,
  booking: PropTypes.object.isRequired,
  gridProps: PropTypes.object.isRequired,
  onClosePopover: PropTypes.func.isRequired,
  onCancelItem: PropTypes.func.isRequired,
  onAddChipDragger: PropTypes.func.isRequired,
  onRemoveChipDragger: PropTypes.func.isRequired,
  onAddClipboardDragger: PropTypes.func.isRequired,
  onPasteBooking: PropTypes.func.isRequired
};

export default ClipboardItem;
