import { prefixUrl, axiosGet, axiosPost, axiosPut, axiosDelete } from '@Utils/ajax-util';

export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';

function fetchCampaignsSuccess(data) {
  return {
    type: GET_CAMPAIGNS,
    state: {
      campaigns: data
    }
  };
}

function createCampaignSuccess(data) {
  return {
    type: CREATE_CAMPAIGN,
    campaign: data
  };
}

export function updateCampaignSuccess(campaign) {
  return {
    type: UPDATE_CAMPAIGN,
    campaign
  };
}

export function fetchCampaigns() {
  const url = prefixUrl('/marketingcampaign/');
  return (dispatch) => dispatch(axiosGet(url, {
    onSuccess: (res) => dispatch(fetchCampaignsSuccess(res.data.campaigns ?? []))
  }));
}

export function fetchCampaignEstimate(id) {
  const url = prefixUrl(`/marketingcampaign/${id}/estimate`);
  return axiosGet(url, {
    onSuccess: (res) => res.data
  });
}

export function fetchCampaignEstimateForSearch(data) {
  const url = prefixUrl('/marketingcampaign/estimate');
  return axiosPost(url, data, {
    onSuccess: (res) => res.data
  });
}

export function createCampaign(data) {
  const url = prefixUrl('/marketingcampaign/');
  return (dispatch) => dispatch(axiosPost(url, data, {
    onSuccess: (res) => {
      dispatch(createCampaignSuccess({ ...data, ...res.data, name: data.campaignName, type: data.campaignType }));
      return res.data;
    }
  }));
}

export function updateCampaign({ id, ...data }) {
  const url = prefixUrl(`/marketingcampaign/${id}`);
  return (dispatch) => dispatch(axiosPut(url, data, {
    onSuccess: (res) => {
      dispatch(updateCampaignSuccess({ id: parseInt(id), ...data, ...res.data }));
      return res.data;
    }
  }));
}

export function scheduleCampaign(id, data) {
  const url = prefixUrl(`/marketingcampaign/${id}/schedule`);
  return (dispatch) => dispatch(axiosPost(url, data, {
    onSuccess: (res) => {
      dispatch(updateCampaignSuccess({ id: parseInt(id), ...data, ...res.data }));
      return res.data;
    }
  }));
}

export function fetchCampaignStatus(id) {
  const url = prefixUrl(`/marketingcampaign/${id}/sent-count`);
  return (dispatch) => dispatch(axiosGet(url, {
    onSuccess: (res) => {
      dispatch(updateCampaignSuccess({ id: parseInt(id), ...res.data }));
      return res.data;
    }
  }));
}

export function fetchCampaignRecipients(id) {
  const url = prefixUrl(`/marketingcampaign/${id}/recipients/`);
  return axiosGet(url, {
    onSuccess: (res) => res.data
  });
}

export function fetchCampaignRecipientsForSearch(data) {
  const url = prefixUrl('/marketingcampaign/preview-recipients');
  return axiosPost(url, data, {
    onSuccess: (res) => res.data
  });
}

export function cancelCampaign(id) {
  const url = prefixUrl(`/marketingcampaign/${id}/cancel`);
  return (dispatch) => dispatch(axiosPut(url, null, {
    onSuccess: (res) => {
      dispatch(updateCampaignSuccess({ id: parseInt(id), ...res.data }));
      return res.data;
    }
  }));
}

export function deleteCampaign(id) {
  const url = prefixUrl(`/marketingcampaign/${id}`);
  return (dispatch) => dispatch(axiosDelete(url, {
    onSuccess: () => dispatch({ type: DELETE_CAMPAIGN, campaignId: id })
  }));
}
