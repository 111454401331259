import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import debounce from 'lodash/debounce';
import Autosuggest from 'react-autosuggest';
import Highlighter from 'react-highlight-words';
import { fetchSuggestions } from '@State/bkf/actions';
import { CUSTOMER_SEARCH_DEBOUNCE } from '@Utils/constants';
import { getOrgWideCustomerDb } from '@State/selectors';
import { formatVehicleRegNo } from '@Utils/vehicle-util';
import { CustomerType, firstLetterUpperCase } from '@Utils/customer-util';

import PhoneUtil from '@Utils/phone-util';
import { txt } from '@Utils/i18n-util';
import Bouncefix from './bouncefix';
import msg from './search-customer.msg';

class SearchCustomer extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    onSelected: PropTypes.func.isRequired,
    deviceType: PropTypes.string,
    deviceOs: PropTypes.string,
    externalKeyboard: PropTypes.bool,
    scope: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
      suggestions: [],
      isLoading: false,
      inputFocused: true
    };

    this.scopeDescription = this.getScopeDescription(props.scope);
    this.includeVehicle = props.scope.indexOf(CustomerType.Vehicle) !== -1;
    this.includeCompany = props.scope.indexOf(CustomerType.Company) !== -1;
    this.includeCustomer = props.scope.indexOf(CustomerType.Person) !== -1;

    this.debouncedFetchSuggestions = debounce(this.fetchSuggestions, CUSTOMER_SEARCH_DEBOUNCE);
  }

  getScopeDescription = (scope) => {
    if (scope.length === 1 && scope[0] === CustomerType.Vehicle) {
      return txt(msg.lblVehicle);
    }
    if (scope.length === 1 && scope[0] === CustomerType.Company) {
      return txt(msg.lblCompany);
    }
    return txt(msg.lblCustomer);
  };

  getScopeIcon = (scope) => {
    if (scope === CustomerType.Vehicle) {
      return 'fa fa-fw fa-car text-muted';
    }
    if (scope === CustomerType.Company) {
      return 'fa fa-fw fa-building text-muted';
    }
    return 'fa fa-fw fa-user text-muted';
  };

  componentDidMount() {
    if (this.searchField) {
      const input = ReactDOM.findDOMNode(this.searchField);
      input.value = ''; // Hack to set focus to the end
      input.focus();
    }
  }

  onSuggestionsFetchRequested = ({ value, reason }) => {
    if (reason === 'suggestion-selected') {
      return;
    }
    if (value?.length <= 2) {
      this.onSuggestionsClearRequested();
      return;
    }

    this.debouncedFetchSuggestions(value);
  };

  fetchSuggestions = (value) => {
    const { scope } = this.props;
    this.setState({ isLoading: true });
    return this.props.fetchSuggestions(value, scope, scope.length === 1)
      .then((suggestions) => {
        const items = [];
        if (this.includeVehicle) {
          items.push({ scope: CustomerType.Vehicle });
        }
        if (this.includeCompany) {
          items.push({ scope: CustomerType.Company });
        }
        if (this.includeCustomer) {
          items.push({ scope: CustomerType.Person });
        }
        this.setState({ isLoading: false, suggestions: items.concat(suggestions) });
      });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [], suggestionSelected: false });
  };

  onSuggestionHighlighted = ({ suggestion }) => {
    this.highlightedSuggestion = suggestion;
  };

  onSuggestionSelected = (event, { suggestion }) => {
    event.preventDefault();
    event.stopPropagation();
    this.selectCustomer(suggestion);
  };

  selectCustomer = (suggestion) => {
    if (!suggestion) {
      return;
    }
    if (suggestion.customerType) {
      this.props.onSelected(this.mapSuggestion(suggestion));
    } else {
      const newSuggestion = this.createSuggestion(suggestion.scope, this.state.value);
      if (newSuggestion) {
        this.props.onSelected(this.mapSuggestion(newSuggestion));
      }
    }
  };

  mapCustomer = ({
    customerId, customerType, name, phoneNumber, otherPhoneNumber, officialIdNo, email, attributes
  }) => {
    switch (customerType) {
      case CustomerType.Person:
        return {
          customerType, customerId, name, email, phoneNumber, otherPhoneNumber, officialIdNo
        };
      case CustomerType.Company:
        return {
          customerType, customerId, name, officialIdNo
        };
      case CustomerType.Vehicle:
      case CustomerType.VehicleRegistry:
        return {
          customerType: CustomerType.Vehicle, customerId, name, officialIdNo, attributes
        };
    }
  };

  mapSuggestion = (suggestion) => {
    const related = suggestion.related?.map((r) => this.mapCustomer(r)) || [];
    const customers = [this.mapCustomer(suggestion), ...related];

    const customer = customers.find((c) => c.customerType === CustomerType.Person);
    const company = customers.find((c) => c.customerType === CustomerType.Company);
    const vehicle = customers.find((c) => c.customerType === CustomerType.Vehicle)
      || customers.find((c) => c.customerType === CustomerType.VehicleRegistry);

    return {
      customer: this.includeCustomer ? customer : null,
      company: this.includeCompany ? company : null,
      vehicle: this.includeVehicle ? vehicle : null
    };
  };

  createSuggestion = (scope, value) => {
    if (value && value.length > 0) {
      const isDigits = value.match(/^\d+$/);

      if (scope === CustomerType.Vehicle) {
        return { customerType: CustomerType.Vehicle, officialIdNo: formatVehicleRegNo(value) };
      }
      if (scope === CustomerType.Company) {
        return isDigits
          ? { customerType: CustomerType.Company, officialIdNo: value }
          : { customerType: CustomerType.Company, name: firstLetterUpperCase(value) };
      }
      return isDigits
        ? { customerType: CustomerType.Person, phoneNumber: value }
        : { customerType: CustomerType.Person, name: firstLetterUpperCase(value) };
    }
  };

  onChange = (event, { newValue }) => {
    this.setState({ value: newValue });
  };

  handleKeyDown = (ev) => {
    if (ev.keyCode === 9 || ev.keyCode === 13) {
      ev.preventDefault();
      this.selectCustomer(this.highlightedSuggestion);
    }
  };

  handleCancel = (ev) => {
    ev.preventDefault();
    this.props.onCancel();
  };

  renderVehicle = (search, vehicleRegNo, vehicleDescription) => {
    const icon = <i className={this.getScopeIcon(CustomerType.Vehicle)} />;
    const empty = <i className="fa fa-fw" />;

    return this.includeVehicle && (vehicleRegNo || vehicleDescription) && (
      <section>
        <div>
          {icon} <strong><Highlighter searchWords={search} textToHighlight={vehicleRegNo} autoEscape /></strong>
        </div>
        {vehicleDescription && (
        <div>
          {empty} <Highlighter searchWords={search} textToHighlight={vehicleDescription} autoEscape />
        </div>
        )}
      </section>
    );
  };

  renderCompany = (search, orgName, orgNo) => {
    const icon = <i className={this.getScopeIcon(CustomerType.Company)} />;
    const empty = <i className="fa fa-fw" />;

    return this.includeCompany && (orgName || orgNo) && (
      <section>
        <div>
          {icon} <strong><Highlighter searchWords={search} textToHighlight={orgName} autoEscape /></strong>
        </div>
        {orgNo && (
        <div>
          {empty} <Highlighter searchWords={search} textToHighlight={orgNo} autoEscape />
        </div>
        )}
      </section>
    );
  };

  renderCustomer = (search, name, phoneNumbers, email) => {
    const icon = <i className={this.getScopeIcon(CustomerType.Person)} />;
    const empty = <i className="fa fa-fw" />;

    return this.includeCustomer && (name || phoneNumbers.length > 0 || email) && (
      <section>
        <div>
          {icon} <strong><Highlighter searchWords={search} textToHighlight={name} autoEscape /></strong>
        </div>
        {phoneNumbers.length > 0 && (
        <div>
          {empty} <Highlighter searchWords={search} textToHighlight={phoneNumbers.join(' / ')} autoEscape />
        </div>
        )}
        {email && (
        <div>
          {empty} <Highlighter searchWords={search} textToHighlight={email} autoEscape />
        </div>
        )}
      </section>
    );
  };

  renderLatestBooking = (latestBookingTs) => {
    return latestBookingTs && (
      <section className="text-muted">
        <i className="fa fa-fw" /> <small><em>{txt(msg.lblLatestBooking)} {moment(latestBookingTs).format('LLL')}</em></small>
      </section>
    );
  };

  renderVehicleRegistryInfo = (customerType) => {
    return this.includeVehicle && customerType === 'VehicleRegistry' && (
      <section className="text-muted">
        <i className="fa fa-fw" /> <small><em>{txt(msg.lblVehicleInfoSource)}</em></small>
      </section>
    );
  };

  renderLocationInfo = (locationId) => {
    return this.props.orgWideCustomerDb && (
      <section className="text-muted">
        <i className="fa fa-fw" /> <small><em><i className="far fa-location-dot mr1" />{this.props.locationNames.get(locationId)}</em></small>
      </section>
    );
  };

  renderCreateItem = (scope) => {
    return (
      <div className="booking-form-suggestion new-item">
        <section>
          <i className={this.getScopeIcon(scope)} /> <strong>{txt(msg.btnAddItem, { description: this.getScopeDescription([scope]) })}</strong>
        </section>
      </div>
    );
  };

  renderCustomerInfo = ({
    customerType, officialIdNo, name, phoneNumber, otherPhoneNumber, email
  }) => {
    const search = [this.state.value];

    const phoneNumbers = [];
    if (phoneNumber) {
      phoneNumbers.push(PhoneUtil.formatPhoneNumber(phoneNumber));
    }
    if (otherPhoneNumber) {
      phoneNumbers.push(PhoneUtil.formatPhoneNumber(otherPhoneNumber));
    }

    switch (customerType) {
      case CustomerType.Vehicle:
      case CustomerType.VehicleRegistry:
        return this.renderVehicle(search, officialIdNo, name);
      case CustomerType.Company:
        return this.renderCompany(search, name, officialIdNo);
      case CustomerType.Person:
        return this.renderCustomer(search, name, phoneNumbers, email);
    }
  };

  renderSuggestion = (props) => {
    const { scope, customerType, latestBookingTs, locationId, related } = props;

    return customerType ? (
      <div className="booking-form-suggestion">
        {this.renderCustomerInfo(props)}
        {related?.map((r) => this.renderCustomerInfo(r))}
        {this.renderLocationInfo(locationId)}
        {this.renderLatestBooking(latestBookingTs)}
        {this.renderVehicleRegistryInfo(customerType)}
      </div>
    ) : this.renderCreateItem(scope);
  };

  renderInputComponent = (inputProps) => {
    return (
      <input
        {...inputProps}
        className="form-control"
        onFocus={(ev) => { inputProps.onFocus(ev); this.setState({ inputFocused: true }); }}
        /* setTimeout required on iOS, otherwise the 'select' event is ignored */
        onBlur={() => { setTimeout(() => { this.setState({ inputFocused: false }); }, 10); }}
        /* Important! Dont remove inputProps.ref call, subtle bug will be happening... */
        ref={(ref) => { inputProps.ref(ref); this.searchField = ref; }}
      />
    );
  };

  render() {
    const { height, maxHeight, noChrome, disabled } = this.props;
    const { value, suggestions } = this.state;
    const iosWithOSKOpen = this.props.deviceOs === 'iOS' && !this.props.externalKeyboard && this.state.inputFocused !== false;
    const inputProps = {
      placeholder: txt(msg.placeholderAddItem, { description: this.scopeDescription }),
      onKeyDown: this.handleKeyDown,
      onChange: this.onChange,
      value
    };

    const theme = {
      suggestionsList: 'booking-form-suggestions',
      suggestionHighlighted: 'highlighted',
      suggestionsContainer: iosWithOSKOpen ? 'booking-form-suggestions-container-ios' : 'booking-form-suggestions-container'
    };

    return (
      <div className={noChrome ? '' : 'booking-form'}>
        {!noChrome && (
          <div className="booking-form-header">
            <div className="cancel">
              <a href="#" onClick={this.handleCancel}>
                <i className="fa fa-chevron-left" /> {txt(msg.btnBack)}
              </a>
            </div>
            <h4 className="title">
              {txt(msg.lblTitle, { description: this.scopeDescription })}
            </h4>
            <div className="save" />
          </div>
        )}
        <Bouncefix className={disabled ? 'booking-form-body disabled' : 'booking-form-body'} style={{ height, maxHeight }}>
          <Autosuggest
            theme={theme}
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionHighlighted={this.onSuggestionHighlighted}
            onSuggestionSelected={this.onSuggestionSelected}
            renderInputComponent={this.renderInputComponent}
            renderSuggestion={this.renderSuggestion}
            getSuggestionValue={() => value}
            alwaysRenderSuggestions
            shouldRenderSuggestions={() => true}
            focusInputOnSuggestionClick={false}
            inputProps={inputProps}
          />
        </Bouncefix>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orgWideCustomerDb: getOrgWideCustomerDb(state),
    locationNames: state.locationNames
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSuggestions: (query, scope, distinct) => {
      return dispatch(fetchSuggestions(query, scope, distinct));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchCustomer);
