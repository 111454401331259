import React, { PureComponent } from 'react';
import { txt } from '@Utils/i18n-util';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import msg from './sms-length-label.msg';

class SmsLengthLabel extends PureComponent {
  render() {
    const { text, campaign } = this.props;
    const textLength = parseInt(text?.length || 0);
    const warningLimit = campaign ? 160 : 150;
    const showWarning = textLength > warningLimit;
    const messageCount = textLength <= 160 ? 1 : Math.ceil(textLength / 153);
    const messageCountText = campaign ? txt(msg.lblSmsPerRecipient) : txt(msg.lblSmsCount);

    let messageClass = 'label label-success';
    if (messageCount > 6) {
      messageClass = 'label label-danger';
    } else if (showWarning) {
      messageClass = 'label label-warning ';
    }

    return (
      <>
        <p className="mt1">
          <span className={messageClass}>
            {txt(msg.lblCharCount)}: {textLength} ({messageCountText}: {messageCount})
          </span>
        </p>
        {showWarning && campaign && (
          <AlertWithIcon warning>
            <strong>{txt(msg.lblNote)}</strong> {txt(msg.txtWarningCampaign)}
          </AlertWithIcon>
        )}
        {showWarning && !campaign && (
          <AlertWithIcon warning>
            <p><strong>{txt(msg.lblNote)}</strong> {txt(msg.txtWarningMessage1)}</p>
            {txt(msg.txtWarningMessage2)}
          </AlertWithIcon>
        )}
      </>
    );
  }
}

export default SmsLengthLabel;
