import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getGroupUrl, getResourceUrl } from '@Utils/navigate';
import Select from '@Components/ui/select/select';
import ResourceSelector from '../resource-selector/resource-selector';

const CalendarHeaderResourceTablet = ({ calendarTitle, routeParams }) => {
  const [selectedValue, setSelectedValue] = useState(calendarTitle);
  useEffect(() => {
    if (calendarTitle) setSelectedValue(calendarTitle);
  }, [calendarTitle]);

  const resolveResourceTargetUrl = (resourceId, resourceEntityType) => {
    return getResourceUrl(resourceId, resourceEntityType, 'week', routeParams);
  };

  const resolveGroupTargetUrl = (groupId) => {
    return getGroupUrl(groupId, routeParams);
  };

  return (
    <div className="resource-selector-tablet">
      <Select value={selectedValue}>
        <ResourceSelector
          routeParams={routeParams}
          resourceTargetUrlResolver={resolveResourceTargetUrl}
          groupTargetUrlResolver={resolveGroupTargetUrl}
        />
      </Select>
    </div>
  );
};

CalendarHeaderResourceTablet.propTypes = {
  calendarTitle: PropTypes.string,
  routeParams: PropTypes.object
};

export default CalendarHeaderResourceTablet;
