import React from 'react';
import styled from 'styled-components';
import { handleContactLink } from '@Utils/wk-embed-bridges';
import { colors, fsw } from '@Components/ui/styled/variables';
import LabelTooltip from './label-tooltip';

const FormGroupWrapper = styled.div`
  margin-bottom: 15px;
  :last-child{
    margin-bottom: 0px;
  }
`;

const FormGroupLabel = styled.div`
  color: ${p => p.color || colors.textMuted};
  font-weight: ${fsw.medium};
  font-size: 12px;
  margin-bottom: 2px;
  user-select: none;
`;

const FormGroup = ({
  wrapperStyle = null, labelColor = null, labelText, text, tooltip, link
}) => {
  const id = labelText?.toLowerCase().split(' ').join('-');
  return (
    <FormGroupWrapper style={wrapperStyle}>
      {labelText && (
        <FormGroupLabel color={labelColor}>
          {labelText}
          {tooltip && <LabelTooltip id={id} text={tooltip} />}
        </FormGroupLabel>
      )}
      {link && text
        ? <a href={link} onClick={handleContactLink}>{text}</a>
        : text ?? '-'}
    </FormGroupWrapper>
  );
};

export default FormGroup;
