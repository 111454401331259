import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeCustomerForm } from '@State/cf-actions';
import CustomerIdContext from '@Components/customers/customer-id-context';
import ModalDialog from '@Components/dialogs/modal-dialog';
import CustomerModalContent from '@Components/customers/customer-modal/customer-modal-content';

const CustomerModal = ({ routeParams }) => {
  const dispatch = useDispatch();

  const formVisible = useSelector(({ cf }) => cf.get('formVisible'));
  const customer = useSelector(({ cf }) => cf.get('customer'));
  const bkfModal = useSelector(({ bkf }) => bkf.get('showModal'));

  const onCloseForm = () => {
    dispatch(closeCustomerForm());
  };

  const customerId = customer?.customerId || customer?.id || '';

  if (!formVisible) return null;

  return (
    <ModalDialog
      focusTrapPaused
      closeOnClickOutside={false}
      onClose={bkfModal ? null : onCloseForm}
      contentSize="customer"
      mobileFullScreen
    >
      <CustomerIdContext.Provider value={customerId}>
        <CustomerModalContent
          routeParams={routeParams}
          customer={customer}
          onCloseForm={onCloseForm}
        />
      </CustomerIdContext.Provider>
    </ModalDialog>
  );
};

export default CustomerModal;
