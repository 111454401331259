import { defineMessages } from 'react-intl';

export default defineMessages({
  booking: {
    id: 'admin.nav.booking',
    defaultMessage: 'Booking'
  },
  services: {
    id: 'admin.nav.services',
    defaultMessage: 'Services'
  },
  servicesHelp: {
    id: 'admin.nav.servicesHelp',
    defaultMessage: 'Treatments, prices etc.'
  },
  addonServices: {
    id: 'admin.nav.addonServices',
    defaultMessage: 'Addon services'
  },
  resources: {
    id: 'admin.nav.resources',
    defaultMessage: 'Resources'
  },
  resourcesHelp: {
    id: 'admin.nav.resourcesHelp',
    defaultMessage: 'People, equipment, rooms'
  },
  users: {
    id: 'admin.nav.users',
    defaultMessage: 'Users'
  },
  usersHelp: {
    id: 'admin.nav.usersHelp',
    defaultMessage: 'Logins & permissions'
  },
  schedules: {
    id: 'admin.nav.schedules',
    defaultMessage: 'Schedules'
  },
  online: {
    id: 'admin.nav.online',
    defaultMessage: 'Online booking'
  },
  bookingInfo: {
    id: 'admin.nav.bookingInfo',
    defaultMessage: 'Booking page / Widget'
  },
  companyInfo: {
    id: 'admin.nav.companyInfo',
    defaultMessage: 'Contact info'
  },
  googleBusiness: {
    id: 'admin.nav.googleBusiness',
    defaultMessage: 'Google Business'
  },
  webBooking: {
    id: 'admin.nav.webBooking',
    defaultMessage: 'Settings'
  },
  payment: {
    id: 'admin.nav.payment',
    defaultMessage: 'Payment'
  },
  paymentHelp: {
    id: 'admin.nav.paymentHelp',
    defaultMessage: 'Settings for receipt etc.'
  },
  vouchers: {
    id: 'admin.nav.vouchers',
    defaultMessage: 'Voucher codes'
  },
  communication: {
    id: 'admin.nav.communication',
    defaultMessage: 'Communication'
  },
  sms: {
    id: 'admin.nav.sms',
    defaultMessage: 'SMS'
  },
  smsHelp: {
    id: 'admin.nav.smsHelp',
    defaultMessage: 'Reminders & confirmations'
  },
  email: {
    id: 'admin.nav.email',
    defaultMessage: 'Email'
  },
  notifications: {
    id: 'admin.nav.notifications',
    defaultMessage: 'Notifications'
  },
  pos: {
    id: 'admin.nav.pos',
    defaultMessage: 'POS'
  },
  cashRegisters: {
    id: 'admin.nav.cashRegisters',
    defaultMessage: 'Cash registers'
  },
  posSettings: {
    id: 'admin.nav.posSettings',
    defaultMessage: 'Settings'
  },
  giftCards: {
    id: 'admin.nav.giftCards',
    defaultMessage: 'Gift cards'
  },
  giftCardTemplates: {
    id: 'admin.nav.giftCardTemplates',
    defaultMessage: 'Gift card templates'
  },
  invoices: {
    id: 'admin.nav.invoices',
    defaultMessage: 'Invoices'
  },
  printers: {
    id: 'admin.nav.printers',
    defaultMessage: 'Printers'
  },
  terminals: {
    id: 'admin.nav.terminals',
    defaultMessage: 'Terminals'
  },
  stock: {
    id: 'admin.nav.stock',
    defaultMessage: 'Stock'
  },
  products: {
    id: 'admin.nav.products',
    defaultMessage: 'Products'
  },
  delivery: {
    id: 'admin.nav.delivery',
    defaultMessage: 'Delivery'
  },
  stockTaking: {
    id: 'admin.nav.stockTaking',
    defaultMessage: 'Stock taking'
  },
  account: {
    id: 'admin.nav.account',
    defaultMessage: 'Account'
  },
  subscription: {
    id: 'admin.nav.subscription',
    defaultMessage: 'Subscription'
  },
  subscriptionHelp: {
    id: 'admin.nav.subscriptionHelp',
    defaultMessage: 'Receipts, payment etc.'
  },
  features: {
    id: 'admin.nav.features',
    defaultMessage: 'Features'
  },
  storage: {
    id: 'admin.nav.storage',
    defaultMessage: 'Personal data'
  },
  storageHelp: {
    id: 'admin.nav.storageHelp',
    defaultMessage: 'GDPR and storage policy'
  },
  contacts: {
    id: 'admin.nav.contacts',
    defaultMessage: 'Contacts & terms'
  },
  sysadmin: {
    id: 'admin.nav.sysadmin',
    defaultMessage: 'System Admin'
  },
  advanced: {
    id: 'admin.nav.advanced',
    defaultMessage: 'Advanced'
  },
  advancedHelp: {
    id: 'admin.nav.advancedHelp',
    defaultMessage: 'Time zone, Locale, Features, Prefs etc.'
  },
  partners: {
    id: 'admin.nav.partners',
    defaultMessage: 'Partners'
  }
});
