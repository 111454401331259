import React from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { txt } from '@Utils/i18n-util';
import CurrencyUtil from '@Utils/currency-util';
import { getSmsEnabled, getSmsCampaignEnabled, getEmailCampaignEnabled } from '@State/selectors';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import PanelRadio from '@Components/inputs/panel-radio';
import { getAudienceTypeName, getSmsCampaignPrice } from '../campaign-helpers';
import msg from './campaign-form.msg';

const getAudienceTypeOptions = (showTopSpenders) => [
  { value: 'All', description: txt(msg.txtAudienceTypeAll) },
  { value: 'Booked', description: txt(msg.txtAudienceTypeBooked) },
  { value: 'NewCustomers', description: txt(msg.txtAudienceTypeNew) },
  showTopSpenders
    ? { value: 'TopSpenders', description: txt(msg.txtAudienceTypeTop) }
    : null
].filter(o => o);

const CampaignType = ({ isNew = false }) => {
  const { register } = useFormContext();
  const smsEnabled = useSelector(getSmsEnabled);
  const smsCampaignEnabled = useSelector(getSmsCampaignEnabled);
  const emailCampaignEnabled = useSelector(getEmailCampaignEnabled);
  const smsCampaignPrice = useSelector(getSmsCampaignPrice);
  const showTopSpenders = useSelector(state => state.locationConfig.get('vunits') > 0);

  return (
    <div>
      <h4>{isNew ? txt(msg.lblNewCampaign) : txt(msg.lblCampaignType)}</h4>
      {!smsCampaignEnabled && (
        <AlertWithIcon warning>
          {smsEnabled
            ? 'SMS-utskick ej tillgängligt - kontakta support för aktivering.'
            : 'SMS-utskick ej tillgängligt - SMS är inte aktiverat på kontot.'}
        </AlertWithIcon>
      )}
      {!emailCampaignEnabled && (
        <AlertWithIcon warning>
          Mejlutskick ej tillgängligt - kontakta support för aktivering.
        </AlertWithIcon>
      )}
      <PanelRadio
        input={{ ...register('campaignType') }}
        value="Sms"
        label={txt(msg.lblTypeSms)}
        disabled={!smsCampaignEnabled}
        description={txt(msg.txtTypeSms, { cost: CurrencyUtil.currency(smsCampaignPrice) })}
      />
      <PanelRadio
        input={{ ...register('campaignType') }}
        value="Email"
        label={txt(msg.lblTypeEmail)}
        disabled={!emailCampaignEnabled}
        description={txt(msg.txtTypeEmail)}
      />
      <h4 className="mt4">{txt(msg.lblRecipients)}</h4>
      {getAudienceTypeOptions(showTopSpenders).map(item => (
        <PanelRadio
          key={item.value}
          input={{ ...register('audienceType') }}
          label={getAudienceTypeName(item.value)}
          {...item}
        />
      ))}
    </div>
  );
};

export default CampaignType;
