import React from 'react';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { Button, Text } from '@Components/ui/styled/main';

const ConfirmShowModal = ({ booking, customer, onConfirm, onClose }) => {
  return (
    <ModalDialog
      contentSize="xl"
      hideCloseButton
      closeOnClickOutside={false}
    >
      {booking.attributes.imageUrl && (
        <img className="ongoing-booking-image" src={booking.attributes.imageUrl} alt="" />
      )}
      <Text center marginBottom fs={15}>
        {`Vill du avsluta bokningen för ${customer.name}?`}
      </Text>
      <div className="mt4 text-center">
        <Button large primary marginRight onClick={onConfirm}>
          Avsluta bokning
        </Button>
        <Button large gray onClick={onClose}>
          Avbryt
        </Button>
      </div>
    </ModalDialog>
  );
};

export default ConfirmShowModal;
