import moment from 'moment';
import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStaffEntryReport } from '@State/staff-actions';
import { getLocationName } from '@State/selectors';
import { Panel } from '@Components/ui/styled/main';
import Loader from '@Components/ui/loader';

const columnStyle = { width: '15%' };
const durationStyle = { width: '70px', textAlign: 'right' };

const formatTime = (time) => moment(time).format('LT');
const formatDate = (date) => moment(date).format('L');
const formatDateTime = (date) => moment(date).format('L LT');

const formatEndTime = (endTime, startTime) => {
  if (!endTime) {
    return '-';
  }
  return moment(endTime).isSame(startTime, 'day')
    ? formatTime(endTime)
    : formatDateTime(endTime);
};

const formatDuration = (duration) => {
  if (duration === 0) {
    return '-';
  }
  const momentDuration = moment.duration(duration);
  const hours = Math.floor(momentDuration.asHours());
  const minutes = momentDuration.minutes();

  return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
};

const getDuration = (entry) => {
  return entry.get('endTime') && !entry.get('deleted')
    ? moment(entry.get('endTime')).diff(entry.get('startTime'))
    : 0;
};

const StaffReport = () => {
  const dispatch = useDispatch();
  const locationName = useSelector(getLocationName);
  const staffState = useSelector(state => state.staffState);
  const staffReportState = useSelector(state => state.staffReportState);

  const [loading, setLoading] = useState(true);

  const fetchReport = async () => {
    try {
      await dispatch(fetchStaffEntryReport());
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (staffReportState.isEmpty()) {
    return (
      <div className="alert alert-info">
        <i className="fa fa-info-circle" /> Det finns ingen data att visa
      </div>
    );
  }

  return (
    <>
      <div className="visible-print">
        <h3>
          Personalliggare för {locationName}
        </h3>
        <br />
      </div>
      {staffReportState.entrySeq().map(([month, users]) => {
        return (
          <Fragment key={month}>
            <h3 className="date-header mt0 mb3">{month.format('MMMM YYYY')}</h3>
            {users.entrySeq().map(([userId, entries]) => {
              const user = staffState.find(s => s.get('id') === userId);
              let userDuration = 0;
              return user && (
                <Fragment key={userId}>
                  <h4>
                    {user.get('name')} <small>{user.get('pno')}</small>
                  </h4>
                  <Panel>
                    <table className="table table-condensed table-report">
                      <thead>
                        <tr>
                          <th style={columnStyle}>Datum</th>
                          <th style={columnStyle}>Incheckad</th>
                          <th style={columnStyle}>Utcheckad</th>
                          <th style={columnStyle}><div style={durationStyle}>Timmar</div></th>
                          <th style={columnStyle}>Ändrad</th>
                          <th style={columnStyle}>Signatur</th>
                        </tr>
                      </thead>
                      {entries.map((entry) => {
                        const isCorrection = entry.get('correction') || entry.get('manualEntry');
                        const duration = getDuration(entry);
                        userDuration += duration;
                        return (
                          <tbody key={entry.get('id')}>
                            <tr className={entry.get('deleted') ? 'text-through' : ''}>
                              <td>{formatDate(entry.get('startTime'))}</td>
                              <td>{formatTime(entry.get('startTime'))}</td>
                              <td>{formatEndTime(entry.get('endTime'), entry.get('startTime'))}</td>
                              <td><div style={durationStyle}>{formatDuration(duration)}</div></td>
                              <td>{isCorrection && formatDateTime(entry.get('createdTs'))}</td>
                              <td>{isCorrection && entry.get('addedBySignature')}</td>
                            </tr>
                          </tbody>
                        );
                      })}
                      <tfoot>
                        <tr>
                          <th>Totalt</th>
                          <th />
                          <th />
                          <th><div style={durationStyle}>{formatDuration(userDuration)}</div></th>
                          <th />
                          <th />
                        </tr>
                      </tfoot>
                    </table>
                  </Panel>
                </Fragment>
              );
            })}
          </Fragment>
        );
      })}
    </>
  );
};

export default StaffReport;
