import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { isBookingCancelled, isBookingNew } from '@Utils/booking-util';
import { setCustomerFormState } from '@State/cf-actions';
import { txt } from '@Utils/i18n-util';
import { styleHiddenInput } from './style';
import msg from './booking-customer.msg';

class BookingCustomer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editCustomer: props.customer && !props.customer.customerId,
      showAllFields: false
    };
  }

  componentDidMount() {
    const { customer, isMobile } = this.props;
    if (!customer && !isMobile && isBookingNew(this.props)) {
      this.focusSearchField();
    } else if (this.state.editCustomer) {
      this.focusEditField();
    }
  }

  componentDidUpdate(prevProps) {
    const { customer, isMobile } = this.props;
    if (prevProps.customer && !customer && !isMobile) {
      this.focusSearchField();
    }
  }

  focusSearchField = () => {
    if (this.searchField) {
      const input = ReactDOM.findDOMNode(this.searchField);
      input && input.focus();
    }
  };

  focusEditField = () => {
    if (this.name && this.phone) {
      const ref = this.name.value && !this.phone.value ? this.phone : this.name;
      const field = ReactDOM.findDOMNode(ref);
      field.focus();
    }
  };

  focusHiddenField = () => {
    if (this.hidden) {
      const hidden = ReactDOM.findDOMNode(this.hidden);
      hidden.style.visibility = 'visible';
      hidden.focus();
      hidden.style.visibility = 'hidden';
    }
  };

  handleClick = (ev) => {
    ev.preventDefault();
    this.focusHiddenField();
    const showAllFields = !!this.props.otherPhoneNumber;
    this.setState({ editCustomer: true, showAllFields }, () => this.focusEditField());
  };

  showAllFields = (ev) => {
    ev.target.blur();
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({ showAllFields: true });
  };

  handleClose = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({ editCustomer: false, showAllFields: false });
  };

  handleClear = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.props.onClearCustomer();
  };

  handleOpen = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.props.onOpenCustomer(this.props.customer);
  };

  renderHiddenInput() {
    return <input ref={(ref) => { this.hidden = ref; }} style={styleHiddenInput} />;
  }

  render() {
    const { editCustomer, showAllFields } = this.state;
    const {
      customer, company, customerId, name, phoneNumber, otherPhoneNumber, email, isMobile
    } = this.props;
    const isNew = isBookingNew(this.props);
    const isCancelled = isBookingCancelled(this.props);
    const isDeleted = customer && customer.deleted;
    const onClickMethod = customerId ? this.handleOpen : this.handleClick;

    if (isCancelled && !customer) {
      return null;
    }

    if (editCustomer) {
      return (
        <div className="booking-form-block form">
          <div className="row tight">
            <div className="form-group col-xs-7">
              <label className="control-label">{company ? txt(msg.lblContactPerson) : txt(msg.lblName)}</label>
              <Field autoComplete="off" name="name" component="input" type="text" className="form-control" ref={(ref) => { this.name = ref; }} />
            </div>
            <div className="form-group col-xs-5">
              <label className="control-label">{txt(msg.lblMobilePhone)}</label>
              <Field autoComplete="off" name="phoneNumber" component="input" type="phone" className="form-control" ref={(ref) => { this.phone = ref; }} />
            </div>
          </div>
          <div className="row tight">
            <div className="form-group col-xs-12">
              <label className="control-label">{txt(msg.lblEmail)}</label>
              <Field autoComplete="off" name="email" component="input" type="email" className="form-control" />
            </div>
          </div>
          {showAllFields ? (
            <div className="row tight">
              <div className="form-group col-xs-7">
                <label className="control-label">{txt(msg.lblPersonalNo)}</label>
                <Field autoComplete="off" name="officialIdNo" component="input" type="phone" className="form-control" />
              </div>
              <div className="form-group col-xs-5">
                <label className="control-label">{txt(msg.lblOtherPhone)}</label>
                <Field autoComplete="off" name="otherPhoneNumber" component="input" type="phone" className="form-control" />
              </div>
            </div>
          ) : (
            <div className="pull-left">
              <button className="btn-link bottom" tabIndex={-1} onClick={this.showAllFields}>
                <i className="fa fa-chevron-circle-right" /> {txt(msg.btnShowAllFields)}
              </button>
            </div>
          )}

          <div className="text-right">
            <button className="btn-label" tabIndex={-1} onClick={this.handleClose}>{txt(msg.btnClose)}</button>
          </div>
        </div>
      );
    }

    if (!customer && (company || isMobile)) {
      return (
        <button className="booking-form-block button" onClick={() => this.props.onSearchStart()}>
          <i className="fa fa-user" /> {company ? txt(msg.btnAddContactPerson) : txt(msg.btnAddCustomer)}
          {this.renderHiddenInput()}
        </button>
      );
    }

    if (!customer) {
      return (
        <div className="booking-form-block search form">
          <label className="control-label">{txt(msg.lblCustomer)}</label>
          <input
            type="text"
            placeholder={txt(msg.searchPlaceholderText)}
            className="form-control"
            onChange={ev => this.props.onSearchStart(ev.target.value)}
            ref={(ref) => { this.searchField = ref; }}
          />
        </div>
      );
    }

    const phoneNumbers = [phoneNumber, otherPhoneNumber].filter(p => p);

    return (
      <div className={isDeleted ? 'booking-form-block cancelled' : 'booking-form-block'} onClick={!isDeleted ? onClickMethod : null}>
        <div className="block-buttons" onClick={ev => ev.stopPropagation()}>
          {!isDeleted && <div><button className="btn-label" tabIndex={-1} onClick={this.handleOpen}>{txt(msg.btnOpen)}</button></div>}
          {isNew && <button className="btn-label" tabIndex={-1} onClick={this.handleClear}>{txt(msg.btnClear)}</button>}
        </div>

        {name && <h4>{name}</h4>}
        {phoneNumbers.length > 0 && <div>{phoneNumbers.join(' / ')}</div>}
        {email && <div>{email}</div>}
        {this.renderHiddenInput()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf } = state;
  const customer = bkf.get('customer');

  return {
    id: bkf.get('id'),
    status: bkf.get('status'),
    company: bkf.get('company'),
    customer,
    initialValues: customer,
    ...getFormValues('bkf-customer')(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onOpenCustomer: (customer) => {
      dispatch(setCustomerFormState({
        formVisible: true,
        customer
      }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'bkf-customer',
  destroyOnUnmount: false
})(BookingCustomer));
