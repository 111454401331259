import { createSelector } from 'reselect';
import { getFeatures } from '@State/selectors';
import { CustomerType, isCustomerCompany, isCustomerPerson, isCustomerVehicle } from '@Utils/customer-util';
import { txt } from '@Utils/i18n-util';
import msg from './customer-tabs.msg';

export const getCustomerTabSections = createSelector(
  getFeatures,
  (locationFeatures) => {
    const { ShowPOS, EnableVehicleBooking } = locationFeatures;

    const sections = [
      { navId: 'overview', name: txt(msg.overview) },
      { navId: 'bookings', name: txt(msg.bookings) },
      { navId: 'logs', name: txt(msg.logs) },
      { navId: 'employees', name: txt(msg.employees) }
    ];
    if (EnableVehicleBooking) {
      sections.push({ navId: 'vehicles', name: txt(msg.vehicles) });
      sections.push({ navId: 'vehicle-info', name: txt(msg.vehicleInfo), news: true });
    }
    if (ShowPOS) {
      sections.push({ navId: 'sales', name: txt(msg.payments) });
      sections.push({ navId: 'gift-cards', name: txt(msg.giftCards) });
    }
    return sections;
  }
);

export const getCustomerTypeTabSections = (sections, customer) => {
  return sections.filter((section) => {
    switch (section.navId) {
      case 'vehicles':
        return !isCustomerVehicle(customer);
      case 'vehicle-info':
        return isCustomerVehicle(customer);
      case 'employees':
        return isCustomerCompany(customer);
      case 'sales':
      case 'gift-cards':
        return isCustomerPerson(customer);
      default:
        return true;
    }
  });
};
