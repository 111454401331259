import React from 'react';
import { txt } from '@Utils/i18n-util';
import { isMobile } from '@Utils/phone-number-parser';
import { formatPhoneNumber } from '@Utils/phone-util';
import RichTextContent from '@Components/inputs/rich-text-content';
import LabelTextGroup from './label-text-group';
import msg from './campaign.msg';

const CampaignContentPanel = ({ campaign, children = null }) => {
  const campaignObj = campaign?.toJS() || {};
  const { type, smsText, emailSubject, smsSender, emailFromName, emailReplyTo } = campaignObj;
  const smsSenderName = isMobile(smsSender) ? formatPhoneNumber(smsSender) : smsSender;

  return (
    <div className="campaign-content select-text">
      {children}
      {type === 'Sms' && (
        <>
          <LabelTextGroup label={txt(msg.lblSender)}>
            <strong>{smsSenderName || 'Cliento'}</strong>
          </LabelTextGroup>
          <LabelTextGroup label={txt(msg.lblMessage)}>
            {smsText}
          </LabelTextGroup>
        </>
      )}
      {type === 'Email' && (
        <>
          <LabelTextGroup label={txt(msg.lblSender)}>
            <strong>{emailFromName || 'Cliento'}</strong>{' '}
            {emailReplyTo && <span>&lt;{emailReplyTo}&gt;</span>}
          </LabelTextGroup>
          <LabelTextGroup label={txt(msg.lblSubject)}>
            <strong>{emailSubject}</strong>
          </LabelTextGroup>
          <LabelTextGroup className="mt2" label={txt(msg.lblContent)}>
            <RichTextContent content={campaign?.get('emailText')} />
          </LabelTextGroup>
        </>
      )}
    </div>
  );
};

export default CampaignContentPanel;
