import React, { Fragment, useState, useEffect } from 'react';
import { TitleDate, ContentBlock } from '@Components/customers/customer-styles';
import CustomerDetailSale from '@Components/customers/customer-detail/sales/customer-detail-sale';
import { groupEventsByMonth, sortEventByTimeAndReverse } from '@Utils/customer-util';
import { getTimeStaffCreatedTime, getTimeByFormat } from '@Utils/time-util';
import { Text } from '@Components/ui/styled/main';

const CustomerDetailSalesList = ({ sales, handleSelect, selectedSale }) => {
  const [groupedSales, setGroupedSales] = useState();
  useEffect(() => {
    if (sales) {
      const newGroupedSales = groupEventsByMonth(sales);
      if (newGroupedSales) {
        setGroupedSales(newGroupedSales);
      }
    }
  }, [sales]);

  if (!groupedSales) return null;
  return (
    groupedSales.map(([date, sales]) => (
      <Fragment key={`${date}`}>
        <TitleDate>{getTimeByFormat(date)}</TitleDate>
        <ContentBlock>
          {sortEventByTimeAndReverse(sales).map((sale, index) => (
            <CustomerDetailSale
              isSelectable
              key={`${sale?.id}_${index}`}
              sale={sale}
              isSelectedSale={selectedSale?.id === sale?.id}
              handleSelect={handleSelect}
            >
              <Text muted fs="12">
                {getTimeStaffCreatedTime(sale.createdTs)}
              </Text>
            </CustomerDetailSale>
          ))}
        </ContentBlock>
      </Fragment>
    ))
  );
};

export default CustomerDetailSalesList;
