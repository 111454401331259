import moment from 'moment/moment';
import { createSelector } from 'reselect';
import { pick } from 'ramda';
import { txt } from '@Utils/i18n-util';
import { setTime } from '@Utils/time-util';
import { CustomerType } from '@Utils/customer-util';
import { getFullPno, isValidPno } from '@Utils/luhn-util';
import { isBookingCancelled, isBookingNew, isBookingReservation, isClassBooking, isSimpleBooking } from '@Utils/booking-util';
import { formatPhoneNumberE164 } from '@Utils/phone-util';
import { getRruleStringFromForm } from './recurring-util';
import msg from './booking-form.msg';

export function getBookingTitle(isNew, isReservation, isSchedule, service) {
  if (isReservation) {
    return txt(msg.titleBlockTime);
  }
  if (isSchedule) {
    return txt(msg.titleSchedule);
  }
  if (isNew) {
    return txt(msg.titleTextNew);
  }
  return service?.name || txt(msg.titleTextChange);
}

export function getSaveBtnText(exceptionType, isSaving) {
  if (exceptionType) {
    return exceptionType === 'open' ? txt(msg.openSchedule) : txt(msg.closeSchedule);
  }
  return isSaving ? txt(msg.savingText) : txt(msg.saveText);
}

export function shouldSendConfirmations(props, initialState, initialCustomer) {
  const { customer, startTime } = props;

  const isNew = customer && isBookingNew(props);
  const isBooking = isSimpleBooking(props);
  const isCancelled = isBookingCancelled(props);
  const isPastBooking = isBooking && moment().isAfter(startTime);

  const isMoved = isBookingMoved(props, initialState);
  const customerDetailsChanged = isCustomerDetailsChanged(customer, initialCustomer);

  return isBooking && !isCancelled && !isPastBooking
    && (isNew || isMoved || customerDetailsChanged);
}

function isBookingMoved(props, initialState) {
  const { customer, startTime, resourceId } = props;
  return customer && (initialState?.startTime !== startTime.toISOString()
    || initialState?.resourceId !== resourceId);
}

function isCustomerDetailsChanged(customer, initialCustomer) {
  return customer && (initialCustomer?.customerId !== customer.customerId
    || initialCustomer?.phoneNumber !== formatPhoneNumberE164(customer.phoneNumber)
    || initialCustomer?.email !== customer.email);
}

function getCompany(props) {
  const { company, companyForm, enableCompanyBooking } = props;
  const { officialIdNo, name } = companyForm || company || {};
  const customerId = company?.customerId;

  return enableCompanyBooking && (customerId || name || officialIdNo) ? {
    customerId,
    customerType: CustomerType.Company,
    officialIdNo,
    name
  } : null;
}

function getFlags(props) {
  const { askedForPerson, dropIn } = props.attributes || {};

  return {
    askedForPerson: askedForPerson === true || undefined,
    dropIn: dropIn === true || undefined
  };
}

export function getCustomer(props) {
  const { customer, customerForm } = props;
  const customerId = customer?.customerId;
  const {
    name, phoneNumber, otherPhoneNumber, email, officialIdNo
  } = customerForm || customer || {};
  const isPno = officialIdNo && isValidPno(officialIdNo);

  return customerForm || customer ? {
    customerId,
    customerType: CustomerType.Person,
    name,
    phoneNumber: formatPhoneNumberE164(phoneNumber),
    otherPhoneNumber: formatPhoneNumberE164(otherPhoneNumber),
    email,
    officialIdNo: isPno ? getFullPno(officialIdNo) : officialIdNo,
    officialIdType: isPno ? 'SE_PNO' : null
  } : null;
}

export function getCustomers(props) {
  const vehicle = getVehicle(props);
  const company = getCompany(props);
  const customer = getCustomer(props);

  if (!customer && !vehicle && !company) {
    return null;
  }
  return [customer, vehicle, company].filter(o => o);
}

export function getService(props) {
  const { price } = props.priceForm || props.service || {};
  const { name } = props.serviceForm || props.service || {};
  const { serviceDuration, afterTime } = props.timeForm || props.service || {};
  const { startTime, endTime } = props;

  return {
    name: name || null,
    afterTime: parseInt(afterTime),
    serviceDuration: parseInt(serviceDuration),
    totalDuration: endTime.diff(startTime, 'minutes'),
    price: price ? parseInt(price) : null
  };
}

export const getServiceInitialValues = createSelector(
  state => state.bkf.get('service'),
  state => state.bkf.get('services'),
  (service, services) => {
    const initialValues = { ...service };
    services && services.valueSeq().forEach(s => {
      initialValues[`servicePrice${s.id}`] = s.price;
    });
    return initialValues;
  }
);

function getServicePrice(bookingId, service, services, initialState) {
  const hasServices = services && services.size > 0;
  if (!service.price && hasServices && !initialState) {
    const sum = (prev, next) => prev + next;
    const price = services.map(s => s.price).reduce(sum, 0);

    if (!service.price && price > 0) {
      const errorData = JSON.stringify({ bookingId, service, services });
      const error = new Error(`Service price missing, using sum of service prices: ${errorData}`);
      console.error(error);
      return price;
    }
  }
  return service.price;
}

export function getServiceProps(service, props, initialState) {
  const { id, services } = props;

  const mapService = s => ({
    id: s.id,
    name: s.name,
    duration: s.duration || s.serviceDuration,
    price: s.price,
    priceFrom: s.priceFrom
  });

  const hasServices = services && services.size > 0;
  const nameChanged = services && services.size === 1 && services.first().name !== service.name;
  const saveDescription = !hasServices || nameChanged;

  return {
    services: services ? [...services.valueSeq().map(mapService)] : [],
    description: saveDescription ? service.name : undefined,
    price: getServicePrice(id, service, services, initialState),
    afterTime: service.afterTime
  };
}

function getSecondaryResources(resources) {
  return resources.valueSeq()
    .filter(r => !r.primary).map(r => r.id);
}

function getAttributes(props) {
  const { attributes, recurring, recurringForm } = props;
  const rrule = recurring && recurringForm && getRruleStringFromForm(recurringForm);
  const include = [
    'colorway', 'chipTmplId', 'fub_bkId', 'bk_rrule', 'imageUrl'
  ];

  return isBookingNew(props) ? {
    bk_rrule: rrule || undefined,
    ...pick(include, attributes),
    ...getFlags(props)
  } : undefined;
}

export function getCustomFields(props) {
  const { customFields, customFieldsForm, customValues } = props;
  const values = customFieldsForm || customValues || {};

  if (!customFields || customFields.length === 0) {
    return null;
  }

  const fields = [];
  customFields.forEach(({ key, type, label }) => {
    fields.push({
      key, type, label, value: values[key]
    });
  });
  return fields;
}

export function getVehicle(props) {
  const { vehicleForm, vehicle, enableVehicleBooking } = props;
  const { officialIdNo, name, attributes } = vehicleForm || vehicle || {};
  const customerId = vehicle?.customerId;

  return enableVehicleBooking && officialIdNo ? {
    customerId,
    customerType: CustomerType.Vehicle,
    officialIdNo,
    name,
    attributes
  } : null;
}

function getNotes(props) {
  return props.notesForm?.note ?? props.note ?? '';
}

function getClassInfo(props) {
  const { classInfoForm, service } = props;
  const { maxSlots } = classInfoForm || service || {};

  return isClassBooking(props) ? {
    maxSlots
  } : {};
}

function getReservationType(props) {
  const { reservationType } = props;

  return isBookingReservation(props) ? reservationType : null;
}

export function getStartTime(props) {
  const { startTime, timeForm } = props;

  return timeForm?.startTime
    ? setTime(startTime, timeForm.startTime)
    : startTime;
}

function getEndTime(startTime, service, props) {
  const { timeForm } = props;
  const { serviceDuration, afterTime } = timeForm || service || {};

  const totalDuration = parseInt(serviceDuration || 0) + parseInt(afterTime || 0);
  const newEndTime = moment(startTime).add(totalDuration, 'minutes');

  return totalDuration > 0 ? newEndTime : props.endTime;
}

export function getConfirmations(props, initialState, initialCustomer) {
  const { sendSmsConfirmation, sendEmailConfirmation } = props.confirmationForm || {};

  return shouldSendConfirmations(props, initialState, initialCustomer) ? {
    sms: sendSmsConfirmation,
    email: sendEmailConfirmation
  } : {};
}

export function getBookingData(props, initialState = false) {
  const { id, resourceId, type } = props;
  const isBooking = isSimpleBooking(props);
  const isNew = isBookingNew(props);

  const customers = isBooking && isNew ? getCustomers(props) : null;
  const service = getService(props);
  const startTime = getStartTime(props);
  const endTime = getEndTime(startTime, service, props);
  const serviceProps = getServiceProps(service, props, initialState);
  const classBooking = getClassInfo(props);

  return {
    id: isNew ? undefined : id,
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
    resourceId,
    type,
    customers,
    ...serviceProps,
    ...classBooking,
    note: getNotes(props),
    attributes: getAttributes(props),
    customFields: getCustomFields(props),
    reservationType: getReservationType(props),
    secondaryResources: getSecondaryResources(props.resources)
  };
}

export function getScheduleData(props) {
  const { startTime, endTime, resourceId, type, exceptionType, openType } = props;

  return {
    startTime,
    endTime,
    resourceId,
    type,
    exceptionType,
    openType,
    comment: getNotes(props)
  };
}

export function updateTimeAndPriceFromServices(services, props, updatePriceOnly = false) {
  const { id, startTime, endTime, resourceServices, resourceId, service: original } = props;
  const hasServices = services && services.length > 0;
  const resSrvs = resourceServices.get(resourceId);

  const findAfterTime = aSrv => {
    const srv = resSrvs
      ? resSrvs.find(srv => srv.id === aSrv.id)
      : null;
    return srv ? srv.afterTime : 0;
  };

  const sum = (prev, next) => prev + next;
  const name = hasServices ? services.map(s => s.name).join(', ') : null;
  const price = hasServices ? services.map(s => s.price).reduce(sum, 0) : 0;
  const afterTime = hasServices ? Math.max(...services.map(s => findAfterTime(s))) : 0;
  const serviceDuration = hasServices ? services.map(s => s.duration || s.serviceDuration || 0).reduce(sum, 0) : 0;
  const newEndTime = serviceDuration ? moment(startTime).add(serviceDuration + (afterTime || 0), 'm') : endTime;

  const newService = hasServices ? {
    name,
    serviceDuration,
    afterTime,
    price
  } : null;

  const service = updatePriceOnly
    ? { ...original, price }
    : newService;

  const newTime = {
    startTime,
    endTime: updatePriceOnly ? endTime : newEndTime
  };

  props.onServiceSelect(id, service, newTime, resourceServices);
}

export function updateResourcesFromServices(services, props) {
  const { resources, resourcesById } = props;

  services.forEach(({ multiResourceRules }) => {
    if (multiResourceRules?.length > 0) {
      multiResourceRules.forEach(({ resourceIds }) => {
        const isFulfilled = resourceIds.some(id => resources.has(id));

        if (!isFulfilled && resourceIds.length === 1) {
          props.onResourceAdd({ ...resourcesById.get(resourceIds[0]), isNew: true });
        }
      });
    }
  });
}

export function validateBooking(props) {
  const isBooking = isSimpleBooking(props);
  const company = isBooking ? getCompany(props) : null;
  const errors = [];

  if (company && company.name && !(company.officialIdNo?.length > 0)) {
    errors.push('company.orgNo');
  }

  return errors.length > 0 ? errors : null;
}
