import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchGoogleBusinessLocations } from '@State/google-business-actions';
import { Panel } from '@Components/ui/styled/main';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import Loader from '@Components/ui/loader';
import ModalDialog from './modal-dialog';

const GoogleBusinessSelectPlace = ({ onSelect, onClose }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [businesses, setBusinesses] = useState(null);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchGoogleBusinessLocations())
      .then(({ availableLocations }) => setBusinesses(availableLocations))
      .finally(() => setLoading(false));
  }, []);

  return (
    <ModalDialog
      contentSize="large"
      title="Koppla Google-konto"
      hideCloseButton
      closeOnClickOutside={false}
      onClose={onClose}
      buttons={onClose && [{
        gray: true,
        name: 'Avbryt',
        onClick: onClose
      }]}
    >
      <AlertWithIcon>
        Välj vilken Google företagsprofil du vill koppla till ditt Cliento-konto.
      </AlertWithIcon>
      {loading && <Loader />}
      {!loading && businesses?.map((business, index) => {
        const { placeId, title, description } = business;
        return (
          <Panel
            key={index}
            onClick={() => onSelect(placeId)}
          >
            <h4>{title}</h4>
            <span className="text-muted">{description}</span>
          </Panel>
        );
      })}
    </ModalDialog>
  );
};

export default GoogleBusinessSelectPlace;
