import React from 'react';
import PropTypes from 'prop-types';

const ScheduleWeeksToggle = ({ weeks, currentWeek, setCurrentWeek }) => {
  const handleClick = (week) => (ev) => {
    ev.preventDefault();
    setCurrentWeek(week);
  };

  return (
    <div className="btn-group schedule-weeks">
      {Array(weeks).fill(null).map((x, i) => i + 1).map((week, index) => (
        <a
          onClick={handleClick(week)}
          href="#"
          key={index}
          className={`btn-week ${currentWeek === week ? 'btn-week-active' : ''}`}
        >
          {week}
          :
          {week > 2 ? 'e' : 'a'} veckan
        </a>
      ))}
      <div className="schedule-week-info">
        <i className="fa fa-info-circle text-highlight-warn" /> {weeks}
        -veckorsschema
      </div>
    </div>
  );
};

ScheduleWeeksToggle.propTypes = {
  weeks: PropTypes.number,
  currentWeek: PropTypes.number,
  setCurrentWeek: PropTypes.func
};

export default ScheduleWeeksToggle;
