import React from 'react';
import moment from 'moment';
import { formatPno } from '@Utils/luhn-util';
import { formatPhoneNumber } from '@Utils/phone-util';
import useCustomerSelect from '@Components/customers/useCustomerSelect';
import { PanelWrapper } from '@Components/customers/customer-styles';
import { Button, FormGroup } from '@Components/ui/styled/main';

const CustomerEmployeeDetails = ({ employee, routeParams }) => {
  const {
    officialIdNo, name, email, phoneNumber, otherPhoneNumber, createdTs, lastBookingTs
  } = employee || {};
  const onCustomerSelect = useCustomerSelect(routeParams);
  return (
    <PanelWrapper defaultCursor>
      <FormGroup labelText="Namn" text={name || '-'} />
      {officialIdNo && <FormGroup labelText="Personummer" text={formatPno(officialIdNo)} />}
      {phoneNumber && <FormGroup labelText="Mobiltelefon" text={formatPhoneNumber(phoneNumber)} />}
      {otherPhoneNumber && <FormGroup labelText="Annat telefonnummer" text={formatPhoneNumber(otherPhoneNumber)} />}
      {email && <FormGroup labelText="E-post" text={email} />}
      <FormGroup labelText="Tillagd" text={moment(createdTs).format('LLL')} />
      <FormGroup labelText="Senaste bokning" text={lastBookingTs ? moment(lastBookingTs).format('LLL') : '-'} />
      <div className="mt2">
        <Button small white onClick={() => onCustomerSelect(employee)}>Visa kund</Button>
      </div>
    </PanelWrapper>
  );
};

export default CustomerEmployeeDetails;
