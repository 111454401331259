import { List, Map } from 'immutable';
import { createSelector } from 'reselect';
import { Decimal } from 'decimal.js-light';
import { pos } from '@Utils/preference-keys';
import { getDiscountAmount, getPosUnitTitle, isPosUnitOpenCurrentDevice, LowStockThreshold } from '@Utils/pos-utils';
import { getSysAdmin, getVatRates } from '@State/selectors';
import { getStockedProducts } from '@State/products-selectors';

const emptyList = List();
export const getSales = state => state.pos.get('sales') || emptyList;
export const getSaleItems = state => state.posSale.get('items') || emptyList;
export const getSaleId = (state) => state.posSale.get('id');
export const getSaleUnpaid = (state) => !state.posSale.get('status') || state.posSale.get('status') === 'Unpaid';
export const getSalePartialPaid = (state) => state.posSale.get('status') === 'PartialPaid';
export const getTransactions = state => state.pos.get('transactions');
export const getPrePayments = state => state.pos.get('prePayments');
export const getSaleDiscount = createSelector(
  getSaleItems,
  items => items.find(item => item.get('itemType') === 'Discount')
);

export const getParkedSales = createSelector(
  getSales,
  (sales) => {
    return sales.filter(item => item.get('status') === 'Parked');
  }
);

export const getPosEnabledUsers = createSelector(
  state => state.cashiersById,
  cashiersById => cashiersById.valueSeq()
);

export const getAutoLoginOperator = createSelector(
  state => getPosEnabledUsers(state),
  state => state.locationConfig.get(pos.useOperatorPinCode),
  (posEnabledUsers, useOperatorPinCode) => {
    return posEnabledUsers.size === 1 && !useOperatorPinCode
      ? posEnabledUsers.first()
      : null;
  }
);

export const getOperatorId = createSelector(
  state => state.pos.get('operator'),
  (operator) => operator?.opId
);

export const hasOpenPosUnit = createSelector(
  state => state.pos.get('posUnit'),
  (posUnit) => {
    return isPosUnitOpenCurrentDevice(posUnit);
  }
);

export const hasRunningPosUnit = createSelector(
  state => state.pos.get('posUnit'),
  (posUnit) => {
    return posUnit && posUnit.get('state') !== 'Closed';
  }
);

export const getAvailablePosUnits = createSelector(
  getSysAdmin,
  state => state.pos.get('posUnits'),
  (isSysAdmin, posUnits) => {
    return posUnits?.filter(posUnit => {
      return isSysAdmin || posUnit.get('type').includes('Onprem');
    });
  }
);

export const getPosTitle = createSelector(
  getAvailablePosUnits,
  state => state.pos.get('posUnit'),
  (posUnits, posUnit) => {
    return posUnits && posUnit
      ? getPosUnitTitle(posUnits, posUnit)
      : 'Kassa';
  }
);

export const getPosOrg = createSelector(
  state => state.posOrgs,
  state => state.pos.get('posUnit'),
  (posOrgs, posUnit) => {
    if (!posOrgs || !posUnit) {
      return null;
    }
    return posOrgs.find(o => o.get('id') === posUnit.get('posOrgId'));
  }
);

export const getPosOrgPrefs = createSelector(
  state => getPosOrg(state),
  (posOrg) => {
    return posOrg?.get('prefs')?.toJS();
  }
);

export const getPosUnitPrefs = createSelector(
  state => state.pos.get('posUnit'),
  (posUnit) => {
    return posUnit?.get('prefs')?.toJS();
  }
);

export const getPosUnitPrefsFromVunitId = createSelector(
  state => state.pos.get('posUnit'),
  state => state.posUnits,
  (state, props) => props.vunitId,
  (posUnit, posUnits, vunitId) => {
    if (posUnit?.get('vunitId') === vunitId) {
      return posUnit.get('prefs')?.toJS();
    }
    const existing = posUnits.find(i => i.get('id') === vunitId);
    return existing?.get('prefs')?.toJS();
  }
);

export const getCashEnabled = createSelector(
  state => getPosOrgPrefs(state),
  (prefs) => {
    return prefs && prefs[pos.paymentMethodCash];
  }
);

export const getTipEnabled = createSelector(
  state => getPosOrgPrefs(state),
  (prefs) => {
    return prefs && prefs[pos.paymentTipEnabled];
  }
);

export const getShowOpenDrawer = createSelector(
  getPosUnitPrefs,
  state => state.printers,
  state => getCashEnabled(state),
  (prefs, printers, cashEnabled) => {
    const printerId = prefs && prefs[pos.defaultPrinterId];
    return !printers.isEmpty() && printerId && cashEnabled;
  }
);

export const hasTerminalError = createSelector(
  state => state.terminals,
  (terminals) => {
    if (!terminals) {
      return false;
    }
    return terminals.findIndex((terminal) => {
      const { deviceStatus, connectionStatus } = terminal.get('terminalStatus').toObject();
      const isOnline = connectionStatus === 'Online';
      const isReady = deviceStatus === 'Ready';
      const isBusy = deviceStatus === 'Busy';

      const isHealthy = isOnline && (isReady || isBusy);
      return !isHealthy;
    }) !== null;
  }
);

export const hasPrinterError = createSelector(
  getPosUnitPrefs,
  state => state.printers,
  (prefs, printers) => {
    const printerId = prefs && prefs[pos.defaultPrinterId];
    if (!printers || !printerId) {
      return false;
    }
    const isStatusOk = status => status >= 200 && status < 300;
    return printers.findIndex(p => p.get('id') === printerId
      && !isStatusOk(p.getIn(['printerStatus', 'statusCode']))) !== -1;
  }
);

export const getPrinterProgress = createSelector(
  getPosUnitPrefs,
  state => state.printers,
  (prefs, printers) => {
    const printerId = prefs && prefs[pos.defaultPrinterId];
    if (!printers || !printerId) {
      return false;
    }
    return printers.findIndex(p => p.get('id') === printerId
      && p.getIn(['printerStatus', 'printing'])) !== -1;
  }
);

export const hasSaleVatError = createSelector(
  getVatRates,
  getSaleItems,
  (vatRates, saleItems) => {
    const vatErrorFilter = item => vatRates.indexOf(item.get('vatPct')) === -1;
    return saleItems.findIndex(vatErrorFilter) !== -1;
  }
);

export const hasSaleLowStock = createSelector(
  getSaleItems,
  getStockedProducts,
  (saleItems, products) => {
    return saleItems.map(i => products.get(i.get('articleId')))
      .filter(p => p).some(p => p.stocked && p.stockQuantity <= LowStockThreshold);
  }
);

export const getSaleCustomerBookingIds = createSelector(
  getSaleItems,
  (saleItems) => {
    const customerBookingIds = saleItems.map(i => i.get('customerBookingId'));
    return [...new Set(customerBookingIds.filter(i => i))];
  }
);

export function getSaleItemAmount({
  amount, quantity, discountType, discountValue
}) {
  const totalAmount = new Decimal(amount).times(quantity);
  const discountAmount = getDiscountAmount(discountType, discountValue, totalAmount);
  return totalAmount.minus(discountAmount).toDecimalPlaces(2);
}

export const getSaleTransactions = createSelector(
  getSaleId,
  getTransactions,
  (saleId, transactions) => {
    return transactions?.get(String(saleId)) || List();
  }
);

export const getSalePrePayments = createSelector(
  getSaleCustomerBookingIds,
  getPrePayments,
  (bookingIds, prePayments) => {
    return bookingIds.reduce((list, id) => {
      const items = prePayments?.get(String(id));
      return items ? list.concat(items) : list;
    }, List());
  }
);

export const getEnableGiftCardPayment = createSelector(
  getSaleItems,
  (items) => {
    return items.findIndex(item => item.get('itemType') === 'VoucherSale') === -1;
  }
);

export const getSaleGrossAmount = createSelector(
  getSaleItems,
  (items) => {
    return items.filter(item => item.get('itemType') !== 'Discount')
      .map(item => getSaleItemAmount(item.toObject()))
      .reduce((prev, next) => next.plus(prev), new Decimal(0))
      .toDecimalPlaces(2);
  }
);

export const getSaleDiscountAmount = createSelector(
  getSaleDiscount,
  getSaleGrossAmount,
  (discount, grossAmount) => {
    if (!discount) {
      return 0;
    }
    return getDiscountAmount(discount.get('discountType'), discount.get('discountValue'), grossAmount);
  }
);

export const getSaleTotalAmount = createSelector(
  getSaleGrossAmount,
  getSaleDiscountAmount,
  (grossAmount, discountAmount) => {
    return grossAmount.minus(discountAmount);
  }
);

const getSalePrePaymentAmount = createSelector(
  getSalePrePayments,
  getSaleUnpaid,
  (prePayments, isUnpaid) => {
    if (prePayments && isUnpaid) {
      return prePayments
        .reduce((prev, next) => prev.plus(next.get('amount')), new Decimal(0))
        .toNumber();
    }
    return 0;
  }
);

export const getSalePaidAmount = createSelector(
  state => state.posSale.get('paidAmount'),
  getSalePrePaymentAmount,
  (paidAmount, prePaidAmount) => {
    return new Decimal(paidAmount || 0).plus(prePaidAmount).toNumber();
  }
);

export const getSaleRemainingAmount = createSelector(
  getSaleTotalAmount,
  getSalePaidAmount,
  (totalAmount, paidAmount) => {
    return totalAmount.minus(paidAmount).toNumber();
  }
);

function formatItemAmount(item, discountAmount) {
  return item.get('itemType') !== 'Discount'
    ? getSaleItemAmount(item.toObject()).toFixed(2)
    : discountAmount.negated().toFixed(2);
}

export const getSaleChecksum = createSelector(
  getSaleItems,
  getSaleTotalAmount,
  getSaleDiscountAmount,
  (items, totalAmount, discountAmount) => {
    const formattedTotalAmount = totalAmount.toFixed(2);
    const formattedItemAmounts = items.map(item => formatItemAmount(item, discountAmount))
      .reduce((prev, next) => prev.concat(next), '');

    return `${formattedTotalAmount}${formattedItemAmounts}`;
  }
);

export const getSaleCustomer = createSelector(
  state => state.posSale.get('customer'),
  (customer) => {
    if (!customer) {
      return null;
    }
    return Map.isMap(customer) ? {
      customerId: customer.get('id'),
      name: customer.get('name'),
      email: customer.get('email'),
      phoneNumber: customer.get('phoneNumber')
    } : {
      customerId: customer.customerId,
      name: customer.name,
      email: customer.email,
      phoneNumber: customer.phoneNumber
    };
  }
);

export const getSaleCustomerGiftCards = createSelector(
  getSaleCustomer,
  state => state.posSale.get('giftCards'),
  (customer, giftCards) => {
    const statuses = ['RedeemedPartial', 'Sold'];
    return customer
      ? giftCards?.filter(g => statuses.includes(g.voucherStatus) && g.remainingValue > 0)
      : null;
  }
);

export const getShowGiftCardInfo = createSelector(
  getPosOrgPrefs,
  getEnableGiftCardPayment,
  getSaleCustomerGiftCards,
  (posOrgPrefs, enableGiftCardPayment, giftCards) => {
    return posOrgPrefs[pos.paymentMethodGiftCard]
      && enableGiftCardPayment && giftCards?.length > 0;
  }
);

export const getDefaultGroupType = createSelector(
  state => state.userClientPreferences,
  (prefs) => prefs?.getIn(['user', pos.defaultGroupType])
);

export const getPosServices = createSelector(
  state => state.servicesById,
  (servicesById) => servicesById.map(s => ({ type: 'Service', ...s }))
);

export const getPosServiceGroups = createSelector(
  state => state.orderedServiceGroups,
  (orderedServiceGroups) => {
    const addonGroup = orderedServiceGroups
      .find(g => g.get('addon') && g.get('serviceIds').size > 0);

    const groups = orderedServiceGroups
      .filter(g => !g.get('addon') && g.get('serviceIds').size > 0);

    return addonGroup ? groups.push(addonGroup) : groups;
  }
);

export const getReconcileRequired = createSelector(
  state => state.posSale.get('responseCode'),
  (responseCode) => responseCode === '87'
);
