import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { is12HourFormat } from '@Utils/i18n-util';
import { colors } from '@Components/ui/styled/variables';
import { getGridProps } from './grid-selectors';

class RowMarker extends Component {
  static propTypes = {
    viewMode: PropTypes.string.isRequired,
    gridScrollHeight: PropTypes.number.isRequired,
    columnWidth: PropTypes.number.isRequired,
    pixelsPerRow: PropTypes.number.isRequired,
    todayInView: PropTypes.bool.isRequired
  };

  componentDidMount() {
    const date = new Date();
    const secToNextTopOfMinute = (60 - date.getSeconds());
    this.initialTimer = setTimeout(() => {
      this.timer = setInterval(() => this.forceUpdate(), 60000);
      this.forceUpdate();
    }, secToNextTopOfMinute * 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.initialTimer);
    clearInterval(this.timer);
  }

  render() {
    if (!this.props.todayInView) {
      return null;
    }

    const {
      gridScrollHeight, columnWidth, viewMode, phoneMode
    } = this.props;
    const secondsPassed = (moment().hour() * 3600) + (moment().minute() * 60) + moment().second();
    const secondsInDay = 24 * 60 * 60;
    const pctOfDay = secondsPassed / secondsInDay;
    const lineTop = pctOfDay * gridScrollHeight;
    const colIdx = viewMode === 'week' ? moment().weekday() : 0;
    const format = is12HourFormat() ? 'h:mm' : 'HH:mm';

    const ballStyle = {
      position: 'absolute',
      display: 'inline-block',
      zIndex: 1000,
      borderRadius: '50%',
      border: '1px solid white',
      background: colors.primaryDark,
      width: 12, /* This needs to be adjustet for phoneMode */
      height: 12, /* This needs to be adjustet for phoneMode */
      top: lineTop - 6,
      left: columnWidth * colIdx - 6,
      paddingTop: '0.2em' /* This needs to be adjustet for phoneMode */
    };

    const lineStyle = {
      zIndex: 1000,
      top: lineTop,
      borderTop: `1px solid ${colors.primaryDark}`
    };

    const timeStyle = {
      position: 'absolute',
      color: colors.primaryDark,
      fontWeight: 500,
      top: lineTop - 8,
      fontSize: `${phoneMode ? '10px' : '11px'}`,
      padding: `${phoneMode ? '1px 3px' : '1px 5px'}`,
      left: `${phoneMode ? '-32px' : '-42px'}`,
      background: '#fff',
      boxShadow: '0px 0px 5px #ccc',
      borderRadius: 5
    };

    return (
      <>
        <div className="hrule print-hidden" style={lineStyle} />
        <div id="row-marker" style={timeStyle}>{moment().format(format)}</div>
        <div style={ballStyle} />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { viewMode } = ownProps.routeParams;
  const {
    viewDateStartEnd, pixelsPerRow, gridScrollHeight, columnWidth
  } = getGridProps(state, ownProps);
  const todayInView = moment().isBetween(viewDateStartEnd.start, viewDateStartEnd.end, 'day', '[)');

  return {
    phoneMode: state.mainViewState.get('phoneMode'),
    viewMode,
    gridScrollHeight,
    columnWidth,
    pixelsPerRow,
    todayInView
  };
};

export default connect(mapStateToProps)(RowMarker);
