import React, { useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CurrencyUtil from '@Utils/currency-util';
import { getVehicleInfo } from '@Utils/vehicle-util';
import DialogPosRefund from '@Components/pos/dialogs/dialog-pos-refund';
import { getAvailablePosUnits } from '@State/pos-selectors';
import {
  getCustomerDetails, getDiscountDescription, getItemDescription, getPaymentMethodName, saleItemSort
} from '@Utils/pos-utils';
import {
  Button, Panel, Text, Cell, Row
} from '@Components/ui/styled/main';
import {
  PanelWrapper, CcuFooter, RefundItemWrap, RefundStyle, RowItem, SummaWrap, ReportTitle, ReportTitleButtons
} from '@Components/pos/style';
import ReceiptTools from './receipt-tools';

const styleTextAmount = { marginRight: '40px' };

const renderTransactionsRow = (id, title, value, tipAmount) => (
  <div key={id}>
    <Row>
      <Cell>{title}</Cell>
      <Cell pullRight>{value}</Cell>
    </Row>
    {tipAmount && (
      <Row>
        <Cell>(Dricks)</Cell>
        <Cell pullRight>({tipAmount})</Cell>
      </Row>
    )}
  </div>
);

export const renderReceiptItemsSubRow = (quantity, description, itemType, value) => (
  <Row>
    <Cell width="30px">{quantity}</Cell>
    <Cell>{getItemDescription(description, itemType)}</Cell>
    <Cell pullRight>{value}</Cell>
  </Row>
);

const renderCommentSubRow = (comment) => (
  <Row>
    <Cell width="30px" />
    <Cell><span className="text-muted">{comment}</span></Cell>
  </Row>
);

export const renderReceiptItemsRow = (item, cashierName) => {
  const {
    id, itemType, description, quantity, totalAmount, discountType, discountAmount, discountValue,
    comment, sellerName, voucherBeneficiary, voucherCode, vehicleRegNo, vehicleDescription
  } = item.toJS();
  const isDiscount = itemType === 'Discount';
  const vehicleInfo = getVehicleInfo(vehicleRegNo, vehicleDescription);

  return (
    <RowItem key={id}>
      {!isDiscount && (<Text>{renderReceiptItemsSubRow(quantity, description, itemType, CurrencyUtil.accountCurrency(totalAmount, 2))}</Text>)}
      {discountAmount !== 0 && (
        <Text>
          {renderReceiptItemsSubRow(null, getDiscountDescription(discountValue, discountType), itemType, CurrencyUtil.accountCurrency(discountAmount))}
        </Text>
      )}
      {cashierName !== sellerName && renderCommentSubRow(`Säljare: ${sellerName}`)}
      {comment && renderCommentSubRow(comment)}
      {vehicleInfo && renderCommentSubRow(vehicleInfo)}
      {voucherBeneficiary && renderCommentSubRow(voucherBeneficiary)}
      {voucherCode && renderCommentSubRow(voucherCode)}
    </RowItem>
  );
};

const Receipt = ({
  receiptId, receipt, showSendReceipt, handleSelectReceipt, printReceipt, routeParams
}) => {
  const availablePosUnits = useSelector(getAvailablePosUnits);

  const hasReceipt = receipt && !receipt.isEmpty();
  const isEcom = receipt.get('vunitName') === 'Ecommerce';
  const isInvoice = receipt.get('receiptType')?.includes('Invoice');
  const isRefund = hasReceipt && receipt.get('transactionType') === 'Refund';
  const isShowRefundBtn = hasReceipt && !isEcom && !isRefund
    && receipt.get('saleStatus') !== 'Refunded' && receipt.get('paidAmount') > 0;

  const [showRefund, setShowRefund] = useState(false);
  const onShowRefund = () => setShowRefund(true);
  const onHideRefund = () => setShowRefund(false);

  let refundTransactions,
    salesTransactions;

  if (hasReceipt) {
    const transactions = receipt.get('transactions').toJS();
    refundTransactions = transactions && transactions.filter(({ transactionType }) => transactionType === 'Refund');
    if (!isRefund) {
      salesTransactions = transactions && transactions.filter(({ transactionType }) => transactionType === 'Sale');
    }
  }

  const getDateText = (createdTs) => `${moment(createdTs).format('LLL')}`;
  const receiptText = isEcom ? 'Onlinebetalning' : 'Kvitto';
  const titleText = isRefund ? 'Returkvitto' : receiptText;
  const customerDetails = getCustomerDetails(receipt.get('customerName'), receipt.get('customerPno'));

  return hasReceipt ? (
    <>
      {showRefund && <DialogPosRefund onClose={onHideRefund} receiptId={receiptId} receipt={receipt} />}
      <ReportTitle>
        <h3 className="mt0 mb0">
          {isInvoice
            ? `Faktura #${receipt.get('receiptNumber')}`
            : `${titleText} #${receipt.get('receiptNumber')}`}
        </h3>
        <ReportTitleButtons>
          {isInvoice && (
            <a href={receipt.get('pdfUrl')} target="_blank" className={receipt.get('pdfUrl') ? 'btn btn-default' : 'hidden'}>
              <i className="far fa-file-alt mr1" /> Ladda ner faktura
            </a>
          )}
          {!isInvoice && isShowRefundBtn && <Button small primary onClick={onShowRefund}>Återköp</Button>}
          <ReceiptTools
            receipt={receipt}
            printReceipt={printReceipt}
            showSendReceipt={showSendReceipt}
            routeParams={routeParams}
          />
        </ReportTitleButtons>
      </ReportTitle>
      <Text bold>{getDateText(receipt.get('createdTs'))}</Text>
      {availablePosUnits.size > 1 ? <Text muted bold>Kassa: {receipt.get('vunitName')}</Text> : null}
      <Text muted bold>Kassör: {receipt.get('cashierName')}</Text>
      {customerDetails ? <Text muted bold>Kund: {customerDetails}</Text> : null}
      <PanelWrapper>
        <Panel>
          {receipt.get('items').sort(saleItemSort).map(item => renderReceiptItemsRow(item, receipt.get('cashierName')))}
          <Row>
            <SummaWrap>
              <Cell><Text bold>Summa</Text></Cell>
              <Cell pullRight>
                <Text bold>{CurrencyUtil.accountCurrency(receipt.get('amount'))}</Text>
              </Cell>
            </SummaWrap>
          </Row>
        </Panel>
        <div>
          <Text pullLeftPadding muted bold>
            {isRefund ? 'Återbetalt' : 'Transaktioner'}{' '}
          </Text>
          <Panel>
            {salesTransactions && salesTransactions.map(({ transactionId, paymentMethod, paymentMethodName, transactionAmount, tipAmount }) => (
              renderTransactionsRow(transactionId, getPaymentMethodName(paymentMethodName || paymentMethod), CurrencyUtil.accountCurrency(transactionAmount), tipAmount ? CurrencyUtil.accountCurrency(tipAmount) : null)
            ))}
            {isRefund && refundTransactions.length > 0 && refundTransactions
              .filter(transaction => transaction.receiptId === receipt.get('receiptId'))
              .map(({ transactionId, paymentMethod, paymentMethodName, transactionAmount, tipAmount }) => (
                renderTransactionsRow(transactionId, getPaymentMethodName(paymentMethodName || paymentMethod), CurrencyUtil.accountCurrency(Math.abs(transactionAmount)), tipAmount ? CurrencyUtil.accountCurrency(tipAmount) : null)
              ))}
          </Panel>
          {!isRefund && refundTransactions.length > 0 && (
          <RefundStyle>
            {refundTransactions.map(({ receiptId, createdTs, transactionAmount }) => (
              <RefundItemWrap key={receiptId} onClick={() => handleSelectReceipt(receiptId)}>
                <Cell>
                  Återköpt {getDateText(createdTs)}
                </Cell>
                <Cell pullRight style={styleTextAmount}>{CurrencyUtil.accountCurrency(transactionAmount)}</Cell>
                <Cell><i className="far fa-chevron-right" /></Cell>
              </RefundItemWrap>
            ))}
          </RefundStyle>
          )}
        </div>
      </PanelWrapper>
      {!isEcom && (
        <CcuFooter>
          {receipt.get('ccuCode') && `Kontrollkod: ${receipt.get('ccuCode')}`}<br />
          {receipt.get('ccuId') && `Kassabeteckning: ${receipt.get('ccuId')}`}
        </CcuFooter>
      )}
    </>
  ) : null;
};

export default Receipt;
