import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import Popover from '@Components/ui/popover';
import { getOrderedPrimaryResources } from '@State/calendar-selectors';
import {
  addServiceResourceMapping, deleteServiceResourceMapping, serviceMappingValues, setServiceResourceMappings
} from '@State/services-actions';
import Button from '@Components/ui/button';
import CustomServiceSettingsModal from './custom-service-settings-modal';
import MultiResourceModal from './multi-resource-modal';

class ServiceResourceMappings extends Component {
  static propTypes = {
    resourcesById: PropTypes.object.isRequired,
    serviceId: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      progress: false,
      saved: false,
      showConfirmSelectAll: false,
      customServiceMapping: null,
      showMultiResource: false
    };
  }

  showConfirmSelectAll = (ev) => {
    ev.preventDefault();
    this.setState({ showConfirmSelectAll: true });
  };

  hideConfirmSelectAll = (ev) => {
    ev && ev.preventDefault();
    this.setState({ showConfirmSelectAll: false });
  };

  selectAll = (ev) => {
    ev.preventDefault();
    this.props.setServiceResourceMappings(this.props.serviceId, this.props.orderedResources.map(r => r.id))
      .then(() => this.hideConfirmSelectAll());
  };

  selectResource = (ev, resourceId) => {
    if (ev.target.checked) {
      this.addMapping(resourceId);
    } else {
      this.deleteMapping(resourceId);
    }
  };

  addMapping = (resourceId) => {
    this.setState({ loading: true, saved: false });
    return this.props.addServiceResourceMapping(this.props.serviceId, resourceId)
      .then(() => this.setState({ loading: false, saved: true }, this.resetSaved));
  };

  deleteMapping = (resourceId) => {
    this.setState({ loading: true, saved: false });
    return this.props.deleteServiceResourceMapping(this.props.serviceId, resourceId)
      .then(() => this.setState({ loading: false, saved: true }, this.resetSaved));
  };

  resetSaved = () => {
    setTimeout(() => { this.setState({ saved: false }); }, 2000);
  };

  showCustomMappingModal = (ev, resource) => {
    ev.preventDefault();
    const serviceMapping = this.getMappedService(resource);
    this.setState({ customServiceMapping: serviceMapping.set('resource', resource) });
  };

  hideCustomMappingModal = (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    this.setState({ customServiceMapping: null });
  };

  showMultiResource = (ev) => {
    ev && ev.preventDefault();
    this.setState({ showMultiResource: true });
  };

  hideMultiResource = (ev) => {
    ev && ev.preventDefault();
    this.setState({ showMultiResource: false });
  };

  render() {
    const { loading, saved, customServiceMapping, showMultiResource } = this.state;
    const { orderedResources, resourcesById, hasCustomValues, service, loaded } = this.props;
    const { multiResourceRules, addon } = service;

    if (!resourcesById) {
      return null;
    }

    const selectedResourceIds = this.getSelectedResourceIds();
    const isAllSelected = selectedResourceIds.length >= orderedResources.length;
    const btnClasses = isAllSelected ? 'btn btn-default disabled' : 'btn btn-default';

    return (
      <>
        <h4>Välj resurser som utför tjänsten</h4>
        {loaded && selectedResourceIds.length === 0 && (
          <div className="alert alert-danger mt3 mb3" role="alert">
            <i className="fa fa-exclamation-circle" />&nbsp;
            OBS! Inga resurser är valda för denna tjänst. För att kunna bokas online
            behöver du välja vilka resurser som utför tjänsten nedan.
          </div>
        )}
        <p>
          Du kan välja flera resurser som krävs för tjänsten,
          t.ex. två personer, ett rum och/eller utrustning.
        </p>
        {multiResourceRules?.length > 0 && !addon ? (
          <>
            {multiResourceRules.map((rule, index) => {
              const { primaryRule, requiredResources, resourceIds } = rule;
              return (
                <Fragment key={index}>
                  <h5 className="mt4">{primaryRule ? 'Primära resurser' : 'Fler resurser'}</h5>
                  {requiredResources > 1 && (
                    <p className="text-muted">
                      <i className="far fa-info-circle mr1" />
                      {requiredResources} resurser krävs
                    </p>
                  )}
                  <div className="resource-mappings">
                    {resourceIds.map(id => resourcesById.has(id) && (
                      <div key={id} className="label-block">{resourcesById.get(id).name}</div>
                    ))}
                  </div>
                </Fragment>
              );
            })}
            <br />
            <Button small gray onClick={this.showMultiResource}>
              Ändra resurser
            </Button>
          </>
        ) : (
          <>
            <p>
              Du kan även ställa in egna värden för respektive resurs, t.ex. om en
              tjänst har olika pris beroende på vem som utför den.
            </p>
            <br />
            {!addon && (
              <div className="pull-right">
                <Button small gray news onClick={this.showMultiResource}>
                  Flera resurser
                  <i className="far fa-user-plus ml1" />
                </Button>
              </div>
            )}
            <Popover
              isOpen={this.state.showConfirmSelectAll}
              body={this.popoverContent()}
              onOuterAction={this.hideConfirmSelectAll}
              preferPlace="below"
            >
              <button onClick={this.showConfirmSelectAll} className={btnClasses} disabled={isAllSelected}>Välj alla</button>
            </Popover>
            &nbsp; &nbsp;
            {loading && <strong className="text-muted">Sparar...</strong>}
            {saved && <strong className="text-success">Sparat!</strong>}
            <br />
            <br />
            <div className="resource-mappings">
              {orderedResources.map(this.renderMapping)}
            </div>
            {hasCustomValues && (
              <div>
                <br />
                <i className="fa fa-info-circle text-highlight-warn" /> = Egna värden inställda för resursen (t.ex. tid/pris)
              </div>
            )}
          </>
        )}
        {customServiceMapping && (
          <CustomServiceSettingsModal
            isAddonService={service.addon}
            showInBooking={service.webShowInBooking}
            serviceMapping={customServiceMapping}
            closeModal={this.hideCustomMappingModal}
          />
        )}
        {showMultiResource && (
          <MultiResourceModal
            onClose={this.hideMultiResource}
            setServiceResourceMappings={this.props.setServiceResourceMappings}
            selectedResourceIds={selectedResourceIds}
            service={service}
          />
        )}
      </>
    );
  }

  hasCustomDiffs = (formValues, mappedService) => {
    if (!formValues || !mappedService || !mappedService.get('customValues')) {
      return false;
    }
    for (let i = 0; i < serviceMappingValues.length; i++) {
      const prop = serviceMappingValues[i];
      if (String(mappedService.get(prop)) !== String(formValues[prop])) {
        return true;
      }
    }
    return false;
  };

  renderMapping = (resource) => {
    const { loading } = this.state;
    const { formValues } = this.props;
    const mappedService = this.getMappedService(resource);
    const hasCustomDiffs = this.hasCustomDiffs(formValues, mappedService);
    const inputId = `resource-${resource.id}`;

    return (
      <div className="resource-mapping" key={resource.id}>
        <input id={inputId} type="checkbox" value="true" checked={!!mappedService} disabled={loading} onChange={ev => this.selectResource(ev, resource.id)} />
        <label htmlFor={inputId}>{resource.name}</label>
        <div>
          {hasCustomDiffs && <i className="fa fa-info-circle text-highlight-warn" />}
          {mappedService && (
          <a href="#" onClick={ev => this.showCustomMappingModal(ev, resource)}>
            Inställningar
          </a>
          )}
        </div>
      </div>
    );
  };

  popoverContent() {
    return (
      <div className="Popover-content-small">
        <a href="#" onClick={this.hideConfirmSelectAll} className="Popover-close"><i className="fa fa-lg fa-times" /></a>
        Vill du välja samtliga resurser som bokningsbara för denna tjänst?
        <br />
        <br />
        <button className="btn btn-default btn-block" onClick={this.selectAll} disabled={this.state.progress}>Välj alla</button>
      </div>
    );
  }

  getSelectedResourceIds = () => {
    const resourceIds = [];
    this.props.orderedResources.forEach((resource) => {
      if (this.getMappedService(resource)) {
        resourceIds.push(resource.id);
      }
    });
    return resourceIds;
  };

  getMappedService(resource) {
    const combinedId = `${resource.id}:${this.props.serviceId}`;
    return this.props.resourceServiceMappingsByCombinedId.get(combinedId);
  }
}

const mapStateToProps = (state) => {
  const { resourcesById, resourceServiceMappingsByCombinedId } = state;
  const formValues = getFormValues('edit-service-form')(state);

  return {
    resourcesById,
    orderedResources: getOrderedPrimaryResources(state),
    resourceServiceMappingsByCombinedId,
    formValues
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addServiceResourceMapping: (serviceId, resourceId) => {
      return dispatch(addServiceResourceMapping(serviceId, resourceId));
    },
    deleteServiceResourceMapping: (serviceId, resourceId) => {
      return dispatch(deleteServiceResourceMapping(serviceId, resourceId));
    },
    setServiceResourceMappings: (serviceId, resourceIds) => {
      return dispatch(setServiceResourceMappings(serviceId, resourceIds));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceResourceMappings);
