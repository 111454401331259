import React, { useState } from 'react';
import { txt } from '@Utils/i18n-util';
import ModalDialog from '@Components/dialogs/modal-dialog';
import DialogLoader from '@Components/dialogs/dialog-loader';
import SelectParkedSaleModal from './select-parked-sale-modal';
import msg from './pos-sale.msg';

const SaleParkingModal = ({ parkedSales, isSaleEmpty, onPark, onUnpark, onClose }) => {
  const [state, setState] = useState('');
  const [progress, setProgress] = useState(false);

  const showParkedSales = () => setState('parkedSales');
  const onCancel = () => setState('');

  const handleUnpark = (sale) => {
    setProgress(true);
    return onUnpark(sale)
      .catch(() => setProgress(false));
  };

  const handlePark = () => {
    setProgress(true);
    return onPark()
      .catch(() => setProgress(false));
  };

  if (progress) {
    return (
      <DialogLoader
        title={state === 'parkedSales' ? txt(msg.lblLoadingReceipt) : txt(msg.lblParkingReceipt)}
      />
    );
  }

  if (state === 'parkedSales') {
    return (
      <SelectParkedSaleModal
        isSaleEmpty={isSaleEmpty}
        parkedSales={parkedSales}
        onSelect={handleUnpark}
        onClose={onCancel}
      />
    );
  }

  return (
    <ModalDialog
      title={txt(msg.lblConfirmParkReceipt)}
      onClose={onClose}
      buttons={[{
        name: txt(msg.btnParkSale),
        primary: true,
        onClick: handlePark,
        disabled: isSaleEmpty
      }, {
        name: txt(msg.btnLoadParked),
        gray: true,
        onClick: showParkedSales,
        disabled: parkedSales.isEmpty()
      }]}
    />
  );
};

export default SaleParkingModal;
