import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { reset } from 'redux-form';
import { useSelector } from 'react-redux';

import LabelSwitchInput from '@Components/ui/label-switch-input';
import { Panel, Flex, Button } from '@Components/ui/styled/main';
import StaffConfigUserLoginForm from '@Components/admin/staff-config/staff-config-user-login-form';
import StaffRoleFormRow from '@Components/admin/staff-config/staff-role-form-row';
import StaffRolePermissions from '@Components/admin/staff-config/staff-role-permissions';
import { USER_LOGIN_CLEAR, replaceUserLogin, addUserLogin } from '@State/users-actions';
import StaffUserAccess from '@Components/admin/staff-config/staff-user-access';
import StaffUserNotifications from './staff-user-notifications';

const StaffLoginEnabled = ({
  id, onChangeToggle, isOn, loginType, dispatch, onChange,
  isLoggedInUser, isAdministrator, isOwner, isSysAdmin
}) => {
  const initialValuesUserLoginForm = {
    username: '',
    password: ''
  };

  const user = useSelector(state => state.usersById.find((user) => user.get('id') === id));
  const userNameChecked = user?.get('userNameChecked');

  const [showLoginForm, setShowLoginForm] = useState(false);
  const toggleLoginForm = () => setShowLoginForm(!showLoginForm);

  const [showNotifications, setShowNotifications] = useState(false);
  const onShowNotifications = () => setShowNotifications(true);
  const onHideNotifications = () => setShowNotifications(false);

  const [showUserAccess, setShowUserAccess] = useState(false);
  const onShowUserAccess = (ev) => {
    ev.preventDefault();
    setShowUserAccess(true);
  };
  const onHideUserAccess = () => setShowUserAccess(false);

  const onCreateNewUser = (values) => {
    const data = { ...values, userId: id };
    userNameChecked.registered ? dispatch(replaceUserLogin(data)) : dispatch(addUserLogin(data));
    hideLoginForm();
  };

  const hideLoginForm = useCallback(
    () => {
      setShowLoginForm(false);
      dispatch({ type: USER_LOGIN_CLEAR, userId: id });
      dispatch(reset('staff-config-user-login'));
    }, [id]
  );

  const preventLockOut = isOn && (isLoggedInUser || isOwner);
  const preventRoleChange = preventLockOut && isAdministrator;

  return (
    <Panel>
      <div className="form-group">
        <LabelSwitchInput
          noBorder
          disabled={preventLockOut}
          label="Tillåt inloggning"
          isOn={isOn}
          id="loginEnabled"
          handleToggle={onChangeToggle}
          description="Låter användaren logga in via web och app"
        />
      </div>
      {isOn && (loginType === 'None' ? (
        <Flex right>
          <StaffConfigUserLoginForm
            dispatch={dispatch}
            id={id}
            userNameChecked={userNameChecked}
            isOpen={showLoginForm}
            onHidePopup={hideLoginForm}
            onSubmit={onCreateNewUser}
            initialValues={initialValuesUserLoginForm}
          >
            <Button small primary onClick={toggleLoginForm}>Lägg till mejl</Button>
          </StaffConfigUserLoginForm>
        </Flex>
      ) : (
        <>
          <a href="#" className="pull-right" onClick={onShowUserAccess}>
            Avancerade rättigheter
          </a>
          <StaffRoleFormRow disabled={preventRoleChange} onChange={onChange} />
          {isSysAdmin && user && <StaffRolePermissions user={user} />}
          <div className="mt3">
            <Button white small onClick={onShowNotifications}>Hantera notiser</Button>
          </div>
        </>
      ))}
      {showUserAccess && (
        <StaffUserAccess user={user} onClose={onHideUserAccess} />
      )}
      {showNotifications && (
        <StaffUserNotifications user={user} onClose={onHideNotifications} />
      )}
    </Panel>
  );
};

StaffLoginEnabled.propTypes = {
  id: PropTypes.number,
  userRole: PropTypes.string,
  onChangeToggle: PropTypes.func,
  isOn: PropTypes.bool,
  loginType: PropTypes.string
};

export default StaffLoginEnabled;
