import React, { memo } from 'react';
import PropTypes from 'prop-types';
import StaffListItem from '@Components/staff/staff-list-item';
import Button from '@Components/ui/button';
import { txt } from '@Utils/i18n-util';
import msg from './staff.msg';

const StaffList = ({ onRedirect, list }) => (
  <div className="columns-list">

    {list.map((item) => (
      <StaffListItem
        key={item.get('id')}
        item={item}
      />
    ))}
    <div className="columns-list-help">
      <p>{txt(msg.txtStaffConfig)}</p>
      <Button small primary onClick={onRedirect}>
        {txt(msg.btnStaffConfig)}
      </Button>
    </div>
  </div>
);

StaffList.propTypes = {
  list: PropTypes.object,
  onRedirect: PropTypes.func
};

export default memo(StaffList);
