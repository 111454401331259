import React from 'react';
import { pos } from '@Utils/preference-keys';
import Button from '@Components/ui/button';
import ProductGroupNav from '@Components/pos/products/product-group-nav';
import ButtonDropdown from '@Components/ui/button-dropdown';
import { DropDownDivider, DropDownItem } from '@Components/ui/drop-down/dropdown';
import { txt } from '@Utils/i18n-util';
import msg from './product.msg';

const ProductHeader = ({
  filter, onFilterChange, onFilterKeyPress, onFilterReset, isDisabled,
  onSelectType, onSetDefaultType, defaultGroupType, selectedType,
  onHideServices, hideServices, onHideProducts, hideProducts,
  posUnitPrefs, togglePosUnitPref
}) => {
  const hasFilter = filter !== '';
  const isServiceDefault = defaultGroupType === 'Services';

  const handleSetDefaultType = (ev) => {
    ev.preventDefault();
    onSetDefaultType(isServiceDefault ? 'Products' : 'Services');
  };

  const handleHideServices = (ev) => {
    ev.preventDefault();
    onHideServices(!hideServices);
  };

  const handleHideProducts = (ev) => {
    ev.preventDefault();
    onHideProducts(!hideProducts);
  };

  const handleShowQuantity = (ev) => {
    ev.preventDefault();
    togglePosUnitPref(pos.showProductStockQuantity);
  };

  const handleStockWarning = (ev) => {
    ev.preventDefault();
    togglePosUnitPref(pos.lowStockQuantityWarning);
  };

  const handleMergeArticles = (ev) => {
    ev.preventDefault();
    togglePosUnitPref(pos.mergeSameArticlesOnReceipt);
  };

  const handleBookingNote = (ev) => {
    ev.preventDefault();
    togglePosUnitPref(pos.showBookingNoteInSale);
  };

  return (
    <div className="pos__list__header">
      <div>
        <input
          type="text"
          className="form-control"
          placeholder={txt(msg.lblSearch)}
          value={filter}
          onChange={onFilterChange}
          onKeyPress={onFilterKeyPress}
          disabled={isDisabled}
        />
        {hasFilter && (
          <Button small gray onClick={onFilterReset} disabled={isDisabled}>
            {txt(msg.lblReset)}
          </Button>
        )}
      </div>
      {hasFilter && <h5>{txt(msg.lblFilterResults, { filter })}</h5>}
      {!hasFilter && (
        <ProductGroupNav
          onSelectType={onSelectType}
          defaultGroupType={defaultGroupType}
          selectedType={selectedType}
          hideServices={hideServices}
          hideProducts={hideProducts}
        />
      )}
      <div>
        <ButtonDropdown disabled={isDisabled} icon="fa fa-cog">
          {!hideServices && !hideProducts && (
            <DropDownItem onClick={handleSetDefaultType} icon="fa-random">
              {isServiceDefault
                ? 'Byt plats på tjänster och produkter'
                : 'Byt plats på produkter och tjänster'}
            </DropDownItem>
          )}
          <DropDownItem
            onClick={hideProducts ? null : handleHideServices}
            icon="fa-blank"
            checked={hideServices}
            disabled={hideProducts}
          >
            Dölj tjänster
          </DropDownItem>
          <DropDownItem
            onClick={hideServices ? null : handleHideProducts}
            icon="fa-blank"
            checked={hideProducts}
            disabled={hideServices}
          >
            Dölj produkter
          </DropDownItem>
          <DropDownDivider />
          <DropDownItem
            onClick={handleBookingNote}
            icon="far fa-info-circle"
            checked={posUnitPrefs[pos.showBookingNoteInSale]}
          >
            Visa anteckningar från bokning
          </DropDownItem>
          <DropDownItem
            onClick={handleMergeArticles}
            icon="far fa-merge"
            checked={posUnitPrefs[pos.mergeSameArticlesOnReceipt]}
          >
            Slå ihop artiklar på kvittot
          </DropDownItem>
          {!hideProducts && (
            <>
              <DropDownDivider />
              <DropDownItem
                onClick={handleShowQuantity}
                icon="far fa-box"
                checked={posUnitPrefs[pos.showProductStockQuantity]}
              >
                Visa lagersaldo för produkter
              </DropDownItem>
              <DropDownItem
                onClick={handleStockWarning}
                icon="far fa-circle-exclamation"
                checked={posUnitPrefs[pos.lowStockQuantityWarning]}
              >
                Varna vid lågt lagersaldo
              </DropDownItem>
            </>
          )}
        </ButtonDropdown>
      </div>
    </div>
  );
};

export default ProductHeader;
