import React, { Component } from 'react';
import { connect } from 'react-redux';
import { company, web } from '@Utils/preference-keys';
import { toggleBKFModal } from '@State/bkf/actions';
import { bkfModals } from '@State/bkf/constants';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { getTimeString } from '@Utils/time-util';
import { formatPhoneNumber } from '@Utils/phone-util';
import { formatOrgNo } from '@Utils/luhn-util';
import { formatVehicleRegNo } from '@Utils/vehicle-util';
import { txt } from '@Utils/i18n-util';
import msg from './booking-print-modal.msg';

class BookingPrintModal extends Component {
  componentDidMount() {
    document.body.classList.add('print-hidden');
  }

  componentWillUnmount() {
    document.body.classList.remove('print-hidden');
  }

  onPrint = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    window.print();
  };

  render() {
    const {
      onClose, companyName, contactNumber, addressLine1, area, resources, resourceLabel,
      startTime, endTime, services, customer, company, vehicle, note, service, customers
    } = this.props;
    const title = `Bokning hos ${companyName}`;
    const address = [addressLine1, area].filter(s => s).join(', ');
    const classCustomers = customers?.valueSeq().filter(c => c.status !== 'Cancelled').toArray();

    return startTime ? (
      <ModalDialog
        contentSize="xl"
        alignTop
        onClose={onClose}
      >
        <div className="print-visible">
          <div className="pull-right print-hidden">
            <button onClick={this.onPrint} className="btn-save mr1">{txt(msg.btnPrint)}</button>
            <button onClick={onClose} className="btn btn-default">{txt(msg.btnClose)}</button>
          </div>
          <h3 className="mt0 mb1">{title}</h3>
          <p className="mt0 mb4">{startTime.format('LLLL')} - {endTime.format('LT')}</p>
          <hr />

          <table className="table-print">
            <tbody>
              <tr>
                <th>{resourceLabel || txt(msg.lblResource)}</th>
                <td>{resources.map(r => r.name).join(', ')}</td>
              </tr>
              <tr>
                <th>{txt(msg.lblService)}</th>
                <td>{services?.size > 0
                  ? services.valueSeq().map(s => (
                    <div key={s.id}>{s.name} ({getTimeString(s.duration)})</div>
                  )) : service?.name}
                </td>
              </tr>
              {vehicle && (
                <tr>
                  <th>{txt(msg.lblVehicle)}</th>
                  <td>
                    {vehicle.officialIdNo && <div>{formatVehicleRegNo(vehicle.officialIdNo)}</div>}
                    {vehicle.name && <div>{vehicle.name}</div>}
                  </td>
                </tr>
              )}
              {company && (
                <tr>
                  <th>{txt(msg.lblCompany)}</th>
                  <td>
                    {company.name && <div>{company.name}</div>}
                    {company.officialIdNo && <div>{formatOrgNo(company.officialIdNo)}</div>}
                  </td>
                </tr>
              )}
              {customer && (
                <tr>
                  <th>{txt(msg.lblCustomerDetails)}</th>
                  <td>
                    {customer.name && <div>{customer.name}</div>}
                    {customer.phoneNumber && <div>{formatPhoneNumber(customer.phoneNumber)}</div>}
                    {customer.email && <div>{customer.email}</div>}
                  </td>
                </tr>
              )}
              {!customer && classCustomers?.length > 0 && (
                <tr>
                  <th>{txt(msg.lblParticipants)}</th>
                  <td>
                    {classCustomers.map(customer => (
                      <div key={customer.id}>
                        {customer.name}, {formatPhoneNumber(customer.phoneNumber)}
                      </div>
                    ))}
                  </td>
                </tr>
              )}
              {note && (
                <tr>
                  <th>{txt(msg.lbNotes)}</th>
                  <td className="text-wrap">{note}</td>
                </tr>
              )}
            </tbody>
          </table>

          {(address || contactNumber) && (
            <p>
              <hr />
              <strong>{companyName}</strong>
              <br />
              {address && (
              <span>
                {address}
                <br />
              </span>
              )}
              {contactNumber && (
              <span>
                {txt(msg.lblContactPhone)}: {contactNumber}
              </span>
              )}
            </p>
          )}
        </div>
      </ModalDialog>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  const { bkf, locationConfig } = state;

  return {
    resourceLabel: locationConfig.get(web.resourceLabel),
    companyName: locationConfig.get(company.companyName),
    contactNumber: locationConfig.get(company.contactNumber),
    addressLine1: locationConfig.get(company.addressLine1),
    area: locationConfig.get(company.area),
    resources: bkf.get('resources'),
    startTime: bkf.get('startTime'),
    endTime: bkf.get('endTime'),
    customer: bkf.get('customer'),
    customers: bkf.get('customers'),
    company: bkf.get('company'),
    services: bkf.get('services'),
    service: bkf.get('service'),
    vehicle: bkf.get('vehicle'),
    note: bkf.get('note')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => dispatch(toggleBKFModal(bkfModals.print, false))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingPrintModal);
