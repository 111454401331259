import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmbeddedInApp } from '@Utils/embedded-util';
import { postWebkitMessage } from '@Utils/wk-embed-bridges';
import { getCurrentVersion, getIsVersionMismatch, getRequiredVersion, getShowReleaseNotesNotification } from '@State/selectors';
import { isNewFeature } from '@Components/sysadmin/release-notes/release-notes-helpers';
import { Button } from '@Components/ui/styled/main';
import ReleaseNotesModal from './release-notes-modal';

class VersionMismatchAlert extends Component {
  static propTypes = {
    embeddedInApp: PropTypes.bool.isRequired,
    currentVersion: PropTypes.number.isRequired,
    requiredVersion: PropTypes.number.isRequired
  };

  state = {
    showReleaseNotes: false
  };

  render() {
    const { embeddedInApp, currentVersion, requiredVersion, isVersionMismatch } = this.props;

    if (isVersionMismatch) {
      console.error(`New version required. currentVersion: ${currentVersion}, requiredVersion: ${requiredVersion}`);

      if (embeddedInApp) {
        this.callEmbedder();
      } else {
        return this.renderNewVersionBanner();
      }
    }
    return null;
  }

  callEmbedder() {
    const { currentVersion, requiredVersion } = this.props;
    postWebkitMessage('newVersionAvailable', { currentVersion, requiredVersion });
  }

  renderNewVersionBanner() {
    return !this.state.showReleaseNotes ? (
      <div className="new-version-alert-container">
        <div className="new-version-alert">
          {isNewFeature(this.props.showNotification) ? (
            <>
              <img className="new-version-alert-icon" alt="Alert icon" src="/icon-party-horn.svg" />
              <div className="new-version-alert-content">
                <p className="new-version-alert-title">Nya funktioner!</p>
                <p>Uppdatera direkt eller läs mer om vad som är nytt.</p>
              </div>
            </>
          ) : (
            <>
              <i className="new-version-alert-icon far fa-cloud-bolt" />
              <div className="new-version-alert-content">
                <p className="new-version-alert-title">Uppdatering tillgänglig</p>
                <p>Innehåller mindre korrigeringar och förbättringar.</p>
              </div>
            </>
          )}

          <div className="new-version-alert-cta-group">
            <Button tiny update onClick={this.refresh}>Uppdatera</Button>
            {this.props.showNotification && (
              <Button
                tiny
                text
                className="new-version-alert-link"
                onClick={() => this.setState({ showReleaseNotes: true })}
              >
                Läs mer
              </Button>
            )}
          </div>
        </div>
      </div>
    ) : (
      <ReleaseNotesModal onClose={() => this.setState({ showReleaseNotes: false })} />
    );
  }

  refresh = (e) => {
    e.preventDefault();
    window.location.reload();
  };
}

const mapStateToProps = (state) => {
  const embeddedInApp = isEmbeddedInApp(state);
  return {
    currentVersion: getCurrentVersion(state),
    requiredVersion: getRequiredVersion(state),
    isVersionMismatch: getIsVersionMismatch(state),
    showNotification: getShowReleaseNotesNotification(state),
    embeddedInApp
  };
};

const VersionMismatchAlertContainer = connect(
  mapStateToProps
)(VersionMismatchAlert);

export default VersionMismatchAlertContainer;
