import React from 'react';
import { useSelector } from 'react-redux';
import { logMeOut } from '@Utils/account';
import { isWrappedInApp } from '@Utils/embedded-util';
import { getSectionUrl, navigate } from '@Utils/navigate';
import { postWebkitMessage } from '@Utils/wk-embed-bridges';
import ModalDialog from '@Components/dialogs/modal-dialog';

const MobileUserMenuModal = ({ routeParams, onClose }) => {
  const wrappedInApp = useSelector(isWrappedInApp);
  const locationOptions = useSelector(state => state.locationOptions);

  const showNotificationSettings = (ev) => {
    ev.preventDefault();
    if (wrappedInApp) {
      postWebkitMessage('showNotificationSettings');
    } else {
      navigate(getSectionUrl(routeParams, 'user-config'));
    }
  };

  const showSwitchAccount = (ev) => {
    ev.preventDefault();
    if (wrappedInApp) {
      postWebkitMessage('showSwitchAccount');
    } else {
      navigate('/');
    }
  };

  const handleLogout = () => {
    if (wrappedInApp) {
      logMeOut(true);
      postWebkitMessage('logMeOut');
    } else {
      logMeOut();
    }
  };

  return (
    <ModalDialog onClose={onClose} zIndex={20000}>
      <div className="mobile-user-menu-buttons" tabIndex={0}>
        <button className="btn-option" onClick={showNotificationSettings}>
          <i className="far fa-bells mr1" /> Notiser
        </button>
        {locationOptions.size > 1 && (
          <button className="btn-option" onClick={showSwitchAccount}>
            <i className="far fa-arrow-right-arrow-left mr1" /> Byt konto
          </button>
        )}
        <button className="btn-option btn-danger" onClick={handleLogout}>
          <i className="far fa-sign-out mr1" /> Logga ut
        </button>
        <button className="btn-option" onClick={onClose}>Avbryt</button>
      </div>
    </ModalDialog>
  );
};

export default MobileUserMenuModal;
