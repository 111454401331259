import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getCustomerTabSections, getCustomerTypeTabSections } from '@Components/customers/customer-detail/selectors';

const CustomerModalTabs = ({ customer, selectedTab, setSelectedTab }) => {
  const sections = useSelector(getCustomerTabSections);
  const customerSections = getCustomerTypeTabSections(sections, customer);

  const onSelect = (e, tab) => {
    e.preventDefault();
    setSelectedTab(tab);
  };

  return (
    <ul className="tabs__list no-margins">
      {customerSections.map((item, index) => {
        return (
          <li
            key={index}
            onClick={e => onSelect(e, item.navId)}
            className={selectedTab === item.navId ? 'tabs__item tabs__item--active' : 'tabs__item'}
          >
            <a href={`#${item.navId}`}>
              {item.name}
              {item.news && <span className="label-news">NY</span>}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

CustomerModalTabs.propTypes = {
  selectedTab: PropTypes.string,
  setSelectedTab: PropTypes.func
};

export default memo(CustomerModalTabs);
