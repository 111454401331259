export const RESET_BKF = 'RESET_BKF';
export const SET_BKF_PROP = 'SET_BKF_PROP';
export const SET_BKF_VEHICLE = 'SET_BKF_VEHICLE';
export const SET_BKF_COMPANY = 'SET_BKF_COMPANY';
export const SET_BKF_CUSTOMER = 'SET_BKF_CUSTOMER';
export const SET_BKF_CUSTOMER_STATUS = 'SET_BKF_CUSTOMER_STATUS';
export const SET_BKF_BOOKING = 'SET_BKF_BOOKING';
export const SET_BKF_SERVICE = 'SET_BKF_SERVICE';
export const ADD_BKF_SERVICE = 'ADD_BKF_SERVICE';
export const REMOVE_BKF_SERVICE = 'REMOVE_BKF_SERVICE';
export const UPDATE_BKF_SERVICE = 'UPDATE_BKF_SERVICE';
export const ADD_BKF_RESOURCE = 'ADD_BKF_RESOURCE';
export const REMOVE_BKF_RESOURCE = 'REMOVE_BKF_RESOURCE';
export const RESET_BKF_NEW_RESOURCE = 'RESET_BKF_NEW_RESOURCE';
export const SET_BKF_TIME = 'SET_BKF_TIME';
export const SET_BKF_RES_TIME = 'SET_BKF_RES_TIME';
export const SET_BKF_ATTRIBUTES = 'SET_BKF_ATTRIBUTES';
export const TOGGLE_BKF_MODAL = 'TOGGLE_BKF_MODAL';
export const TOGGLE_BKF_VIEW = 'TOGGLE_BKF_VIEW';

export const RES_SRV_LOADED = 'RES_SRV_LOADED';
export const CLEAR_RES_SRV = 'CLEAR_RES_SRV';

export const BOOKING_SALE_LOADED = 'BOOKING_SALE_LOADED';
export const BOOKING_REMINDERS_LOADED = 'BOOKING_REMINDERS_LOADED';

export const BOOKING_CUSTOMER_ADDED = 'BOOKING_CUSTOMER_ADDED';
export const BOOKING_CUSTOMER_REMOVED = 'BOOKING_CUSTOMER_REMOVED';

export const bkfModals = {
  addCustomer: 'addCustomer',
  sendSms: 'sendSms',
  class: 'class',
  events: 'events',
  payment: 'payment',
  print: 'print',
  image: 'image',
  notes: 'notes'
};

export const bkfViews = {
  service: 'service',
  customer: 'customer',
  resource: 'resource',
  notes: 'notes',
  options: 'options'
};
