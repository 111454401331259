import axios from 'axios';
import { fromJS } from 'immutable';
import * as FileSaver from 'file-saver';

import PhoneUtil from '@Utils/phone-util';
import {
  axiosDefault, axiosErrorHandler, checkStatusAxios, prefixUrl, prefixCustomerUrl,
  axiosFormData, axiosGet, axiosDelete, axiosPut, axiosPost, axiosPatch
} from '@Utils/ajax-util';
import { updateBKFCustomer } from '@State/bkf/actions';

export const BOOKING_HISTORY_LOADED = 'BOOKING_HISTORY_LOADED';
export const BOOKING_HISTORY_UPDATED = 'BOOKING_HISTORY_UPDATED';
export const CUSTOMERS_FETCHED = 'CUSTOMERS_FETCHED';
export const CUSTOMER_FETCHED = 'CUSTOMER_FETCHED';
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const CUSTOMER_UPDATED = 'CUSTOMER_UPDATED';
export const CUSTOMER_DELETED = 'CUSTOMER_DELETED';
export const CUSTOMER_SEARCH_RESULT = 'CUSTOMER_SEARCH_RESULT';
export const CUSTOMER_LOG_FETCHED = 'CUSTOMER_LOG_FETCHED';
export const CUSTOMER_COMMENT_SAVED = 'CUSTOMER_COMMENT_SAVED';
export const CUSTOMER_COMMENT_UPDATED = 'CUSTOMER_COMMENT_UPDATED';
export const CUSTOMER_COMMENT_DELETED = 'CUSTOMER_COMMENT_DELETED';
export const CUSTOMER_CLEAR_FORM = 'CUSTOMER_CLEAR_FORM';
export const CUSTOMER_SALES_FETCHED = 'CUSTOMER_SALES_FETCHED';
export const CUSTOMER_GIFT_CARDS_FETCHED = 'CUSTOMER_GIFT_CARDS_FETCHED';
export const CUSTOMER_ASSOCIATIONS_FETCHED = 'CUSTOMER_ASSOCIATIONS_FETCHED';
export const CUSTOMER_ASSOCIATION_REMOVED = 'CUSTOMER_ASSOCIATION_REMOVED';
export const CUSTOMER_NOTE_UPDATED = 'CUSTOMER_NOTE_UPDATED';
export const CUSTOMER_INVOICE_CUSTOMER_UPDATED = 'CUSTOMER_INVOICE_CUSTOMER_UPDATED';

export function bookingHistoryLoaded(response) {
  return {
    type: BOOKING_HISTORY_LOADED,
    response
  };
}

export function customersFetched(result) {
  return {
    type: CUSTOMERS_FETCHED,
    result
  };
}

export function customerCreated(customer) {
  return {
    type: CREATE_CUSTOMER,
    customer
  };
}

export function customerNoteUpdated(id, notes) {
  return {
    type: CUSTOMER_NOTE_UPDATED,
    id,
    notes
  };
}

export function customerInvoiceCustomerUpdated(id, invoiceCustomerId) {
  return {
    type: CUSTOMER_INVOICE_CUSTOMER_UPDATED,
    id,
    invoiceCustomerId
  };
}

export function customerUpdated(id, customer) {
  return (dispatch) => {
    dispatch(updateBKFCustomer(id, customer));
    return dispatch({
      type: CUSTOMER_UPDATED,
      id,
      customer
    });
  };
}

export function customerDeleted(id) {
  return {
    type: CUSTOMER_DELETED,
    id
  };
}

export function customersSearched(result) {
  return {
    type: CUSTOMER_SEARCH_RESULT,
    result
  };
}

export function customerCommentSaved(result) {
  return {
    type: CUSTOMER_COMMENT_SAVED,
    result
  };
}

export function customerCommentUpdated(id, commentId, comment, files) {
  return {
    type: CUSTOMER_COMMENT_UPDATED,
    id,
    commentId,
    comment,
    files
  };
}

export function customerCommentDeleted(commentId) {
  return {
    type: CUSTOMER_COMMENT_DELETED,
    commentId
  };
}

export function customerLoaded(customer) {
  return {
    type: CUSTOMER_FETCHED,
    customer
  };
}

export function customerClearForm() {
  return {
    type: CUSTOMER_CLEAR_FORM
  };
}

export function customerSalesLoaded(sales) {
  return {
    type: CUSTOMER_SALES_FETCHED,
    sales
  };
}

export function customerGiftCardsLoaded(giftCards, saleId) {
  return {
    type: CUSTOMER_GIFT_CARDS_FETCHED,
    giftCards,
    saleId
  };
}

export function customerAssociationsLoaded(associations) {
  return {
    type: CUSTOMER_ASSOCIATIONS_FETCHED,
    associations
  };
}

export function customerAssociationRemoved(customerId, otherCustomerId) {
  return {
    type: CUSTOMER_ASSOCIATION_REMOVED,
    customerId,
    otherCustomerId
  };
}

export function customerBookingsUpdated(booking) {
  return {
    type: BOOKING_HISTORY_UPDATED,
    booking
  };
}

export function createCustomer(customer) {
  const url = prefixUrl('/customers/sparse/');
  return dispatch => dispatch(axiosPost(url, customer, {
    onSuccess: ({ data }) => {
      dispatch(customerCreated({ ...customer, ...data }));
      return data.id;
    },
    throwOnError: true
  }));
}

export function updateCustomerInfo(id, info) {
  return (dispatch, getState) => {
    const url = prefixCustomerUrl(`/customers/${id}/info`, getState());
    return dispatch(axiosPut(url, info, {
      onSuccess: () => dispatch(customerUpdated(id, info)),
      throwOnError: true
    }));
  };
}

export function updateCustomerAddress(id, address) {
  return (dispatch, getState) => {
    const url = prefixCustomerUrl(`/customers/${id}/address`, getState());
    return dispatch(axiosPut(url, address, {
      onSuccess: () => dispatch(customerUpdated(id, address)),
      throwOnError: true
    }));
  };
}

export function updateCustomerNotes(id, notes) {
  return (dispatch, getState) => {
    const url = prefixCustomerUrl(`/customers/${id}/notes`, getState());
    return dispatch(axiosPut(url, { notes }, {
      onSuccess: () => dispatch(customerNoteUpdated(id, notes))
    }));
  };
}

function updateCustomerInvoiceCustomer(id, invoiceCustomerId) {
  const url = prefixUrl(`/customers/${id}/invoice-customer/${invoiceCustomerId}`);
  return dispatch => dispatch(axiosPut(url, null, {
    onSuccess: () => dispatch(customerInvoiceCustomerUpdated(id, invoiceCustomerId))
  }));
}

function deleteCustomerInvoiceCustomer(id) {
  const url = prefixUrl(`/customers/${id}/invoice-customer`);
  return dispatch => dispatch(axiosDelete(url, {
    onSuccess: () => dispatch(customerInvoiceCustomerUpdated(id))
  }));
}

export function setCustomerInvoiceCustomer(id, invoiceCustomerId) {
  return dispatch => {
    return invoiceCustomerId
      ? dispatch(updateCustomerInvoiceCustomer(id, invoiceCustomerId))
      : dispatch(deleteCustomerInvoiceCustomer(id));
  };
}

export function deleteCustomer(id) {
  return (dispatch, getState) => {
    const url = prefixCustomerUrl(`/customers/${id}`, getState());
    return dispatch(axiosDelete(url, {
      onSuccess: () => dispatch(customerDeleted(id))
    }));
  };
}

export function exportCustomers(resourceId, includeHistory) {
  const params = [];
  if (resourceId) {
    params.push(`resourceId=${resourceId}`);
  }
  if (includeHistory) {
    params.push('includeHistory=true');
  }

  const query = params.length > 0 ? `?${params.join('&')}` : '';
  const url = prefixUrl(`/export/customers.csv${query}`);

  return axiosGet(url, {
    config: { responseType: 'blob' },
    onSuccess: (res) => {
      let fileName = 'customers.csv';
      const contentDisposition = res.headers['content-disposition'];
      if (contentDisposition) {
        const match = contentDisposition.match(/filename=([^;]+)/);
        if (match.length === 2) {
          fileName = match[1];
        }
      }
      FileSaver.saveAs(new Blob([res.data]), fileName);
    }
  });
}

export function fetchCustomerLogEntries(customerId, refresh = false) {
  const url = prefixUrl(`/customers/${customerId}/logentries`);

  return (dispatch, getState) => {
    const { customerLogEntries } = getState();
    const logentries = customerLogEntries.get('logs');

    if (logentries.size > 0 && !refresh) {
      return Promise.resolve({ logentries: logentries?.toJS() });
    }

    return dispatch(axiosGet(url, {
      onSuccess: ({ data }) => {
        dispatch({ type: CUSTOMER_LOG_FETCHED, logentries: data });
        return data;
      }
    }));
  };
}

export function fetchCustomers(scope, limit, offset = 0) {
  const url = prefixUrl(`/customers/all?scope=${scope.join(',')}&offset=${offset}&limit=${limit}`);
  return dispatch => dispatch(axiosGet(url, {
    onSuccess: ({ data }) => dispatch(customersFetched(data))
  }));
}

export function searchCustomers(text, scope) {
  const url = prefixUrl(`/search?scope=${scope.join(',')}&query=${encodeURIComponent(text)}`);
  return dispatch => dispatch(axiosGet(url, {
    onSuccess: ({ data }) => dispatch(customersSearched(data))
  }));
}

function getCustomerCommentData(comment, files) {
  if (files.length > 0) {
    const formData = new FormData();
    const deleteFiles = files.filter(f => f.id && f.delete);
    if (deleteFiles.length > 0) {
      formData.append('deleteFileIds', deleteFiles.map(f => f.id));
    }
    files.filter(f => !f.id).forEach(file => {
      formData.append('files', file);
    });
    formData.append('comment', comment);
    return formData;
  }
  return JSON.stringify({ comment });
}

export function saveCustomerComment(id, comment, files = [], persist = true) {
  const hasFiles = files.length > 0;
  const url = prefixUrl(hasFiles
    ? `/customers/${id}/logentries/create`
    : `/customers/${id}/logentries`);
  const config = hasFiles ? axiosFormData() : axiosDefault();
  const data = getCustomerCommentData(comment, files);

  return dispatch => axios
    .post(url, data, config)
    .then(res => dispatch(checkStatusAxios(res)))
    .then(res => {
      if (persist) {
        dispatch(customerCommentSaved(res.data));
      }
      return res.data;
    })
    .catch(error => axiosErrorHandler(error, dispatch));
}

export function updateCustomerComment(id, commentId, comment, files = []) {
  const hasFiles = files.length > 0;
  const url = prefixUrl(hasFiles
    ? `/customers/${id}/logentries/${commentId}/update`
    : `/customers/${id}/logentries/${commentId}`);
  const config = hasFiles ? axiosFormData() : axiosDefault();
  const data = getCustomerCommentData(comment, files);
  const method = hasFiles ? axios.post : axios.put;

  return dispatch => method(url, data, config)
    .then(res => dispatch(checkStatusAxios(res)))
    .then(res => dispatch(customerCommentUpdated(id, commentId, comment, res.data?.files)))
    .catch(error => axiosErrorHandler(error, dispatch));
}

export function deleteCustomerComment(id, commentId) {
  const url = prefixUrl(`/customers/${id}/logentries/${commentId}`);
  return dispatch => dispatch(axiosDelete(url, {
    onSuccess: () => dispatch(customerCommentDeleted(commentId))
  }));
}

export function fetchCustomer(id) {
  const url = prefixUrl(`/customers/${id}`);
  return dispatch => dispatch(axiosGet(url, {
    onSuccess: ({ data }) => dispatch(customerLoaded(data))
  }));
}

export function fetchCustomerSales(id) {
  const url = prefixUrl(`/customers/${id}/sales`);
  return (dispatch, getState) => {
    const { customerSales } = getState();
    const sales = customerSales.get('sales');
    const loading = customerSales.get('loading');
    if (sales > 0 && !loading) {
      return Promise.resolve({ sales });
    }
    return dispatch(axiosGet(url, {
      onSuccess: ({ data }) => dispatch(customerSalesLoaded(data?.sales))
    }));
  };
}

export function fetchCustomerGiftCards(id, saleId) {
  const url = prefixUrl(`/customers/${id}/gift-cards`);
  return (dispatch, getState) => {
    const { customerGiftCards } = getState();
    const giftCards = customerGiftCards.get('giftCards');
    const loading = customerGiftCards.get('loading');
    if (giftCards > 0 && !loading) {
      return Promise.resolve({ giftCards });
    }
    return dispatch(axiosGet(url, {
      onSuccess: ({ data }) => dispatch(customerGiftCardsLoaded(data?.vouchers, saleId))
    }));
  };
}

export function fetchCustomerEvents(id) {
  const url = prefixUrl(`/customers/${id}/history`);
  return axiosGet(url, {
    onSuccess: ({ data }) => data.events
  });
}

export function fetchCustomerAssociations(id) {
  const url = prefixUrl(`/customers/${id}/associations`);
  return dispatch => dispatch(axiosGet(url, {
    onSuccess: ({ data }) => dispatch(customerAssociationsLoaded(data.associations))
  }));
}

export function addCustomerAssociation(id, otherCustomerId, associationType) {
  const url = prefixUrl(`/customers/${id}/associate-customer`);
  return dispatch => dispatch(axiosPut(url, { otherCustomerId, associationType }, {
    onSuccess: () => dispatch(fetchCustomerAssociations(id))
  }));
}

export function removeCustomerAssociation(id, otherCustomerId) {
  const url = prefixUrl(`/customers/${id}/disassociate-customer/${otherCustomerId}`);
  return dispatch => dispatch(axiosPut(url, null, {
    onSuccess: () => dispatch(customerAssociationRemoved(id, otherCustomerId))
  }));
}

function toggleCustomerProperty(customerId, urlPart, value, update) {
  return (dispatch, getState) => {
    const url = prefixCustomerUrl(`/customers/${customerId}/${urlPart}/${value}`, getState());
    return dispatch(axiosPut(url, null, {
      onSuccess: () => dispatch(customerUpdated(customerId, update)),
      throwOnError: true
    }));
  };
}

export function toggleBooking(id, blockWebBooking) {
  return toggleCustomerProperty(id, 'block', blockWebBooking, { blockWebBooking });
}

export function toggleAllowMarketing(id, allowMarketing) {
  return toggleCustomerProperty(id, 'allow-marketing', allowMarketing, { allowMarketing });
}

export function toggleReviewReminders(id, reviewReminders) {
  return toggleCustomerProperty(id, 'review-reminders', reviewReminders, { reviewReminders });
}

export function toggleSmsReminders(id, smsReminders) {
  return toggleCustomerProperty(id, 'sms-reminders', smsReminders, { smsReminders });
}

export function toggleOwnedByLocation(id, ownedByLocation) {
  const update = ownedByLocation
    ? { ownedByLocation, associatedResourceId: null }
    : { ownedByLocation };

  return toggleCustomerProperty(id, 'owned-by-location', ownedByLocation, update);
}

export function toggleAssociatedToResource(id, associatedResourceId) {
  if (!associatedResourceId) {
    return (dispatch, getState) => {
      const url = prefixCustomerUrl(`/customers/${id}/associated-resource`, getState());
      return dispatch(axiosDelete(url, {
        onSuccess: () => dispatch(customerUpdated(id, { associatedResourceId: null }))
      }));
    };
  }
  const update = { associatedResourceId, ownedByLocation: false };
  return toggleCustomerProperty(id, 'associated-resource', associatedResourceId, update);
}

export function fetchCustomerReceipt(id) {
  if (!id) {
    return Promise.resolve();
  }
  const url = prefixUrl(`/pos/receipts/by-id/${id}`);
  return axiosGet(url, {
    onSuccess: ({ data }) => fromJS(data)
  });
}

export function fetchBookingsHistory(customerId) {
  const url = prefixUrl(`/customers/${customerId}/bookings`);

  return (dispatch, getState) => {
    const { customerBookings } = getState();
    const bookings = customerBookings.get('bookings').toJS();
    const loading = customerBookings.get('loading');

    if (bookings.length > 0 && !loading) {
      return Promise.resolve({ response: bookings });
    }

    return dispatch(axiosGet(url, {
      onSuccess: ({ data }) => {
        dispatch(bookingHistoryLoaded(data));
        return data;
      }
    }));
  };
}

export function changeCustomerBookingStatus({ prevState, ...booking }) {
  return (dispatch, getState) => {
    const { id, customerIds, status } = booking;
    const url = prefixCustomerUrl(`/bookings/${id}/status/${status}`, getState());
    dispatch(customerBookingsUpdated(booking));
    return dispatch(axiosPut(url, { customerIds }, {
      onError: () => dispatch(customerBookingsUpdated(prevState))
    }));
  };
}

export function changeCustomerBookingFlag({ prevState, ...booking }) {
  return (dispatch, getState) => {
    const { id, customerIds, attributes } = booking;
    const url = prefixCustomerUrl(`/bookings/${id}/attribs`, getState());
    dispatch(customerBookingsUpdated(booking));
    return dispatch(axiosPatch(url, { customerIds, attributes }, {
      onError: () => dispatch(customerBookingsUpdated(prevState))
    }));
  };
}

export function sendSms(customerId, { sender, msisdn, message }) {
  const url = prefixUrl(`/customers/${customerId}/send-sms`);
  const data = {
    sender: PhoneUtil.isMobile(sender) ? PhoneUtil.formatPhoneNumberE164(sender) : sender,
    msisdn: PhoneUtil.formatPhoneNumberE164(msisdn),
    message
  };
  return axiosPost(url, data);
}

export function fetchVehicleInfo(regNo, customerId) {
  const url = prefixUrl('/lookup-vehicle-data');
  const config = axiosDefault();

  return (dispatch) => {
    return axios.post(url, { regNo, customerId }, config)
      .then(({ data }) => {
        if (data?.result) {
          const vehicleData = {
            officialIdNo: regNo,
            name: data.result.summary,
            attributes: data.result.attribs
          };
          if (customerId) {
            dispatch(customerUpdated(customerId, vehicleData));
          }
          return vehicleData;
        }
        throw new Error('No vehicle found');
      });
  };
}
