import React from 'react';
import { txt } from '@Utils/i18n-util';
import msg from './booking-labels.msg';

const BookingLabelsList = ({ status, dropIn, askedForPerson }) => {
  return (
    <div className="booking-status-small-wrapper mt1">
      {status === 'Show'
      && (
      <span className="booking-label active">
        <span className="label-circle label-status" />
        {txt(msg.show)}
      </span>
      )}
      {status === 'NoShow'
      && (
      <span className="booking-label active">
        <span className="label-circle label-status-no-show" />
        {txt(msg.noShow)}
      </span>
      )}
      {askedForPerson
      && (
      <span className="booking-label active">
        <span className="label-circle label-asked-for-person" />
        {txt(msg.askedForPerson)}
      </span>
      )}
      {dropIn
      && (
      <span className="booking-label active">
        <span className="label-circle label-drop-in" />
        {txt(msg.dropIn)}
      </span>
      )}
    </div>
  );
};

export default BookingLabelsList;
