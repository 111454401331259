import React, { Component, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import { Messages, getLanguageCode, IntlContext } from '@Utils/i18n-util';
import JavascriptErrorAlert from '@Components/dialogs/javascript-error-alert';
import LocationSelection from '@Components/location-selection';
import SwitchLocation from '@Components/location-selection/SwitchLocation';
import MainViewContainer from '@Containers/main-view';
import Chat from '@Containers/chat';
import FortnoxAuth from '@Components/dialogs/fortnox-auth';
import Loader from '@Components/ui/loader';

const SysAdminLazy = lazy(() => import('@Containers/sysadmin'));
const SysAdmin = props => (
  <Suspense fallback={<Loader />}>
    <SysAdminLazy {...props} />
  </Suspense>
);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true
    };
  }

  componentDidCatch(error, errorInfo) {
    if (typeof Sentry !== 'undefined') {
      Sentry.captureException(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return <JavascriptErrorAlert />;
    }

    const { locale } = this.props;
    const languageCode = getLanguageCode(locale);
    const messages = Messages[languageCode];

    return (
      <IntlProvider locale={languageCode} messages={messages}>
        <IntlContext>
          <Route path="/:org?/:loc?/" component={Chat} />
          <Switch>
            <Route path="/" exact component={LocationSelection} />
            <Route path="/:org(sysadmin)/fortnox-auth" component={FortnoxAuth} />
            <Route path="/:org(sysadmin)/:loc?/:section(accounts)?/:id?/:tabId?" component={SysAdmin} />
            <Route path="/:org/:loc/" exact component={SwitchLocation} />
            <Route path="/:org/:loc/:section" component={MainViewContainer} />
          </Switch>
        </IntlContext>
      </IntlProvider>
    );
  }
}

export default App;
