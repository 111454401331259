import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  change, Field, formValueSelector, reduxForm
} from 'redux-form';
import CurrencyUtil from '@Utils/currency-util';
import SubmitButton from '@Components/ui/submit-button';
import Popover from '@Components/ui/popover';
import {
  createValidator, required, number, minValue
} from '@Utils/validation';
import TextInput from '@Components/inputs/text-input';
import { getVatRates } from '@State/selectors';
import SelectField from '@Components/inputs/select-field';
import PeriodInput from '@Components/inputs/period-input';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';

class VoucherTemplateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteProgress: false,
      showConfirmDelete: false
    };
  }

  showConfirmDelete = (ev) => {
    ev.preventDefault();
    this.setState({ showConfirmDelete: true, deleteProgress: false });
  };

  hideConfirmDelete = (ev) => {
    ev.preventDefault();
    this.setState({ showConfirmDelete: false });
  };

  onDelete = (ev) => {
    ev.preventDefault();
    this.setState({ deleteProgress: true });
    this.props.onDelete(this.props.voucherTemplateId)
      .catch(() => this.setState({ deleteProgress: false }));
  };

  render() {
    const { handleSubmit, submitting, vatRates, accountingType, onClose } = this.props;
    const showVatPct = accountingType === 'SinglePurpose';
    const accountingTypeHelpText = showVatPct
      ? 'Förvald momssats. Moms redovisas vid försäljning av presentkort.'
      : 'Öppen momssats. Moms redovisas vid inlösen av presentkort.';

    return (
      <form onSubmit={handleSubmit}>
        <Field name="description" component={TextInput} label="Namn" />
        <Field name="comment" component={TextInput} label="Detaljer" helpText="T.ex. vad som ingår i presentkortet. Visas på kvittot." />
        <Field
          name="paymentMethodName"
          component={TextInput}
          label="Beskrivning vid inlösen"
          helpText='Beskrivning av betalsättet vid inlösen av presentkort. Om fältet lämnas tomt visas "Presentkort".'
        />
        <Field name="initialValue" component={TextInput} label="Värde" width={100} unitLabel={CurrencyUtil.currencySymbol()} />
        <Field name="validityPeriod" component={PeriodInput} label="Giltighetstid" vertical />
        <Field name="accountingType" component={SelectField} label="Bokföringstyp" width={200} helpText={accountingTypeHelpText}>
          <option value="SinglePurpose">Enfunktionsvoucher</option>
          <option value="MultiPurpose">Flerfunktionsvoucher</option>
        </Field>
        {showVatPct && (
          <Field name="vatPct" component={SelectField} label="Momssats" width={100}>
            {vatRates.map(vat => <option key={vat} value={vat}>{`${vat}%`}</option>)}
          </Field>
        )}
        {onClose ? (
          <FormButtonsFooter
            onClose={onClose}
            submitting={submitting}
            handleSubmit={handleSubmit}
            submitText="Skapa mall"
          />
        ) : (
          <div className="form-group">
            <br />
            <SubmitButton {...this.props} />{' '}
            <Popover isOpen={this.state.showConfirmDelete} body={this.popoverContent()} onOuterAction={this.hideConfirmDelete}>
              <button className="btn-delete" onClick={this.showConfirmDelete}>Ta bort</button>
            </Popover>
          </div>
        )}
      </form>
    );
  }

  popoverContent() {
    return (
      <div className="Popover-content-small">
        <a href="#" onClick={this.hideConfirmDelete} className="Popover-close"><i className="fa fa-lg fa-times" /></a>
        Är du säker på att du vill ta bort mallen?
        <br />
        <br />
        <button className="btn-delete btn-block" onClick={this.onDelete} disabled={this.state.deleteProgress}>Ta bort mall</button>
      </div>
    );
  }
}

const validator = createValidator({
  description: [required],
  initialValue: [number, minValue(0)],
  accountingType: [required],
  vatPct: [required]
});

const selector = formValueSelector('voucher-template-form');

const mapStateToProps = (state) => {
  return {
    vatRates: getVatRates(state),
    accountingType: selector(state, 'accountingType')
  };
};

const mapDispatchToProps = dispatch => ({
  setFormValue: (property, value) => {
    dispatch(change('voucher-template-form', property, value));
  }
});

export default reduxForm({
  form: 'voucher-template-form',
  enableReinitialize: true,
  validate: validator
})(connect(mapStateToProps, mapDispatchToProps)(VoucherTemplateForm));
