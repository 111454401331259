import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { txt } from '@Utils/i18n-util';
import { getResourcesInView, hasAnyResourceAllServices } from '@State/calendar-selectors';

import {
  resetFindTime,
  addFindTimeService,
  removeFindTimeService,
  addFindTimeResource,
  removeFindTimeResource,
  fetchAvailableSlots,
  setFindTimeServices,
  setFindTimeResources,
  resetFindTimeSlots
} from '@State/find-time/actions';

import AlertWithIcon from '@Components/ui/alert-with-icon';
import FindTimeService from './find-time-service';
import SearchService from '../booking/search-service';
import SearchResource from '../booking/search-resource';
import FindTimeSlots from './find-time-slots';
import msg from '../booking/booking-form.msg';

class FindTimeForm extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    deviceType: PropTypes.string.isRequired,
    deviceOs: PropTypes.string.isRequired,
    externalKeyboard: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      isSearching: false,
      searchService: false,
      searchResource: false
    };
  }

  componentDidMount() {
    const { resourcesInView, services, resourceAvailable } = this.props;
    const hasServices = services && services.size > 0;

    if (hasServices && !resourceAvailable) {
      this.props.onSetServices(null);
    }
    this.props.onSetResources(resourcesInView);

    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    const { slots, showInCalendar } = this.props;
    if (slots && !showInCalendar) {
      this.props.onResetSlots();
    }

    document.removeEventListener('keydown', this.handleKeyDown);
  }

  getMaxHeight = () => this.props.gridClientHeight - 50;

  getBodyHeight = () => 400;

  render() {
    return (
      <div className="booking-form-container">
        {this.renderContent()}
      </div>
    );
  }

  renderContent() {
    const { slots, showInCalendar } = this.props;
    const { searchService, searchResource } = this.state;

    if (searchService) {
      return this.renderSearchService();
    }
    if (searchResource) {
      return this.renderSearchResource();
    }
    if (slots && !showInCalendar) {
      return this.renderSlots();
    }
    return this.renderFindTimeForm();
  }

  renderSearchService() {
    return (
      <>
        <SearchService
          context="FindTime"
          deviceType={this.props.deviceType}
          deviceOs={this.props.deviceOs}
          externalKeyboard={this.props.externalKeyboard}
          onAdd={this.handleServiceAdd}
          onRemove={this.handleServiceRemove}
          onCancel={this.handleCancelSearch}
          maxHeight={this.getMaxHeight()}
          height={!this.props.isModal && this.state.bodyHeight}
          disabled={this.state.isSearching}
          routeParams={this.props.routeParams}
        />
        {this.renderSearchFooter()}
      </>
    );
  }

  handleCancelSearch = () => {
    this.setState({ searchService: false, searchResource: false });
  };

  renderSearchResource() {
    return (
      <>
        <SearchResource
          context="FindTime"
          deviceType={this.props.deviceType}
          deviceOs={this.props.deviceOs}
          externalKeyboard={this.props.externalKeyboard}
          onAdd={this.handleResourceAdd}
          onRemove={this.handleResourceRemove}
          onCancel={this.handleCancelSearch}
          maxHeight={this.getMaxHeight()}
          height={!this.props.isModal && this.state.bodyHeight}
          disabled={this.state.isSearching}
        />
        {this.renderSearchFooter()}
      </>
    );
  }

  renderSlots() {
    return (
      <FindTimeSlots
        onSelect={() => { console.log('Create booking'); }}
        onCancel={this.props.onResetSlots}
        maxHeight={this.getMaxHeight()}
        height={!this.props.isModal && this.state.bodyHeight}
      />
    );
  }

  renderFindTimeForm() {
    const { resourceAvailable, services } = this.props;
    const { isSearching } = this.state;
    const hasServices = services && services.size > 0;
    const saveText = this.getSaveBtnText(isSearching);

    return (
      <div className={isSearching ? 'booking-form disabled' : 'booking-form'}>
        <div className="booking-form-header mobile">
          <div className="cancel">
            <a href="#" onClick={this.handleClose}>{txt(msg.cancelBtnLabel)}</a>
          </div>
          <h4 className="title">Hitta tid</h4>
          <div className="save">
            <a href="#" onClick={this.handleListFindTime}>{saveText}</a>
          </div>
        </div>
        <div
          className="booking-form-body"
          style={{ maxHeight: this.getMaxHeight() + 48 }}
        >
          <AlertWithIcon center>
            Hitta en ledig tid för valda resurser
          </AlertWithIcon>
          <FindTimeService
            onSearchStart={this.handleServiceSearchStart}
            onClearService={this.handleClearService}
          />
          {hasServices && !resourceAvailable && (
            <AlertWithIcon warning icon="fa fa-exclamation-triangle">
              Ingen av de valda resurserna utför dessa tjänster
            </AlertWithIcon>
          )}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  renderSearchFooter = () => {
    return (
      <div className="booking-form-footer search-service">
        <div />
        <div className="buttons">
          <button type="submit" className="btn-save" onClick={this.handleCancelSearch}>
            {txt(msg.backBtnLabel)}
          </button>
        </div>
      </div>
    );
  };

  getSaveBtnText = (isSearching) => {
    return isSearching ? 'Söker...' : 'Visa lista';
  };

  renderFooter = () => {
    const { services, resourceAvailable } = this.props;
    const { isSearching } = this.state;
    const hasServices = services && services.size > 0;
    const saveText = this.getSaveBtnText(isSearching);
    const submitDisabled = !hasServices || !resourceAvailable;
    const disabled = isSearching;

    return (
      <div className={disabled ? 'booking-form-footer disabled' : 'booking-form-footer'}>
        <div>
          <button type="button" className="btn-cancel" onClick={this.handleClose} tabIndex={3}>
            Stäng
          </button>
        </div>
        <div className="buttons">
          <button type="button" className="btn btn-default" disabled={submitDisabled} onClick={this.handleCalendarFindTime} tabIndex={1}>
            Visa i kalender
          </button>
          <button type="submit" className="btn-save" disabled={submitDisabled} onClick={this.handleListFindTime} tabIndex={2}>
            {saveText}
          </button>
        </div>
      </div>
    );
  };

  handleCalendarFindTime = (ev) => {
    const { routeParams } = this.props;

    this.setState({ isSearching: true });
    return this.props.fetchAvailableSlots(routeParams, true)
      .finally(() => this.props.onClose());
  };

  handleListFindTime = (ev) => {
    const { routeParams } = this.props;

    this.setState({ isSearching: true });
    return this.props.fetchAvailableSlots(routeParams)
      .finally(() => this.setState({ isSearching: false }));
  };

  handleClose = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.props.onClose();
  };

  handleKeyDown = (ev) => {
    if (ev.keyCode === 27) {
      this.handleClose(ev);
    }
  };

  handleServiceAdd = (service) => {
    this.props.onServiceAdd(service);
  };

  handleServiceRemove = (service) => {
    this.props.onServiceRemove(service);
  };

  handleResourceAdd = (resource) => {
    this.props.onResourceAdd(resource);
  };

  handleResourceRemove = (resource) => {
    this.props.onResourceRemove(resource);
  };

  handleClearService = () => {
    this.props.onSetServices(null);
  };

  handleServiceSearchStart = () => {
    this.setState({ searchService: true, bodyHeight: this.getBodyHeight() });
  };
}

const mapStateToProps = (state, ownProps) => {
  const { findTime, mainViewState, gridViewState } = state;

  return {
    resourcesInView: getResourcesInView(state, ownProps),
    resourceAvailable: hasAnyResourceAllServices(state, ownProps),
    services: findTime.get('services'),
    resources: findTime.get('resources'),
    showInCalendar: findTime.get('showInCalendar'),
    slots: findTime.get('slots'),
    deviceType: mainViewState.get('deviceType'),
    deviceOs: mainViewState.get('deviceOs'),
    externalKeyboard: gridViewState.get('externalKeyboard'),
    gridClientHeight: gridViewState.get('gridClientHeight')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onReset: () => dispatch(resetFindTime()),
    onResetSlots: () => dispatch(resetFindTimeSlots()),
    onServiceAdd: (service) => dispatch(addFindTimeService(service)),
    onServiceRemove: (service) => dispatch(removeFindTimeService(service)),
    onSetServices: (services) => dispatch(setFindTimeServices(services)),
    onResourceAdd: (resource) => dispatch(addFindTimeResource(resource)),
    onResourceRemove: (resource) => dispatch(removeFindTimeResource(resource)),
    onSetResources: (resources) => dispatch(setFindTimeResources(resources)),
    fetchAvailableSlots: (routeParams, showInCalendar) => {
      return dispatch(fetchAvailableSlots(routeParams, showInCalendar));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FindTimeForm);
