import React, { lazy, Suspense } from 'react';
import Loader from '../loader';

const JsonEditorForm = lazy(() => import('./jsoneditor-form'));

const JsonEditor = (props) => (
  <Suspense fallback={<Loader />}>
    <JsonEditorForm {...props} />
  </Suspense>
);

export default JsonEditor;
