import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CustomerType, getCustomerTypeIcon } from '@Utils/customer-util';
import { createCustomer } from '@State/customer-actions';
import { EXPORT_CUSTOMERS, hasPermission } from '@Utils/permissions';
import { getFeatures, getPermissions, getRouteParams } from '@State/selectors';
import CustomerExportDialog from '@Components/customers/customer-export-dialog';
import AddCustomerModal from '@Components/customers/add-customer-modal';
import Header, { Toolbar } from '@Components/nav/header';
import Popover from '@Components/ui/popover';
import Button from '@Components/ui/button';
import { getCustomersUrl, navigate } from '@Utils/navigate';
import { txt } from '@Utils/i18n-util';
import util from '@Utils/customer-util.msg';
import msg from './customer-header.msg';

class CustomerHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showExport: false,
      showPopover: false,
      showNewCustomerForm: false,
      customerType: CustomerType.Person
    };
  }

  setAddCustomerState = (customerType) => {
    this.setState({ showNewCustomerForm: true, customerType, showPopover: false });
  };

  onAddCustomer = (ev) => {
    ev.target.blur();
    ev.preventDefault();
    this.setAddCustomerState(CustomerType.Person);
  };

  exportCustomers = (ev) => {
    ev.target.blur();
    ev.preventDefault();
    this.setState({ showExport: true });
  };

  onShowPopover = () => this.setState({ showPopover: true });

  onHidePopover = () => this.setState({ showPopover: false });

  onHideExport = () => this.setState({ showExport: false });

  onHideNewCustomerForm = () => this.setState({ showNewCustomerForm: false });

  onNewCustomerCreated = async ({ customerId }) => {
    navigate(getCustomersUrl(this.props.routeParams, customerId));
    this.onHideNewCustomerForm();
  };

  getTypeButton = (type) => (
    <Button small block gray onClick={() => this.setAddCustomerState(type)}>
      <i className={`${getCustomerTypeIcon(CustomerType[type])} text-muted mr1`} />
      {txt(util[type])}
    </Button>
  );

  popoverBody = () => {
    const { EnableCompanyBooking, EnableVehicleBooking } = this.props.features;

    return (
      <div className="Popover-dialog-sm button-wrapper">
        <div className="menu-button-wrapper">
          {this.getTypeButton(CustomerType.Person)}
          {EnableCompanyBooking && this.getTypeButton(CustomerType.Company)}
          {EnableVehicleBooking && this.getTypeButton(CustomerType.Vehicle)}
        </div>
      </div>
    );
  };

  render() {
    const { showExport, showPopover, showNewCustomerForm, customerType } = this.state;
    const { routeParams, permissions, features, phoneMode } = this.props;
    const canExport = hasPermission(permissions, EXPORT_CUSTOMERS);
    const { EnableCompanyBooking, EnableVehicleBooking } = features;
    const usePopover = EnableCompanyBooking || EnableVehicleBooking;

    return (
      <>
        <Header
          showMenu
          routeParams={routeParams}
          className="thin-header with-sidebar"
          title={txt(msg.title)}
        >
          <Toolbar>
            {usePopover ? (
              <Popover
                preferPlace="below"
                isOpen={showPopover}
                onOuterAction={this.onHidePopover}
                body={this.popoverBody()}
                className="Popover-big-radius"
              >
                <button type="button" className="btn btn-cta" onClick={this.onShowPopover}>
                  {txt(msg.addBtnLabel)}
                </button>
              </Popover>
            ) : (
              <button type="button" className="btn btn-cta" onClick={this.onAddCustomer}>
                {txt(msg.addBtnLabel)}
              </button>
            )}
            {canExport && !phoneMode && (
              <button type="button" className="btn btn-cta-secondary" onClick={this.exportCustomers}>{txt(msg.downloadBtnLabel)}</button>
            )}
          </Toolbar>
        </Header>
        {showExport && <CustomerExportDialog onClose={this.onHideExport} />}
        {showNewCustomerForm && (
          <AddCustomerModal
            routeParams={routeParams}
            customerType={customerType}
            onCreated={this.onNewCustomerCreated}
            onClose={this.onHideNewCustomerForm}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    phoneMode: state.mainViewState.get('phoneMode'),
    routeParams: getRouteParams(state, ownProps),
    permissions: getPermissions(state, ownProps),
    features: getFeatures(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  createNewCustomer: (values) => dispatch(createCustomer(values))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerHeader);
