import { Map, fromJS } from 'immutable';
import { CLEAR_LOCATION_STATE } from '@State/account-actions';
import { CLEAR_VIEWDATA, RECEIVE_VIEWDATA } from '@State/view-actions';
import {
  FETCHED_SCHEDULES,
  UPDATED_USE_LOCATION_SCHEDULE,
  FETCHED_ADMIN_SCHEDULE_RESOURCE,
  SET_SCHEDULE_BLOCKS,
  SCHEDULE_SAVED,
  SCHEDULE_CREATED,
  FETCHED_SCHEDULE_EXCEPTIONS,
  DELETED_SCHEDULE_EXCEPTION,
  FETCHED_PUBLIC_HOLIDAYS
} from '@State/schedule-constants';

export function adminSchedules(state = Map({}), action = null) {
  switch (action.type) {
    case FETCHED_SCHEDULES:
      return state.clear().set('schedules', action.schedules)
        .set('newScheduleKey', action.newScheduleKey);

    case FETCHED_SCHEDULE_EXCEPTIONS:
      return state.set('exceptions', action.exceptions);

    case FETCHED_PUBLIC_HOLIDAYS:
      return state.set('holidays', fromJS(action.holidays).valueSeq());

    case DELETED_SCHEDULE_EXCEPTION:
      const newExceptions = action.date ? state.get('exceptions').filter(ex => ex.date !== action.date) : [];
      return state.set('exceptions', newExceptions);

    case FETCHED_ADMIN_SCHEDULE_RESOURCE:
      const { schedules, useLocationSchedule, newScheduleKey } = action.data;
      return state.set('schedules', schedules)
        .set('useLocationSchedule', useLocationSchedule)
        .set('newScheduleKey', newScheduleKey);

    case UPDATED_USE_LOCATION_SCHEDULE:
      return state.set('useLocationSchedule', action.useLocationSchedule);

    case SCHEDULE_SAVED: {
      const schedules = state.get('schedules').map((schedule) => {
        return schedule.key === action.schedule.key ? action.schedule : schedule;
      });
      return state.set('schedules', schedules);
    }

    case SCHEDULE_CREATED: {
      return state.set('schedules', [...state.get('schedules'), action.data]);
    }

    case CLEAR_LOCATION_STATE:
      return state.clear();

    default:
      return state;
  }
}

export default function schedulesByResource(state = Map({}), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
    case CLEAR_VIEWDATA:
      return state.clear();

    case SET_SCHEDULE_BLOCKS: {
      const { resourceId, type, ...scheduleData } = action;
      return state.set(resourceId, {
        resourceId,
        ...scheduleData
      });
    }

    case RECEIVE_VIEWDATA: {
      return state.clear().withMutations(map => {
        for (const sc of action.viewData.schedules) {
          map.set(sc.resourceId, sc);
        }
        if (action.viewData.mrSchedule) {
          map.set('combined', action.viewData.mrSchedule);
        }
      });
    }

    default:
      return state;
  }
}
