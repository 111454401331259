import React from 'react';

import { colors } from '@Components/ui/styled/variables';
import EventCard from '@Components/ui/event-card';
import CustomerListSubtext from '@Components/customers/customer-list-subtext';

const CustomerVehicle = ({
  onSelectEvent, isSelectable = false, selectedEventId, ...item
}) => {
  const { otherCustomerId, officialIdNo, name } = item;
  const isSelected = selectedEventId === otherCustomerId;
  const handleSelectEvent = () => onSelectEvent && onSelectEvent(item);

  return (
    <EventCard
      isSelectable={isSelectable}
      isSelected={isSelected}
      borderColor={colors.darkGray40}
      handler={handleSelectEvent}
      icon="far fa-user"
    >
      {name && (
        <strong>
          {name}
        </strong>
      )}
      <p className="text-muted">
        <CustomerListSubtext customer={item} />
      </p>
    </EventCard>
  );
};

export default CustomerVehicle;
