import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleBKFView } from '@State/bkf/actions';
import ModalDialog from '@Components/dialogs/modal-dialog';
import BookingForm from './booking-form';

const BookingFormModal = ({ id, routeParams, onClose }) => {
  const dispatch = useDispatch();
  const showView = useSelector(state => state.bkf.get('showView'));

  const handleClose = () => {
    if (showView) {
      dispatch(toggleBKFView(null));
    } else {
      onClose();
    }
  };

  return (
    <ModalDialog
      alignTop
      noPadding
      mobileFullScreen
      contentSize="medium"
      initialFocus=".booking-form"
      onClose={handleClose}
    >
      <BookingForm isModal id={id} routeParams={routeParams} onClose={handleClose} />
    </ModalDialog>
  );
};

export default BookingFormModal;
