import React from 'react';
import styled from 'styled-components';
import { colors } from '@Components/ui/styled/variables';
import SwitchInput from '@Components/ui/switch-input/switch-input';
import { styleLabelInputNoBorder } from '@Components/admin/pos-config/style';

const LabelSwitchInputWrap = styled.div`
  border-radius: 4px;
  border: solid 1px #E3E3E3;
  padding: 13px 15px;
  width: 100%;
  text-align: left;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};

  .form-group {
    flex: 1;
    margin-bottom: 5px;
  }
  .form-control {
    height: 32px;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;

  .react-switch-label:not(.large) {
    margin-top: 3px;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 12px;

  label {
    margin : 0;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
  }
  .description {
    line-height: 18px;
    color: ${colors.textMuted};
  }
  .label-text {
    flex: 1;
    line-height: 24px;
    font-weight: 600;
    margin-right: 10px;
    position: relative;

    .label-news {
      top: 5px;
      margin-left: 6px;
    }

    &.text-muted {
      font-weight: 500;
      color: ${colors.textColor};
    }
  }
  .label-child {
    margin-left: 10px;
    font-weight: 400;
  }
  .danger-text {
    color: ${colors.danger};
  }
`;

const ChildContainer = styled.div`
  margin-top: 16px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
`;

const ProgressIcon = styled.i`
  font-size: 24px;
  color: ${colors.textMuted};
`;

const LockIcon = styled.i`
  font-size: 15px;
  color: ${colors.softGray80};
`;

const LabelSwitchInput = ({
  noBorder = false, label, description = null, id, isOn, handleToggle, labelWrapStyle = null,
  labelStyle = null, labelClass = null, progress = false, large = false,
  children = null, disabled = false, switchLocked = false, news = false,
  ...props
}) => (
  <LabelSwitchInputWrap disabled={disabled} style={noBorder ? { ...styleLabelInputNoBorder, ...labelWrapStyle } : labelStyle}>
    <SwitchContainer>
      <LabelContainer disabled={disabled}>
        <label className={`label-text ${labelClass}`} htmlFor={id}>
          {label}
          {news ? <span className="label-news">NY</span> : null}
        </label>
        {description && <span className="description">{description}</span>}
      </LabelContainer>
      {progress && (
        <IconContainer>
          <ProgressIcon className="far fa-fw fa-circle-notch fa-spin" />
        </IconContainer>
      )}
      {switchLocked && (
        <IconContainer>
          <LockIcon className="fa fa-fw fa-lock" />
        </IconContainer>
      )}
      {!progress && !switchLocked && (
        <SwitchInput
          id={id}
          isOn={isOn}
          large={large}
          disabled={disabled}
          handleToggle={handleToggle}
          {...props}
        />
      )}
    </SwitchContainer>
    {children && isOn && (
      <ChildContainer>
        {children}
      </ChildContainer>
    )}
  </LabelSwitchInputWrap>
);

export default LabelSwitchInput;
