import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveCustomTextsPreferences } from '@State/preferences-actions';
import JsonEditor from '@Components/ui/jsoneditor/jsoneditor';

const AdminConfigCustomTexts = () => {
  const dispatch = useDispatch();
  const customTexts = useSelector(state => state.preferencesViewState.get('customTextsPreferences')) || {};

  return (
    <JsonEditor
      title="Custom Texts"
      json={customTexts}
      onSubmit={(values) => dispatch(saveCustomTextsPreferences(values))}
    />
  );
};

export default AdminConfigCustomTexts;
