import React, { Fragment } from 'react';

import { Cell } from '@Components/ui/styled/main';
import CurrencyUtil from '@Utils/currency-util';
import { saleItemSort } from '@Utils/pos-utils';

import CustomerDetailSaleSpecificationItem from '@Components/customers/customer-detail/sales/customer-detail-sale-specification-item';
import { SaleSummaWrap } from '@Components/customers/customer-styles';
import { txt } from '@Utils/i18n-util';
import msg from './customer-sales.msg';

const CustomerDetailSaleSpecification = ({ items = [], paidAmount }) => (
  <div>
    {items.sort(saleItemSort)
      .map(item => (
        <CustomerDetailSaleSpecificationItem key={item.get('id')} item={item} />
      ))}
    <SaleSummaWrap>
      <Cell width="20px" />
      <Cell><strong>{txt(msg.lblTotal)}</strong></Cell>
      <Cell pullRight>
        <strong>{CurrencyUtil.accountCurrency(paidAmount)}</strong>
      </Cell>
    </SaleSummaWrap>
  </div>
);

export default CustomerDetailSaleSpecification;
