import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  vehicleAttributeValue, vehicleOwnerFields, vehicleBaseFields,
  vehiclePreferenceKeys, isOwnerUnknown
} from '@Utils/vehicle-util';
import { getFeatures } from '@State/selectors';
import { fetchVehicleInfo } from '@State/customer-actions';
import useCustomer from '@Components/customers/useCustomer';
import CustomerEmptyTab from '@Components/customers/customer-detail/customer-empty-tab';
import ProgressButton from '@Components/ui/progress-button';
import Loader from '@Components/ui/loader';
import { txt } from '@Utils/i18n-util';
import { ScrollContent, ScrollWrapper } from '@Components/customers/customer-styles';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import Row from '@Components/ui/styled/row';
import msg from './customer-vehicle-info.msg';

function renderTableRow(key, value) {
  return value != null ? (
    <tr key={key}>
      <td><strong>{vehiclePreferenceKeys[key] || key}</strong></td>
      <td className="text-right">{vehicleAttributeValue(key, value)}</td>
    </tr>
  ) : null;
}

const CustomerVehicleInfo = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const { selectedCustomer, isLoading } = useCustomer();
  const { id, officialIdNo, attributes } = selectedCustomer || {};
  const hasAttributes = attributes && Object.keys(attributes).length > 0;
  const { EnableVehicleRegistrySearch } = useSelector(getFeatures);

  const handleRefresh = () => {
    return dispatch(fetchVehicleInfo(officialIdNo, id))
      .then(() => setError(null))
      .catch((err) => setError(err));
  };

  if (isLoading || attributes === undefined) {
    return <Loader />;
  }
  if (selectedCustomer && !hasAttributes) {
    return (
      <CustomerEmptyTab
        title={txt(msg.noVehicleInfoTitle)}
        text={txt(msg.noVehicleInfoText)}
      >
        {error && (
          <AlertWithIcon icon="fa fa-exclamation-circle" error>
            Kunde inte hämta fordonsinfo. Kontrollera reg.numret och försök igen.
          </AlertWithIcon>
        )}
        {EnableVehicleRegistrySearch && (
          <ProgressButton small gray onClick={handleRefresh}>
            {txt(msg.btnSearchVehicleInfo)}
          </ProgressButton>
        )}
      </CustomerEmptyTab>
    );
  }

  return (
    <Row scrollContainer>
      <ScrollWrapper>
        <ScrollContent>
          {!isOwnerUnknown(attributes) && (
            <>
              <h5>Ägare</h5>
              <table className="table table-striped">
                <tbody>
                  {vehicleOwnerFields.map((key) => {
                    return renderTableRow(key, attributes[key]);
                  })}
                </tbody>
              </table>
            </>
          )}
          <h5>Fordonsdata</h5>
          <table className="table table-striped">
            <tbody>
              {vehicleBaseFields.map((key) => {
                return renderTableRow(key, attributes[key]);
              })}
            </tbody>
          </table>
          <h5>Övrig teknisk data</h5>
          <table className="table table-striped">
            <tbody>
              {Object.entries(attributes).map(([key, value]) => {
                if (vehicleOwnerFields.indexOf(key) !== -1 || vehicleBaseFields.indexOf(key) !== -1) {
                  return null;
                }
                return renderTableRow(key, value);
              })}
            </tbody>
          </table>
        </ScrollContent>
      </ScrollWrapper>
    </Row>
  );
};

export default CustomerVehicleInfo;
