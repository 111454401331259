export const NETWORK_FAILED = 'NETWORK_FAILED';
export const VERSION_MISMATCH = 'VERSION_MISMATCH';
export const APP_NOTIFICATION = 'APP_NOTIFICATION';

export function networkFailure(title, message, details) {
  return {
    type: NETWORK_FAILED,
    title: title || 'Ett fel inträffade',
    message: message || 'Ett fel inträffade vid kontakt med servern. Försök igen eller kontakta oss om felet kvarstår.',
    details
  };
}

export function resetNetworkFailure() {
  return {
    type: NETWORK_FAILED,
    message: null
  };
}

export function versionMismatch(currentVersion, requiredVersion) {
  return {
    type: VERSION_MISMATCH,
    state: {
      currentVersion,
      requiredVersion
    }
  };
}

export function showAppNotification(data) {
  return {
    type: APP_NOTIFICATION,
    data
  };
}

export function resetAppNotification() {
  return {
    type: APP_NOTIFICATION,
    data: null
  };
}
