import { defineMessages } from 'react-intl';

export default defineMessages({
  simpleBooking: {
    id: 'calendar.bkf.type.simpleBooking',
    defaultMessage: 'New booking'
  },
  classBooking: {
    id: 'calendar.bkf.type.classBooking',
    defaultMessage: 'New class'
  },
  reservation: {
    id: 'calendar.bkf.type.reservation',
    defaultMessage: 'Block time'
  }
});
