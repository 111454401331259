import { defineMessages } from 'react-intl';

export default defineMessages({
  closeBtnLabel: {
    id: 'calendar.bkf.class.closeBtnLabel',
    defaultMessage: 'Close'
  },
  participants: {
    id: 'calendar.bkf.class.participants',
    defaultMessage: 'Participants'
  },
  selectAll: {
    id: 'calendar.bkf.class.selectAll',
    defaultMessage: 'Select all'
  },
  addCustomer: {
    id: 'calendar.bkf.class.addCustomer',
    defaultMessage: 'Add customer'
  },
  noCustomers: {
    id: 'calendar.bkf.class.noCustomers',
    defaultMessage: 'No participants'
  },
  pendingBooking: {
    id: 'calendar.bkf.class.pendingBooking',
    defaultMessage: 'Pending online booking...'
  },
  btnOpen: {
    id: 'calendar.bkf.class.btnOpen',
    defaultMessage: 'Open'
  },
  btnShow: {
    id: 'calendar.bkf.class.btnShow',
    defaultMessage: 'Show'
  },
  btnNoShow: {
    id: 'calendar.bkf.class.btnNoShow',
    defaultMessage: 'No show'
  }
});
