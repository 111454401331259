import { defineMessages } from 'react-intl';

export default defineMessages({
  lblNewCampaign: {
    id: 'campaign.form.lblNewCampaign',
    defaultMessage: 'Create new campaign'
  },
  lblCampaignType: {
    id: 'campaign.form.lblCampaignType',
    defaultMessage: 'Select type of campaign'
  },
  lblRecipients: {
    id: 'campaign.form.lblRecipients',
    defaultMessage: 'Select recipients'
  },
  btnCancel: {
    id: 'campaign.form.btnCancel',
    defaultMessage: 'Cancel'
  },
  btnContinue: {
    id: 'campaign.form.btnContinue',
    defaultMessage: 'Continue'
  },
  btnSend: {
    id: 'campaign.form.btnSend',
    defaultMessage: 'Send'
  },
  btnSchedule: {
    id: 'campaign.form.btnSchedule',
    defaultMessage: 'Schedule'
  },
  btnBack: {
    id: 'campaign.form.btnBack',
    defaultMessage: 'Back'
  },
  lblTypeSms: {
    id: 'campaign.form.lblTypeSms',
    defaultMessage: 'SMS campaign'
  },
  lblTypeEmail: {
    id: 'campaign.form.lblTypeEmail',
    defaultMessage: 'Email campaign'
  },
  txtTypeSms: {
    id: 'campaign.form.txtTypeSms',
    defaultMessage: 'High delivery security. Most people read an SMS immediately. Cost for SMS is added.'
  },
  txtTypeEmail: {
    id: 'campaign.form.txtTypeEmail',
    defaultMessage: 'Send email to your customers. Costs nothing extra, but there is a risk that it will end up in the spam folder.'
  },
  txtCampaignNotAvailable: {
    id: 'campaign.form.txtCampaignNotAvailable',
    defaultMessage: 'Campaign not available. Contact support for activation.'
  },
  txtConfirmDraft: {
    id: 'campaign.form.txtConfirmDraft',
    defaultMessage: 'Do you want to save a draft of the campaign?'
  },
  btnSaveDraft: {
    id: 'campaign.form.btnSaveDraft',
    defaultMessage: 'Save draft'
  },
  btnDeleteDraft: {
    id: 'campaign.form.btnDeleteDraft',
    defaultMessage: 'Delete draft'
  },
  txtAudienceTypeAll: {
    id: 'campaign.form.txtAudienceTypeAll',
    defaultMessage: 'All customers or customers belonging to a specific resource'
  },
  txtAudienceTypeBooked: {
    id: 'campaign.form.txtAudienceTypeBooked',
    defaultMessage: 'Customers with bookings during a certain period'
  },
  txtAudienceTypeNew: {
    id: 'campaign.form.txtAudienceTypeNew',
    defaultMessage: 'Customers who have been added during a certain period'
  },
  txtAudienceTypeTop: {
    id: 'campaign.form.txtAudienceTypeTop',
    defaultMessage: 'Customers who have spent more than a certain amount during a certain period'
  },
  lblShowRecipients: {
    id: 'campaign.form.lblShowRecipients',
    defaultMessage: 'Show recipients'
  },
  lblSpentMore: {
    id: 'campaign.form.lblSpentMore',
    defaultMessage: 'Spent more than'
  },
  lblSelectionPerResource: {
    id: 'campaign.form.lblSelectionPerResource',
    defaultMessage: 'Selection per resource'
  },
  lblSelectResources: {
    id: 'campaign.form.lblSelectResources',
    defaultMessage: 'Select resources'
  },
  lblSelectionPerService: {
    id: 'campaign.form.lblSelectionPerService',
    defaultMessage: 'Selection per service'
  },
  lblSelectServices: {
    id: 'campaign.form.lblSelectServices',
    defaultMessage: 'Select services'
  },
  lblCampaignFilter: {
    id: 'campaign.form.lblCampaignFilter',
    defaultMessage: '{campaignType} to {audienceType}'
  },
  lblNewCustomers: {
    id: 'campaign.form.lblNewCustomers',
    defaultMessage: 'New customers'
  },
  txtNewCustomers: {
    id: 'campaign.form.txtNewCustomers',
    defaultMessage: 'Customers who have been added during selected period'
  },
  lblDateForBooking: {
    id: 'campaign.form.lblDateForBooking',
    defaultMessage: 'Date for booking'
  },
  txtDateForBooking: {
    id: 'campaign.form.txtDateForBooking',
    defaultMessage: 'Customers who has a booking during selected period'
  },
  lblBookingCreated: {
    id: 'campaign.form.lblBookingCreated',
    defaultMessage: 'Booking created'
  },
  txtBookingCreated: {
    id: 'campaign.form.txtBookingCreated',
    defaultMessage: 'Customers who has a booking created during selected period'
  },
  lblTopCustomers: {
    id: 'campaign.form.lblTopCustomers',
    defaultMessage: 'Top customers'
  },
  txtTopCustomers: {
    id: 'campaign.form.txtTopCustomers',
    defaultMessage: 'Customers who has spent more than selected amount during selected period'
  },
  lblAllCustomers: {
    id: 'campaign.form.lblAllCustomers',
    defaultMessage: 'All customers'
  },
  lblCustomersBelongToResource: {
    id: 'campaign.form.lblCustomersBelongToResource',
    defaultMessage: 'Customers belonging to resource'
  },
  txtCustomersBelongToResource: {
    id: 'campaign.form.txtCustomersBelongToResource',
    defaultMessage: 'Customers who are connected to the resource on the customer card'
  },
  lblCustomersBookedWithResource: {
    id: 'campaign.form.lblCustomersBookedWithResource',
    defaultMessage: 'Customers booked with resource'
  },
  txtCustomersBookedWithResource: {
    id: 'campaign.form.txtCustomersBookedWithResource',
    defaultMessage: 'Customers who have been booked with the resource during the selected period'
  },
  lblCustomersBookedService: {
    id: 'campaign.form.lblCustomersBookedService',
    defaultMessage: 'Customers who booked service'
  },
  txtCustomersBookedService: {
    id: 'campaign.form.txtCustomersBookedService',
    defaultMessage: 'Customers who have been booked for one or more services'
  },
  txtSmsSenderInfo: {
    id: 'campaign.form.txtSmsSenderInfo',
    defaultMessage: 'Max 11 characters, only letters, numbers and underscore (_). Sender can also be a phone number that the recipient can reply to.'
  },
  txtSmsContentInfo: {
    id: 'campaign.form.txtSmsContentInfo',
    defaultMessage: 'Write your message. The message can be up to 916 characters. Note that if the message exceeds 160 characters, more than one message per recipient will be sent.'
  },
  lblAllowReply: {
    id: 'campaign.form.lblAllowReply',
    defaultMessage: 'Allow customers to reply to the email'
  },
  txtAllowReply: {
    id: 'campaign.form.txtAllowReply',
    defaultMessage: 'Enter an email address so the customer can reply to the campaign.'
  },
  lblSender: {
    id: 'campaign.form.lblSender',
    defaultMessage: 'Enter sender'
  },
  lblScheduleCampaign: {
    id: 'campaign.form.lblScheduleCampaign',
    defaultMessage: 'Schedule campaign'
  },
  lblSendNow: {
    id: 'campaign.form.lblSendNow',
    defaultMessage: 'Send now'
  },
  lblSchedule: {
    id: 'campaign.form.lblSchedule',
    defaultMessage: 'Schedule'
  },
  txtTimePassed: {
    id: 'campaign.form.txtTimePassed',
    defaultMessage: 'The time has already passed, the campaign will be sent immediately.'
  },
  lblConfirmCampaign: {
    id: 'campaign.form.lblConfirmCampaign',
    defaultMessage: 'Confirm campaign'
  },
  txtNoRecipients: {
    id: 'campaign.form.txtNoRecipients',
    defaultMessage: 'The selection has no recipients. Adjust the selection to be able to send the message.'
  },
  lblTime: {
    id: 'campaign.form.lblTime',
    defaultMessage: 'Time to send'
  },
  lblCost: {
    id: 'campaign.form.lblCost',
    defaultMessage: 'Total cost'
  },
  txtLimitExceeded: {
    id: 'campaign.form.txtLimitExceeded',
    defaultMessage: 'The campaign cannot be sent as it exceeds the limit for number of sent SMS. Contact support if you want to increase the limit.'
  },
  txtConfirmSms: {
    id: 'campaign.form.txtConfirmSms',
    defaultMessage: 'I approve that SMS is sent to selected customers as above, and that my account is debited {cost}'
  },
  txtConfirmEmail: {
    id: 'campaign.form.txtConfirmEmail',
    defaultMessage: 'I approve that email is sent to selected customers as above'
  }
});
