import moment from 'moment';
import UAParser from 'ua-parser-js';
import { Decimal } from 'decimal.js-light';
import { currencySymbol } from '@Utils/currency-util';
import { pos } from '@Utils/preference-keys';
import { colors } from '@Components/ui/styled/variables';
import { formatPno } from '@Utils/luhn-util';
import { txt } from '@Utils/i18n-util';
import msg from '@Components/pos/payment/pos-payment.msg';

export const LowStockThreshold = 3;
export const OtherPaymentMethods = [1, 2, 3, 4, 5];

export const PaymentModal = {
  GiftCard: 'giftCardPayment',
  Other: 'otherPayment',
  Invoice: 'invoicePayment',
  Fortnox: 'fortnoxPayment',
  Klarna: 'klarnaPayment',
  Swish: 'swishPayment',
  Cash: 'cashPayment',
  Card: 'cardPayment'
};

export const isPosUnitClosed = posUnit => posUnit?.get('state') === 'Closed';
export const isPosUnitOpenOtherDevice = posUnit => posUnit?.get('state') === 'OpenOtherDevice';
export const isPosUnitOpenCurrentDevice = posUnit => posUnit?.get('state') === 'OpenCurrentDevice';
export const isCashierAdmin = posUnit => posUnit?.get('cashierRole') === 'Admin';

export function getArticleName(item) {
  return [item.brand, item.name].filter(s => s).join(', ');
}

export function getProductDescription(product, posUnitPrefs) {
  const { brand, name, supplierBrand, supplierProductName } = product;
  const productBrand = supplierBrand || brand;
  const productName = supplierProductName || name;
  const showProductBrand = posUnitPrefs && posUnitPrefs[pos.showProductBrandOnReceipt];
  return showProductBrand && productBrand
    ? `${productBrand}, ${productName}`
    : productName;
}

export function getDiscountAmount(discountType, discountValue, grossAmount = 0) {
  const decimalValue = new Decimal(discountValue || 0);
  const discountAmount = discountType === 'Percentage'
    ? decimalValue.dividedBy(100).times(grossAmount)
    : decimalValue;
  return discountAmount.absoluteValue().toDecimalPlaces(2);
}

export function formatDiscount(discountValue, discountType, decimals) {
  const fractionDigits = decimals === undefined ? 2 : parseInt(decimals);
  const properties = { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits };
  const value = parseFloat(discountValue || 0).toLocaleString('sv-SE', properties);
  const symbol = discountType === 'Percentage' ? '%' : currencySymbol();
  return `${value} ${symbol}`;
}

export function getItemDescription(description, itemType) {
  const typeDescription = itemType === 'Service' ? 'Tjänst' : 'Produkt';
  return description || typeDescription;
}

export function getDiscountDescription(discountValue, discountType) {
  return discountType === 'Percentage'
    ? `Rabatt (${formatDiscount(discountValue, discountType, 0)})`
    : 'Rabatt';
}

export function getColorForItem(item, products, productGroups, serviceColors) {
  const articleId = parseInt(item.get('articleId') || 0);
  const serviceId = parseInt(item.get('serviceId') || 0);

  if (item.get('itemType') === 'VoucherSale') {
    return colors.customerGiftcardBg;
  }
  if (articleId) {
    const { color } = products.get(articleId) || {};
    const group = productGroups.find(g => g.get('articleIds').contains(articleId));
    return color || group?.get('color');
  }
  if (serviceId) {
    return serviceColors[serviceId];
  }
  return null;
}

export function getLowStockForItem(item, products) {
  const articleId = parseInt(item.get('articleId') || 0);
  const product = articleId ? products.get(articleId) : null;

  return product && product.stocked
    && product.stockQuantity <= LowStockThreshold;
}

export function getBookingIdForSale(items) {
  const bookingItem = items && items.find(item => item.get('bookingId'));
  return bookingItem && bookingItem.get('bookingId');
}

export function getVunitIdForReceipt(receipts, receiptId) {
  return receipts?.find(r => r.get('receiptId') === receiptId)?.get('vunitId');
}

export function saleItemSort(a, b) {
  if (a.get('itemType') === 'Discount') return 1;
  if (b.get('itemType') === 'Discount') return -1;

  if (!a.get('id')) return 1;
  if (!b.get('id')) return -1;

  return a.get('id') < b.get('id') ? -1 : 1;
}

export function getTransactionTypeName(transactionType) {
  switch (transactionType) {
    case 'OpenDpst':
      return 'Insättning vid öppning';
    case 'OpenWtdrl':
      return 'Korrektur vid öppning';
    case 'CloseWtdrl':
      return 'Uttag vid dagsavslut';
    case 'MnlDpst':
      return 'Manuell insättning';
    case 'MnlWtdrl':
      return 'Manuellt uttag';
    default:
      return transactionType;
  }
}

export function getPaymentMethodName(paymentMethod, posOrgPrefs) {
  if (posOrgPrefs && isOtherPaymentMethod(paymentMethod)) {
    return getOtherPaymentMethodName(posOrgPrefs, parseInt(paymentMethod.slice(-1)));
  }

  switch (paymentMethod) {
    case 'Card':
    case 'Stripe':
    case 'ExternalCard':
    case 'ExternalCardReader':
      return txt(msg.Card);
    case 'Invoice':
    case 'FortnoxInvoice':
      return txt(msg.Invoice);
    case 'Cash':
    case 'PosCash':
      return txt(msg.Cash);
    case 'Voucher':
      return txt(msg.GiftCard);
    case 'PrepaidBankPayment':
      return 'Förbetalt belopp';
    case 'SwishMerchant':
      return 'Swish Handel';
    case 'KlarnaPayments':
      return 'Klarna Payments';
    default:
      return paymentMethod;
  }
}

export function getRefundDialogTitle(paymentMethod) {
  switch (paymentMethod) {
    case 'Card':
    case 'Stripe':
    case 'ExternalCard':
    case 'ExternalCardReader':
      return 'Återbetala till kort';
    case 'Invoice':
    case 'FortnoxInvoice':
      return 'Återbetala med faktura';
    case 'Cash':
    case 'PosCash':
      return 'Återbetala kontant';
    case 'Voucher':
      return 'Återbetala med presentkort';
    case 'SwishMerchant':
      return 'Återbetala med Swish Handel';
    case 'KlarnaPayments':
      return 'Återbetala med Klarna Payments';
    default:
      return `Återbetala med ${paymentMethod}`;
  }
}

export function getRefundDialogText(paymentMethod) {
  switch (paymentMethod) {
    case 'Card':
    case 'ExternalCardReader':
      return 'Slå in beloppet och genomför återbetalning till kundens kort';
    case 'Swish':
      return 'Betala tillbaka till kunden med Swish och bekräfta';
    case 'Klarna':
      return 'Betala tillbaka beloppet med Klarna till kunden';
    case 'Invoice':
    case 'FortnoxInvoice':
      return 'Betala tillbaka beloppet med faktura till kunden';
    case 'Cash':
      return 'Betala tillbaka beloppet kontant till kunden';
    case 'Voucher':
      return 'Betala tillbaka beloppet med presentkort till kunden';
    default:
      return 'Betala tillbaka beloppet till kunden och bekräfta';
  }
}

export function getGiftCardStatus(status) {
  switch (status) {
    case 'Allocated':
      return 'Allokerat';
    case 'PendingSale':
      return 'Ej sålt';
    case 'Sold':
      return '-';
    case 'RedeemedPartial':
      return 'Delvis inlöst';
    case 'RedeemedFully':
      return 'Inlöst';
    case 'Void':
      return 'Makulerat';
    default:
      return status;
  }
}

export function getGiftCardType(accountingType, vatPct) {
  switch (accountingType) {
    case 'SinglePurpose':
      return `Enfunktion (${vatPct}%)`;
    case 'MultiPurpose':
      return 'Flerfunktion';
    default:
      return accountingType;
  }
}

export const getSumByType = (data, type, typeField) => {
  return data.filter((item) => item[typeField] === type).length;
};

export const getIconByPayment = (payment) => {
  switch (payment) {
    case 'Cash':
      return 'far fa-coins';
    case 'Card':
    case 'Stripe':
    case 'ExternalCardReader':
      return 'far fa-credit-card-blank';
    case 'Invoice':
    case 'FortnoxInvoice':
      return 'far fa-file-alt';
    case 'Voucher':
      return 'far fa-gift';
    case 'PrepaidBankPayment':
      return 'far fa-money-bill-transfer';
    default:
      return 'fal fa-receipt';
  }
};

export const getReceiptTypeIcon = (receiptType) => {
  switch (receiptType) {
    case 'Invoice':
      return 'fal fa-file-alt';
    default:
      return 'fal fa-receipt';
  }
};

export function isOtherPaymentMethod(paymentMethod) {
  return /Other\d/.test(paymentMethod);
}

export function getOtherPaymentMethodName(prefs, number) {
  return prefs && prefs[`${pos.paymentMethodOther}${number}Name`] || 'Annat betalsätt';
}

export function getSaleItemsWithVatPctDiff(saleItems, giftCard) {
  const { accountingType, vatPct } = giftCard;
  return accountingType === 'SinglePurpose' && saleItems
    ? saleItems.filter(item => {
      const includeItem = item.get('itemType') === 'Product' || item.get('itemType') === 'Service';
      const hasVatPctDiff = item.get('vatPct') !== vatPct;
      return includeItem && hasVatPctDiff;
    }) : null;
}

export function hasMultiplePosUnits(posUnits, posUnit) {
  return posUnits
    .filter(u => u.get('posOrgId') === posUnit.get('posOrgId'))
    .count() > 1;
}

export function getPosUnitTitle(posUnits, posUnit) {
  return hasMultiplePosUnits(posUnits, posUnit)
    ? `${posUnit.get('vunitName')}: ${posUnit.get('posOrgName')}`
    : posUnit.get('posOrgName');
}

const posUnitTypes = [
  'Onprem', 'OnpremNoCCU', 'OnpremDemo', 'Ecom', 'ExtPayments'
];

function getTypeIndex(type) {
  const index = posUnitTypes.indexOf(type);
  return index !== -1 ? index : posUnitTypes.length;
}

export function sortPosUnits(a, b) {
  const typeA = getTypeIndex(a?.get('type'));
  const typeB = getTypeIndex(b?.get('type'));

  if (typeA < typeB) return -1;
  if (typeA > typeB) return 1;

  return a?.get('name')?.localeCompare(b?.get('name'), 'sv') || 0;
}

export function getPosNameByType(type) {
  switch (type) {
    case 'Onprem':
    case 'OnpremNoCCU':
      return 'Cliento Kassaregister SE v.1.X';
    case 'OnpremDemo':
      return 'Cliento Kassaregister DEMO';
    default:
      return null;
  }
}

export function getPosUnitTitleForType(type, name) {
  switch (type) {
    case 'Ecom':
      return 'Onlinebetalningar';
    case 'ExtPayments':
      return 'Övriga betalningar';
    default:
      return name;
  }
}

export function getPosUnitDescription(type) {
  switch (type) {
    case 'Ecom':
      return 'Registrering av betalningar online, via kort, Swish eller Klarna.';
    case 'ExtPayments':
      return 'Registrering av övriga betalningar som inte ska registreras i kontrollenhet, som t.ex. faktura.';
    default:
      return null;
  }
}

export function getUseSwishIntegration(prefs, transactions) {
  const hasTransations = transactions && !transactions.isEmpty();
  return prefs && prefs[pos.swishPayeeAlias]
    && prefs[pos.swishCertId]
    && prefs[pos.useSwishIntegration]
    && !hasTransations;
}

export function getUseKlarnaPayments(prefs, transactions) {
  const hasTransations = transactions && !transactions.isEmpty();
  return prefs && prefs[pos.klarnaUsername]
    && prefs[pos.klarnaPassword]
    && !hasTransations;
}

export function formatSwishNumber(number) {
  return number?.replace(/^(\d{3})(\d{3})(\d{2})(\d{2})$/, '$1-$2 $3 $4');
}

export function getSaleSectionLabel(item) {
  const vehicleInfo = item?.get('vehicleRegNo')
    ? [item.get('vehicleRegNo'), item.get('vehicleDescription')].filter(s => s).join(' - ')
    : null;
  const bookingInfo = item?.get('bookingStartTs')
    ? `Bokning - ${moment(item.get('bookingStartTs')).format('LLL')}`
    : 'Bokning';
  return vehicleInfo ?? bookingInfo;
}

export function getCustomerDetails(customerName, customerPno) {
  return [customerName, customerPno && formatPno(customerPno)].filter(s => s).join(', ');
}

export function getUserAgentDetails(userAgent) {
  const parser = new UAParser(userAgent);
  return parser.getResult();
}
