import PropTypes from 'prop-types';
import React, { Component } from 'react';

class PeriodInput extends Component {
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const valueSet = !this.props.input.value && nextProps.input.value;
    const initialChanged = this.props.meta.initial !== nextProps.meta.initial;

    if (valueSet || initialChanged) {
      this.setState(this.getState(nextProps));
    }
  }

  getState = (props) => {
    const values = this.getPeriodValues(props.input.value);
    return {
      amount: values[1],
      period: values[2]
    };
  };

  getPeriodValues = (value) => {
    const regex = /^P([0-9]+)([A-Z])$/g;
    return regex.exec(value) || ['', '0', 'D'];
  };

  getPeriodString = (amount, period) => {
    return `P${amount}${period}`;
  };

  changeAmount = (ev) => {
    ev.preventDefault();
    this.setState({ amount: ev.target.value });
    this.props.input.onChange(this.getPeriodString(ev.target.value, this.state.period));
  };

  changePeriod = (ev) => {
    ev.preventDefault();
    this.setState({ period: ev.target.value });
    this.props.input.onChange(this.getPeriodString(this.state.amount, ev.target.value));
  };

  render() {
    const { amount, period } = this.state;
    const {
      vertical, input: { name }, label, labelAfter, description, meta: { touched, error }
    } = this.props;
    const isSingleAmount = parseInt(amount, 10) === 1;

    const labelClass = vertical ? 'control-label' : 'col-sm-3 control-label';
    const inputClass = vertical ? '' : 'col-sm-6';

    return (
      <div className={touched && error ? 'form-group has-error' : 'form-group'}>
        <label className={labelClass}>{label}</label>
        <div className={inputClass}>
          <input
            type="number"
            name={`${name}-amount`}
            value={amount}
            className="form-control inline-xs"
            onChange={this.changeAmount}
          />
          <div className="select-container inline-md">
            <select
              name={`${name}-period`}
              value={period}
              className="form-control"
              onChange={this.changePeriod}
            >
              <option value="D">{isSingleAmount ? 'dag' : 'dagar'}</option>
              <option value="W">{isSingleAmount ? 'vecka' : 'veckor'}</option>
              <option value="M">{isSingleAmount ? 'månad' : 'månader'}</option>
              <option value="Y">år</option>
            </select>
          </div>
          <span className="form-control-static">
            {labelAfter}
          </span>
          {touched && error && <span className="help-block">{error}</span>}
          {description && <span className="form-control-description">{description}</span>}
        </div>
      </div>
    );
  }
}

PeriodInput.propTypes = {
  label: PropTypes.string.isRequired
};

export default PeriodInput;
