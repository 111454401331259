import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import customerIdContext from '@Components/customers/customer-id-context';
import { fetchCustomerSales } from '@State/customer-actions';
import CustomerDetailInvoice from '@Components/customers/customer-detail/sales/customer-detail-invoice';
import CustomerDetailReceipt from '@Components/customers/customer-detail/sales/customer-detail-receipt';
import CustomerDetailSalesList from '@Components/customers/customer-detail/sales/customer-detail-sales-list';
import { Row, Text } from '@Components/ui/styled/main';
import ModalDialog from '@Components/dialogs/modal-dialog';
import {
  ScrollContent, ScrollWrapper, ColumnLeft, ColumnRight, HeaderTextEmpty
} from '@Components/customers/customer-styles';
import { sortEventByTimeAndReverse, groupEventsByMonth } from '@Utils/customer-util';
import CustomerEmptyTab from '@Components/customers/customer-detail/customer-empty-tab';
import Loader from '@Components/ui/loader';
import { getCustomerSalesUrl } from '@Utils/navigate';
import { getFeatures } from '@State/selectors';
import { txt } from '@Utils/i18n-util';
import msg from './customer-sales.msg';

const CustomerDetailSales = ({ modalView, routeParams, onSelectSales, ...props }) => {
  const dispatch = useDispatch();
  const tabId = props.tabId || routeParams?.tabId;
  const customerId = +useContext(customerIdContext);

  const { EnablePOS } = useSelector(getFeatures);
  const phoneMode = useSelector(state => state.mainViewState.get('phoneMode'));
  const customerSales = useSelector((state) => state.customerSales);
  const sales = customerSales.get('sales');
  const loading = customerSales.get('loading');
  const groupedSales = groupEventsByMonth(sales);

  const selectedSale = useMemo(() => {
    if (sales.length === 0) return null;
    if (tabId) {
      const saleById = sales.find((sale) => sale.id === +tabId);
      if (saleById) return (saleById);
    } else {
      const firstSale = sortEventByTimeAndReverse(groupedSales[0][1])[0]; // sort it and take first
      if (firstSale) return (firstSale);
    }
  }, [sales, tabId]);

  useEffect(() => {
    if (EnablePOS) {
      dispatch(fetchCustomerSales(customerId));
    }
  }, [customerId]);

  const [showModal, setShowModal] = useState(null);
  const handleSelect = (tabId) => {
    onSelectSales(tabId, 'sales');

    if (phoneMode) {
      setShowModal(true);
    }
  };

  if (!EnablePOS) {
    return (
      <HeaderTextEmpty>
        <img src="/pos.svg" width="200" />
        <Text dark center fs="13" fontWeight={400}>
          Här kan du se dina kunders köphistorik om du använder Cliento Kassa.
          <br />
          <br />
          Kontakta support för en offert.
        </Text>
      </HeaderTextEmpty>
    );
  }

  if (sales && sales.length > 0 && selectedSale?.id && !modalView && !routeParams?.tabId) {
    return <Redirect to={getCustomerSalesUrl(routeParams, selectedSale.id)} />;
  }

  if (loading) {
    return <Loader topPosition />;
  }

  if (!groupedSales || !selectedSale?.saleReceiptId) {
    return (
      <CustomerEmptyTab
        title={txt(msg.noPaymentsTitle)}
        text={txt(msg.noPaymentsText)}
      />
    );
  }

  const renderList = () => (
    <CustomerDetailSalesList
      sales={sales}
      handleSelect={handleSelect}
      selectedSale={selectedSale}
    />
  );

  const renderDetails = () => {
    if (selectedSale?.saleReceiptType === 'Invoice' && selectedSale?.saleInvoiceId) {
      return <CustomerDetailInvoice selectedSale={selectedSale} />;
    }
    if (selectedSale?.saleReceiptType === 'Receipt' && selectedSale?.saleReceiptId) {
      return <CustomerDetailReceipt selectedSale={selectedSale} />;
    }
    return null;
  };

  if (phoneMode) {
    return (
      <Row scrollContainer>
        <ScrollWrapper>
          <ScrollContent>
            {renderList()}
          </ScrollContent>
        </ScrollWrapper>
        {showModal && (
          <ModalDialog
            contentSize="large"
            title={txt(msg.lblDetails)}
            closeButtonText={txt(msg.btnClose)}
            onClose={() => setShowModal(null)}
          >
            {renderDetails()}
          </ModalDialog>
        )}
      </Row>
    );
  }

  return (
    <Row scrollContainer>
      <ColumnLeft>
        <ScrollWrapper>
          <ScrollContent>
            {renderList()}
          </ScrollContent>
        </ScrollWrapper>
      </ColumnLeft>
      <ColumnRight>
        <ScrollWrapper>
          <ScrollContent>
            {renderDetails()}
          </ScrollContent>
        </ScrollWrapper>
      </ColumnRight>
    </Row>
  );
};
CustomerDetailSales.propTypes = {
  onSelectSales: PropTypes.func.isRequired
};

export default CustomerDetailSales;
