import { prefixUrl, axiosGet, axiosPut, axiosPost } from '@Utils/ajax-util';

export const ADMIN_CONFIG_LOADED = 'ADMIN_CONFIG_LOADED';
export const PARTNERS_LOADED = 'PARTNERS_LOADED';
export const PARTNER_PREFS_UPDATED = 'PARTNER_PREFS_UPDATED';
export const PARTNER_CUSTOM_TEXTS_UPDATED = 'PARTNER_CUSTOM_TEXTS_UPDATED';

export function getAdminConfig() {
  const url = prefixUrl('/location/admin-configuration');
  return (dispatch) => dispatch(axiosGet(url, {
    onSuccess: (res) => dispatch({ type: ADMIN_CONFIG_LOADED, config: res.data })
  }));
}

export function fetchPartners() {
  const url = prefixUrl('/partners/');
  return (dispatch) => dispatch(axiosGet(url, {
    onSuccess: (res) => dispatch({ type: PARTNERS_LOADED, partners: res.data })
  }));
}

export function replacePartnerPreferences(id, prefs) {
  const url = prefixUrl(`/partners/${id}/prefs/`);
  return (dispatch) => dispatch(axiosPut(url, prefs, {
    onSuccess: () => dispatch({ type: PARTNER_PREFS_UPDATED, id, prefs })
  }));
}

export function replacePartnerCustomTexts(id, customTexts) {
  const url = prefixUrl(`/partners/${id}/custom-texts/`);
  return (dispatch) => dispatch(axiosPut(url, customTexts, {
    onSuccess: () => dispatch({ type: PARTNER_CUSTOM_TEXTS_UPDATED, id, customTexts })
  }));
}

export function restoreAutoDeleted() {
  const url = prefixUrl('/storage/restore-auto-deleted');
  return axiosPost(url, null, {
    onSuccess: (res) => res.data
  });
}

export function fetchNotificationSubscriptions() {
  const url = prefixUrl('/notifications/all-subscriptions/');
  return axiosGet(url, {
    onSuccess: (res) => res.data
  });
}
