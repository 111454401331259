import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDefaultVatPct } from '@State/selectors';
import Popover from '@Components/ui/popover';
import { createProductGroup, createProduct } from '@State/products-actions';
import { getSectionUrl, navigate } from '@Utils/navigate';
import AddItemForm from '../../sortable-list/add-item-form';
import AddGroupForm from '../../sortable-list/add-group-form';

class ProductActionButtons extends Component {
  static propTypes = {
    onAddProduct: PropTypes.func.isRequired,
    onAddGroup: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      addProduct: false,
      addGroup: false,
      addGroupColor: false
    };
  }

  render() {
    return (
      <>
        {this.renderAddProductButton()}
        {this.renderAddGroupButton()}
      </>
    );
  }

  renderAddProductButton() {
    const disabled = this.props.productGroups.isEmpty();
    return (
      <Popover
        isOpen={this.state.addProduct}
        body={this.addProductContent()}
        preferPlace="below"
        className="Popover-big-radius"
        onOuterAction={this.handleCloseProductPopover}
      >
        <button type="button" className="btn btn-cta" disabled={disabled} onClick={this.handleOpenProductPopover}>
          Lägg till produkt
        </button>
      </Popover>
    );
  }

  renderAddGroupButton() {
    return (
      <Popover
        isOpen={this.state.addGroup}
        body={this.addGroupContent()}
        preferPlace="below"
        className="Popover-big-radius"
        onOuterAction={this.handleCloseGroupPopover}
      >
        <button type="button" className="btn btn-cta-secondary" onClick={this.handleOpenGroupPopover}>
          Lägg till grupp
        </button>
      </Popover>
    );
  }

  handleOpenGroupPopover = () => {
    this.setState({ addGroup: true });
  };

  handleCloseGroupPopover = () => {
    if (!this.state.addGroupColor) {
      this.setState({ addGroup: false });
    }
  };

  handleOpenProductPopover = () => {
    this.setState({ addProduct: true });
  };

  handleCloseProductPopover = () => {
    this.setState({ addProduct: false });
  };

  handleOpenColorPopover = () => {
    this.setState({ addGroupColor: true });
  };

  handleCloseColorPopover = () => {
    this.setState({ addGroupColor: false });
  };

  handleAddProduct = ({ groupId, name, prepend }) => {
    const { defaultVatPct } = this.props;
    return this.props
      .onAddProduct({ groupId, name, prepend, vatPct: defaultVatPct, showInPos: true })
      .then(() => this.handleCloseProductPopover());
  };

  handleAddGroup = group => {
    return this.props
      .onAddGroup(group)
      .then(() => this.handleCloseGroupPopover());
  };

  addProductContent() {
    return (
      <AddItemForm
        onClosePopover={this.handleCloseProductPopover}
        onAddItem={this.handleAddProduct}
        placeholder="Ange namn på produkten"
        groups={this.props.productGroups}
      />
    );
  }

  addGroupContent() {
    return (
      <AddGroupForm
        showColorPicker
        onClosePopover={this.handleCloseGroupPopover}
        onAddGroup={this.handleAddGroup}
        colorPickerState={this.state.addGroupColor}
        handleColorPickerOpen={this.handleOpenColorPopover}
        handleColorPickerClose={this.handleCloseColorPopover}
        checkboxes={[{
          name: 'showInPos',
          label: 'Visa i kassan',
          checked: true
        }]}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { productGroups, adminViewState } = state;
  const groupIds = productGroups.map(item => item.get('id')).toJS();

  return {
    defaultVatPct: getDefaultVatPct(state),
    collapsedIds: adminViewState.get('productsCollapsedIds'),
    productGroups,
    groupIds
  };
};

const mapDispatchToProps = (dispatch, { routeParams }) => {
  return {
    onAddGroup: (group) => {
      return dispatch(createProductGroup(group));
    },
    onAddProduct: (product) => {
      return dispatch(createProduct(product))
        .then(({ id }) => navigate(getSectionUrl(routeParams, 'admin/products', id)));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductActionButtons);
