import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleBKFModal } from '@State/bkf/actions';
import { bkfModals } from '@State/bkf/constants';
import ModalDialog from '@Components/dialogs/modal-dialog';
import Button from '@Components/ui/button';

const BookingNotesModal = () => {
  const dispatch = useDispatch();
  const customer = useSelector(state => state.bkf.get('customer'));

  const onClose = () => {
    dispatch(toggleBKFModal(bkfModals.addCustomer, false));
  };

  return (
    <ModalDialog
      contentSize="medium"
      title="Meddelande från kund"
      closeButtonText="Stäng"
      onClose={onClose}
    >
      <div className="text-wrap">
        {customer?.customerNote}
      </div>
      <div className="text-right mt3">
        <Button gray small onClick={onClose}>Stäng</Button>
      </div>
    </ModalDialog>
  );
};

export default BookingNotesModal;
