import React from 'react';
import { useSelector } from 'react-redux';
import { getFeatures, getSysAdmin, getWebPaymentEnabled } from '@State/selectors';
import Feature from './feature';

const Features = ({ modal }) => {
  const { EnablePOS } = useSelector(getFeatures);
  const isSysAdmin = useSelector(getSysAdmin);
  const webPaymentEnabled = useSelector(getWebPaymentEnabled);

  return (
    <>
      <div className="feature-group">
        {isSysAdmin && (
          <Feature
            sysAdminOnly
            name="EnableClassBooking"
            title="Grupp- och klassbokning"
            icon="fa fa-fw fa-users"
            description="Aktivera grupp- och klassbokning för att en kund ska kunna boka in flera personer i samma bokning, eller för att kunna lägga upp pass som flera kunder kan boka in sig på."
          />
        )}
      </div>
      <div className="feature-group">
        <Feature
          name="EnableEcomPayments"
          title="Onlinebetalning"
          icon="fa fa-fw fa-credit-card"
          description="Aktivera onlinebetalning för att ta betalt av kunder direkt vid bokning med kort, Swish eller Klarna. Vänligen kontakta support för mer information."
          sysAdminRequired
        />
        {!modal && (
          <>
            <Feature
              name="EnableDiscountVouchers"
              title="Rabattkoder"
              icon="fa fa-fw fa-tag"
              description="Tillåt betalning helt eller delvis med rabattkod vid onlinebokning."
              disabled={!webPaymentEnabled}
            />
            {isSysAdmin && (
              <Feature
                sysAdminOnly
                name="EnableDeferredSales"
                title="Bokföring via skuldkonto"
                icon="fa fa-fw fa-calendar-check"
                description="Tillåt bokföring av betalda bokningar på skuldkonto tills bokningen har passerats."
                disabled={!webPaymentEnabled}
              />
            )}
            {isSysAdmin && (
              <Feature
                sysAdminOnly
                name="EnablePrepaidBookings"
                title="Förbetalning via insättning"
                icon="fa fa-fw fa-money-bill-transfer"
                description="Tillåt registrering av förbetalning av hela eller delar av beloppet på en bokning."
                disabled={!EnablePOS}
              />
            )}
          </>
        )}
      </div>
      <div className="feature-group">
        <Feature
          name="EnableCompanyBooking"
          title="Företagsbokning"
          icon="fa fa-fw fa-building"
          description="Aktivera företagsbokning för att samla in och använda organisationsnummer samt företagsnamn i bokningen."
        />
      </div>
      <div className="feature-group">
        <Feature
          name="EnableVehicleBooking"
          title="Fordonsbokning"
          icon="fa fa-fw fa-car"
          description="Aktivera fordonsbokning för att samla in och använda registreringsnummer och fordonsinformation i bokningen."
        />
        <Feature
          name="EnableVehicleRegistrySearch"
          dependency="EnableVehicleBooking"
          title="Sökning i bilregistret"
          icon="fa fa-fw fa-search"
          description="Sök ägare och fordonsuppgifter direkt i bilregistret. Extra kostnad tillkommer, vänligen kontakta support för mer information."
          sysAdminRequired
        />
      </div>
      {isSysAdmin && (
        <div className="feature-group">
          <Feature
            sysAdminOnly
            name="ShowOngoing"
            title="Visa pågående bokningar"
            icon="fa fa-fw fa-circle-play"
            description="Visar en särskild vy över dagens pågående bokningar, med ett flöde för att checka in och ta betalt."
          />
        </div>
      )}
    </>
  );
};

const FeaturesConfig = ({ modal }) => {
  if (modal) {
    return <Features modal />;
  }
  return (
    <div className="columns-container">
      <div className="columns-content">
        <div className="columns-content-container width-large">
          <div className="columns-content-body with-padding">
            <Features />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesConfig;
