import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetNetworkFailure } from '@State/app-actions';
import { getBannerStyle } from '@Utils/embedded-util';

class NetworkErrorAlert extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.networkError && nextProps.networkError) {
      setTimeout(() => this.props.onClose(), 5000);
    }
  }

  onClose = (ev) => {
    ev.preventDefault();
    this.props.onClose();
  };

  render() {
    const { networkError, bannerStyle } = this.props;

    if (!networkError) {
      return null;
    }

    const { title, message, details } = networkError;
    return (
      <div className="alert-floating-container" style={bannerStyle}>
        <div className="alert-floating alert-danger">
          <a href="#" className="pull-right text-muted" onClick={this.onClose}><i className="fa fa-times" /></a>
          <strong>{title}</strong>
          <br />
          <div>{message}</div>
          {details && <small className="text-muted">{details}</small>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    networkError: state.appState.get('networkError'),
    bannerStyle: getBannerStyle(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => dispatch(resetNetworkFailure())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NetworkErrorAlert);
