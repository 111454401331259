import { defineMessages } from 'react-intl';

export default defineMessages({
  blockAllDay: {
    id: 'calendar.bkf.allDay.blockAllDay',
    defaultMessage: 'Block all day'
  },
  closeAllDay: {
    id: 'calendar.bkf.allDay.closeAllDay',
    defaultMessage: 'Close all day'
  }
});
