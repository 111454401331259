import moment from 'moment';
import 'moment/locale/sv';
import 'moment-timezone';
import { useIntl } from 'react-intl';
import { config, locale } from '@Utils/preference-keys';
import PhoneNumberParser from '@Utils/phone-number-parser';
import CurrencyUtil from '@Utils/currency-util';

import en from '@Localization/en.json';
import sv from '@Localization/sv.json';

export const Messages = { en, sv };

let intl;
export const txt = (...args) => intl.formatMessage(...args);
export const IntlContext = ({ children }) => {
  intl = useIntl();
  return children;
};

export const getLocaleString = locale => String(locale || 'sv_SE').replace('_', '-');

export const getLanguageCode = locale => getLocaleString(locale).substring(0, 2);

export function initLocationConfig(configuration) {
  const countryCode = configuration[locale.preferredE164CountryCode] || '46';
  PhoneNumberParser.initialize(String(countryCode), '0', '00');

  const localeString = getLocaleString(configuration[config.locale] || 'sv_SE');
  const currencyCode = configuration[config.currency] || 'SEK';
  CurrencyUtil.initialize(currencyCode, localeString);

  moment.locale(localeString);
  moment.tz.setDefault(configuration[config.timeZone] || 'Europe/Stockholm');
}

export function getVatRegNo(orgNo, region) {
  if (!orgNo) {
    return orgNo;
  }
  switch (region) {
    case 'SE':
      return `SE${orgNo.replace('-', '')}01`;
    case 'FI':
      return `FI${orgNo.replace('-', '')}`;
    default:
      return orgNo;
  }
}

export function is12HourFormat() {
  return /AM|PM/.test(moment().format('LT'));
}
