import styled from 'styled-components';
import {
  colors, fs, fsw, screens
} from '@Components/ui/styled/variables';
import {
  Column, Panel, Row, Text
} from '@Components/ui/styled/main';

const {
  grayLightest, textMuted, linkColor
} = colors;

export const StyledLink = styled.div`
  color: ${linkColor};
`;

export const PanelWrapper = styled(Panel)`
  user-select: text;
  white-space: pre-line;
  padding: ${p => p.smallPadding ? '12px 16px' : '16px'};
  display: flex;
  flex-direction: column;
  background-color: ${p => p.bgColor ? p.bgColor : grayLightest};
  cursor: ${p => p.defaultCursor ? 'default' : 'pointer'};
`;

export const TitleBlock = styled.p`
  font-weight: ${fsw.medium};
  color: ${p => p.color || textMuted};
  margin-bottom: 5px;

  &::first-letter {
    text-transform: uppercase;
  }
  &:not(first-child) {
    margin-top: 20px;
  }
`;

export const TitleDate = styled(TitleBlock)`
  margin-bottom: 10px;
`;

export const TitleHeader = styled(TitleBlock)`
  text-transform: uppercase;
  color: ${colors.textColor};
  font-weight: 600;
  margin-bottom: 15px;
`;

export const ContentBlock = styled.div`
`;

export const ScrollWrapper = styled.div`
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1;

  @media (max-width: ${screens.sm}) {
    min-width: 300px;
  }
`;

export const ScrollContent = styled.div`
  position: absolute;
  padding: 0 20px 20px 20px;
  width: 100%;
`;

export const TextMuted = styled(Text)`
  font-size: ${fs.s};
  color: ${textMuted};
`;

export const CustomerEventIcon = styled.i`
  float: right;
`;

export const UpperText = styled.div`
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const ColumnLeft = styled(Column)`
  position: relative;
  width: 50%;
  flex: none;
`;

export const ColumnRight = styled(Column)`
  position: relative;
  width: 50%;
  flex: none;
`;

export const LogWrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  padding-top: 20px;
`;

export const CustomerBookingsTimeStyle = styled.div`
  line-height: 17px;
  padding-top: 1px;
  text-decoration: ${({ isCancelled }) => isCancelled && 'line-through'};

  :first-letter, p:first-letter {
    text-transform: uppercase;
  };
`;
export const CustomerBookingsWrapper = styled.div`
  p {
    margin-bottom: 3px;
  }
  p:last-child {
    margin-bottom: 0;
  }
`;

export const CustomerBookingsResource = styled.div`
  font-weight: ${fsw.book};
  color: ${colors.textMuted};
`;

export const CustomerBookingsNote = styled.div`
  margin-top: 5px;
  font-weight: ${fsw.book};
  font-style: italic;
`;

export const CustomerFormTitle = styled.p`
  font-size: ${fs.xl};
  font-weight: ${fsw.bold};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 15px;
  color:  #000;
`;

export const SaleRow = styled(Row)`
  padding-bottom: 0;
`;

export const SaleItemStyled = styled.div`
  margin-bottom: 16px;
`;

export const SaleSummaWrap = styled.div`
  border-top: 1px solid ${colors.softGray40};
  padding-top: 12px;
  width: 100%;
  display: flex;
`;

export const RowTransaction = styled(Row)`
  margin-bottom: 20px;
  padding-bottom: 0;
  &:last-child {
    margin-bottom: 0;
  }
`;
export const RowFormGroups = styled(Row)`
  margin-bottom: 15px;
  padding-bottom: 0;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const HeaderTextEmpty = styled.div`
  width: 100%;
  padding: 40px 20px 0 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    margin-bottom: 20px;
  }
  > div {
    margin-bottom: 40px;
  }
`;

export const getCardIcon = (channel) => {
  switch (channel) {
    case 'Web':
      return 'fal fa-globe';
    case 'WebVacci':
      return 'fal fa-yin-yang';
    case 'Cal':
    default:
      return 'fal fa-calendar';
  }
};

export const getLogIcon = (type) => {
  switch (type) {
    case 'Sms':
      return 'far fa-paper-plane';
    default:
      return 'far fa-comment-alt';
  }
};

export const CustomerFutureWrapper = styled.div`
  margin-bottom: 25px;
`;
