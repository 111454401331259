import moment from 'moment';
import { capitalize } from 'underscore.string';
import isString from 'lodash/isString';

export const formatDate = (value, format) => {
  if (value) {
    const date = moment(value);
    if (date.isSame('9999-01-01')) return 'tillsvidare';
    return capitalize(date.format(isString(format) ? format : 'LLLL'));
  }
};

export const getDayName = (day) => capitalize(moment(day === 7 ? 0 : day, 'd').format('dddd'));
export const getShortDayName = (day) => capitalize(moment(day === 7 ? 0 : day, 'd').format('ddd'));
