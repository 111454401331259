import { Map, fromJS } from 'immutable';
import { CLEAR_LOCATION_STATE } from '@State/account-actions';
import { SET_LOCATION_FEATURE } from '@State/features-actions';
import {
  ADMIN_CONFIG_LOADED, PARTNERS_LOADED, PARTNER_PREFS_UPDATED, PARTNER_CUSTOM_TEXTS_UPDATED
} from '@State/admin-config-actions';

export function adminConfigState(state = Map({ }), action = null) {
  switch (action.type) {
    case ADMIN_CONFIG_LOADED:
      return state.clear().merge(action.config);

    case SET_LOCATION_FEATURE:
      return state.setIn(['features', action.name], action.enabled);

    case CLEAR_LOCATION_STATE:
      return state.clear();

    default:
      return state;
  }
}

export function partnersById(state = Map(), action = null) {
  switch (action.type) {
    case PARTNERS_LOADED: {
      return state.clear().withMutations((map) => {
        for (const partner of action.partners) {
          if (partner.id) {
            map.set(partner.id, fromJS(partner));
          }
        }
      });
    }

    case PARTNER_PREFS_UPDATED:
      return state.setIn([parseInt(action.id), 'prefs'], fromJS(action.prefs));

    case PARTNER_CUSTOM_TEXTS_UPDATED:
      return state.setIn([parseInt(action.id), 'customTexts'], fromJS(action.customTexts));

    default:
      return state;
  }
}
