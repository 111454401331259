import React, { useState } from 'react';
import EventsModal from '@Components/dialogs/events-modal';
import Button from '@Components/ui/button';
import { txt } from '@Utils/i18n-util';
import msg from './history-button.msg';

const HistoryButton = ({ bookingId, customerId, white = false }) => {
  const [showHistory, setShowHistory] = useState(false);

  return (
    <>
      <Button gray={!white} white={white} small onClick={() => setShowHistory(true)}>{txt(msg.btnShowHistory)}</Button>
      {showHistory && (
        <EventsModal bookingId={bookingId} customerId={customerId} onClose={() => setShowHistory(false)} />
      )}
    </>
  );
};

export default HistoryButton;
