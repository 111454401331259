import * as R from 'ramda';
import { createSelector } from 'reselect';
import { resource as resourceKeys, web } from '@Utils/preference-keys';
import {
  prefixUrl, prefixOrgUrl, axiosGet, axiosPut, axiosPost,
  axiosDelete, axiosPatch
} from '@Utils/ajax-util';
import { getWebPaymentEnabled } from './selectors';
import { configChanged } from './account-actions';

export const PREFERENCES_LOADED = 'PREFERENCES_LOADED';
export const CHANGE_PREFERENCES = 'CHANGE_PREFERENCES';
export const CHANGE_CONTACT_INFO = 'CHANGE_CONTACT_INFO';
export const RESET_RESOURCE_PREFERENCES = 'RESET_RESOURCE_PREFERENCES';

export const getInitialValues = createSelector(
  state => state.preferencesViewState.get('jsonPreferences'),
  (state, keys) => keys,
  (preferences, keys) => {
    if (!preferences) {
      return null;
    }
    const values = {};
    Object.values(keys).forEach(key => {
      const value = preferences.get(key);
      if (typeof value !== 'undefined') {
        values[key] = value;
      }
    });
    return values;
  }
);

export const getInitialResourceValues = createSelector(
  state => state.preferencesViewState.get('resourcePreferences'),
  state => state.preferencesViewState.get('jsonPreferences'),
  (state, resource) => resource,
  getWebPaymentEnabled,
  (preferences, locationPrefs, { id, hashId, prefs, ...resourceValues }, webPaymentEnabled) => {
    const values = {
      ...resourceValues,
      useCustomValues: preferences?.get('useCustomValues') === true,
      color: preferences?.get('color')
    };
    resourceKeys.forEach(key => {
      const resourceValue = preferences && preferences.get(key);
      const defaultValue = locationPrefs && locationPrefs.get(key);
      values[key] = resourceValue !== undefined && resourceValue !== null ? resourceValue : defaultValue;
    });
    if (webPaymentEnabled && values[web.paymentEnabled] === undefined) {
      values[web.paymentEnabled] = true;
    }
    return values;
  }
);

export const getResourcePreference = (state, key) => {
  return state.preferencesViewState.getIn(['resourcePreferences', key]);
};

function notificationPreferencesSuccess(data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      notifications: data
    }
  };
}

function resourcePreferencesSuccess({ prefs, resource }, id) {
  return {
    type: CHANGE_PREFERENCES,
    resourceId: id,
    state: {
      resourcePreferences: prefs,
      resource
    }
  };
}

function resetResourcePreferences(resourceId) {
  return {
    type: RESET_RESOURCE_PREFERENCES,
    resourceId
  };
}

function customTextsPreferencesSuccess(data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      customTextsPreferences: data
    }
  };
}

function privatePreferencesSuccess(data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      privatePreferences: data
    }
  };
}

function templatesPreferencesSuccess(data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      templatesPreferences: data
    }
  };
}

function companyInfoFetched(data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      companyInfo: R.omit(['contacts'], data),
      contacts: data.contacts
    }
  };
}

function companyInfoSaved(orgLoc, data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      orgLoc,
      companyInfo: data
    }
  };
}

function contactInfoSaved(contactType, data) {
  return {
    type: CHANGE_CONTACT_INFO,
    contactType,
    contact: data
  };
}

function jsonPreferencesLoaded(data) {
  return {
    type: PREFERENCES_LOADED,
    state: {
      jsonPreferences: data
    }
  };
}

export function updateLocalPreferences(data) {
  return {
    type: CHANGE_PREFERENCES,
    state: {
      jsonPreferences: data
    }
  };
}

export function getJsonPreferences(ignoreCache = false) {
  return (dispatch, getState) => {
    const { preferencesViewState } = getState();
    if (!ignoreCache && preferencesViewState.get('jsonPreferencesLoaded')) {
      return Promise.resolve();
    }
    const url = prefixUrl('/preferences/');
    return dispatch(loadPreferences(url, jsonPreferencesLoaded));
  };
}

export function getNotificationPreferences() {
  const url = prefixUrl('/notifications/');
  return loadPreferences(url, notificationPreferencesSuccess);
}

export function saveNotificationPreferences(data) {
  const url = prefixUrl('/notifications/');
  return replacePreferences(url, data, notificationPreferencesSuccess);
}

export function getResourcePreferences(resourceId) {
  return (dispatch, getState) => {
    const { resourcesById } = getState();
    if (!resourceId || !resourcesById.get(resourceId)) {
      return;
    }
    const { prefs, ...resource } = resourcesById.get(resourceId);
    dispatch(resetResourcePreferences(resourceId));
    return dispatch(resourcePreferencesSuccess({ prefs, resource }, resourceId));
  };
}

export function mergeResourcePreferences(resourceId, data) {
  const url = prefixUrl(`/resources/${resourceId}/preferences`);
  return dispatch => dispatch(axiosPatch(url, data, {
    onSuccess: () => dispatch(resourcePreferencesSuccess({ prefs: data }, resourceId)),
    throwOnError: true
  }));
}

export function replaceResourcePreferences(resourceId, data) {
  const url = prefixUrl(`/resources/${resourceId}/preferences`);
  return dispatch => dispatch(axiosPut(url, data, {
    onSuccess: () => {
      dispatch(resetResourcePreferences(resourceId));
      dispatch(resourcePreferencesSuccess({ prefs: data }, resourceId));
    }
  }));
}

export function getTemplatesPreferences() {
  const url = prefixUrl('/preferences/templates');
  return loadPreferences(url, templatesPreferencesSuccess);
}

export function saveTemplatesPreferences(data) {
  const url = prefixUrl('/preferences/templates');
  return replacePreferences(url, data, templatesPreferencesSuccess);
}

export function getCustomTextsPreferences() {
  const url = prefixUrl('/preferences/custom-texts');
  return loadPreferences(url, customTextsPreferencesSuccess);
}

export function saveCustomTextsPreferences(data) {
  const url = prefixUrl('/preferences/custom-texts');
  return replacePreferences(url, data, customTextsPreferencesSuccess);
}

export function getPrivatePreferences() {
  const url = prefixUrl('/preferences/private-prefs');
  return loadPreferences(url, privatePreferencesSuccess);
}

export function savePrivatePreferences(data) {
  const url = prefixUrl('/preferences/private-prefs');
  return replacePreferences(url, data, privatePreferencesSuccess);
}

function loadPreferences(url, callback, id) {
  return dispatch => dispatch(axiosGet(url, {
    onSuccess: ({ data }) => dispatch(callback(data, id))
  }));
}

function replacePreferences(url, data, callback) {
  return dispatch => dispatch(axiosPut(url, data, {
    onSuccess: () => dispatch(callback(data))
  }));
}

export function mergeJsonPreferences(data) {
  const url = prefixUrl('/preferences/');
  return dispatch => dispatch(axiosPatch(url, data, {
    onSuccess: () => dispatch(updateLocalPreferences(data))
  }));
}

export function replaceJsonPreferences(data) {
  const url = prefixUrl('/preferences/');
  return dispatch => dispatch(axiosPut(url, data, {
    onSuccess: () => dispatch(updateLocalPreferences(data))
  }));
}

export function fetchCompanyInfo() {
  const url = prefixOrgUrl('/company-info/');
  return dispatch => dispatch(axiosGet(url, {
    onSuccess: ({ data }) => dispatch(companyInfoFetched(data))
  }));
}

export function saveCompanyInfo(orgLoc, data) {
  const url = prefixOrgUrl('/company-info/');
  return dispatch => dispatch(axiosPut(url, data, {
    onSuccess: () => dispatch(companyInfoSaved(orgLoc, data))
  }));
}

export function saveContactInfo(contactType, data) {
  const url = prefixOrgUrl(`/contacts/${contactType}`);
  return dispatch => dispatch(axiosPut(url, data, {
    onSuccess: () => dispatch(contactInfoSaved(contactType, data))
  }));
}

export function deleteContactInfo(contactType) {
  const url = prefixOrgUrl(`/contacts/${contactType}`);
  return dispatch => dispatch(axiosDelete(url, {
    onSuccess: () => dispatch(contactInfoSaved(contactType, null))
  }));
}

const businessDataUrl = (path) => prefixUrl(`/business-data/admin${path}`);

export function validateLocationSlug(slug) {
  return axiosGet(businessDataUrl(`/slug?slug=${slug}`));
}

export function setLocationSlug(slug) {
  const url = businessDataUrl('/slug');
  return dispatch => dispatch(axiosPut(url, { slug }, {
    onSuccess: () => dispatch(configChanged({ slug }))
  }));
}

export function toggleSearchEngine(searchEngineEnabled) {
  const url = businessDataUrl('/publish');
  return dispatch => dispatch(axiosPost(url, { searchEngineEnabled }, {
    onSuccess: () => dispatch(configChanged({ searchEngineEnabled }))
  }));
}

export function updateContactDetails(values) {
  const url = businessDataUrl('/contact-details');
  return dispatch => dispatch(axiosPut(url, values, {
    onSuccess: () => dispatch(updateLocalPreferences(values))
  }));
}
