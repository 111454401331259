import React from 'react';
import { useSelector } from 'react-redux';
import { txt } from '@Utils/i18n-util';
import { pos } from '@Utils/preference-keys';
import { getShowGiftCardInfo } from '@State/pos-selectors';
import { getOtherPaymentMethodName, OtherPaymentMethods, PaymentModal } from '@Utils/pos-utils';
import msg from './pos-payment.msg';

const PosPaymentButtons = ({
  disabled, showModal, posOrgPrefs, remainingAmount, enableGiftCardPayment, partialPaid
}) => {
  const showGiftCardInfo = useSelector(getShowGiftCardInfo);

  const noAmount = remainingAmount === 0;
  const invoiceUseFortnox = posOrgPrefs[pos.invoiceUseFortnox];

  return (
    <div className="pos-payment">
      {posOrgPrefs[pos.paymentMethodGiftCard] && (
        <button
          disabled={disabled || !enableGiftCardPayment}
          onClick={() => showModal(PaymentModal.GiftCard)}
          className={showGiftCardInfo ? 'highlight-success' : ''}
        >
          <i className="far fa-gift-card" />
          {txt(msg.GiftCard)}
        </button>
      )}
      {OtherPaymentMethods.map(number => posOrgPrefs[pos.paymentMethodOther + number] && (
        <button key={number} disabled={disabled} onClick={() => showModal(PaymentModal.Other, number)}>
          {getOtherPaymentMethodName(posOrgPrefs, number)}
        </button>
      ))}
      {!invoiceUseFortnox && posOrgPrefs[pos.paymentMethodInvoice] && (
        <button disabled={disabled || noAmount || partialPaid} onClick={() => showModal(PaymentModal.Invoice)}>
          <i className="far fa-file-alt" />
          {txt(msg.Invoice)}
        </button>
      )}
      {invoiceUseFortnox && posOrgPrefs[pos.paymentMethodFortnoxInvoice] && (
        <button disabled={disabled || noAmount || partialPaid} onClick={() => showModal(PaymentModal.Fortnox)}>
          <i className="far fa-file-alt" />
          {txt(msg.Invoice)}
        </button>
      )}
      {posOrgPrefs[pos.paymentMethodKlarna] && (
        <button disabled={disabled || noAmount} onClick={() => showModal(PaymentModal.Klarna)}>
          <img src="/klarna.svg" />
          {txt(msg.Klarna)}
        </button>
      )}
      {posOrgPrefs[pos.paymentMethodSwish] && (
        <button disabled={disabled || noAmount} onClick={() => showModal(PaymentModal.Swish)}>
          <img src="/swish.svg" />
          {txt(msg.Swish)}
        </button>
      )}
      {posOrgPrefs[pos.paymentMethodCash] && (
        <button disabled={disabled} onClick={() => showModal(PaymentModal.Cash)}>
          <i className="far fa-coins" />
          {txt(msg.Cash)}
        </button>
      )}
      {posOrgPrefs[pos.paymentMethodCard] && (
        <button disabled={disabled || noAmount} onClick={() => showModal(PaymentModal.Card)}>
          <i className="far fa-credit-card" />
          {txt(msg.Card)}
        </button>
      )}
    </div>
  );
};

export default PosPaymentButtons;
