import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { formatOrgNo } from '@Utils/luhn-util';
import PosOrgSettings from '@Components/admin/pos-config/pos-org-settings';
import JsonEditor from '@Components/ui/jsoneditor/jsoneditor';

import {
  updateOrg, fetchPosOrgs, fetchPosUnitsByOrgId, refreshTerminals, fetchTerminalRefData,
  replaceOrgSettings, updateOrgContacts, fetchContactsByOrgId, saveContactInfo, deleteContactInfo,
  deletePosOrg
} from '@State/pos-config-actions';
import { fetchUsers, fetchUserPosRoles } from '@State/users-actions';
import { navigate, getPosOrgUrl } from '@Utils/navigate';
import { getSysAdmin } from '@State/selectors';
import Loader from '@Components/ui/loader';
import PosOrgTerminals from '@Components/admin/pos-config/pos-org-terminals';
import PosOrgPrinters from '@Components/admin/pos-config/pos-org-printers';
import PosOrgPayment from '@Components/admin/pos-config/pos-org-payment';
import PosOrgAccounting from '@Components/admin/pos-config/pos-org-accounting';
import PosUserRoles from '@Components/admin/pos-config/pos-user-roles';
import PosOrgEditModal from '@Components/admin/pos-config/pos-org-edit-modal';
import PosOrgContactsModal from '@Components/admin/pos-config/pos-org-contacts-modal';
import { classes } from '@Components/ui/utils';
import PosConfigSubNav from '@Components/admin/pos-config/pos-config-subnav';

class PosOrgs extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      editOrg: false,
      editContacts: false
    };
  }

  showEditForm = () => this.setState({ editOrg: true });

  showContactsForm = () => this.setState({ editContacts: true });

  hideEditForm = (ev) => {
    ev && ev.preventDefault();
    this.setState({ editOrg: false });
  };

  hideContactsForm = (ev) => {
    ev && ev.preventDefault();
    this.setState({ editContacts: false });
  };

  componentDidMount() {
    this.props.loadData()
      .then(() => this.setState({ loading: false }));

    if (this.props.posOrgId) {
      this.props.fetchContactsByOrgId(this.props.posOrgId);
      this.props.fetchPosUnitsByOrgId(this.props.posOrgId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.posOrgId && nextProps.posOrgId !== this.props.posOrgId) {
      this.props.fetchContactsByOrgId(nextProps.posOrgId);
      this.props.fetchPosUnitsByOrgId(nextProps.posOrgId);

      // If prefs are missing this is a new pos org, refresh list
      const { posOrg } = nextProps;
      if (posOrg && !posOrg.get('prefs')) {
        this.props.fetchPosOrgs();
      }
    }
  }

  updateOrg = values => {
    return this.props.updateOrg(values, values.id)
      .then(() => this.hideEditForm());
  };

  updateContacts = values => {
    return this.props.updateContacts(this.props.posOrgId, values)
      .then(() => this.hideContactsForm());
  };

  handleDeleteOrg = (orgId) => {
    const { routeParams } = this.props;
    return this.props.deletePosOrg(orgId)
      .then(() => navigate(getPosOrgUrl(routeParams)));
  };

  onItemClick = id => {
    const { routeParams } = this.props;
    navigate(getPosOrgUrl(routeParams, id, routeParams.tab));
  };

  getContactsInitialValues = () => {
    const { posContacts } = this.props;
    const owner = posContacts?.get('Owner');
    const billing = posContacts?.get('Billing');

    return {
      ownerName: owner?.get('name'),
      ownerEmail: owner?.get('email'),
      ownerPhone: owner?.get('phone'),
      enableBilling: !!billing,
      billingName: billing?.get('name'),
      billingEmail: billing?.get('email'),
      billingPhone: billing?.get('phone')
    };
  };

  renderOrgsList = (org) => {
    const { posOrgId } = this.props;
    const { id, companyName, companyOrgNo } = org.toJS();
    const idOrg = parseInt(id);
    const isSelected = idOrg === posOrgId;
    const onClick = () => this.onItemClick(id);
    const classList = classes({
      'columns-list-item': true,
      selected: isSelected
    });

    return (
      <div className={classList} key={idOrg} onClick={onClick}>
        <div className="list-item-header">{companyName}</div>
        <div className="list-item-subtext">{formatOrgNo(companyOrgNo)}</div>
      </div>
    );
  };

  handleSavePrefs = (prefs) => {
    const { replaceOrgSettings, posOrgId } = this.props;
    return replaceOrgSettings(posOrgId, prefs);
  };

  renderTabBody = () => {
    const { editOrg, editContacts } = this.state;
    const { routeParams, posOrg, posContacts, posUnits } = this.props;

    switch (routeParams.tab) {
      case 'terminal':
        return (
          <>
            <PosOrgPrinters posOrg={posOrg} />
            <PosOrgTerminals />
          </>
        );

      case 'users':
        return posOrg && <PosUserRoles posOrg={posOrg} />;

      case 'payment':
        return posOrg && <PosOrgPayment posOrg={posOrg} />;

      case 'accounting':
        return posOrg && <PosOrgAccounting posOrg={posOrg} />;

      case 'prefs':
        return (
          <JsonEditor
            title="Org prefs"
            json={posOrg.get('prefs')?.toJS() || {}}
            onSubmit={this.handleSavePrefs}
          />
        );

      default:
        return (
          <>
            <PosOrgSettings
              onShowEditOrg={this.showEditForm}
              onShowEditContacts={this.showContactsForm}
              onDeleteOrg={this.handleDeleteOrg}
              posOrg={posOrg}
              posContacts={posContacts}
              routeParams={routeParams}
              posUnits={posUnits}
            />
            {editOrg && (
              <PosOrgEditModal
                onSubmit={this.updateOrg}
                onClose={this.hideEditForm}
                initialValues={posOrg.toJS()}
              />
            )}
            {editContacts && (
              <PosOrgContactsModal
                onSubmit={this.updateContacts}
                onClose={this.hideContactsForm}
                initialValues={this.getContactsInitialValues()}
              />
            )}
          </>
        );
    }
  };

  render() {
    const {
      posOrgs, posOrg, posOrgId, isSysAdmin, enablePos, routeParams
    } = this.props;
    const { loading } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (!posOrgId && !posOrgs.isEmpty()) {
      return (
        <Redirect
          to={getPosOrgUrl(routeParams, posOrgs.sortBy(o => o.get('companyName')).first().get('id'))}
        />
      );
    }

    return (
      <div className="columns-container">
        <div className="columns-sidebar">
          <div className="columns-header">
            <h1>Företag</h1>
          </div>
          <div className="columns-list">
            {posOrgs.sortBy(o => o.get('companyName')).map(org => this.renderOrgsList(org))}
          </div>
        </div>
        <div className="columns-content">
          <div className="columns-content-container">
            <div className="columns-content-body">
              <div className="columns-content-header">
                <h3>{posOrg?.get('companyName')}</h3>
              </div>
              <PosConfigSubNav routeParams={routeParams} isSysAdmin={isSysAdmin} enablePos={enablePos} />
              <div className="with-padding">
                {posOrg && this.renderTabBody()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    posOrgs, posContacts, posUnits, allTerminals, printers, locationFeatures
  } = state;

  const posOrgId = parseInt(props.match.params.id);
  const posOrg = posOrgId && posOrgs.find(org => org.get('id') === posOrgId);
  const isSysAdmin = getSysAdmin(state);
  const routeParams = {
    ...props.match.params,
    entityId: posOrgId
  };

  return {
    routeParams,
    isSysAdmin,
    posOrgs,
    posOrg,
    posContacts,
    posUnits,
    allTerminals,
    printers,
    posOrgId,
    enablePos: locationFeatures.get('EnablePOS')
  };
};

const mapDispatchToProps = dispatch => ({
  loadData: () => Promise.all([
    dispatch(fetchUsers()),
    dispatch(fetchPosOrgs()),
    dispatch(fetchUserPosRoles()),
    dispatch(fetchTerminalRefData())
  ]),
  fetchPosOrgs: () => dispatch(fetchPosOrgs()),
  updateOrg: (values, id) => dispatch(updateOrg(values, id)),
  deletePosOrg: (id) => dispatch(deletePosOrg(id)),
  replaceOrgSettings: (id, settings) => dispatch(replaceOrgSettings(id, settings)),
  updateContacts: (id, contacts) => dispatch(updateOrgContacts(id, contacts)),
  fetchContactsByOrgId: id => dispatch(fetchContactsByOrgId(id)),
  fetchPosUnitsByOrgId: id => dispatch(fetchPosUnitsByOrgId(id)),
  fetchTerminalRefData: () => dispatch(fetchTerminalRefData()),
  refreshTerminals: () => dispatch(refreshTerminals()),
  saveContactInfo: (id, contactType, data) => {
    if (data.useContactInfo === 'true') {
      return dispatch(saveContactInfo(id, contactType, data));
    }
    return dispatch(deleteContactInfo(id, contactType));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PosOrgs);
