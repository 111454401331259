import { defineMessages } from 'react-intl';

export default defineMessages({
  GiftCard: {
    id: 'pos.payment.GiftCard',
    defaultMessage: 'Voucher'
  },
  Klarna: {
    id: 'pos.payment.Klarna',
    defaultMessage: 'Klarna'
  },
  Swish: {
    id: 'pos.payment.Swish',
    defaultMessage: 'Swish'
  },
  Cash: {
    id: 'pos.payment.Cash',
    defaultMessage: 'Cash'
  },
  Card: {
    id: 'pos.payment.Card',
    defaultMessage: 'Card'
  },
  Invoice: {
    id: 'pos.payment.Invoice',
    defaultMessage: 'Invoice'
  },
  lblToPay: {
    id: 'pos.payment.lblToPay',
    defaultMessage: 'To pay {amount}'
  },
  lblChange: {
    id: 'pos.payment.lblChange',
    defaultMessage: 'Change {amount}'
  },
  lblProcessingPayment: {
    id: 'pos.payment.lblProcessingPayment',
    defaultMessage: 'Processing payment...'
  },
  lblClose: {
    id: 'pos.payment.lblClose',
    defaultMessage: 'Close'
  },
  lblSend: {
    id: 'pos.payment.lblSend',
    defaultMessage: 'Send'
  },
  lblCancel: {
    id: 'pos.payment.lblCancel',
    defaultMessage: 'Cancel'
  },
  lblPrint: {
    id: 'pos.payment.lblPrint',
    defaultMessage: 'Print'
  },
  lblThrow: {
    id: 'pos.payment.lblThrow',
    defaultMessage: 'Throw receipt'
  },
  lblSms: {
    id: 'pos.payment.lblSms',
    defaultMessage: 'SMS'
  },
  lblEmail: {
    id: 'pos.payment.lblEmail',
    defaultMessage: 'Email'
  },
  lblSmsNotAvailable: {
    id: 'pos.payment.lblSmsNotAvailable',
    defaultMessage: 'SMS not available'
  },
  lblPrintNotAvailable: {
    id: 'pos.payment.lblPrintNotAvailable',
    defaultMessage: 'Print not available'
  },
  lblPaymentCompleted: {
    id: 'pos.payment.lblPaymentCompleted',
    defaultMessage: 'Payment completed'
  },
  lblRefundCompleted: {
    id: 'pos.payment.lblRefundCompleted',
    defaultMessage: 'Refund completed'
  },
  lblPaid: {
    id: 'pos.payment.lblPaid',
    defaultMessage: 'Paid'
  },
  lblRefunded: {
    id: 'pos.payment.lblRefunded',
    defaultMessage: 'Refunded'
  },
  lblTip: {
    id: 'pos.payment.lblTip',
    defaultMessage: 'Tip'
  },
  lblReceiptHeading: {
    id: 'pos.payment.lblReceiptHeading',
    defaultMessage: 'Receipt?'
  },
  lblPrinting: {
    id: 'pos.payment.lblPrinting',
    defaultMessage: 'Printing receipt...'
  },
  lblEmailReceipt: {
    id: 'pos.payment.lblEmailReceipt',
    defaultMessage: 'Email receipt'
  },
  lblSmsReceipt: {
    id: 'pos.payment.lblSmsReceipt',
    defaultMessage: 'SMS receipt'
  },
  lblEmailAddress: {
    id: 'pos.payment.lblEmailAddress',
    defaultMessage: 'Email address'
  },
  lblMobilePhone: {
    id: 'pos.payment.lblMobilePhone',
    defaultMessage: 'Mobile phone'
  }
});
