import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { changeCustomerBookingFlag, changeCustomerBookingStatus } from '@State/customer-actions';
import { txt } from '@Utils/i18n-util';
import msg from './booking-labels.msg';

const BookingLabelsPanel = ({
  customerId, id, status, attributes
}) => {
  const dispatch = useDispatch();
  const customerIds = [customerId];
  const { dropIn, askedForPerson } = attributes || {};

  const handleDropInClick = () => {
    const newAttributes = { ...attributes, dropIn: !dropIn };
    dispatch(changeCustomerBookingFlag({
      id, attributes: newAttributes, customerIds, prevState: { id, attributes }
    }));
  };
  const handleAFPClick = () => {
    const newAttributes = { ...attributes, askedForPerson: !askedForPerson };
    dispatch(changeCustomerBookingFlag({
      id, attributes: newAttributes, customerIds, prevState: { id, attributes }
    }));
  };
  const handleShowClick = () => {
    const newStatus = status === 'Show' ? 'Booked' : 'Show';
    dispatch(changeCustomerBookingStatus({
      id, status: newStatus, customerIds, prevState: { id, status }
    }));
  };
  const handleNoShowClick = () => {
    const newStatus = status === 'NoShow' ? 'Booked' : 'NoShow';
    dispatch(changeCustomerBookingStatus({
      id, status: newStatus, customerIds, prevState: { id, status }
    }));
  };

  const baseClasses = 'booking-label';
  const askedForClasses = baseClasses + (askedForPerson ? ' active' : '');
  const dropInClasses = baseClasses + (dropIn ? ' active' : '');
  const showClasses = baseClasses + (status === 'Show' ? ' active' : '');
  const noShowClasses = baseClasses + (status === 'NoShow' ? ' active' : '');

  return (
    <div className="booking-status-small-wrapper booking-labels-wrapper">
      <span className={showClasses} onClick={handleShowClick}>
        <span className="label-circle label-status" />
        {txt(msg.show)}
      </span>
      <span className={noShowClasses} onClick={handleNoShowClick}>
        <span className="label-circle label-status-no-show" />
        {txt(msg.noShow)}
      </span>
      <span className={askedForClasses} onClick={handleAFPClick}>
        <span className="label-circle label-asked-for-person" />
        {txt(msg.askedForPerson)}
      </span>
      <span className={dropInClasses} onClick={handleDropInClick}>
        <span className="label-circle label-drop-in" />
        {txt(msg.dropIn)}
      </span>
    </div>
  );
};

export default memo(BookingLabelsPanel);
