import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveTemplatesPreferences } from '@State/preferences-actions';
import JsonEditor from '@Components/ui/jsoneditor/jsoneditor';
import Loader from '@Components/ui/loader';

const AdminConfigTemplates = () => {
  const dispatch = useDispatch();
  const templatesPreferences = useSelector(state => state.preferencesViewState.get('templatesPreferences'));
  if (!templatesPreferences) {
    return (<Loader />);
  }

  return (
    <JsonEditor
      title="Templates"
      json={templatesPreferences}
      onSubmit={(values) => dispatch(saveTemplatesPreferences(values))}
    />
  );
};

export default AdminConfigTemplates;
