import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { isBookingCancelled } from '@Utils/booking-util';
import { setCustomerFormState } from '@State/cf-actions';
import { fetchVehicleInfo } from '@State/bkf/actions';
import { txt } from '@Utils/i18n-util';
import { styleHiddenInput } from './style';
import msg from './booking-vehicle.msg';

class BookingVehicle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editVehicle: false,
      searchError: false
    };
  }

  handleClick = (ev) => {
    ev.preventDefault();

    const hidden = ReactDOM.findDOMNode(this.hidden);
    hidden.style.visibility = 'visible';
    hidden.focus();
    hidden.style.visibility = 'hidden';

    this.setState({ editVehicle: true }, () => {
      const vehicleRegNo = ReactDOM.findDOMNode(this.vehicleRegNo);
      vehicleRegNo.focus();
    });
  };

  handleSearch = (ev) => {
    ev.preventDefault();
    this.props.onSearchStart();
  };

  handleClose = (ev) => {
    ev.preventDefault();
    this.setState({ editVehicle: false });
  };

  handleClear = (ev) => {
    ev.preventDefault();
    this.props.onClearVehicle();
  };

  handleOpen = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.props.onOpenCustomer(this.props.vehicle);
  };

  handleRefresh = (ev) => {
    ev.preventDefault();
    const { officialIdNo, customerId } = this.props;
    this.props.fetchVehicleInfo(officialIdNo, customerId)
      .then(() => this.handleClose(ev))
      .catch(() => {
        this.setState({ searchError: true }, () => {
          setTimeout(() => this.setState({ searchError: false }), 1500);
        });
      });
  };

  renderHiddenInput() {
    return <input ref={(ref) => { this.hidden = ref; }} style={styleHiddenInput} />;
  }

  normalize = value => value && value.toUpperCase();

  render() {
    const { editVehicle, searchError } = this.state;
    const {
      vehicle, customerId, officialIdNo, name, enableVehicleRegistrySearch
    } = this.props;
    const clickMethod = customerId ? this.handleOpen : this.handleClick;
    const showVehicleSearch = enableVehicleRegistrySearch && officialIdNo;
    const isCancelled = isBookingCancelled(this.props);
    const isDeleted = vehicle && vehicle.deleted;

    if (isCancelled && !vehicle) {
      return null;
    }

    if (editVehicle) {
      return (
        <div className="booking-form-block form">
          <div className="row tight">
            <div className="form-group col-xs-12">
              <label className="control-label">{txt(msg.lblVehicleRegNo)}</label>
              <Field autoComplete="off" name="officialIdNo" component="input" type="text" className="form-control" normalize={this.normalize} ref={(ref) => { this.vehicleRegNo = ref; }} />
            </div>
          </div>
          <div className="row tight">
            <div className="form-group col-xs-12">
              <label className="control-label">{txt(msg.lblVehicleDescription)}</label>
              <Field autoComplete="off" name="name" component="input" type="text" className="form-control" />
            </div>
          </div>
          {showVehicleSearch && (
            <div className="pull-left">
              <button className={searchError ? 'btn-label error' : 'btn-label'} tabIndex={-1} onClick={this.handleRefresh}>
                {searchError ? txt(msg.lblNoVehicleFound) : txt(msg.lblSearchVehicle)}
              </button>
            </div>
          )}
          <div className="text-right">
            <button className="btn-label" tabIndex={-1} onClick={this.handleClose}>{txt(msg.btnClose)}</button>
          </div>
        </div>
      );
    }

    if (!vehicle) {
      return (
        <button className="booking-form-block button" onClick={this.handleSearch}>
          <i className="fa fa-car" /> {txt(msg.btnAddVehicle)}
          {this.renderHiddenInput()}
        </button>
      );
    }

    return (
      <div className={isDeleted ? 'booking-form-block cancelled' : 'booking-form-block'} onClick={!isDeleted ? clickMethod : null}>
        <div className="block-buttons" onClick={ev => ev.stopPropagation()}>
          {!isDeleted && <div><button className="btn-label" tabIndex={-1} onClick={this.handleOpen}>{txt(msg.btnOpen)}</button></div>}
          {!isCancelled && <button className="btn-label" tabIndex={-1} onClick={this.handleClear}>{txt(msg.btnClear)}</button>}
        </div>
        <h4>
          <i className="fa fa-car" /> {officialIdNo}
        </h4>
        {name}
        {this.renderHiddenInput()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf, locationFeatures } = state;
  const vehicle = bkf.get('vehicle');

  return {
    vehicle,
    initialValues: vehicle,
    status: bkf.get('status'),
    ...getFormValues('bkf-vehicle')(state),
    enableVehicleRegistrySearch: locationFeatures.get('EnableVehicleRegistrySearch')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchVehicleInfo: (regNo, customerId) => dispatch(fetchVehicleInfo(regNo, customerId)),
    onOpenCustomer: (customer) => {
      dispatch(setCustomerFormState({
        formVisible: true,
        customer
      }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'bkf-vehicle',
  destroyOnUnmount: false
})(BookingVehicle));
