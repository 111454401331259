import { isDevelopment } from "./config";
;
const defaultOptions = {
    followSwipeThreshold: 0,
    fastSwipeSpeed: 200,
    debug: isDevelopment()
};
function isFunction(action) {
    return typeof action === 'function';
}
class Swipe {
    constructor(element, options) {
        this.enable = () => {
            this.element.addEventListener('touchstart', this.startTouch, { passive: true });
            this.element.addEventListener('touchmove', this.moveTouch, { passive: true });
            this.element.addEventListener('touchend', this.endTouch, { passive: true });
            this.element.addEventListener('touchcancel', this.endTouch, { passive: true });
        };
        this.disable = () => {
            this.element.removeEventListener('touchstart', this.startTouch);
            this.element.removeEventListener('touchmove', this.moveTouch);
            this.element.removeEventListener('touchend', this.endTouch);
            this.element.removeEventListener('touchcancel', this.endTouch);
        };
        this.debugLog = (message, data) => {
            if (this.options.debug) {
                console.log(message, data);
            }
        };
        this.getThresholds = (element) => {
            const clientSize = this.isHorizontal
                ? element.clientWidth
                : element.clientHeight;
            return {
                slowSwipeThreshold: clientSize * 0.5,
                fastSwipeThreshold: clientSize * 0.1
            };
        };
        this.isActualHorizontal = () => {
            const diffX = this.touchEndX - this.touchStartX;
            const diffY = this.touchEndY - this.touchStartY;
            return Math.abs(diffX) > Math.abs(diffY);
        };
        this.getTimeDiff = () => {
            return performance.now() - this.touchStartTime;
        };
        this.getDistanceDiff = () => {
            return this.isHorizontal
                ? this.touchEndX - this.touchStartX
                : this.touchEndY - this.touchStartY;
        };
        this.isScrollable = (target) => {
            const hasScrollableContent = target.scrollHeight > target.clientHeight;
            const style = window.getComputedStyle(target);
            const overflow = this.isHorizontal ? style.overflowX : style.overflowY;
            return hasScrollableContent && ['auto', 'scroll'].includes(overflow);
        };
        this.hasScrollableParent = (event) => {
            let target = event.target;
            while (target && target !== this.element) {
                if (this.isScrollable(target)) {
                    const { onSwipeDown, onSwipeUp } = this.options;
                    const { scrollTop, scrollHeight, clientHeight } = target;
                    const atTop = scrollTop === 0;
                    const atBottom = scrollHeight - scrollTop === clientHeight;
                    if (isFunction(onSwipeDown) && !atTop) {
                        return true;
                    }
                    if (isFunction(onSwipeUp) && !atBottom) {
                        return true;
                    }
                }
                target = target.parentElement;
            }
            return false;
        };
        this.getTranslateX = () => {
            const { followSwipeThreshold, onSwipeLeft, onSwipeRight } = this.options;
            const diffX = this.getDistanceDiff();
            if (Math.abs(diffX) < followSwipeThreshold) {
                return 0;
            }
            if (isFunction(onSwipeLeft) && !isFunction(onSwipeRight)) {
                return Math.min(diffX, 0);
            }
            if (isFunction(onSwipeRight) && !isFunction(onSwipeLeft)) {
                return Math.max(diffX, 0);
            }
            return diffX;
        };
        this.getTranslateY = () => {
            const { followSwipeThreshold, onSwipeUp, onSwipeDown } = this.options;
            const diffY = this.getDistanceDiff();
            if (Math.abs(diffY) < followSwipeThreshold) {
                return 0;
            }
            if (isFunction(onSwipeUp) && !isFunction(onSwipeDown)) {
                return Math.min(diffY, 0);
            }
            if (isFunction(onSwipeDown) && !isFunction(onSwipeUp)) {
                return Math.max(diffY, 0);
            }
            return diffY;
        };
        this.setElementStyle = () => {
            const diff = this.isHorizontal ? this.getTranslateX() : this.getTranslateY();
            if (Math.abs(diff) > this.options.followSwipeThreshold) {
                this.element.style.setProperty('transition', 'none');
                this.element.style.setProperty('transform', this.isHorizontal
                    ? `translateX(${diff}px)`
                    : `translateY(${diff}px)`);
            }
            else {
                this.resetElementStyle();
            }
        };
        this.resetElementStyle = () => {
            if (!this.element || !this.element.style.transform) {
                return;
            }
            this.element.style.removeProperty('transition');
            this.element.style.removeProperty('transform');
        };
        this.returnToInitialPosition = () => {
            if (!this.element || !this.element.style.transform) {
                return;
            }
            this.element.style.setProperty('transition', `transform ${this.animationTime}ms`);
            this.element.style.setProperty('transform', 'translate(0)');
            setTimeout(() => this.resetElementStyle(), this.animationTime);
        };
        this.executeSwipeAction = (action) => {
            if (isFunction(action)) {
                action();
            }
        };
        this.completeSwipeAndExecuteAction = () => {
            const { onSwipeDown, onSwipeUp, onSwipeLeft, onSwipeRight } = this.options;
            const distanceDiff = this.getDistanceDiff();
            this.element.style.setProperty('transition', `transform ${this.animationTime}ms`);
            this.element.style.setProperty('transform', this.isHorizontal
                ? `translateX(${distanceDiff < 0 ? -100 : 100}%)`
                : `translateY(${distanceDiff < 0 ? -100 : 100}%)`);
            setTimeout(() => {
                this.resetElementStyle();
                if (this.isHorizontal) {
                    this.executeSwipeAction(distanceDiff > 0 ? onSwipeRight : onSwipeLeft);
                }
                else {
                    this.executeSwipeAction(distanceDiff > 0 ? onSwipeDown : onSwipeUp);
                }
            }, this.animationTime);
        };
        this.hasMethodForCurrentSwipe = (distanceDiff) => {
            const { onSwipeDown, onSwipeUp, onSwipeLeft, onSwipeRight } = this.options;
            return this.isHorizontal
                ? (distanceDiff > 0 ? isFunction(onSwipeRight) : isFunction(onSwipeLeft))
                : (distanceDiff > 0 ? isFunction(onSwipeDown) : isFunction(onSwipeUp));
        };
        this.swipeThresholdReached = () => {
            const { fastSwipeSpeed, slowSwipeThreshold, fastSwipeThreshold } = this.options;
            const timeDiff = this.getTimeDiff();
            const distanceDiff = this.getDistanceDiff();
            const absDistanceDiff = Math.abs(distanceDiff);
            const isFastSwipe = timeDiff < fastSwipeSpeed;
            if (!this.hasMethodForCurrentSwipe(distanceDiff)) {
                return false;
            }
            this.debugLog('Swipe threshold check', { isFastSwipe, absDistanceDiff });
            return (absDistanceDiff > slowSwipeThreshold)
                || (isFastSwipe && absDistanceDiff > fastSwipeThreshold);
        };
        this.startTouch = (event) => {
            if (this.hasScrollableParent(event)) {
                this.debugLog('Scrollable parent found, ignoring swipe');
                return;
            }
            this.touchActive = true;
            this.touchStartTime = performance.now();
            this.touchStartX = this.touchEndX = event.targetTouches[0].clientX;
            this.touchStartY = this.touchEndY = event.targetTouches[0].clientY;
        };
        this.moveTouch = (event) => {
            if (!this.touchActive || window.draggingActive) {
                return;
            }
            this.touchEndX = event.targetTouches[0].clientX;
            this.touchEndY = event.targetTouches[0].clientY;
            this.setElementStyle();
        };
        this.endTouch = () => {
            if (!this.touchActive || window.draggingActive) {
                return;
            }
            try {
                if (this.swipeThresholdReached()) {
                    this.completeSwipeAndExecuteAction();
                }
                else if (this.getDistanceDiff() !== 0) {
                    this.returnToInitialPosition();
                }
                else {
                    this.resetElementStyle();
                }
            }
            catch (error) {
                console.error('Error in endTouch', error);
                window.Sentry.captureException(error);
            }
            this.touchActive = false;
        };
        this.element = element;
        const { onSwipeLeft, onSwipeRight } = options;
        this.isHorizontal = isFunction(onSwipeLeft) || isFunction(onSwipeRight);
        this.animationTime = this.isHorizontal ? 150 : 250;
        const thresholds = this.getThresholds(element);
        this.options = Object.assign(Object.assign(Object.assign({}, defaultOptions), thresholds), options);
        this.debugLog('Swipe initialized', this.options);
    }
}
export default Swipe;
