import { defineMessages } from 'react-intl';

export default defineMessages({
  lblPartialPaid: {
    id: 'pos.notification.lblPartialPaid',
    defaultMessage: 'Payment started, changes not possible'
  },
  lblEnterPinCode: {
    id: 'pos.login.lblEnterPinCode',
    defaultMessage: 'Enter PIN'
  },
  lblLoggingIn: {
    id: 'pos.login.lblLoggingIn',
    defaultMessage: 'Logging in...'
  },
  lblSelectCashier: {
    id: 'pos.login.lblSelectCashier',
    defaultMessage: 'Select cashier'
  },
  lblSelectPosUnit: {
    id: 'pos.login.lblSelectPosUnit',
    defaultMessage: 'Select POS'
  },
  lblOpen: {
    id: 'pos.login.lblOpen',
    defaultMessage: 'Open'
  },
  lblOpenOtherDevice: {
    id: 'pos.login.lblOpenOtherDevice',
    defaultMessage: 'Busy'
  },
  lblClosed: {
    id: 'pos.login.lblClosed',
    defaultMessage: 'Closed'
  },
  lblUnauthorized: {
    id: 'pos.login.lblUnauthorized',
    defaultMessage: 'Unauthorized'
  },
  lblUnauthorizedText: {
    id: 'pos.login.lblUnauthorizedText',
    defaultMessage: 'You are not authorized to use the POS'
  },
  lblPosClosed: {
    id: 'pos.dialog.lblPosClosed',
    defaultMessage: 'POS is closed'
  },
  btnOpenPos: {
    id: 'pos.dialog.btnOpenPos',
    defaultMessage: 'Open POS'
  },
  txtOpenOtherDevice: {
    id: 'pos.dialog.txtOpenOtherDevice',
    defaultMessage: 'POS is being used on another device'
  },
  txtPaymentInProgress: {
    id: 'pos.dialog.txtPaymentInProgress',
    defaultMessage: 'Payment in progress! Wait until the purchase is complete and try again.'
  },
  btnUseThisDevice: {
    id: 'pos.dialog.btnUseThisDevice',
    defaultMessage: 'Use on this device'
  }
});
