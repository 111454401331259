import React from 'react';
import groupBy from 'lodash/groupBy';
import { reportingFormat } from '@Utils/format';
import { getBookingReportColumns } from '@Components/reports/reports-helpers';

const BookingReportServices = ({ services }) => {
  const servicesByGroup = groupBy(services, r => r.group || 'Övriga');
  const serviceGroupNames = Object.keys(servicesByGroup).sort();

  return (
    <>
      {serviceGroupNames.map((groupName) => {
        const services = servicesByGroup[groupName];
        const groupColumns = getBookingReportColumns(services);
        const servicesByName = groupBy(services, r => [r.name, r.price]);
        const serviceNames = Object.keys(servicesByName).sort();

        return (
          <table key={groupName} className="table table-condensed table-report">
            <thead>
              <tr>
                <th className="col-xs-3">{groupName}</th>
                <th className="col-xs-1 text-right">Pris</th>
                <th className="col-xs-1 text-right">Antal</th>
                <th className="col-xs-1 text-right">Show</th>
                <th className="col-xs-1 text-right">No show</th>
                <th className="col-xs-1 text-right">Avbokade</th>
                <th className="col-xs-1 text-right">Värde</th>
                <th className="col-xs-1 text-right">Uteblivet <sup>1</sup></th>
                <th className="col-xs-1 text-right">Netto</th>
              </tr>
            </thead>
            <tbody>
              {serviceNames.map((serviceName) => {
                const service = servicesByName[serviceName];
                const { name, price } = service[0] || {};
                const columns = getBookingReportColumns(service);
                return (
                  <tr key={serviceName}>
                    <td className="child">{name || '-'}</td>
                    <td className="text-right">{reportingFormat(price)}</td>
                    <td className="text-right">{columns.totalCount}</td>
                    <td className="text-right">{columns.showCount}</td>
                    <td className="text-right">{columns.noShowCount}</td>
                    <td className="text-right">{columns.cancelledCount}</td>
                    <td className="text-right">{columns.totalRevenue}</td>
                    <td className="text-right">{columns.noRevenue}</td>
                    <td className="text-right">{columns.revenue}</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <th>Summa</th>
                <th />
                <th className="text-right">{groupColumns.totalCount}</th>
                <th className="text-right">{groupColumns.showCount}</th>
                <th className="text-right">{groupColumns.noShowCount}</th>
                <th className="text-right">{groupColumns.cancelledCount}</th>
                <th className="text-right">{groupColumns.totalRevenue}</th>
                <th className="text-right">{groupColumns.noRevenue}</th>
                <th className="text-right">{groupColumns.revenue}</th>
              </tr>
            </tfoot>
          </table>
        );
      })}
    </>
  );
};

export default BookingReportServices;
