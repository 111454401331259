import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPosOrg } from '@State/pos-selectors';
import { changeReceiptCustomer } from '@State/pos-actions';
import ModalDialog from '@Components/dialogs/modal-dialog';
import SearchCustomerModal from '@Components/customers/search-customer-modal';
import { CustomerType } from '@Utils/customer-util';
import LabelSwitchInput from '@Components/ui/label-switch-input';
import Panel from '@Components/ui/styled/panel';
import Button from '@Components/ui/button';
import { formatPno } from '@Utils/luhn-util';
import ProgressButton from '@Components/ui/progress-button';

const ChangeCustomerModal = ({ routeParams, receipt, onClose }) => {
  const dispatch = useDispatch();
  const posOrg = useSelector(getPosOrg);
  const [customer, setCustomer] = useState(null);
  const [updateVoucher, setUpdateVoucher] = useState(true);
  const [progress, setProgress] = useState(false);

  const title = `Byt kund på kvitto #${receipt.get('receiptNumber')}`;
  const hasVoucher = receipt.get('items').some((item) => item.get('itemType') === 'VoucherSale');

  const toggleUpdateVoucher = () => {
    setUpdateVoucher(!updateVoucher);
  };

  const onCustomerSelected = (customer) => {
    setCustomer(customer);
  };

  const onChangeCustomer = () => {
    setProgress(true);
    return dispatch(changeReceiptCustomer(posOrg.get('id'), receipt.get('receiptId'), customer, updateVoucher))
      .then(() => onClose())
      .catch(() => setProgress(false));
  };

  if (!customer) {
    return (
      <SearchCustomerModal
        title={title}
        routeParams={routeParams}
        onSelected={onCustomerSelected}
        onCancel={onClose}
        scope={[CustomerType.Person]}
      />
    );
  }

  return (
    <ModalDialog
      title={title}
      contentSize="medium"
      onClose={onClose}
      onEnter={onChangeCustomer}
      closeOnClickOutside={false}
    >
      <Panel>
        <strong>Ny kund</strong>
        <span className="pull-right">
          {[customer.name, customer.officialIdNo ? formatPno(customer.officialIdNo) : null].filter(Boolean).join(', ')}
        </span>
      </Panel>
      {hasVoucher && (
        <Panel>
          <LabelSwitchInput
            noBorder
            label="Flytta presentkort till ny kund"
            id="updateVoucher"
            isOn={updateVoucher}
            handleToggle={toggleUpdateVoucher}
          />
        </Panel>
      )}
      <div className="text-right mt2">
        <Button small gray marginRight onClick={onClose}>Avbryt</Button>
        <ProgressButton small primary progress={progress} onClick={onChangeCustomer}>Byt kund</ProgressButton>
      </div>
    </ModalDialog>
  );
};

export default ChangeCustomerModal;
