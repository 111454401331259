import React, { memo } from 'react';
import uniqBy from 'lodash/uniqBy';
import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';

import { getCustomerHeading, getCustomerTypeIcon } from '@Utils/customer-util';
import ColumnListItem from '@Components/ui/column/column-list-item';
import { txt } from '@Utils/i18n-util';
import msg from './customer-list.msg';
import useCustomerSelect from './useCustomerSelect';
import CustomerListSubtext from './customer-list-subtext';

const CustomerList = ({ routeParams, selectedCustomerId, customerEntries }) => {
  const phoneMode = useSelector(state => state.mainViewState.get('phoneMode'));
  const onSelect = useCustomerSelect(routeParams);

  return (
    <div className="columns-list">
      {customerEntries && customerEntries.size > 0
        ? (
          <>
            {uniqBy(customerEntries.toJS(), 'customerId').map((item) => {
              const { customerType, customerId } = item;
              const isSelectedItem = selectedCustomerId && +selectedCustomerId === +customerId;

              return (
                <ColumnListItem
                  onClick={() => onSelect(item)}
                  key={customerId}
                  header={getCustomerHeading(item)}
                  icon={getCustomerTypeIcon(customerType)}
                  selected={!phoneMode && isSelectedItem}
                  subText={<CustomerListSubtext customer={item} />}
                />
              );
            })}
          </>
        )
        : (
          <div>
            <div className="columns-list-help">
              <em>{txt(msg.txtNoCustomers)}</em>
            </div>
          </div>
        )}
    </div>
  );
};

export default memo(
  CustomerList,
  (prevProps, nextProps) => !!isEqual(prevProps, nextProps)
);
