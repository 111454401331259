import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';

import Swipe from '@Utils/swipe';
import { clientBuildNumber } from '@Utils/config';
import { getEmbedVersion, getMobileNavStyle } from '@Utils/embedded-util';
import { navigate, getGroupUrl, getResourceUrl, getSectionUrl } from '@Utils/navigate';
import { toggleGridScrollability } from '@State/view-actions';
import { getCalendarTitle } from '@State/selectors';
import MobileUserMenu from '@Components/nav/mobile-user-menu';
import { classes } from '../ui/utils';
import ResourceSelector from '../calendar/resource-selector/resource-selector';
import MobileNavItem from '../calendar/resource-selector/mobile-nav-item';

class MobileNav extends Component {
  constructor(props) {
    super(props);
    this.element = null;
    this.state = {
      open: false
    };
  }

  componentDidMount() {
    this.swipe = new Swipe(this.element, {
      onSwipeLeft: this.hideSelector
    });
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.routeParams, this.props.routeParams) && this.state.open) {
      setTimeout(() => this.hideSelector(), 100);
    }
  }

  showSelector = (ev) => {
    ev.preventDefault();
    this.props.toggleScrollability(false);
    this.setState({ open: true });
    this.swipe.enable();
  };

  hideSelector = (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    this.props.toggleScrollability(true);
    this.setState({ open: false });
    this.swipe.disable();
  };

  render() {
    const { open } = this.state;
    const { dateLabel, routeParams, mobileNavStyle, embedVersion } = this.props;
    const classListHide = classes({
      'mobile-nav-backdrop': true,
      'show-mobile-nav': open
    });
    const classList = classes({
      'mobile-nav-container': true,
      'show-mobile-nav': open
    });

    return (
      <>
        <div className={classListHide} role="presentation" onClick={this.hideSelector} />
        <div className={classList} style={mobileNavStyle} ref={(el) => { this.element = el; }}>
          <div className="mobile-nav-header">
            <MobileUserMenu routeParams={routeParams} />
          </div>
          <div className="mobile-nav-list">
            <ul className="mobile-nav-items">
              <MobileNavItem
                routeParams={routeParams}
                icon="far fa-address-book"
                label="Kundregister"
                href={getSectionUrl(routeParams, 'customers')}
              />
            </ul>
            {this.resourceSelector()}
          </div>
          <div className="mobile-nav-footer">
            Cliento build #{clientBuildNumber()}
            {embedVersion && <span><span className="ml1 mr1">&bull;</span>App version {embedVersion}</span>}
          </div>
        </div>
        {this.renderCalendarTitle(dateLabel)}
      </>
    );
  }

  renderCalendarTitle(subTitle) {
    const { calendarTitle } = this.props;

    return (
      <a href="#" onClick={this.showSelector} className="mobile-resource-header">
        <img src="/mobile-nav-bars.png" className="mobile-nav-bars" alt="" />
        {subTitle && (
          <p className="mobile-resource-title">
            {calendarTitle}
            {subTitle && <span className="mobile-resource-sub-title">{subTitle}</span>}
          </p>
        )}
      </a>
    );
  }

  resourceSelector() {
    return (
      <ResourceSelector
        routeParams={this.props.routeParams}
        resourceTargetUrlResolver={this.resolveResourceTargetUrl}
        groupTargetUrlResolver={this.resolveGroupTargetUrl}
      />
    );
  }

  getRouteParams = () => {
    const { routeParams, viewMode, viewDate, lastView } = this.props;

    return {
      ...routeParams,
      viewMode: viewMode || lastView?.viewMode || 'day',
      viewDate: viewDate || lastView?.viewDate || moment().format('YYYY-MM-DD')
    };
  };

  resolveResourceTargetUrl = (resourceId, resourceEntityType) => {
    return getResourceUrl(resourceId, resourceEntityType, 'week', this.getRouteParams());
  };

  resolveGroupTargetUrl = (groupId) => {
    return getGroupUrl(groupId, this.getRouteParams());
  };
}

MobileNav.propTypes = {
  tabletMode: PropTypes.bool.isRequired,
  phoneMode: PropTypes.bool.isRequired,
  resourcesById: PropTypes.object.isRequired,
  orderedGroups: PropTypes.object.isRequired,
  routeParams: PropTypes.object.isRequired,
  changeGroup: PropTypes.func.isRequired,
  changeResource: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const { mainViewState, calendarViewState, orderedGroups, resourcesById } = state;
  const {
    viewDate, viewMode, entityType, entityId
  } = ownProps.routeParams;

  return {
    viewDate,
    viewMode,
    entityType,
    entityId,
    phoneMode: mainViewState.get('phoneMode'),
    tabletMode: mainViewState.get('tabletMode'),
    lastView: calendarViewState.get('lastView'),
    resourcesById,
    orderedGroups,
    calendarTitle: getCalendarTitle(state, ownProps),
    mobileNavStyle: getMobileNavStyle(state),
    embedVersion: getEmbedVersion(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeGroup: (groupId) => {
      navigate(getGroupUrl(groupId, ownProps.routeParams));
    },
    changeResource: (resId, resourceEntityType) => {
      const { routeParams } = ownProps;
      navigate(getResourceUrl(resId, resourceEntityType, routeParams.viewMode, routeParams));
    },
    toggleScrollability: (scrollable) => {
      dispatch(toggleGridScrollability(scrollable));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileNav);
