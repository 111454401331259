import React from 'react';
import PropTypes from 'prop-types';
import { getSectionUrl } from '@Utils/navigate';
import Segment, { SegmentItem } from '@Components/ui/tb-segment';
import { CenterBlocks } from '@Components/ui/styled/main';
import { txt } from '@Utils/i18n-util';
import msg from './pos-header.msg';

class PosHeaderNav extends React.Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired
  };

  getLinkUrl = (subsection) => {
    const { routeParams } = this.props;
    return getSectionUrl(routeParams, 'pos', subsection);
  };

  render() {
    const { routeParams } = this.props;
    const { subsection } = routeParams;

    return (
      <CenterBlocks flex={1}>
        <Segment>
          <SegmentItem large href={this.getLinkUrl()} active={!subsection}>
            {txt(msg.lblPayment)}
          </SegmentItem>
          <SegmentItem large href={this.getLinkUrl('receipts')} active={subsection === 'receipts'}>
            {txt(msg.lblReceipts)}
          </SegmentItem>
          <SegmentItem large href={this.getLinkUrl('reports')} active={subsection === 'reports'}>
            {txt(msg.lblReports)}
          </SegmentItem>
        </Segment>
      </CenterBlocks>
    );
  }
}

export default PosHeaderNav;
