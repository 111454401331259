import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import useKeyPress from '@Hooks/useKeyPress';

import { txt } from '@Utils/i18n-util';
import Modal from '@Components/ui/modal';
import { colors, screens } from '@Components/ui/styled/variables';
import { getModalDialogStyle } from '@Utils/embedded-util';
import useSwipeToDismiss from '@Hooks/useSwipeToDismiss';
import {
  BtnBack,
  DialogButtonsVertical,
  DialogButton,
  DialogTitle,
  DialogTitleBtn,
  DialogTitleRow,
  DialogCloseX
} from '@Components/dialogs/dialog-styles';
import msg from './dialog.msg';

function getDialogWidth({ size }) {
  switch (size) {
    case 'full':
      return '1200px';
    case 'xxl':
      return '800px';
    case 'customer':
      return '700px';
    case 'xl':
      return '600px';
    case 'large':
      return '500px';
    case 'medium':
      return '400px';
    case 'small':
      return '350px';
    default:
      return '320px';
  }
}

function getDialogHeight({ size }) {
  switch (size) {
    case 'customer':
      return '90vh';
    default:
      return 'auto';
  }
}

function getDialogPadding({ largePadding, noPadding }) {
  if (noPadding) {
    return '0px';
  }
  return largePadding ? '24px' : '16px';
}

export const ModalDialogContent = styled.div`
  position: relative;
  width: ${getDialogWidth};
  max-width: 100%;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: ${getDialogPadding};
  height: ${getDialogHeight};
  min-height: 150px;
  margin: 20px;

  ${p => p.mobileFullScreen && css`
    @keyframes slideUp {
      from {
        transform: translateY(100%);
      }
      to {
        transform: translateY(0);
      }
    }
    @media (max-width: ${screens.sm}) {
      height: 100%;
      animation: slideUp 0.25s ease-in-out;
    }
  `};

  .sale-gray-bg {
    margin: -18px -16px -14px 16px;
    padding: 18px;
    top: 2px;
    position: relative;
    border-radius: 0 8px 8px 0;
    background: ${colors.softGray40};
  }
`;

const ModalDialog = (props) => {
  const {
    title, onBack, onClose, onEnter, buttons = [], children, initialFocus,
    className, closeButtonText, closeButtonX = false, hideCloseButton = false,
    closeOnClickOutside = true, leftBtn = false, contentSize, alignTop = false,
    focusTrapPaused = false, largePadding, noPadding, mobileFullScreen, zIndex
  } = props;

  const ref = useRef();
  const dialogStyle = useSelector(getModalDialogStyle);

  useKeyPress(13, () => onEnter && onEnter(), [onEnter]);
  useSwipeToDismiss(ref, onClose);

  return (
    <Modal
      focusTrapPaused={focusTrapPaused}
      underlayClickExits={closeOnClickOutside}
      titleText={title || ' '}
      onExit={onClose}
      initialFocus={initialFocus}
      verticallyCenter={contentSize !== 'full' && !alignTop}
      underlayStyle={zIndex ? { zIndex } : null}
    >
      <ModalDialogContent
        ref={ref}
        className={className}
        mobileFullScreen={mobileFullScreen}
        size={contentSize}
        largePadding={largePadding}
        noPadding={noPadding}
        style={dialogStyle}
      >
        {title && (
          <DialogTitle large={largePadding}>
            {onBack && (
              <BtnBack onClick={onBack}><i className="fas fa-chevron-left" /></BtnBack>
            )}
            <DialogTitleRow center={hideCloseButton || closeButtonX} leftBtn={leftBtn || onBack || hideCloseButton}>{title}</DialogTitleRow>
            {!hideCloseButton && !closeButtonX && (
              <DialogTitleBtn tabIndex={0} onClick={onClose}>{closeButtonText || txt(msg.btnCancel)}</DialogTitleBtn>
            )}
            {closeButtonX && (
              <DialogCloseX tabIndex={0} onClick={onClose}><i className="fa fa-times" /></DialogCloseX>
            )}
          </DialogTitle>
        )}
        {children}
        {buttons.length > 0 && (
          <DialogButtonsVertical>
            {buttons.filter(b => b).map(({ name, onClick, ...props }, index) => (
              <DialogButton {...props} key={index} onClick={onClick}>
                {name}
              </DialogButton>
            ))}
          </DialogButtonsVertical>
        )}
      </ModalDialogContent>
    </Modal>
  );
};

ModalDialog.propTypes = {
  title: PropTypes.string,
  buttons: PropTypes.array
};

export default ModalDialog;
