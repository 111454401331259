import React from 'react';
import { useSelector } from 'react-redux';
import Circle from '@Components/ui/styled/circle';
import { getSaleId, getSaleDiscount } from '@State/pos-selectors';
import SaleToolbarCustomerButton from '@Components/pos/sale/sale-toolbar-customer-button';
import { colors } from '@Components/ui/styled/variables';
import { txt } from '@Utils/i18n-util';
import msg from './pos-sale.msg';

const SaleToolbarButtons = ({
  routeParams, isSaleEmpty, showModal, parkedSalesCount, partialPaid
}) => {
  const hasOpenSale = useSelector(state => !!getSaleId(state));
  const discount = useSelector(state => getSaleDiscount(state));

  const isSaleDisabled = partialPaid || !hasOpenSale;
  const isDisabledDiscount = !!discount || isSaleDisabled;
  const buttonClass = 'btn btn-default';

  const classList = partialPaid ? `${buttonClass} disabled` : buttonClass;
  const onAddDiscount = () => showModal('addDiscount');
  const onShowParking = () => showModal('saleParking');
  const onVoidSale = () => showModal('voidSale');

  return (
    <div className="pos-sale-toolbar">
      <SaleToolbarCustomerButton
        routeParams={routeParams}
        isSaleDisabled={isSaleDisabled}
        showModal={showModal}
      />
      <button className={classList} onClick={onAddDiscount} disabled={isDisabledDiscount}>
        {txt(msg.btnDiscount)}
      </button>
      <button className={buttonClass} onClick={onShowParking} disabled={isSaleEmpty && !parkedSalesCount}>
        {txt(msg.btnParkSale)}
        {parkedSalesCount > 0 && (
          <Circle mrr="0px" mrl={6} w={15} bg={colors.primaryDark}>{parkedSalesCount}</Circle>
        )}
      </button>
      <button className="btn btn-default clear-receipt-btn" onClick={onVoidSale} disabled={isSaleDisabled}>
        {txt(msg.btnVoidSale)}
      </button>
    </div>
  );
};

export default SaleToolbarButtons;
