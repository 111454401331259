import React from 'react';
import { Text } from '@Components/ui/styled/main';
import { ContentBlock, TitleDate } from '@Components/customers/customer-styles';

import CustomerBookingEvent from '@Components/customers/customer-detail/bookings/customer-booking-event';
import CustomerDetailLog from '@Components/customers/customer-detail/logs/customer-detail-log';
import CustomerDetailSale from '@Components/customers/customer-detail/sales/customer-detail-sale';
import { getTimeStaffCreatedTime, getTimeByFormat } from '@Utils/time-util';
import { sortEventByTimeAndReverse } from '@Utils/customer-util';

const renderEvent = (event, props) => {
  const {
    comment, createdTs, typeData, id, type, files
  } = event;
  if (typeData === 'bookings') {
    return (
      <CustomerBookingEvent
        isSelectable
        onSelectEvent={props.handlerOnClickBookings}
        {...event}
        {...props}
      />
    );
  }
  if (typeData === 'logs') {
    return (
      <CustomerDetailLog
        handlerSelectLogs={props.handlerOnSelectLogs}
        comment={comment}
        files={files}
        id={id}
        created={createdTs}
        type={type}
      />
    );
  }
  return (
    <CustomerDetailSale
      isSelectable
      sale={event}
      handleSelect={props.handlerOnClickSales}
    >
      <Text muted fs="12">
        {getTimeStaffCreatedTime(createdTs)}
      </Text>
    </CustomerDetailSale>
  );
};

const CustomerDetailMonthList = ({ events, ...props }) => (
  <>
    {events.map(([date, groupedEvents], index) => (
      <div key={`${date}_${index}`}>
        <TitleDate>{getTimeByFormat(date)}</TitleDate>
        <ContentBlock>
          {sortEventByTimeAndReverse(groupedEvents).map((event) => (
            <div key={event.id}>
              {renderEvent(event, props)}
            </div>
          ))}
        </ContentBlock>
      </div>
    ))}
  </>
);

export default CustomerDetailMonthList;
