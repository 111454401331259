import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyUtil from '@Utils/currency-util';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { fetchBooking } from '@State/booking-actions';
import { resetAppNotification } from '@State/app-actions';
import Loader from '@Components/ui/loader';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import Panel from '@Components/ui/styled/panel';
import { getCalendarUrl, navigate, navigateToBooking } from '@Utils/navigate';
import { viewDateFromDate } from '@Utils/time-util';

const Heading = ({ action }) => {
  switch (action) {
    case 'booked':
      return (
        <h4 className="mt0 mb2">
          <i className="fa fa-circle-check text-success mr1" />
          Ny bokning!
        </h4>
      );
    case 'cancelled':
      return (
        <h4 className="mt0 mb2">
          <i className="fa fa-circle-minus text-danger mr1" />
          Avbokning
        </h4>
      );
    case 'reviewed':
      return (
        <h4 className="mt0 mb2">
          <span className="mr1">⭐️</span>
          Nytt omdöme!
        </h4>
      );
    default: return null;
  }
};

const AppNotificationModal = ({ routeParams }) => {
  const dispatch = useDispatch();
  const [booking, setBooking] = useState(null);
  const [progress, setProgress] = useState(false);
  const notification = useSelector(({ appState }) => appState.get('notification'));

  const { action, bookingId } = notification || {};
  const { customers, services, startTime, endTime, price } = booking || {};

  useEffect(() => {
    if (bookingId) {
      setProgress(true);
      dispatch(fetchBooking(bookingId))
        .then((response) => setBooking(response))
        .finally(() => setProgress(false));
    }
  }, [bookingId]);

  const onShowBooking = () => {
    navigateToBooking(routeParams, booking);
    onClose();
  };

  const onClose = () => {
    dispatch(resetAppNotification());
  };

  return (
    <ModalDialog
      className="dialog"
      buttons={!progress && [booking && {
        name: 'Visa bokning',
        primary: true,
        onClick: onShowBooking
      }, {
        gray: true,
        name: 'Stäng',
        onClick: onClose
      }]}
    >
      {progress && <Loader />}
      {!progress && action && <Heading action={action} />}
      {booking && !progress && (
        <Panel>
          {customers.length > 0 && (
            <strong>{customers[0]?.name}</strong>
          )}
          <p className="mb2">{moment(startTime).format('LLL')}</p>
          {booking.services.length > 0 && (
            <strong>{services.map(s => s.name).join(', ')}</strong>
          )}
          <p className="mb0">
            {moment(endTime).diff(startTime, 'minutes')} min, {CurrencyUtil.accountCurrency(price)}
          </p>
        </Panel>
      )}
      {!booking && !progress && (
        <AlertWithIcon icon="fa fa-exclamation-circle" error>
          Kunde inte ladda bokning
        </AlertWithIcon>
      )}
    </ModalDialog>
  );
};

export default AppNotificationModal;
