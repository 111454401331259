import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Link } from 'react-router-dom';
import { web, sms, email } from '@Utils/preference-keys';
import SubmitButton from '@Components/ui/submit-button';
import Popover from '@Components/ui/popover';
import { resourceStaffTypes } from '@Utils/resource-util';
import {
  createValidator, required, maxLength
} from '@Utils/validation';
import { defaultSmsCancelTemplate, defaultSmsConfirmTemplate, defaultSmsReminderTemplate } from '@Utils/templates';
import { getSectionUrl } from '@Utils/navigate';
import { getWebPaymentEnabled } from '@State/selectors';
import { vipBookingUrl, webBookingUrl } from '@Utils/config';
import TextInputHorizontal from '@Components/inputs/text-input-horizontal';
import SmsTemplateInput from '@Components/inputs/sms-template-input';
import WebBookingStrategy from '@Components/inputs/web-booking-strategy';
import EmailIcsConfig from '@Components/inputs/email-ics-config';
import RichTextEditorField from '@Components/inputs/rich-text-editor';
import CompanyAddress from '@Components/inputs/company-address';
import PosUnitSelectField from '@Components/admin/payment-config/pos-unit-select-field';
import PriceSettingsForm from '@Components/admin/web-booking/price-settings-form';

const stylesTextBlock = { marginTop: 5, marginBottom: 10 };
class ResourceSettingsForm extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    resourceId: PropTypes.number.isRequired,
    resource: PropTypes.object.isRequired,
    features: PropTypes.object.isRequired,
    deleteResource: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    useCustomValues: PropTypes.bool.isRequired,
    smsReminderWithin: PropTypes.number.isRequired,
    previewValues: PropTypes.object.isRequired,
    name: PropTypes.string
  };

  state = {
    confirmDelete: false,
    deleteProgress: false
  };

  render() {
    const {
      routeParams, handleSubmit, features, useCustomValues, useSmsReminders, locationPaymentEnabled, resourceType,
      smsReminderWithin, slotFinderStrategy, interval, resource, previewValues, paymentEnabled, slug
    } = this.props;
    const showSmsEmailSettings = features.emailOnBookOnline || features.emailOnCancelOnline
      || features.smsOnBookOnline || features.smsOnCancelOnline;
    const webBookingLink = `${webBookingUrl()}${slug}/`;
    const vipBookingLink = `${vipBookingUrl()}${resource.hashId}`;
    const showStaffType = resourceType === 'Person';

    return (
      <form autoComplete="off" className="form-horizontal" onSubmit={handleSubmit}>
        <Field name="name" component={TextInputHorizontal} label="Namn" validate={[required, maxLength(50)]} />
        <Field name="webName" component={TextInputHorizontal} label="Namn i onlinebokningen" validate={[required, maxLength(50)]} />

        {showStaffType && (
          <div className="form-group">
            <label className="col-sm-3 control-label">Anställningsform</label>
            <div className="col-sm-5">
              <div className="select-container">
                <Field name="resourceStaffType" component="select" className="form-control">
                  {Object.keys(resourceStaffTypes).map(key => (
                    <option key={key} value={key}>{resourceStaffTypes[key]}</option>
                  ))}
                </Field>
              </div>
              <p className="text-muted" style={stylesTextBlock}>
                Denna information används t.ex. för att avgöra om en kund tillhör kontot eller resursen.
              </p>
            </div>
          </div>
        )}

        {/* Hide this for now while we decide what to do with resource colors
        <div className="form-group">
          <div className="col-sm-offset-3 col-sm-5">
            <Field component={ColorPicker} name="color" />
          </div>
        </div>
        */}

        <hr className={!showStaffType ? 'mt5' : null} />

        <div className="form-group">
          <div className="col-sm-offset-3 col-sm-9">
            <div className="checkbox">
              <label>
                <Field name="useCustomValues" component="input" type="checkbox" />
                Använd egna inställningar
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-offset-3 col-sm-9">
            <p className="text-muted">
              Med egna inställningar kan du ändra resursens inställningar separat från kontots inställningar.
              <br />
              Kontots inställningar hittar du här:{' '}
              <Link to={getSectionUrl(routeParams, 'admin', 'web-booking')}>Kontoinställningar</Link>
            </p>
          </div>
        </div>

        <hr />

        {useCustomValues && (
          <div>
            <CompanyAddress />
            <hr />
          </div>
        )}

        <div className="row">
          <div className="col-sm-offset-3 col-sm-9">
            <h4>Inställningar för onlinebokning</h4>
          </div>
        </div>

        <div className="form-group">
          <div className="col-sm-6 col-sm-offset-3">
            <div className="checkbox">
              <label>
                <Field name="webBookingEnabled" component="input" type="checkbox" />
                Aktivera publik onlinebokning
              </label>
            </div>
            <p className="text-muted" style={stylesTextBlock}>
              Om du vill att resursen ska gå att boka online från kontots gemensamma onlinebokning.
            </p>
            <p className="text-muted" style={stylesTextBlock}>
              Länk till publik bokning: <a href={webBookingLink} target="_blank">{webBookingLink}</a>
            </p>
            <div className="checkbox">
              <label>
                <Field name="vipBookingEnabled" component="input" type="checkbox" />
                Aktivera VIP-bokning
              </label>
            </div>
            <p className="text-muted" style={stylesTextBlock}>
              VIP-bokning är en privat onlinebokning där kunder enbart kan boka dig, och bara om de har länken.
              Du kan t.ex. mejla eller smsa länken till dina kunder.
            </p>
            <p className="text-muted" style={stylesTextBlock}>
              Länk till din VIP-bokning: <a href={vipBookingLink} target="_blank">{vipBookingLink}</a>
            </p>
          </div>
        </div>

        {useCustomValues && (
          <div>
            <div className="form-group">
              <label className="col-sm-3 control-label">Algoritm</label>
              <div className="col-sm-9">
                <div className="row">
                  <div className="col-sm-9">
                    <WebBookingStrategy propertyName={web.slotFinderStrategy} slotFinderStrategy={slotFinderStrategy} interval={interval} />
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div className="form-group">
              <label className="col-sm-3 control-label">Tillåt bokning senast</label>
              <div className="col-sm-3">
                <Field name={web.minTimeBeforeBooking} component="input" type="number" className="form-control" required min={0} />
              </div>
              <p className="col-sm-3 form-control-static">minuter innan</p>
            </div>

            <div className="form-group">
              <label className="col-sm-3 control-label">Tillåt bokning upp till</label>
              <div className="col-sm-3">
                <Field name={web.bookingMaxDaysInAdvance} component="input" type="number" className="form-control" required min={1} max={730} />
              </div>
              <p className="col-sm-3 form-control-static">dagar innan</p>
            </div>

            {showSmsEmailSettings && (
              <div className="form-group">
                <label className="col-sm-3 control-label">Bokningsbekräftelse</label>
                <div className="col-sm-9">
                  {features.emailOnBookOnline && (
                    <div className="checkbox">
                      <label>
                        <Field name={email.onBookOnline} component="input" type="checkbox" />
                        Mejla vid bokning online
                      </label>
                    </div>
                  )}
                  {features.emailOnCancelOnline && (
                    <div className="checkbox">
                      <label>
                        <Field name={email.onCancelOnline} component="input" type="checkbox" />
                        Mejla vid avbokning online
                      </label>
                    </div>
                  )}
                  {features.smsOnBookOnline && features.EnableSmsConfirmations && (
                    <div className="checkbox">
                      <label>
                        <Field name={sms.onBookOnline} component="input" type="checkbox" />
                        Skicka SMS vid bokning online
                      </label>
                    </div>
                  )}
                  {features.smsOnCancelOnline && features.EnableSmsConfirmations && (
                    <div className="checkbox">
                      <label>
                        <Field name={sms.onCancelOnline} component="input" type="checkbox" />
                        Skicka SMS vid avbokning online
                      </label>
                    </div>
                  )}
                </div>
              </div>
            )}

            {(features.emailConfirmExtraInfo || features.emailIcsConfirm) && (
              <>
                <hr />
                <div className="row">
                  <div className="col-sm-offset-3 col-sm-9">
                    <h4>Inställningar för mejl</h4>
                  </div>
                </div>
              </>
            )}

            {(features.emailConfirmExtraInfo) && (
              <div>
                <div className="form-group">
                  <label className="col-sm-3 control-label">
                    Extra information vid bokning
                  </label>
                  <div className="col-sm-6">
                    <Field name={email.confirmExtraInfo} component={RichTextEditorField} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-offset-3 col-sm-5">
                    <p className="text-muted">
                      Lägg till extra information i bokningsbekräftelse,
                      som till exempel öppettider eller avbokningsregler.
                    </p>
                  </div>
                </div>

                <hr />
                <div className="form-group">
                  <label className="col-sm-3 control-label">
                    Extra information vid avbokning
                  </label>
                  <div className="col-sm-6">
                    <Field name={email.cancelExtraInfo} component={RichTextEditorField} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-offset-3 col-sm-5">
                    <p className="text-muted">
                      Lägg till extra information i avbokningsbekräftelse,
                      som till exempel information om återbetalning eller ombokning.
                    </p>
                  </div>
                </div>
              </div>
            )}

            {(features.emailIcsConfirm) && (
              <div>
                <hr />
                <div className="form-group">
                  <div className="col-sm-offset-3 col-sm-9">
                    <EmailIcsConfig prefix="preferences" {...this.props} />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {features.useSmsReminders && (
          <>
            <hr />
            <div className="row">
              <div className="col-sm-offset-3 col-sm-9">
                <h4>Inställningar för SMS</h4>
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-offset-3 col-sm-9">
                <div className="checkbox">
                  <label>
                    <Field name="useSmsReminders" component="input" type="checkbox" />
                    Skicka SMS-påminnelser
                  </label>
                </div>
                {!useCustomValues && (
                  <p className="text-muted" style={stylesTextBlock}>
                    Påminnelse skickas tidigast {smsReminderWithin} timmar innan bokningen
                  </p>
                )}
              </div>
            </div>
          </>
        )}

        {useCustomValues && (
          <div>
            {features.reminderSendTimes && useSmsReminders && (
              <div>
                <div className="form-group">
                  <label className="col-sm-3 control-label">Skicka påminnelse innan bokning</label>
                  <div className="col-sm-9">
                    <Field name={sms.reminderWithin} component="input" type="number" className="form-control inline-sm" min={1} max={120} required />
                    <p className="form-control-static inline-md">timmar innan</p>
                  </div>
                </div>
                <br />
              </div>
            )}
            {features.smsConfirmTemplate && features.EnableSmsConfirmations && (
              <div>
                <div className="form-group">
                  <label className="col-sm-3 control-label">Text i bokningsbekräftelse</label>
                  <div className="col-sm-9">
                    <Field
                      name={sms.confirmTemplate}
                      component={SmsTemplateInput}
                      defaultTemplate={defaultSmsConfirmTemplate()}
                      replaceValues={previewValues}
                    />
                  </div>
                </div>
              </div>
            )}

            {features.smsReminderTemplate && (
              <div>
                <div className="form-group">
                  <label className="col-sm-3 control-label">Text i påminnelse</label>
                  <div className="col-sm-9">
                    <Field
                      name={sms.reminderTemplate}
                      component={SmsTemplateInput}
                      defaultTemplate={defaultSmsReminderTemplate()}
                      replaceValues={previewValues}
                    />
                  </div>
                </div>
              </div>
            )}

            {features.smsCancelTemplate && (
              <div>
                <div className="form-group">
                  <label className="col-sm-3 control-label">Text i avbokningsbekräftelse</label>
                  <div className="col-sm-9">
                    <Field
                      name={sms.cancelTemplate}
                      component={SmsTemplateInput}
                      defaultTemplate={defaultSmsCancelTemplate()}
                      replaceValues={previewValues}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {locationPaymentEnabled && (
          <>
            <hr />
            <div className="row">
              <div className="col-sm-offset-3 col-sm-9">
                <h4>Inställningar för betalning</h4>
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-offset-3 col-sm-6">
                <div className="checkbox">
                  <label>
                    <Field name={web.paymentEnabled} component="input" type="checkbox" />
                    Aktivera betalning vid bokning
                  </label>
                </div>
                <p className="text-muted" style={stylesTextBlock}>
                  Tillåter kunder att betala vid onlinebokning.
                  Ställ in på tjänsten om betalning krävs vid bokning.
                </p>
              </div>
            </div>
            {paymentEnabled && (
              <div className="form-group">
                <div className="col-sm-offset-3 col-sm-5">
                  <PosUnitSelectField />
                  <p className="text-muted" style={stylesTextBlock}>
                    Koppla onlinebetalningar till det företag som resursen hör till.
                  </p>
                </div>
              </div>
            )}
          </>
        )}

        {useCustomValues && (
          <>
            <hr />
            <PriceSettingsForm />
          </>
        )}

        <hr />

        <div className="form-group">
          <div className="col-sm-offset-3 col-sm-5">
            <SubmitButton {...this.props} /> &nbsp;
            <Popover isOpen={this.state.confirmDelete} body={this.confirmDeleteContent()} onOuterAction={this.hideConfirmDelete}>
              <a href="#" className="btn btn-delete" onClick={this.showConfirmDelete}>Ta bort</a>
            </Popover>
          </div>
        </div>
      </form>
    );
  }

  showConfirmDelete = (ev) => {
    ev.preventDefault();
    this.setState({ confirmDelete: true, deleteProgress: false });
  };

  hideConfirmDelete = (ev) => {
    if (ev) { ev.preventDefault(); }
    this.setState({ confirmDelete: false });
  };

  confirmDeleteContent() {
    return (
      <div className="Popover-content-small">
        <a href="#" onClick={this.hideConfirmDelete} className="Popover-close"><i className="fa fa-lg fa-times" /></a>
        <p>Är du säker på att du vill ta bort resursen? Detta går inte att ångra!</p>

        <div className="text-right">
          <button type="button" className="btn-block btn-delete" onClick={this.deleteResource} disabled={this.state.deleteProgress}>Ta bort resurs</button>
        </div>
      </div>
    );
  }

  deleteResource = (ev) => {
    ev.preventDefault();
    this.setState({ deleteProgress: true });
    this.props.deleteResource()
      .catch(() => this.setState({ deleteProgress: false }));
  };
}

const selector = formValueSelector('resource-preferences');

const mapStateToProps = (state) => {
  return {
    locationPaymentEnabled: getWebPaymentEnabled(state),
    resourceType: selector(state, 'resourceType'),
    useCustomValues: selector(state, 'useCustomValues'),
    useSmsReminders: selector(state, 'useSmsReminders'),
    paymentEnabled: selector(state, web.paymentEnabled),
    slotFinderStrategy: selector(state, web.slotFinderStrategy),
    smsReminderWithin: state.locationConfig.get(sms.reminderWithin),
    includeIcs: selector(state, email.includeIcs),
    icsHeader: selector(state, email.icsHeader),
    icsLocation: selector(state, email.icsLocation),
    icsNotes: selector(state, email.icsNotes),
    interval: selector(state, 'interval')
  };
};

export default reduxForm({
  form: 'resource-preferences',
  enableReinitialize: true
})(connect(mapStateToProps)(ResourceSettingsForm));
