import React from 'react';
import { ReleaseNoteLabels, getReleaseNoteColor } from '@Components/sysadmin/release-notes/release-notes-helpers';
import RichTextContent from '@Components/inputs/rich-text-content';
import Label from '@Components/ui/label';

const ReleaseNotesItem = ({ releaseNote }) => {
  const { title, label, date, imageUrl } = releaseNote;

  return (
    <div className="select-text">
      <h3 className="release-notes-modal-heading mt0 mb1 mr4">{title}</h3>

      <div className="release-notes-modal-meta mb3">
        <Label bgColor={getReleaseNoteColor(label)}>
          {ReleaseNoteLabels[label] || label}
        </Label>
        <Label bgColor="transparent" textColor="rgba(0, 0, 0, 0.5)">
          {date}
        </Label>
      </div>

      {imageUrl && (
      <img src={imageUrl} className="mb3" />
      )}

      <RichTextContent
        className="release-notes-modal-content mb3"
        content={releaseNote?.text}
      />
    </div>
  );
};

export default ReleaseNotesItem;
