import axios from 'axios';
import deepEqual from 'fast-deep-equal';
import {
  axiosDefault, axiosErrorHandler, axiosPut, checkStatusAxios, prefixUrl
} from '@Utils/ajax-util';

export const VOUCHERS_FETCHED = 'VOUCHERS_FETCHED';
export const VOUCHER_CREATED = 'VOUCHER_CREATED';
export const VOUCHER_UPDATED = 'VOUCHER_UPDATED';

export function fetchVouchers() {
  return dispatch => {
    const url = prefixUrl('/voucher/discount-voucher/');
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((vouchers) => dispatch({ type: VOUCHERS_FETCHED, vouchers }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

function updateVoucherRules(id, rules) {
  const url = prefixUrl(`/voucher/discount-voucher/${id}/rules`);
  return axiosPut(url, rules || {}, { throwOnError: true });
}

export function createVoucher(data) {
  return (dispatch) => {
    const url = prefixUrl('/voucher/discount-voucher/');
    const config = axiosDefault();

    return axios
      .post(url, data, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((voucher) => {
        dispatch({ type: VOUCHER_CREATED, voucher });

        return Object.values(data.voucherRules || {}).some(Boolean)
          ? dispatch(updateVoucherRules(voucher.id, data.voucherRules))
          : Promise.resolve();
      })
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

function getExistingVoucher(state, id) {
  const { vouchers } = state;

  return vouchers.get('activeVouchers').find(v => v.get('id') === id)
    || vouchers.get('nonActiveVouchers').find(v => v.get('id') === id);
}

export function updateVoucher(voucher) {
  return (dispatch, getState) => {
    const url = prefixUrl(`/voucher/discount-voucher/${voucher.id}`);
    const config = axiosDefault();

    return axios
      .put(url, voucher, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({ type: VOUCHER_UPDATED, voucher });

        const existingVoucher = getExistingVoucher(getState(), voucher.id).toJS();
        return !deepEqual(existingVoucher.voucherRules, voucher.voucherRules)
          ? dispatch(updateVoucherRules(voucher.id, voucher.voucherRules))
          : Promise.resolve();
      })
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function voidVoucher(id) {
  return (dispatch) => {
    const url = prefixUrl(`/voucher/${id}/void`);
    const config = axiosDefault();
    const voucher = { id, voucherStatus: 'Void' };

    return axios
      .put(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: VOUCHER_UPDATED, voucher }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function validateVoucherCode(code) {
  return dispatch => {
    const url = prefixUrl(`/voucher/validate-code?code=${encodeURIComponent(code)}`);
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}
