import React from 'react';
import moment from 'moment';
import useCustomerSelect from '@Components/customers/useCustomerSelect';
import { PanelWrapper } from '@Components/customers/customer-styles';
import { Button, FormGroup } from '@Components/ui/styled/main';

const CustomerVehicleDetails = ({ routeParams, vehicle }) => {
  const { officialIdNo, name, createdTs, lastBookingTs } = vehicle || {};
  const onCustomerSelect = useCustomerSelect(routeParams);
  return (
    <PanelWrapper defaultCursor>
      <FormGroup labelText="Reg.nummer" text={officialIdNo} />
      <FormGroup labelText="Beskrivning" text={name || '-'} />
      <FormGroup labelText="Tillagd" text={moment(createdTs).format('LLL')} />
      <FormGroup labelText="Senaste bokning" text={lastBookingTs ? moment(lastBookingTs).format('LLL') : '-'} />
      <div className="mt2">
        <Button small white onClick={() => onCustomerSelect(vehicle)}>Visa fordon</Button>
      </div>
    </PanelWrapper>
  );
};

export default CustomerVehicleDetails;
