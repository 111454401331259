import React from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import {
  getSaleTotalAmount, getSaleRemainingAmount, getSalePaidAmount, getPosOrgPrefs,
  hasPrinterError, getEnableGiftCardPayment, getSaleCustomer, getSaleItems,
  getSaleGrossAmount, getSalePartialPaid, getSaleTransactions, getPosUnitPrefs, getPrinterProgress
} from '@State/pos-selectors';
import {
  addPayment, createInvoice, completeSale, hidePaymentModal, showPaymentModal, getSaleReceiptId
} from '@State/pos-actions';
import { txt } from '@Utils/i18n-util';
import { pos } from '@Utils/preference-keys';
import { formatPhoneNumberE164 } from '@Utils/phone-util';
import { fetchCustomerGiftCards } from '@State/customer-actions';
import { PaymentModal, getBookingIdForSale, getOtherPaymentMethodName } from '@Utils/pos-utils';
import CustomerIdContext from '@Components/customers/customer-id-context';
import ExternalPayment from '@Components/pos/payment/external-payment';
import SwishPayment from '@Components/pos/payment/swish-payment';
import KlarnaPayment from '@Components/pos/payment/klarna-payment';
import CashPayment from '@Components/pos/payment/cash-payment';
import CardPayment from '@Components/pos/payment/card-payment';
import GiftCardPayment from '@Components/pos/payment/gift-card-payment';
import ReceiptModal from '@Components/pos/payment/receipt-modal';
import InvoiceModal from '@Components/pos/dialogs/invoice-modal';
import InvoiceReceiptModal from '@Components/pos/dialogs/invoice-receipt-modal';
import PosPaymentButtons from '@Components/pos/payment/pos-payment-buttons';
import DialogLoader from '@Components/dialogs/dialog-loader';
import ChecksumMismatchError from '@Components/pos/dialogs/checksum-mismatch-error';
import msg from './pos-payment.msg';

const beforeUnloadMessage = 'Betalning pågår, är du säker på att du vill lämna sidan?';

class PosPayment extends React.PureComponent {
  state = {
    isLoading: false,
    otherNumber: null,
    createdInvoice: null
  };

  componentDidMount() {
    const { txProgress } = this.props;

    if (txProgress) {
      this.handleTxProgress(txProgress);
    }

    window.addEventListener('beforeunload', this.beforeUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeUnload);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      status, receiptId, invoiceId, paymentMethod, transactionStatus, remainingAmount,
      txProgress, checksumMismatch
    } = nextProps;
    const isSucceeded = transactionStatus === 'Succeeded';
    const remainingAmountChanged = remainingAmount !== this.props.remainingAmount;
    const invoicePayment = paymentMethod?.includes('Invoice');

    if (txProgress && !this.props.txProgress) {
      this.handleTxProgress(txProgress);
    }
    if ((receiptId || invoiceId) && status === 'Paid') {
      this.showModal(invoicePayment ? 'invoiceReceipt' : 'receipt');
    }
    if (isSucceeded && status === 'PartialPaid' && remainingAmountChanged) {
      this.hideModal();
    }
    if (!this.props.checksumMismatch && checksumMismatch) {
      this.hideModal();
    }
  }

  handleTxProgress = (txProgress) => {
    const { transactionStatus, posPaymentMethod } = txProgress.toObject();
    if (transactionStatus == 'InProgress' && posPaymentMethod == 'Card') {
      this.showModal(PaymentModal.Card);
    }
    if (transactionStatus == 'InProgress' && posPaymentMethod == 'SwishMerchant') {
      this.showModal(PaymentModal.Swish);
    }
    if (transactionStatus == 'InProgress' && posPaymentMethod == 'KlarnaPayments') {
      this.showModal(PaymentModal.Klarna);
    }
  };

  beforeUnload = (ev) => {
    if (!this.props.modal) {
      return true;
    }
    (ev || window.event).returnValue = beforeUnloadMessage;
    return beforeUnloadMessage;
  };

  showModal = (modal, otherNumber) => {
    this.props.showPaymentModal(modal);
    this.setState({ otherNumber });
  };

  hideModal = () => {
    this.props.hidePaymentModal();
    this.setState({ isLoading: false, createdInvoice: null });
  };

  resetLoading = () => {
    this.setState({ isLoading: false });
  };

  addSwishPayment = (amount, phoneNumber) => {
    const paymentMethod = phoneNumber ? 'SwishMerchant' : 'Swish';
    return this.addPayment(paymentMethod, amount, phoneNumber ? {
      msisdn: formatPhoneNumberE164(phoneNumber)
    } : null);
  };

  addCashPayment = (amount) => this.addPayment('Cash', amount);

  createInvoice = (invoice) => this.addInvoicePayment('Invoice', invoice);

  createFortnoxInvoice = (invoice) => this.addInvoicePayment('FortnoxInvoice', invoice);

  addInvoicePayment = (paymentMethod, invoice) => {
    this.setState({ isLoading: true, createdInvoice: invoice });
    const { remainingAmount } = this.props;
    const invoiceData = {
      paymentMethod,
      amount: remainingAmount,
      ...invoice
    };
    return this.props.createInvoice(invoiceData)
      .finally(this.resetLoading);
  };

  addKlarnaPayment = (amount, useKlarnaPayments, phoneNumber) => {
    const paymentMethod = useKlarnaPayments ? 'KlarnaPayments' : 'Klarna';
    return this.addPayment(paymentMethod, amount, phoneNumber ? {
      msisdn: formatPhoneNumberE164(phoneNumber)
    } : null);
  };

  addExternalCardPayment = (amount) => this.addPayment('ExternalCardReader', amount);

  addGiftCardPayment = (giftCard) => {
    const { remainingAmount, customer, saleId } = this.props;
    const { id, remainingValue } = giftCard;
    const amountReceived = Math.min(remainingAmount, remainingValue);
    return this.addPayment('Voucher', amountReceived, { voucherId: id })
      .then(() => {
        if (customer) {
          this.props.fetchCustomerGiftCards(customer.customerId, saleId);
        }
      });
  };

  addOtherPayment = (amount) => {
    const { otherNumber } = this.state;
    return this.addPayment(`Other${otherNumber}`, amount);
  };

  addPayment = (paymentMethod, amountReceived, props) => {
    this.setState({ isLoading: true });
    return this.props.addPayment({ paymentMethod, amountReceived, ...props })
      .finally(this.resetLoading);
  };

  addCardPayment = (terminalId, amount, retry) => {
    return this.props.addPayment({
      paymentMethod: 'Card',
      amountReceived: amount,
      checkLatestFinTx: retry,
      terminalId
    });
  };

  completeSale = (receiptData) => {
    return this.props.completeSale(receiptData)
      .then(this.hideModal);
  };

  render() {
    const { isLoading, otherNumber, createdInvoice } = this.state;
    const {
      customer, bookingId, saleId, disabled, remainingAmount, paidAmount, grossAmount, changeDue, invoiceUrl,
      printerProgress, posOrgPrefs, printerError, enableGiftCardPayment, txProgress, saleItems, partialPaid,
      tipAmount, checksumMismatch, transactions, posUnitPrefs, invoiceId, modal
    } = this.props;
    const invoiceModal = [PaymentModal.Invoice, PaymentModal.Fortnox].includes(modal);

    return (
      <>
        <Prompt message={beforeUnloadMessage} when={!!modal} />
        <PosPaymentButtons
          enableGiftCardPayment={enableGiftCardPayment}
          remainingAmount={remainingAmount}
          showModal={this.showModal}
          disabled={!!modal || disabled}
          partialPaid={partialPaid}
          posOrgPrefs={posOrgPrefs}
        />
        {checksumMismatch && <ChecksumMismatchError />}
        {isLoading && !invoiceModal && <DialogLoader title={txt(msg.lblProcessingPayment)} />}
        {modal === PaymentModal.Swish && !isLoading && (
          <SwishPayment
            totalAmount={remainingAmount}
            onSubmit={this.addSwishPayment}
            onClose={this.hideModal}
            posOrgPrefs={posOrgPrefs}
            customer={customer}
            transactions={transactions}
          />
        )}
        {modal === PaymentModal.Klarna && !isLoading && (
          <KlarnaPayment
            totalAmount={remainingAmount}
            onSubmit={this.addKlarnaPayment}
            onClose={this.hideModal}
            posOrgPrefs={posOrgPrefs}
            customer={customer}
            transactions={transactions}
          />
        )}
        {invoiceModal && (
          <CustomerIdContext.Provider value={customer?.customerId}>
            <InvoiceModal
              isLoading={isLoading}
              saleItems={saleItems}
              grossAmount={grossAmount}
              totalAmount={remainingAmount}
              onSubmit={modal === PaymentModal.Fortnox
                ? this.createFortnoxInvoice
                : this.createInvoice}
              onClose={this.hideModal}
            />
          </CustomerIdContext.Provider>
        )}
        {modal === PaymentModal.Other && !isLoading && (
          <ExternalPayment
            totalAmount={remainingAmount}
            onSubmit={this.addOtherPayment}
            onClose={this.hideModal}
            title={getOtherPaymentMethodName(posOrgPrefs, otherNumber)}
            text="Bekräfta för att registrera betalning"
            submitText="Registrera betalning"
          />
        )}
        {modal === PaymentModal.GiftCard && !isLoading && (
          <GiftCardPayment
            totalAmount={remainingAmount}
            onSubmit={this.addGiftCardPayment}
            onClose={this.hideModal}
          />
        )}
        {modal === PaymentModal.Cash && !isLoading && (
          <CashPayment
            totalAmount={remainingAmount}
            onSubmit={this.addCashPayment}
            onClose={this.hideModal}
          />
        )}
        {modal === PaymentModal.Card && !isLoading && (
          <CardPayment
            saleId={saleId}
            txProgress={txProgress}
            totalAmount={remainingAmount}
            onSubmit={this.addCardPayment}
            onSubmitExternal={this.addExternalCardPayment}
            onClose={this.hideModal}
          />
        )}
        {modal === 'receipt' && (
          <ReceiptModal
            customer={customer}
            bookingId={bookingId}
            totalAmount={paidAmount + tipAmount}
            changeAmount={changeDue}
            tipAmount={tipAmount}
            transactions={transactions}
            printerProgress={printerProgress}
            defaultPrinterId={posUnitPrefs[pos.defaultPrinterId]}
            alwaysPrintReceipt={posUnitPrefs[pos.alwaysPrintReceipt]}
            printerError={printerError}
            onSubmit={this.completeSale}
          />
        )}
        {modal === 'invoiceReceipt' && (
          <InvoiceReceiptModal
            invoice={createdInvoice}
            invoiceId={invoiceId}
            invoiceUrl={invoiceUrl}
            saleItems={saleItems}
            grossAmount={grossAmount}
            totalAmount={paidAmount}
            onSubmit={this.completeSale}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  modal: state.pos.get('modal'),
  saleId: state.posSale.get('id'),
  posUnit: state.pos.get('posUnit'),
  status: state.posSale.get('status'),
  receiptId: getSaleReceiptId(state),
  invoiceId: state.posSale.get('invoiceId'),
  invoiceUrl: state.posSale.get('invoiceUrl'),
  paymentMethod: state.posSale.get('paymentMethod'),
  changeDue: state.posSale.get('changeDue'),
  tipAmount: state.posSale.get('tipAmount'),
  printerProgress: getPrinterProgress(state),
  checksumMismatch: state.pos.get('checksumMismatch'),
  transactionStatus: state.posSale.get('transactionStatus'),
  txProgress: state.posSale.get('txProgress'),
  customer: getSaleCustomer(state),
  saleItems: getSaleItems(state),
  posOrgPrefs: getPosOrgPrefs(state),
  posUnitPrefs: getPosUnitPrefs(state),
  totalAmount: getSaleTotalAmount(state),
  grossAmount: getSaleGrossAmount(state),
  remainingAmount: getSaleRemainingAmount(state),
  paidAmount: getSalePaidAmount(state),
  enableGiftCardPayment: getEnableGiftCardPayment(state),
  printerError: hasPrinterError(state),
  bookingId: getBookingIdForSale(state.posSale.get('items')),
  partialPaid: getSalePartialPaid(state),
  transactions: getSaleTransactions(state)
});

const mapDispatchToProps = dispatch => ({
  addPayment: payment => dispatch(addPayment(payment)),
  createInvoice: invoice => dispatch(createInvoice(invoice)),
  completeSale: data => dispatch(completeSale(data)),
  showPaymentModal: (modal) => dispatch(showPaymentModal(modal)),
  hidePaymentModal: () => dispatch(hidePaymentModal()),
  fetchCustomerGiftCards: (customerId, saleId) => dispatch(fetchCustomerGiftCards(customerId, saleId))
});

export default connect(mapStateToProps, mapDispatchToProps)(PosPayment);
