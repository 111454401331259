import React, { useMemo, useState } from 'react';
import moment from 'moment';
import ModalDialog from '@Components/dialogs/modal-dialog';
import DialogAlert from '@Components/dialogs/dialog-alert';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import VoucherForm from './voucher-form';

const VoucherModal = ({ voucher, onSubmit, onClose }) => {
  const [saved, setSaved] = useState(null);
  const [error, setError] = useState(null);

  const initialValues = useMemo(() => ({
    ...voucher,
    noRedeemBefore: moment(voucher?.noRedeemBeforeTs)
      .startOf('day').format('YYYY-MM-DD'),
    noRedeemAfter: moment(voucher?.noRedeemAfterTs)
      .startOf('day').add(voucher ? 0 : 1, 'year').subtract(voucher ? 1 : 0, 'day')
      .format('YYYY-MM-DD'),
    discountType: voucher ? voucher.discountType : 'Amount'
  }), []);

  const handleSubmit = (values) => {
    setError(null);
    const data = {
      ...values,
      noRedeemAfter: moment(values.noRedeemAfter).add(1, 'day').format('YYYY-MM-DD')
    };
    return onSubmit(data)
      .then(() => setSaved(values.code))
      .catch(error => setError(error));
  };

  if (saved) {
    return (
      <DialogAlert
        success
        icon="fa fa-check-circle"
        text={`Rabattkod ${saved} ${voucher ? 'sparades' : 'lades till'}`}
        onClose={onClose}
      />
    );
  }

  return (
    <ModalDialog
      title={voucher ? 'Ändra rabattkod' : 'Lägg till rabattkod'}
      closeButtonText="Stäng"
      contentSize="medium"
      closeOnClickOutside={false}
      onClose={onClose}
    >
      {error && (
        <AlertWithIcon warning icon="fa fa-exclamation-circle">
          Kunde inte lägga till rabattkod. Kontrollera uppgifterna och försök igen.
        </AlertWithIcon>
      )}
      <VoucherForm
        voucher={voucher}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        onClose={onClose}
      />
    </ModalDialog>
  );
};

export default VoucherModal;
