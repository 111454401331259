import { navigateTo } from '@Utils/navigate';
import { bookingChanged, removeTempBooking, bookingAdded, bookingCancelled, bookingDeleted, bookingMoved, bookingAttributeChanged, bookingStatusChanged } from '@State/booking-actions';
import { addToClipboard, bookingPasted, removeFromClipboard as _removeFromClipboard, removeClipboardDragger, removePasteDragger } from '@State/clipboard-actions';
import { showAppNotification } from '@State/app-actions';
import { toggleScheduleEditMode } from '@State/view-actions';
import { getScheduleBlocksForUpdate, scheduleBlocksUpdated } from '@State/schedule-actions';
import { getScheduleBounds } from '@Utils/schedule-utils';
import { logMeOut } from '@Utils/account';
import { isEmbeddedOrWrapped } from './embedded-util';
let reduxStore;
export function getReduxState() {
    return reduxStore.getState();
}
export function getLocationConfig() {
    return reduxStore.getState().locationConfig.toJS();
}
export function createWebkitBridges(store, state) {
    reduxStore = store;
    if (isEmbeddedOrWrapped(state)) {
        window.print = () => {
            postWebkitMessage('print');
        };
    }
    window.Cliento = {
        navigateTo,
        addBooking,
        updateBooking,
        clearTempBooking,
        deleteBooking,
        cancelBooking,
        moveBooking,
        moveViaClipboard,
        copyViaClipboard,
        pasteBooking,
        removeFromClipboard,
        addAndRemoveFromClipboard,
        cancelPasteFromClipboard,
        changeBookingFlag,
        changeBookingStatus,
        setScheduleEditMode,
        getGridViewState,
        getScheduleStartAndEnd,
        getScheduleBlocks,
        updateScheduleBlocks,
        clearAccessToken,
        openNotification
    };
}
function isContactLink(href) {
    return href.startsWith('mailto:') || href.startsWith('tel:');
}
export function handleContactLink(ev) {
    const state = getReduxState();
    if (isEmbeddedOrWrapped(state) && isContactLink(ev.target.href)) {
        ev.preventDefault();
        postWebkitMessage('contact', { action: ev.target.href });
    }
}
function updateBooking(booking) {
    console.log('wk-bridges - updateBooking()', booking);
    reduxStore.dispatch(bookingChanged(booking.id, Object.assign({}, booking)));
}
function moveBooking(moveEvent) {
    console.log('wk-bridges - moveBooking()', moveEvent);
    reduxStore.dispatch(bookingMoved(moveEvent, false /* isUndo */));
}
function clearTempBooking() {
    console.log('wk-bridges - clearTempBooking()');
    reduxStore.dispatch(removeTempBooking());
}
function addBooking(booking) {
    console.log('wk-bridges - addBooking()', booking);
    reduxStore.dispatch(bookingAdded(booking));
}
function deleteBooking(bookingId) {
    reduxStore.dispatch(bookingDeleted(parseInt(bookingId)));
}
function cancelBooking(bookingId, customerIds) {
    reduxStore.dispatch(bookingCancelled(parseInt(bookingId), customerIds));
}
function moveViaClipboard(booking) {
    const { id, sourceResourceId } = booking;
    reduxStore.dispatch(addToClipboard(parseInt(id), false, sourceResourceId));
}
function copyViaClipboard(booking) {
    const { id, sourceResourceId } = booking;
    reduxStore.dispatch(addToClipboard(parseInt(id), true, sourceResourceId));
}
function pasteBooking(booking) {
    reduxStore.dispatch(bookingPasted(booking));
}
function removeFromClipboard(bkId) {
    reduxStore.dispatch(_removeFromClipboard(parseInt(bkId)));
    reduxStore.dispatch(removeClipboardDragger());
    reduxStore.dispatch(removePasteDragger());
}
function addAndRemoveFromClipboard(booking) {
    reduxStore.dispatch(bookingAdded(booking));
    reduxStore.dispatch(_removeFromClipboard(booking.id));
    reduxStore.dispatch(removeClipboardDragger());
    reduxStore.dispatch(removePasteDragger());
}
function cancelPasteFromClipboard() {
    reduxStore.dispatch(removeClipboardDragger());
    reduxStore.dispatch(removePasteDragger());
}
function changeBookingFlag(change) {
    reduxStore.dispatch(bookingAttributeChanged(change));
}
function changeBookingStatus(change) {
    reduxStore.dispatch(bookingStatusChanged(change));
}
export function postWebkitMessage(handler, json = {}) {
    var _a;
    console.log('postWebkitMessage', handler, JSON.stringify(json));
    if (typeof window.webkit !== 'undefined') {
        const handlers = window.webkit.messageHandlers || {};
        (_a = handlers[handler]) === null || _a === void 0 ? void 0 : _a.postMessage(JSON.stringify(json));
    }
    else {
        // console.error('No window.webkit object defined');
    }
}
export function notifyClipboardState(state) {
    postWebkitMessage('clipboardState', state);
}
export function notifyColumnHeaderState(state) {
    postWebkitMessage('columnHeaderState', state);
}
function getGridViewState() {
    const state = reduxStore.getState().gridViewState.toJS();
    postWebkitMessage('gridViewState', state);
}
function setScheduleEditMode(state) {
    reduxStore.dispatch(toggleScheduleEditMode(state));
}
function getScheduleStartAndEnd({ resourceId, date }) {
    const { schedulesByResource } = reduxStore.getState();
    const times = getScheduleBounds(schedulesByResource, resourceId, date);
    postWebkitMessage('scheduleStartAndEnd', {
        resourceId,
        date,
        start: times ? times.startTime.format('HH:mm') : null,
        end: times ? times.endTime.format('HH:mm') : null
    });
}
function getScheduleBlocks(exception) {
    const blocks = reduxStore.dispatch(getScheduleBlocksForUpdate(exception));
    postWebkitMessage('scheduleBlocks', {
        exception,
        blocks
    });
}
function updateScheduleBlocks({ resourceId, blocks }) {
    reduxStore.dispatch(scheduleBlocksUpdated(resourceId, blocks));
}
function clearAccessToken() {
    logMeOut(true);
}
function openNotification(data) {
    console.log('openNotification', data);
    reduxStore.dispatch(showAppNotification(data));
}
