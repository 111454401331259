import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { txt } from '@Utils/i18n-util';
import { PosSaleRow, PosSaleRowTotal } from '@Components/pos/style';
import PosSaleTransactionsModal from './pos-sale-transactions-modal';
import msg from './pos-sale.msg';

const SaleFooter = ({
  totalValue,
  paidAmountValue,
  remainingAmountValue,
  transactions,
  prePayments
}) => {
  const [showTransactions, setShowTransactions] = useState(false);
  const hasTransactions = !transactions?.isEmpty() || prePayments?.size > 0;

  return (
    <>
      <PosSaleRow bold>
        <p>{txt(msg.lblTotal)}</p>
        <p>{totalValue}</p>
      </PosSaleRow>
      <PosSaleRow bold onClick={hasTransactions ? () => setShowTransactions(!showTransactions) : null}>
        <p>
          {txt(msg.lblPaid)}
          {hasTransactions && (
            <span className="text-muted">
              Visa transaktioner
              <i className="fa fa-chevron-circle-right" />
            </span>
          )}
        </p>
        <p>{paidAmountValue}</p>
      </PosSaleRow>
      {showTransactions && (
        <PosSaleTransactionsModal
          prePayments={prePayments}
          transactions={transactions}
          onClose={() => setShowTransactions(false)}
        />
      )}
      <PosSaleRowTotal>
        <p>{txt(msg.lblToPay)}</p>
        <p>{remainingAmountValue}</p>
      </PosSaleRowTotal>
    </>
  );
};

SaleFooter.propTypes = {
  totalValue: PropTypes.string,
  paidAmountValue: PropTypes.string,
  remainingAmountValue: PropTypes.string,
  transactions: PropTypes.object
};

export default SaleFooter;
