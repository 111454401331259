import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import { colors } from '@Components/ui/styled/variables';
import styled from 'styled-components';
import { getDateUrl, navigateAsync } from '@Utils/navigate';
import GridScroller from '@Components/calendar/grid/grid-scroller';
import { getGridProps } from '@Components/calendar/grid/grid-selectors';
import { txt } from '@Utils/i18n-util';
import msg from './date-navigator.msg';
import DateNavigatorSkipWeeks from './date-navigator-skip-weeks';

export const Wrapper = styled.div`
  display: inline-flex;
  cursor: pointer !important;
  align-items: ${props => props.phoneMode ? 'flex-start' : 'center'};
`;

export const StyledLink = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  line-height: 13px;
  border: 0;
  min-width: 30px;
  background: ${props => props.today ? colors.primaryDark : colors.softGray80};
  user-select: none;
  padding: 6px;
  height: 26px;
  color: ${props => props.today ? colors.softGray20 : colors.textColor};

  i {
     margin: auto;
  }
  :hover, :active, :focus {
    color: ${props => props.today ? colors.softGray20 : colors.textColor};
    text-decoration: none;
  }
  :active {
    background: ${props => props.today ? colors.primaryDark : colors.softGray};
  }
`;

const TodayLink = styled(StyledLink)`
  /* text-transform: uppercase; */
  min-width: 55px;
  font-weight: 600;
  margin: 0 4px;
`;

class DateNavigator extends React.Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    viewDateStartEnd: PropTypes.object.isRequired,
    todayInView: PropTypes.bool.isRequired
  };

  render() {
    const { routeParams, phoneMode, todayInView } = this.props;

    return (
      <Wrapper phoneMode={phoneMode}>
        <StyledLink phoneMode={phoneMode} onClick={this.handleNavigate('back', routeParams)}><i className="fa fa-chevron-left" /></StyledLink>
        <TodayLink phoneMode={phoneMode} onClick={this.handleNavigate('today', routeParams)} today={todayInView}>{txt(msg.today)}</TodayLink>
        <StyledLink phoneMode={phoneMode} onClick={this.handleNavigate('forward', routeParams)}><i className="fa fa-chevron-right" /></StyledLink>
        <DateNavigatorSkipWeeks onClick={(weeks) => this.handleSkipWeeks(weeks, routeParams)} />
      </Wrapper>
    );
  }

  handleSkipWeeks = (weeks, routeParams) => {
    navigateAsync(getDateUrl(`skip-${weeks}`, routeParams));
  };

  handleNavigate = (direction, routeParams) => () => {
    if (this.props.todayInView && direction === 'today') {
      new GridScroller(this.props.gridProps).scrollToMoment(moment(), true);
    }
    navigateAsync(getDateUrl(direction, routeParams));
  };
}

const mapStateToProps = (state, ownProps) => {
  const gridProps = getGridProps(state, ownProps);
  const { viewDateStartEnd } = gridProps;
  const todayInView = moment().isBetween(viewDateStartEnd.start, viewDateStartEnd.end, 'day', '[)');

  return {
    todayInView,
    gridProps
  };
};

export default connect(
  mapStateToProps
)(DateNavigator);
