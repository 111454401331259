import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { formatPhoneNumber } from '@Utils/phone-util';
import CurrencyUtil from '@Utils/currency-util';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { formatSwishNumber, getUseSwishIntegration } from '@Utils/pos-utils';
import { pos } from '@Utils/preference-keys';
import { cancelPayment, fetchPaymentStatus, getSwishQrCodeObjectUrl, paymentResult } from '@State/pos-actions';
import Text from '@Components/ui/styled/text-element';
import SetAmountModal from '@Components/pos/payment/set-amount-modal';
import Loader from '@Components/ui/loader';
import Panel from '@Components/ui/styled/panel';
import { Flex } from '@Components/ui/styled/main';
import DialogAlert from '@Components/dialogs/dialog-alert';
import PaymentStatusUpdate from './payment-status-update';

const ContentContainer = styled.div`
  margin: 10px 20px 20px 20px;
  text-align: center;
`;

const LoaderContainer = styled.div`
  position: relative;
  height: 100px;
`;

const SwishPayment = ({
  totalAmount, onSubmit, onClose, posOrgPrefs, customer, transactions
}) => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const posUnit = useSelector(state => state.pos.get('posUnit'));
  const transactionStatus = useSelector(({ posSale }) => {
    return posSale.get('transactionStatus') || posSale.getIn(['txProgress', 'transactionStatus']);
  });
  const saleId = useSelector(state => state.posSale.get('id'));
  const items = useSelector(state => state.posSale.get('items'));
  const message = items?.first()?.get('description');

  const {
    [pos.swishPayeeAlias]: swishPayeeAlias, [pos.paymentTipEnabled]: tipEnabled
  } = posOrgPrefs;
  const useSwishIntegration = getUseSwishIntegration(posOrgPrefs, transactions);
  const customerPhoneNumber = useSwishIntegration
    ? formatPhoneNumber(customer?.phoneNumber)
    : null;
  const useQrCode = !useSwishIntegration && swishPayeeAlias;

  const [amount, setAmount] = useState(totalAmount);
  const [showAmount, setShowAmount] = useState(null);
  const [showQrCode, setShowQrCode] = useState(useQrCode);
  const [objectUrl, setObjectUrl] = useState(null);
  const [loading, setLoading] = useState(useQrCode);
  const [phoneNumber, setPhoneNumber] = useState(customerPhoneNumber || '');
  const [editPhoneNumber, setEditPhoneNumber] = useState(!customerPhoneNumber);
  const notUsingIntegration = !useSwishIntegration || showQrCode;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [editPhoneNumber]);

  useEffect(() => {
    if (showQrCode) {
      setLoading(true);
      getSwishQrCodeObjectUrl(swishPayeeAlias, amount, message)
        .then(setObjectUrl)
        .finally(() => setLoading(false));
    }
  }, [amount, showQrCode]);

  const onSetAmount = (newAmount) => {
    setAmount(showAmount === 'tip'
      ? totalAmount + newAmount
      : newAmount);
    setTimeout(() => setShowAmount(null), 100);
  };

  const onShowQrCode = () => {
    setShowQrCode(true);
    setPhoneNumber(null);
  };

  const onResetPaymentResult = () => {
    dispatch(paymentResult(posUnit.get('vunitId'), null));
  };

  const onRecipientChange = (ev) => {
    setPhoneNumber(ev.target.value);
  };

  const onEditPhoneNumber = () => {
    setEditPhoneNumber(true);
    setPhoneNumber('');
  };

  if (showAmount) {
    return (
      <SetAmountModal
        amountType={showAmount}
        totalAmount={totalAmount}
        onSubmit={onSetAmount}
        onClose={() => setShowAmount(null)}
      />
    );
  }

  if (transactionStatus === 'Rejected') {
    return (
      <DialogAlert
        warning
        icon="fa fa-exclamation-triangle"
        text="Betalning kunde inte genomföras, eller avbröts av användaren"
        onClose={onResetPaymentResult}
      />
    );
  }

  if (transactionStatus === 'InProgress') {
    return (
      <ModalDialog
        title="Genomför köp..."
        hideCloseButton
        closeOnClickOutside={false}
        buttons={[{
          name: 'Avbryt',
          gray: true,
          onClick: () => dispatch(cancelPayment(saleId))
            .then(onResetPaymentResult)
        }]}
      >
        <ContentContainer>
          <LoaderContainer>
            <Loader />
            <PaymentStatusUpdate
              saleId={saleId}
              fetchPaymentStatus={() => dispatch(fetchPaymentStatus(saleId))}
            />
          </LoaderContainer>
          <Text>
            Be kunden godkänna betalningen i Swish-appen
          </Text>
        </ContentContainer>
      </ModalDialog>
    );
  }

  return (
    <ModalDialog
      title="Betala med Swish"
      onClose={onClose}
      closeOnClickOutside={false}
      onEnter={() => onSubmit(amount, phoneNumber)}
      buttons={[useSwishIntegration && !showQrCode && {
        name: 'Skicka till telefon',
        primary: !showQrCode,
        disabled: !phoneNumber,
        onClick: () => onSubmit(amount, phoneNumber)
      }, notUsingIntegration && {
        name: 'Betalning mottagen',
        gray: useSwishIntegration && !showQrCode,
        primary: !useSwishIntegration || showQrCode,
        onClick: () => onSubmit(amount)
      }, !showQrCode && {
        name: 'Visa QR-kod',
        gray: true,
        onClick: onShowQrCode
      }, notUsingIntegration && {
        name: 'Dela upp betalning',
        gray: true,
        onClick: () => setShowAmount('split')
      }, notUsingIntegration && tipEnabled && { // Not available with Swish Merchant yet
        name: 'Lägg till dricks',
        gray: true,
        onClick: () => setShowAmount('tip')
      }]}
    >
      <ContentContainer>
        {loading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <>
            <Text marginBottom={!objectUrl} fs={30}>{CurrencyUtil.accountCurrency(amount, 2)}</Text>
            {!useSwishIntegration && !objectUrl && (
              <Text muted>
                Be kunden Swisha beloppet och bekräfta att betalningen mottagits
              </Text>
            )}
            {objectUrl && (
              <>
                <img src={objectUrl} className="pos-swish-qr-code" />
                <div className="text-muted text-center">
                  {formatSwishNumber(swishPayeeAlias)}
                </div>
              </>
            )}
          </>
        )}
      </ContentContainer>
      {useSwishIntegration && !showQrCode && !loading && (
        <>
          <Panel className="mb1" onClick={editPhoneNumber ? null : onEditPhoneNumber}>
            {editPhoneNumber ? (
              <Flex>
                <img src="/swish-color.svg" className="pos-phone-input-logo" />
                <input
                  type="tel"
                  ref={inputRef}
                  value={phoneNumber}
                  placeholder="Ange mobilnummer"
                  className="pos-phone-input-number"
                  onChange={onRecipientChange}
                />
              </Flex>
            ) : (
              <Flex>
                <img src="/swish-color.svg" className="pos-phone-input-logo" />
                <div className="pos-phone-input-number">{customerPhoneNumber}</div>
                <i className="fa fa-times-circle pos-phone-input-edit" />
              </Flex>
            )}
          </Panel>
          <p className="text-muted text-right">
            {formatSwishNumber(swishPayeeAlias)}
          </p>
        </>
      )}
    </ModalDialog>
  );
};

export default SwishPayment;
