import moment from 'moment';
import interact from 'interactjs';
import ReactDOM from 'react-dom';
import { toggleDragging } from '@State/view-actions';
import { AppVersionEditSchedule, isMinVersion } from '@Utils/embedded-util';
import CoordHelper from './grid-coord-helper';

export class GridMarkerDragHandler {
  constructor(marker) {
    this.marker = marker;

    this.cHelper = new CoordHelper(marker.props);

    this.handlers = {
      dragStart: this.onDragStart.bind(this),
      dragMove: this.onDragMove.bind(this),
      dragEnd: this.onDragEnd.bind(this),
      hold: this.onHold.bind(this),
      tap: this.onTap.bind(this)
    };

    this.makeItDraggable();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.cHelper = new CoordHelper(nextProps);
    const ch = this.coordsHelper;
    const newH = nextProps.duration / 60 * ch.hourHeight;
    const newEndTime = moment(this.state.startTime).add(nextProps.duration, 'm');

    const newState = {
      coords: { ...this.state.coords, height: ch.withoutMargin(newH) },
      endTime: newEndTime
    };

    this.setState(newState);
  }

  get coordsHelper() {
    return this.cHelper;
  }

  get state() {
    return this.marker.state;
  }

  get props() {
    return this.marker.props;
  }

  setState(state) {
    this.marker.setState(state);
  }

  dispose() {
    if (this.interactable) {
      this.interactable.unset();
    }
  }

  makeItDraggable() {
    const manualStart = interact.supportsTouch() && (window.navigator.userAgent.indexOf('Windows NT 10') == -1);
    interact.pointerMoveTolerance(5);
    const evOwEl = document.getElementById('eventOwner');

    const scrollEl = document.getElementById('gridcontainer');
    this.interacting = false;
    this.interactable = interact(evOwEl)
      .pointerEvents({
        holdDuration: 150
      })
      .draggable({
        manualStart,
        inertia: false,
        autoScroll: {
          container: scrollEl,
          margin: 25,
          distance: 1,
          interval: 1
        },
        restrict: {
          restriction: evOwEl
        }
      })
      .preventDefault('never')
      .styleCursor(false)
      .origin('parent')
      .on('hold', this.handlers.hold)
      // .on('tap', this.handlers.tap) /* dont use tap, as it will still trigger a click event, which is causing loads of other problems */
      .on('click', (ev) => {
        if (this.interacting || window.interHack) {
          ev.stopImmediatePropagation();
          ev.preventDefault();
        } else {
          this.handlers.tap(ev);
        }
      })
      .on('dragstart', this.handlers.dragStart)
      .on('dragmove', this.handlers.dragMove)
      .on('dragend', this.handlers.dragEnd)
      .on('move', (event) => {
        if (event.interaction.interacting()) {
          // This is supposedly preventing the div to scroll while moving/marking.
          event.preventDefault();
        }
      })
      .on('touchmove', (event) => {
        if (this.interacting) {
          // This is supposedly preventing the div to scroll while moving/marking.
          event.preventDefault();
        }
      });
  }

  onHold(event) {
    event.preventDefault();
    event.stopPropagation();

    try {
      const { interaction } = event;
      if (!interaction.interacting()) {
        interaction.start({ name: 'drag' },
          event.interactable,
          event.currentTarget);
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  }

  onTap(event) {
    event.stopPropagation();
    event.preventDefault();

    try {
      if (!this.marker.isCurrentViewBookable()) {
        return;
      }

      // Event clientX/Y on 'tap' event has a different offset than 'drag/move' events, so adjusting for this
      //

      /* This is for TAP events */
      // const tapX = event.pageX;
      // const tapY = event.pageY;

      /* This is for CLICK events */
      const tapX = event.offsetX;// - targetOffset.left;
      const tapY = event.offsetY;// - targetOffset.top;

      const ch = this.coordsHelper;
      const newColIdx = ch.columnIdx(tapX);
      const newY = ch.withYMargin(ch.snapToGridY(tapY));
      const newX = ch.withXMargin(ch.snapToGridX(tapX));
      const leftPct = ch.leftPct(newX);
      const widthPct = ch.widthPct(ch.draggingWidth);
      const newStartTime = ch.timeFor(newX, newY);

      const markerHeight = this.props.singleClickSelectMode === 'hour' ? ch.hourHeight : ch.rowHeight;
      const newEndTime = ch.timeFor(newX, newY + markerHeight);
      const duration = newEndTime.diff(newStartTime, 'minutes');

      this.setState({
        coords: {
          ...this.state.coords,
          top: newY,
          leftPct,
          widthPct,
          height: ch.withoutMargin(ch.hourHeight),
          colIdx: newColIdx
        },
        visible: false,
        startTime: newStartTime,
        endTime: newEndTime
      });

      const chipEl = ReactDOM.findDOMNode(this.marker);

      const endEvent = {
        chipEl,
        colIdx: newColIdx,
        startTime: newStartTime,
        endTime: newEndTime,
        duration
      };

      const { scheduleEditMode } = this.marker.props;
      if (scheduleEditMode) {
        this.marker.props.editSchedule(endEvent);
      } else {
        this.marker.props.onDragSelect(endEvent);
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  }

  onDragStart(event) {
    try {
      /*
      For some reason I had used this line of code to fix a bug that I cant reprodice anymore:

      const yCoord = event.interaction.downEvent.layerY || event.clientY;

      But that fails with Chrome on Windows and seeminlgy on Firefox on Android. So I wrote this list of browsers that works with clientY:

       These browsers works with event.clientY:
       Mac:
         - Safari, Chrome, Firefox
       Windows:
         - Edge, Chrome, Firefox
       Ios:
         - Safari (iphone), Chrome (iphone)
       Android:
         - Chrome, Firefox

       Funny enough, that seems to cover everything, so the bug must have vanished.. But leaving this comment in case it re-appears.
       */

      const yCoord = event.clientY;

      if (!this.marker.isCurrentViewBookable()) {
        return;
      }
      const ch = this.coordsHelper;
      const newColIdx = ch.columnIdx(event);
      const newY = ch.withYMargin(ch.snapToGridY(yCoord));
      const newX = ch.withXMargin(ch.snapToGridX(event));
      const leftPct = ch.leftPct(newX);
      const widthPct = ch.widthPct(ch.draggingWidth);
      const newStartTime = ch.timeFor(newX, newY);
      const newEndTime = ch.timeFor(newX, newY + ch.rowHeight);

      toggleDragging(true);
      this.setState({
        coords: {
          ...this.state.coords,
          top: newY,
          leftPct,
          widthPct,
          height: ch.withoutMargin(ch.rowHeight),
          colIdx: newColIdx
        },
        resizeChipOriginalHeight: ch.rowHeight,
        orirginalChipTop: newY,
        visible: true,
        startTime: newStartTime,
        endTime: newEndTime
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  }

  onDragMove(event) {
    this.interacting = true;

    try {
      const ch = this.coordsHelper;
      const originalTop = this.state.orirginalChipTop;
      const dh = (this.state.resizeChipOriginalHeight + event.dy);
      const x = Math.ceil(this.state.coords.colIdx * ch.columnWidth);
      const newH = ch.snapToGridY(dh);
      const topHeight = ch.calcTopHeight(dh, originalTop, newH);
      const newStartTime = ch.timeFor(x, topHeight.top);
      const newEndTime = ch.timeFor(x, topHeight.top + topHeight.height);

      this.setState({
        coords: {
          ...this.state.coords,
          top: topHeight.top,
          height: ch.withoutMargin(topHeight.height)
        },
        resizeChipOriginalHeight: dh,
        startTime: newStartTime,
        endTime: newEndTime
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  }

  onDragEnd(event) {
    /* Delaying the reset of interacting, so that the click event that occurs after releasing, dont trigger a new "tap/click" outside the dragged area */
    setTimeout(() => {
      this.interacting = false;
    }, 100);

    // this.interacting = false;

    try {
      if (!this.marker.isCurrentViewBookable()) {
        return;
      }

      const chipEl = ReactDOM.findDOMNode(this.marker);

      const duration = this.state.endTime.diff(this.state.startTime, 'minutes');
      const endEvent = {
        chipEl,
        colIdx: this.state.coords.colIdx,
        startTime: this.state.startTime,
        endTime: this.state.endTime,
        duration
      };

      toggleDragging(false);
      this.setState({
        visible: false
      });

      const { scheduleEditMode, embeddedInApp, embedVersion } = this.marker.props;
      if (scheduleEditMode && embeddedInApp && !isMinVersion(embedVersion, AppVersionEditSchedule)) {
        // Only run this for older versions of the app
        this.marker.props.editSchedule(endEvent);
      } else {
        this.marker.props.onDragSelect(endEvent);
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  }
}
