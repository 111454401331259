import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popover from '@Components/ui/popover';
import { createStockTake } from '@State/stock-taking-actions';
import { getDefaultDateLabel } from '@Components/reports/reports-helpers';
import { getSectionUrl, navigate } from '@Utils/navigate';
import AddItemForm from '../../sortable-list/add-item-form';

const styleDisplayIB = { display: 'inline-block' };

class StockTakingToolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addStockTake: false
    };
  }

  render() {
    return (
      <div className="columns-toolbar">
        {this.renderAddStockTakeButton()}
      </div>
    );
  }

  renderAddStockTakeButton() {
    return (
      <Popover
        isOpen={this.state.addStockTake}
        body={this.addStockTakeContent()}
        preferPlace="below"
        className="Popover-big-radius"
        onOuterAction={this.handleCloseStockTakePopover}
      >
        <div style={styleDisplayIB}>
          <button type="button" className="btn btn-cta" onClick={this.handleOpenStockTakePopover}>Ny inventering</button>
        </div>
      </Popover>
    );
  }

  handleOpenStockTakePopover = () => {
    this.setState({ addStockTake: true });
  };

  handleCloseStockTakePopover = () => {
    this.setState({ addStockTake: false });
  };

  handleAddStockTake = ({ name }) => {
    return this.props
      .onAddStockTake({ label: name })
      .then(() => this.handleCloseStockTakePopover());
  };

  addStockTakeContent() {
    const { stockTakes } = this.props;

    return (
      <AddItemForm
        onClosePopover={this.handleCloseStockTakePopover}
        onAddItem={this.handleAddStockTake}
        placeholder="Ange namn på inventeringen"
        name={getDefaultDateLabel('Inventering')}
        submitText="Skapa"
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { stockTakes } = state;

  return {
    stockTakes
  };
};

const mapDispatchToProps = (dispatch, { routeParams }) => {
  return {
    onAddStockTake: (stockTake) => {
      return dispatch(createStockTake(stockTake))
        .then(id => navigate(getSectionUrl(routeParams, 'admin/stock-taking', id)));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StockTakingToolbar);
