import axios from 'axios';
import moment from 'moment';
import { STAFF_PNO_UPDATED } from '@State/staff-actions';
import {
  axiosDefault, axiosErrorHandler, checkStatusAxios, prefixUrl
} from '@Utils/ajax-util';
import { networkFailure } from '@State/app-actions';
import { prefixApiUrl } from '@Utils/url-util';

export const CASHIERS_LOADED = 'CASHIERS_LOADED';
export const USERS_LOADED = 'USERS_LOADED';
export const USER_ADDED = 'USER_ADDED';
export const USER_UPDATED = 'USER_UPDATED';
export const USER_PIN_UPDATED = 'USER_PIN_UPDATED';
export const USER_NAME_UPDATED = 'USER_NAME_UPDATED';
export const USER_DELETED = 'USER_DELETED';
export const USER_POS_ROLES_LOADED = 'USER_POS_ROLES_LOADED';
export const USER_POS_ROLE_UPDATED = 'USER_POS_ROLE_UPDATED';
export const USER_RESOURCE_ADDED = 'USER_RESOURCE_ADDED';
export const USER_RESOURCE_REMOVED = 'USER_RESOURCE_REMOVED';
export const USER_ACCESS_ADDED = 'USER_ACCESS_ADDED';
export const USER_ACCESS_REMOVED = 'USER_ACCESS_REMOVED';
export const USER_POS_ROLE_LOADED = 'USER_POS_ROLE_LOADED';
export const USER_POS_ROLE_DELETED = 'USER_POS_ROLE_DELETED';
export const USER_CREATED = 'USER_CREATED';
export const USER_NAME_CHECKED = 'USER_NAME_CHECKED';
export const USER_LOGIN_CLEAR = 'USER_LOGIN_CLEAR';
export const USER_ADDED_LOGIN = 'USER_ADDED_LOGIN';
export const USER_REPLACED_LOGIN = 'USER_REPLACED_LOGIN';
export const USER_STAFF_PNO_UPDATED = 'USER_STAFF_PNO_UPDATED';
export const USER_STAFF_JOURNAL_DISABLED = 'USER_STAFF_JOURNAL_DISABLED';
export const USER_STAFF_JOURNAL_ENABLED = 'USER_STAFF_JOURNAL_ENABLED';
export const USER_POS_ROLE_TOGGLE_UPDATED = 'USER_POS_ROLE_TOGGLE_UPDATED';
export const USER_LOGIN_CHANGED = 'USER_LOGIN_CHANGED';
export const USER_PERMISSIONS_UPDATED = 'USER_PERMISSIONS_UPDATED';
export const USER_APIKEYS_LOADED = 'USER_APIKEYS_LOADED';
export const USER_APIKEY_GENERATED = 'USER_APIKEY_GENERATED';
export const USER_POS_RESOURCE_UPDATED = 'USER_POS_RESOURCE_UPDATED';
export const USER_EMPLOYMENT_UPDATED = 'USER_EMPLOYMENT_UPDATED';
export const USER_SALES_TERMS_UPDATED = 'USER_SALES_TERMS_UPDATED';
export const USER_TITLE_UPDATED = 'USER_TITLE_UPDATED';
export const USER_PRESENTATION_UPDATED = 'USER_PRESENTATION_UPDATED';

export function fetchCashiers() {
  return (dispatch) => {
    const url = prefixUrl('/users/cashiers/');
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(cashiers => dispatch({ type: CASHIERS_LOADED, cashiers }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchUsers() {
  return (dispatch) => {
    const url = prefixUrl('/users/');
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(users => dispatch({ type: USERS_LOADED, users }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function createUser(user) {
  return (dispatch) => {
    const url = prefixUrl('/users/sparse/');
    const config = axiosDefault();

    return axios.post(url, user, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((user) => {
        dispatch({ type: USER_CREATED, user });
        return user;
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function addUserLogin({ userId, ...user }) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${userId}/add-login/`);
    const config = axiosDefault();

    return axios.post(url, user, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => dispatch({ type: USER_ADDED_LOGIN, userId, user }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function replaceUserLogin({ userId, ...user }) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${userId}/replace-login/`);
    const config = axiosDefault();

    return axios.post(url, user, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: USER_REPLACED_LOGIN, user, userId }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function setUserPno(userId, pno) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${userId}/set-pno`);
    const config = axiosDefault();

    return axios.put(url, { pno }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: USER_STAFF_PNO_UPDATED, pno, userId }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function setUserTitle(userId, title) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${userId}/title`);
    const config = axiosDefault();

    return axios.put(url, { title }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: USER_TITLE_UPDATED, title, userId }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function setUserPresentation(userId, presentation) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${userId}/title`);
    const config = axiosDefault();

    return axios.put(url, { presentation }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: USER_PRESENTATION_UPDATED, presentation, userId }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function staffJournalToggle(isEnabled, userId) {
  return (dispatch) => {
    const urlPart = isEnabled ? 'enable' : 'disable';
    const action = isEnabled ? USER_STAFF_JOURNAL_ENABLED : USER_STAFF_JOURNAL_DISABLED;
    const url = prefixUrl(`/users/${userId}/staffjournal/${urlPart}`);
    const config = axiosDefault();

    return axios.post(url, {}, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: action, userId }))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function checkUserName(userName, userId) {
  return (dispatch) => {
    const url = prefixUrl(`/users/check-username?username=${encodeURIComponent(userName)}`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((user) => dispatch({ type: USER_NAME_CHECKED, user, userId }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function addUser(user) {
  return (dispatch) => {
    const url = prefixUrl('/users/invite');
    const config = axiosDefault();

    return axios.post(url, user, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((user) => {
        dispatch({ type: USER_ADDED, user });
        return user;
      })
      .catch((error) => {
        if (error.response.status === 400) {
          const title = 'Användaren finns redan';
          const message = `Det finns redan en användare eller inbjudan för ${user.email}`;
          dispatch(networkFailure(title, message));
        } else {
          axiosErrorHandler(error, dispatch);
        }
      });
  };
}

export function updateUser(user) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${user.id}`);
    const config = axiosDefault();

    return axios.put(url, user, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch(({ type: USER_UPDATED, user })))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function setUserOperatorPin(userId, operatorPin) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${userId}/operator-pin`);
    const config = axiosDefault();

    return axios.put(url, { operatorPin }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: USER_PIN_UPDATED, userId, operatorPin }));
  };
}

export function deleteUser(userId) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${userId}`);
    const config = axiosDefault();

    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: USER_DELETED, userId }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function cancelInvite(inviteId) {
  return (dispatch) => {
    const url = prefixUrl(`/users/invite/${inviteId}`);
    const config = axiosDefault();

    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: USER_DELETED, inviteId }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchUserPosRole(userId) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${userId}/pos-roles/`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ roles }) => dispatch({ type: USER_POS_ROLE_LOADED, userId, roles }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchUserPosRoles() {
  return (dispatch) => {
    const url = prefixUrl('/users/pos-roles/');
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ roles }) => dispatch({ type: USER_POS_ROLES_LOADED, roles }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function setUserPosRole(userId, posOrgId, cashierRole) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${userId}/pos-role`);
    const config = axiosDefault();

    return axios.put(url, { posOrgId, cashierRole }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({
        type: USER_POS_ROLE_UPDATED, userId, posOrgId, cashierRole
      }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deleteUserPosRole(userId, posOrgId) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${userId}/pos-role/${posOrgId}`);
    const config = axiosDefault();

    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: USER_POS_ROLE_DELETED, userId, posOrgId }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateUserName(userId, name) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${userId}/name`);
    const config = axiosDefault();

    return axios.put(url, { name }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: USER_NAME_UPDATED, userId, name }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateUserPno(locUserId, pno) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${locUserId}/set-pno`);
    const config = axiosDefault();

    return axios.put(url, { pno }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: STAFF_PNO_UPDATED, pno, locUserId }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

function userResourceAdded(userId, resource) {
  return {
    type: USER_RESOURCE_ADDED,
    userId,
    resource: {
      resourceId: resource.id,
      resourceName: resource.name
    }
  };
}

function userResourceRemoved(userId, resource) {
  return {
    type: USER_RESOURCE_REMOVED,
    userId,
    resourceId: resource.id
  };
}

export function addUserResource(userId, resource) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${userId}/resource-association`);
    const config = axiosDefault();

    return axios.post(url, { resourceId: resource.id }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch(userResourceAdded(userId, resource)))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function removeUserResource(userId, resource) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${userId}/resource-association/${resource.id}`);
    const config = axiosDefault();

    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch(userResourceRemoved(userId, resource)))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

function userAccessAdded(userId, access) {
  return {
    type: USER_ACCESS_ADDED,
    userId,
    access
  };
}

function userAccessRemoved(userId, access) {
  return {
    type: USER_ACCESS_REMOVED,
    userId,
    access
  };
}

export function addUserAccess(userId, access) {
  return (dispatch) => {
    const urlPart = access.entity === 'Grp' ? 'group-acl' : 'resource-acl';
    const url = prefixUrl(`/users/${userId}/${urlPart}/${access.entityId}`);
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch(userAccessAdded(userId, access)))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function removeUserAccess(userId, access) {
  return (dispatch) => {
    const urlPart = access.entity === 'Grp' ? 'group-acl' : 'resource-acl';
    const url = prefixUrl(`/users/${userId}/${urlPart}/${access.entityId}`);
    const config = axiosDefault();

    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch(userAccessRemoved(userId, access)))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function updateUserPosRoleToggle(userId, isEnabled = false) {
  return (dispatch) => {
    const posRoleState = isEnabled ? 'enable' : 'disable';
    const url = prefixUrl(`/users/${userId}/pos-role/${posRoleState}`);
    const config = axiosDefault();

    return axios.post(url, {}, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: USER_POS_ROLE_TOGGLE_UPDATED, isEnabled, userId }))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function setUserLogin(userId, isUserLoginEnabled = false) {
  return (dispatch) => {
    const loginEnabled = isUserLoginEnabled ? 'enable' : 'disable';
    const url = prefixUrl(`/users/${userId}/login/${loginEnabled}`);
    const config = axiosDefault();

    return axios.post(url, {}, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: USER_LOGIN_CHANGED, userId, isUserLoginEnabled }))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function makeOwner(userId) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${userId}/owner`);
    const config = axiosDefault();

    return axios.put(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => axiosErrorHandler(error, dispatch))
      .then(() => dispatch(fetchUsers()));
  };
}

export function removeOwner(userId) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${userId}/owner`);
    const config = axiosDefault();

    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => axiosErrorHandler(error, dispatch))
      .then(() => dispatch(fetchUsers()));
  };
}

export function updateUserPermissions(userId, permissions) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${userId}/permissions`);
    const config = axiosDefault();

    return axios.put(url, { permissions }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: USER_PERMISSIONS_UPDATED, userId, permissions }))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function fetchApiKeys() {
  return (dispatch) => {
    const url = prefixUrl('/users/api-keys/');
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ keys }) => dispatch({ type: USER_APIKEYS_LOADED, keys }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function generateApiKey(userId) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${userId}/generate-apikey`);
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ apiKey }) => {
        const key = { apiKey, created: moment().toISOString() };
        dispatch({ type: USER_APIKEY_GENERATED, userId, key });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function setUserPosResource(userId, resourceId) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${userId}/use-prices-from-resource`);
    const config = axiosDefault();

    return axios.post(url, { resourceId }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => dispatch({ type: USER_POS_RESOURCE_UPDATED, userId, resourceId }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function saveUserEmployment(userId, employment) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${userId}/set-employment`);
    const config = axiosDefault();

    return axios.put(url, employment, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => dispatch({ type: USER_EMPLOYMENT_UPDATED, userId, employment }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function saveUserSalesTerms(userId, salesTerms) {
  return (dispatch) => {
    const url = prefixUrl(`/users/${userId}/set-product-sales-terms`);
    const config = axiosDefault();

    return axios.put(url, salesTerms, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(() => dispatch({ type: USER_SALES_TERMS_UPDATED, userId, salesTerms }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchReleaseNotes(offset, limit) {
  return (dispatch) => {
    const query = `?offset=${offset}&limit=${limit}`;
    const url = prefixApiUrl(`/release-notes/${query}`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}
