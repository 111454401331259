import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { savePrivatePreferences } from '@State/preferences-actions';
import JsonEditor from '@Components/ui/jsoneditor/jsoneditor';
import Loader from '@Components/ui/loader';

const AdminConfigPrivatePrefs = () => {
  const dispatch = useDispatch();
  const privatePreferences = useSelector(state => state.preferencesViewState.get('privatePreferences'));
  if (!privatePreferences) {
    return (<Loader />);
  }
  return (
    <JsonEditor
      title="Private Prefs"
      json={privatePreferences}
      onSubmit={(values) => dispatch(savePrivatePreferences(values))}
    />
  );
};

export default AdminConfigPrivatePrefs;
