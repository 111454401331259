import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { txt } from '@Utils/i18n-util';
import Button from '@Components/ui/button';
import Header, { Toolbar } from '../nav/header';
import CampaignModal from './form/campaign-modal';
import msg from './campaign.msg';

const CampaignHeader = () => {
  const routeParams = useParams();
  const [showModal, setShowModal] = useState(false);

  const onNewCampaign = useCallback(() => {
    setShowModal(true);
  }, []);

  const onHideModal = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <Header
      routeParams={routeParams}
      className="thin-header with-sidebar"
    >
      <Toolbar>
        <Button primary small onClick={onNewCampaign}>
          {txt(msg.btnNewCampaign)}
        </Button>
        {showModal && (
          <CampaignModal isNew onClose={onHideModal} routeParams={routeParams} />
        )}
      </Toolbar>
    </Header>
  );
};

export default CampaignHeader;
