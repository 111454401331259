import { fromJS, Map, List } from 'immutable';
import { CLEAR_LOCATION_STATE } from './account-actions';
import { doMoveGroup, doMoveItem } from './action-helpers';
import { CHANGE_PREFERENCES, RESET_RESOURCE_PREFERENCES } from './preferences-actions';
import { UPDATED_USE_LOCATION_SCHEDULE } from './schedule-constants';
import {
  ADD_GROUP, ADD_RESOURCE, DELETE_GROUP, DELETE_RESOURCE,
  RECEIVE_GROUPS_AND_RESOURCES,
  RENAME_GROUP,
  SORT_GROUPS,
  UPDATE_RESOURCE,
  GROUP_MOVED,
  RESOURCE_MOVED,
  RECEIVE_USE_LOCATION_SCHEDULE
} from './resource-actions';

export function orderedGroups(state = List(), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case DELETE_RESOURCE: {
      const resourceId = action.id;
      let resIdx = -1;

      const gIdx = state.findIndex((g) => {
        resIdx = g.get('resourceIds').indexOf(resourceId);
        return resIdx != -1;
      });

      const oldGroup = state.get(gIdx);
      const resources = oldGroup.get('resourceIds');
      const newGroup = oldGroup.set('resourceIds', resources.delete(resIdx));
      return state.set(gIdx, newGroup);
    }
    case ADD_RESOURCE: {
      const gIdx = state.findIndex(g => g.get('id') === action.groupId);
      const oldGroup = state.get(gIdx);
      const resources = oldGroup.get('resourceIds');
      const resourceIds = action.prepend
        ? resources.unshift(action.resource.id)
        : resources.push(action.resource.id);
      return state.set(gIdx, oldGroup.set('resourceIds', resourceIds));
    }
    case RENAME_GROUP: {
      const gIdx = state.findIndex(g => g.get('id') === action.id);
      const oldGroup = state.get(gIdx);
      const newGroup = oldGroup.set('name', action.name);
      return state.set(gIdx, newGroup);
    }
    case DELETE_GROUP: {
      const delIdx = state.findIndex(g => g.get('id') === action.groupId);
      return (delIdx === -1) ? state : state.delete(delIdx);
    }
    case SORT_GROUPS: {
      return state.clear().withMutations((list) => {
        for (const group of action.groups) {
          // find the name of the group
          //
          const name = state.find(g => g.get('id') === group.groupId).get('name');

          list.push(fromJS({
            id: group.groupId,
            resourceIds: group.itemIds,
            name
          }));
        }
      });
    }

    case GROUP_MOVED:
      return doMoveGroup(state, action.moveAction);

    case RESOURCE_MOVED:
      return doMoveItem(state, action.moveAction, 'resourceIds');

    case ADD_GROUP: {
      const { group, prepend } = action;
      return prepend
        ? state.unshift(fromJS(group))
        : state.push(fromJS(group));
    }

    case RECEIVE_GROUPS_AND_RESOURCES:
      return fromJS(action.groups);

    default:
      return state;
  }
}

export function resourcesById(state = Map({}), action = null) {
  switch (action.type) {
    case RECEIVE_USE_LOCATION_SCHEDULE:
      return state.set('useLocationSchedule', action.useLocationSchedule);

    case CLEAR_LOCATION_STATE:
      return state.clear();

    case ADD_RESOURCE: {
      const { resource } = action;
      return state.set(resource.id, resource);
    }
    case UPDATE_RESOURCE: {
      const resource = state.get(action.resource.id);
      const newResource = { ...resource, ...action.resource };
      return state.set(resource.id, newResource);
    }
    case DELETE_RESOURCE:
      return state.delete(action.id);

    case RECEIVE_GROUPS_AND_RESOURCES:
      const newState = state.clear().withMutations((map) => {
        for (const resource of action.resources) {
          map.set(resource.id, resource);
        }
      });
      return newState;

    case CHANGE_PREFERENCES: {
      if (action.resourceId && action.state.resourcePreferences) {
        const resource = state.get(action.resourceId);
        return state.set(resource.id, { ...resource, prefs: action.state.resourcePreferences });
      }
      return state;
    }

    case RESET_RESOURCE_PREFERENCES: {
      if (action.resourceId) {
        const resource = state.get(action.resourceId);
        return state.set(resource.id, { ...resource, prefs: null });
      }
      return state;
    }

    case UPDATED_USE_LOCATION_SCHEDULE: {
      const { resourceId, useLocationSchedule } = action;
      const resource = state.get(resourceId);
      return state.set(resource.id, { ...resource, useLocationSchedule });
    }

    default:
      return state;
  }
}
