import React from 'react';
import PropTypes from 'prop-types';

const ConfirmResize = ({ duration, onClosePopover, onConfirmResize, phoneMode }) => {
  const className = phoneMode ? 'modal-body' : 'Popover-content';
  return (
    <div className={className}>
      <div className="booking-form-block nopointer">
        <p>
          <strong>
            Vill du ändra bokningens längd till {duration} minuter?
          </strong>
        </p>
      </div>

      <div className="text-right">
        <button type="button" className="btn btn-default" onClick={onClosePopover}>Avbryt</button> &nbsp;
        <button type="button" className="btn-save" onClick={onConfirmResize}>Ändra</button>
      </div>
    </div>
  );
};

ConfirmResize.propTypes = {
  duration: PropTypes.number.isRequired,
  onClosePopover: PropTypes.func.isRequired,
  onConfirmResize: PropTypes.func.isRequired
};

export default ConfirmResize;
