import { defineMessages } from 'react-intl';

export default defineMessages({
  btnAddPno: {
    id: 'staff.details.btnAddPno',
    defaultMessage: 'Add personal number'
  },
  btnManualEntry: {
    id: 'staff.details.btnManualEntry',
    defaultMessage: 'Manual entry'
  },
  lblMissingPno: {
    id: 'staff.details.lblMissingPno',
    defaultMessage: 'Personal number missing'
  },
  lblLast90Days: {
    id: 'staff.details.lblLast90Days',
    defaultMessage: 'Last 90 days'
  },
  lblCheckedIn: {
    id: 'staff.details.lblCheckedIn',
    defaultMessage: 'Checked in'
  },
  lblCheckedOut: {
    id: 'staff.details.lblCheckedOut',
    defaultMessage: 'Checked out'
  },
  btnCheckIn: {
    id: 'staff.details.btnCheckIn',
    defaultMessage: 'Check in'
  },
  btnCheckOut: {
    id: 'staff.details.btnCheckOut',
    defaultMessage: 'Check out'
  },
  lblUserCheckedIn: {
    id: 'staff.details.lblUserCheckedIn',
    defaultMessage: '{name} is checked in'
  }
});
