import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavTabsLink from '@Components/ui/nav-tabs-link';

class ServiceSubNav extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    locationFeatures: PropTypes.object.isRequired
  };

  getSections = (showJsonPrefs, isAddonService) => [
    { navId: 'settings', name: 'Inställningar' },
    !isAddonService && { navId: 'addons', name: 'Tilläggstjänster' },
    { navId: 'custom-fields', name: 'Egna fält' },
    !isAddonService && { navId: 'schedule', name: 'Schema', news: true },
    showJsonPrefs && { navId: 'prefs', name: 'Prefs' }
  ].filter(s => s);

  render() {
    const { showJsonPrefs, routeParams, service } = this.props;
    const isAddonService = service && service.addon;
    const sections = this.getSections(showJsonPrefs, isAddonService);

    return (
      <NavTabsLink
        subUrl={`admin/${routeParams.subsection}`}
        routeParams={routeParams}
        sections={sections}
      />
    );
  }
}

const mapStateToProps = (state, { routeParams }) => {
  const { locationFeatures, servicesById } = state;
  const serviceId = parseInt(routeParams.id);
  const service = servicesById.get(serviceId);

  return {
    locationFeatures,
    service
  };
};

export default connect(mapStateToProps)(ServiceSubNav);
