import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { useDropzone } from 'react-dropzone';

import useIsMounted from '@Hooks/useIsMounted';
import Button from '@Components/ui/button';
import { Flex } from '@Components/ui/styled/main';
import Popover from '@Components/ui/popover';
import useOnClickOutside from '@Hooks/useOnClickOutside';
import ConfirmBlock from '@Components/ui/confirm-block';
import { colors } from '@Components/ui/styled/variables';
import { txt } from '@Utils/i18n-util';
import msg from './comment-box.msg';

const dropzoneOptions = {
  noClick: true,
  noKeyboard: true,
  accept: {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg']
  }
};

const DragOverlay = styled.div`
  position: absolute;
  inset: 0;
  margin: -1px;
  border-radius: 8px;
  border: 2px dashed rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.9);
  z-index: 1;
  font-size: 19px;
  font-weight: 500;
  color: ${colors.darkGray40};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.div`
  position: relative;

  img {
    height: 80px;
    margin: 5px 10px 5px 0;
    border-radius: 5px;
  }
  button {
    position: absolute;
    top: -2px;
    right: 2px;
    border: 0;
    outline: 0;
    border-radius: 50%;
    background: ${colors.softGray40};
    font-size: 12px;
    line-height: 12px;
    padding: 0;
    height: 20px;
    width: 20px;

    &:hover {
      background-color: ${colors.softGray80};
    }
  }
`;

const CommentBoxStyle = styled.div`
  position: relative;
  height: 36px;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  padding: 6px 10px;
  margin: ${p => p.newComment ? '0 20px 10px 20px' : '0 0 10px 0'};

  textarea {
    width: 100%;
    background-color: transparent;
    border: none;
    resize: none;
    outline: none;
    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }
  > div > button {
    margin-top: 5px;
    margin-bottom: 3px;
  }
  ${({ active }) => active && css`
    height: fit-content;
    textarea:focus {
      border: none;
    }
  `}
`;

const CommentBox = (props) => {
  const boxRef = useRef();
  const textRef = useRef();
  const isMounted = useIsMounted();
  const [active, setActive] = useState(props.active || false);
  const [comment, setComment] = useState(props.comment || '');
  const [loading, setLoading] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [files, setFiles] = useState(props.files || []);
  const [images, setImages] = useState([]);

  const onDrop = (droppedFiles) => {
    if (!props.allowFiles) {
      return;
    }
    if (droppedFiles.length > 0) {
      setFiles([...files, ...droppedFiles]);
    }
  };

  useEffect(() => {
    setImages(files.map((file) => {
      if (file.id && file.url) {
        return file.url;
      }
      return URL.createObjectURL(file);
    }));
  }, [files]);

  const removeImage = (index) => {
    const newFiles = [...files];
    if (newFiles[index].id) {
      newFiles[index] = { ...newFiles[index], delete: true };
    } else {
      newFiles.splice(index, 1);
    }
    setFiles(newFiles);
  };

  const {
    getRootProps, getInputProps, isDragActive, open
  } = useDropzone({ ...dropzoneOptions, onDrop });

  const handleChangeComment = e => {
    const { value } = e.target;
    if (isMounted()) {
      setComment(value);
    }
  };

  const handleActive = () => {
    if (isMounted()) {
      textRef.current?.focus();
      setActive(true);
    }
  };

  const handleSaveComment = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    await props.onSave(comment, files);

    if (isMounted()) {
      resetForm();
    }
  };

  const resetForm = () => {
    setLoading(false);
    setComment('');
    setFiles([]);
    setImages([]);
    setTimeout(() => {
      setActive(false);
      textRef.current?.blur();
    }, 0);
  };

  useOnClickOutside(boxRef, () => {
    props.newComment && props.comment === comment
      && files.length === 0 && active && resetForm();
  });

  useEffect(() => {
    props.active && handleActive();
  }, [props.active]);

  const hideConfirm = () => {
    if (isMounted()) setShowMenu(false);
  };
  const showConfirm = () => {
    if (isMounted()) setShowMenu(true);
  };

  const confirmDelete = () => {
    setShowMenu(false);
    props.onRemove && props.onRemove();
  };

  const renderPopupBody = (
    <div className="Popover-dialog-sm button-wrapper">
      <ConfirmBlock
        title={txt(msg.confirmDelete)}
        onCancel={hideConfirm}
        onConfirm={confirmDelete}
        confirmDanger
      />
    </div>
  );

  const hasContent = comment !== '' || files.some(f => !f.delete);

  return (
    <div {...getRootProps()}>
      {props.allowFiles && <input {...getInputProps()} />}
      <CommentBoxStyle
        ref={boxRef}
        onClick={handleActive}
        active={active}
        newComment={props.newComment}
      >
        {isDragActive && (
          <DragOverlay>Lägg till bild</DragOverlay>
        )}
        <TextareaAutosize
          minRows={1}
          value={comment}
          disabled={loading}
          placeholder={props.placeholder || txt(msg.lblAddComment)}
          onChange={handleChangeComment}
          ref={textRef}
        />
        {active && images.length > 0 && (
          <Flex vCenter>
            {images.map((image, index) => !files[index]?.delete && (
              <Image key={index}>
                <img src={image} />
                <button onClick={() => removeImage(index)}>
                  <i className="fa fa-times" />
                </button>
              </Image>
            ))}
          </Flex>
        )}
        {active && (
          <Flex vCenter>
            <Button
              loading={loading}
              disabled={loading || !hasContent}
              primary
              small
              marginRight
              onClick={handleSaveComment}
            >
              {txt(msg.btnSave)}
            </Button>
            {props.allowFiles && (
              <Button
                disabled={loading}
                gray
                small
                marginRight
                onClick={open}
              >
                <i className="fa fa-image mr1" />
                Lägg till bild
              </Button>
            )}
            <Button
              small
              noBorder
              onClick={props.newComment ? resetForm : props.onCancel}
            >
              <i className="fa fa-lg-image fa-times" />
            </Button>
            {props?.isShowRemove && (
              <Flex right vCenter>
                <Popover
                  preferPlace="below"
                  isOpen={showMenu}
                  onOuterAction={hideConfirm}
                  body={renderPopupBody}
                >
                  <Button linkStyle bold onClick={showConfirm}>
                    {txt(msg.btnDelete)}
                  </Button>
                </Popover>
              </Flex>
            )}
          </Flex>
        )}
      </CommentBoxStyle>
    </div>
  );
};

CommentBox.propTypes = {
  comment: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
};

export default CommentBox;
