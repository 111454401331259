import React from 'react';
import ReactTooltip from 'react-tooltip';

const LabelTooltip = ({ id, text }) => {
  return (
    <>
      <i className="fa fa-info-circle text-muted ml1" data-tip={text} data-for={id} />
      <ReactTooltip id={id} effect="solid" place="top" className="label-tooltip" />
    </>
  );
};

export default LabelTooltip;
