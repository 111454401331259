import { defineMessages } from 'react-intl';

export default defineMessages({
  txtLowStock: {
    id: 'pos.sale.txtLowStock',
    defaultMessage: 'Low stock on one or more products'
  },
  lblNoArticles: {
    id: 'pos.sale.lblNoArticles',
    defaultMessage: 'No articles added'
  },
  lblOther: {
    id: 'pos.sale.lblOther',
    defaultMessage: 'Other'
  },
  txtVatError: {
    id: 'pos.sale.txtVatError',
    defaultMessage: 'Incorrect VAT on product, purchase not possible'
  },
  txtPrinterError: {
    id: 'pos.sale.txtPrinterError',
    defaultMessage: 'No printer available, digital receipts only'
  },
  txtDemoMode: {
    id: 'pos.sale.txtDemoMode',
    defaultMessage: 'Note! The POS is not connected to a control unit. Use only for testing/demo.'
  },
  btnAddCustomer: {
    id: 'pos.sale.btnAddCustomer',
    defaultMessage: 'Add customer'
  },
  btnDiscount: {
    id: 'pos.sale.btnDiscount',
    defaultMessage: 'Discount'
  },
  btnParkSale: {
    id: 'pos.sale.btnParkSale',
    defaultMessage: 'Park'
  },
  btnVoidSale: {
    id: 'pos.sale.btnVoidSale',
    defaultMessage: 'Clear'
  },
  lblVoidSale: {
    id: 'pos.sale.lblVoidSale',
    defaultMessage: 'Clear receipt'
  },
  txtVoidSale: {
    id: 'pos.sale.txtVoidSale',
    defaultMessage: 'Do you want to clear the receipt?'
  },
  lblRemoveCustomer: {
    id: 'pos.sale.lblRemoveCustomer',
    defaultMessage: 'Remove'
  },
  txtRemoveCustomer: {
    id: 'pos.sale.txtRemoveCustomer',
    defaultMessage: 'Do you want to remove the customer from the receipt?'
  },
  lblFewInStock: {
    id: 'pos.sale.lblFewInStock',
    defaultMessage: 'Only {stockQuantity} left in stock'
  },
  lblNoneInStock: {
    id: 'pos.sale.lblNoneInStock',
    defaultMessage: 'None left in stock'
  },
  lblSeller: {
    id: 'pos.sale.lblSeller',
    defaultMessage: 'Seller'
  },
  lblTotal: {
    id: 'pos.sale.lblTotal',
    defaultMessage: 'Total'
  },
  lblPaid: {
    id: 'pos.sale.lblPaid',
    defaultMessage: 'Paid'
  },
  lblToPay: {
    id: 'pos.sale.lblToPay',
    defaultMessage: 'To pay'
  },
  lblLoadingReceipt: {
    id: 'pos.sale.lblLoadingReceipt',
    defaultMessage: 'Loading receipt...'
  },
  lblParkingReceipt: {
    id: 'pos.sale.lblParkingReceipt',
    defaultMessage: 'Parking receipt...'
  },
  lblConfirmParkReceipt: {
    id: 'pos.sale.lblConfirmParkReceipt',
    defaultMessage: 'Park receipt?'
  },
  btnLoadParked: {
    id: 'pos.sale.btnLoadParked',
    defaultMessage: 'Load parked'
  }
});
