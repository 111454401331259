import { defineMessages } from 'react-intl';

export default defineMessages({
  lblSmsPerRecipient: {
    id: 'inputs.smsLengthLabel.lblSmsPerRecipient',
    defaultMessage: 'SMS per recipient'
  },
  lblSmsCount: {
    id: 'inputs.smsLengthLabel.lblSmsCount',
    defaultMessage: 'Number of SMS'
  },
  lblCharCount: {
    id: 'inputs.smsLengthLabel.lblCharCount',
    defaultMessage: 'Character count'
  },
  lblNote: {
    id: 'inputs.smsLengthLabel.lblNote',
    defaultMessage: 'Note!'
  },
  txtWarningCampaign: {
    id: 'inputs.smsLengthLabel.txtWarningCampaign',
    defaultMessage: 'Your SMS text will be sent as more than one SMS per recipient.'
  },
  txtWarningMessage1: {
    id: 'inputs.smsLengthLabel.txtWarningMessage1',
    defaultMessage: 'Your SMS text will be sent as more than one SMS if it is longer than 160 characters.'
  },
  txtWarningMessage2: {
    id: 'inputs.smsLengthLabel.txtWarningMessage2',
    defaultMessage: 'The character count varies depending on the length of the resource and service names and the date.'
  }
});
