import React, { useState } from 'react';
import styled from 'styled-components';
import { CustomerType } from '@Utils/customer-util';
import ModalDialog from '@Components/dialogs/modal-dialog';
import SearchCustomer from '@Components/calendar/booking/search-customer';
import AddCustomerModal from '@Components/customers/add-customer-modal';
import { txt } from '@Utils/i18n-util';
import msg from './search-customer-modal.msg';

const Container = styled.div`
  .booking-form-body {
    padding: 0;
  }
`;

const SearchCustomerModal = ({ onCancel, onSelected, routeParams, title = null, ...props }) => {
  const [showAddCustomer, setShowAddCustomer] = useState(null);

  const handleSelected = ({ customer, company, vehicle }) => {
    if (customer?.customerId) {
      onSelected(customer);
    } else if (company?.customerId) {
      onSelected(company);
    } else if (vehicle?.customerId) {
      onSelected(vehicle);
    } else {
      setShowAddCustomer(company?.customerType || vehicle?.customerType || CustomerType.Person);
    }
  };

  if (showAddCustomer) {
    return (
      <AddCustomerModal
        routeParams={routeParams}
        customerType={showAddCustomer}
        onCreated={(customer) => onSelected(customer)}
        onClose={() => setShowAddCustomer(false)}
      />
    );
  }

  return (
    <ModalDialog
      contentSize="medium"
      title={title || txt(msg.addCustomer)}
      onClose={onCancel}
      closeButtonText="Stäng"
    >
      <Container>
        <SearchCustomer
          noChrome
          onSelected={handleSelected}
          maxHeight={document.body.scrollHeight}
          height={400}
          {...props}
        />
      </Container>
    </ModalDialog>
  );
};

export default SearchCustomerModal;
