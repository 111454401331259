import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customerClearForm } from '@State/customer-actions';
import { getCustomersUrl, navigate } from '@Utils/navigate';
import { setCustomerFormState } from '@State/cf-actions';

export default (routeParams = {}) => {
  const dispatch = useDispatch();
  const phoneMode = useSelector(state => state.mainViewState.get('phoneMode'));

  const { id, section, tab } = routeParams;
  const isCustomers = section === 'customers';

  const getCustomer = ({ otherCustomerId, ...customer }) => {
    if (otherCustomerId) {
      return {
        ...customer,
        customerId: otherCustomerId
      };
    }
    return customer;
  };

  return useCallback((customer) => {
    const selectedCustomer = getCustomer(customer);
    const { customerId } = selectedCustomer;

    if (phoneMode || !isCustomers) {
      dispatch(customerClearForm());
      dispatch(setCustomerFormState({ formVisible: true, customer: selectedCustomer }));
    } else if (parseInt(id) !== parseInt(customerId)) {
      dispatch(customerClearForm());
      navigate(getCustomersUrl(routeParams, customerId, tab));
    }
  }, [routeParams.id, routeParams.tab, phoneMode]);
};
