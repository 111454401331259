import React from 'react';
import moment from 'moment';
import { createSelector } from 'reselect';
import { DATE_PICKER_FORMAT, SERVER_FORMAT } from '@Utils/time-constants';
import { getSmsCampaignEnabled } from '@State/selectors';
import { formatPhoneNumber, isMobile } from '@Utils/phone-util';
import { config, email, sms } from '@Utils/preference-keys';
import { txt } from '@Utils/i18n-util';
import formMsg from './form/campaign-form.msg';
import msg from './campaign.msg';

export const emailReplyToEnabled = (value) => {
  return typeof value === 'string' && value !== 'no-reply@cliento.com';
};

const notEmpty = (value) => String(value || '').trim().length > 0;

export const campaignHasContent = (campaign) => {
  const { type, emailSubject, emailText, smsText } = campaign;
  return type === 'Email'
    ? notEmpty(emailSubject) && notEmpty(emailText)
    : notEmpty(smsText);
};

export const shorten = (value, maxLength) => {
  return value.length > maxLength
    ? `${value.substring(0, maxLength)}...`
    : value;
};

export const getCampaignTitle = (campaign, maxLength = 20) => {
  const { type, emailSubject, smsText } = campaign;
  if (type === 'Email' && emailSubject) {
    return shorten(emailSubject, maxLength);
  }
  if (type === 'Sms' && smsText) {
    return shorten(smsText, maxLength);
  }
  return txt(msg.lblNewCampaign);
};

export function getCampaignTypeName(campaignType) {
  switch (campaignType) {
    case 'Email':
      return txt(msg.lblEmail);
    case 'Sms':
      return txt(msg.lblSms);
    default:
      return null;
  }
}

export function getAudienceTypeName(audienceType) {
  switch (audienceType) {
    case 'All':
      return txt(msg.lblAllCustomers);
    case 'Booked':
      return txt(msg.lblBookedCustomers);
    case 'NewCustomers':
      return txt(msg.lblNewCustomers);
    case 'TopSpenders':
      return txt(msg.lblTopCustomers);
    default:
      return null;
  }
}

export function getDateFilterTypeName(dateFilterType) {
  switch (dateFilterType) {
    case 'CustomerCreatedTime':
      return txt(msg.lblCustomerCreated);
    case 'BookingCreatedTime':
      return txt(msg.lblBookingCreated);
    case 'ReceiptCreatedTime':
      return txt(msg.lblPurchaseDate);
    case 'StartTime':
      return txt(msg.lblBookingDate);
    default:
      return null;
  }
}

export function getResourceFilterName(resourceFilterType) {
  switch (resourceFilterType) {
    case 'Owned':
      return txt(msg.lblOwnedByResource);
    case 'Booked':
      return txt(msg.lblBookedAtResource);
    default:
      return null;
  }
}

export function getCampaignStatusIcon(status) {
  switch (status) {
    case 'DONE':
      return 'far fa-circle-check text-success';
    case 'PROCESSING':
      return 'far fa-spinner fa-spin text-muted';
    case 'PENDING':
      return 'far fa-clock text-muted';
    case 'CANCELLED':
      return 'far fa-ban text-danger';
    default:
      return null;
  }
}

export function getCampaignStatusLabel(status) {
  switch (status) {
    case 'DONE':
      return txt(msg.lblSent);
    case 'PENDING':
      return txt(msg.lblPending);
    case 'PROCESSING':
      return txt(msg.lblProcessing);
    case 'CANCELLED':
      return txt(msg.lblCancelled);
    case 'SYSTEM_CANCELLED':
      return txt(msg.lblSystemCancelled);
    default:
      return null;
  }
}

export function getCampaignRecipientStatus(status) {
  switch (status) {
    case 'SENT':
    case 'EMAIL_SENT':
    case 'DELIVERY_UNKNOWN':
      return txt(msg.lblSent);
    case 'PENDING':
    case 'SEND_REJECTED':
      return txt(msg.lblNotSent);
    case 'DELIVERED':
      return txt(msg.lblDelivered);
    case 'DELIVERY_FAILED':
      return txt(msg.lblFailed);
    default:
      return null;
  }
}

export const getCampaignFilterTitle = (campaignType, audienceType) => {
  return txt(formMsg.lblCampaignFilter, {
    campaignType: getCampaignTypeName(campaignType),
    audienceType: getAudienceTypeName(audienceType)?.toLowerCase()
  });
};

const getSmsSender = (smsSender) => {
  if (smsSender === 'Cliento') {
    return null;
  }
  return isMobile(smsSender) ? formatPhoneNumber(smsSender) : smsSender;
};

const getEmailFromName = (emailFromName) => {
  return emailFromName === 'Cliento' ? null : emailFromName;
};

export const getSmsCampaignPrice = createSelector(
  state => state.locationConfig,
  (locationConfig) => {
    const smsBulkPrice = locationConfig.get(config.smsPrices)
      .find((smsPrice) => smsPrice.get('priceType') === 'Bulk');

    return smsBulkPrice?.get('price');
  }
);

export const getCampaignDefaultValues = createSelector(
  getSmsCampaignEnabled,
  state => state.locationConfig,
  (smsCampaignEnabled, locationConfig) => {
    return {
      campaignName: '',
      campaignType: smsCampaignEnabled ? 'Sms' : 'Email',
      smsText: null,
      smsSender: getSmsSender(locationConfig.get(sms.smsSender)),
      emailFromName: getEmailFromName(locationConfig.get(email.fromName)),
      emailReplyTo: locationConfig.get(email.replyTo),
      emailSubject: null,
      emailText: null,
      fromDate: moment().startOf('month'),
      toDate: moment().endOf('month'),
      sendDate: moment().add(1, 'day').startOf('day'),
      sendTime: '12:00',
      sendAtTime: 'now',
      audienceType: 'All',
      serviceFilterType: 'All',
      dateFilterType: null,
      resourceFilterType: 'All',
      resourceIds: [],
      serviceIds: [],
      cost: null
    };
  }
);

export function getDateFilterType(campaign, defaultValues) {
  const { audienceType, dateFilterType } = campaign?.searchParams || campaign || defaultValues;

  switch (audienceType) {
    case 'All':
      return null;
    case 'NewCustomers':
      return 'CustomerCreatedTime';
    case 'TopSpenders':
      return 'ReceiptCreatedTime';
    case 'Booked':
      return ['StartTime', 'BookingCreatedTime']
        .includes(dateFilterType || '') ? dateFilterType : 'StartTime';
    default:
      return dateFilterType;
  }
}

export const getCampaignPayload = (values) => {
  const {
    resourceIds,
    serviceIds,
    audienceType,
    dateFilterType,
    fromDate,
    toDate,
    resourceFilterType,
    serviceFilterType,
    cost,
    ...rest
  } = values;
  return {
    ...rest,
    searchParams: {
      resourceIds: resourceFilterType === 'All' ? null : resourceIds,
      serviceIds: audienceType === 'All' || serviceFilterType === 'All' ? null : serviceIds,
      audienceType,
      dateFilterType: getDateFilterType(values),
      fromDate: !dateFilterType ? null : fromDate.format(DATE_PICKER_FORMAT),
      toDate: !dateFilterType ? null : toDate.format(DATE_PICKER_FORMAT),
      resourceFilterType: resourceFilterType === 'All' ? null : resourceFilterType,
      cost: audienceType === 'TopSpenders' ? cost : null
    }
  };
};

export const getCampaignFormState = (campaign = {}, defaultValues = {}) => {
  const { name, searchParams, type, id, ...rest } = campaign || {};
  const { fromDate, toDate, resourceIds, serviceIds } = searchParams || {};
  const { fromDate: defaultFrom, toDate: defaultTo } = defaultValues || {};

  const values = {
    ...searchParams,
    serviceFilterType: serviceIds ? 'Booked' : 'All',
    dateFilterType: getDateFilterType(campaign, defaultValues),
    campaignType: type,
    campaignName: name,
    resourceIds: resourceIds ?? [],
    serviceIds: serviceIds ?? [],
    fromDate: fromDate ? moment(fromDate) : moment(defaultFrom),
    toDate: toDate ? moment(toDate) : moment(defaultTo),
    id
  };
  Object.keys(defaultValues).forEach(key => {
    if (!values[key]) {
      values[key] = rest[key] ?? defaultValues[key];
    }
  });

  return values;
};

export const getDeliveryTime = (values) => {
  const { sendDate, sendTime, sendAtTime } = values;
  const [hour = 9, minute = 0] = sendTime?.split(':') || [];
  const deliveryTime = sendAtTime === 'now'
    ? moment()
    : sendDate.set('hour', hour).set('minute', minute).set('second', 0);

  return deliveryTime.format(SERVER_FORMAT);
};
