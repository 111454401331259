import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const SubNavItem = ({
  id, name, icon, helpText, news, beta, notification, section, subsection, org, loc, query = ''
}) => {
  const url = `/${org}/${loc}/${section}/${id}${query}`;
  const iconClasses = `fa fa-fw ${icon}`;
  const baseClass = 'subnav-nav-item';
  const classes = subsection === id ? `${baseClass} ${baseClass}--active` : baseClass;

  return (
    <li className={classes} key={id}>
      <Link to={url} data-tip={name} data-for="sub-nav">
        {notification && <img src="/exclamation-circle-duotone.svg" className="notification" />}
        <i className={iconClasses} />
        <span>{name}</span>
        {news ? <span className="label-news">NY</span> : null}
        {beta ? <span className="label-news">BETA</span> : null}
        {helpText && <div className="help-text">{helpText}</div>}
      </Link>
    </li>
  );
};

SubNavItem.propTypes = {
  id: PropTypes.string.isRequired,
  org: PropTypes.string.isRequired,
  loc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  subsection: PropTypes.string,
  notification: PropTypes.string
};

export default SubNavItem;
