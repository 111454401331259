import React, { useCallback } from 'react';
import Header, { Toolbar } from '@Components/nav/header';
import { getSectionUrl, navigate } from '@Utils/navigate';

const onPrint = (ev) => {
  ev.preventDefault();
  ev.target.blur();
  window.print();
};

const OngoingHeader = ({ match }) => {
  const routeParams = match.params;
  const showList = routeParams.subsection === 'list';

  const onShowOngoing = useCallback(() => {
    navigate(getSectionUrl(routeParams, 'ongoing'));
  }, []);
  const onShowList = useCallback(() => {
    navigate(getSectionUrl(routeParams, 'ongoing', 'list'));
  }, []);

  return (
    <Header
      className="thin-header"
      routeParams={routeParams}
      title="Pågående bokningar"
      onBackButtonClick={onShowOngoing}
      showBackButton={showList}
    >
      <Toolbar>
        {showList
          ? <button type="button" className="btn btn-cta-secondary" onClick={onPrint}>Skriv ut</button>
          : <button type="button" className="btn btn-cta" onClick={onShowList}>Visa lista</button>}
      </Toolbar>
    </Header>
  );
};

export default OngoingHeader;
