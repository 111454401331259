import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCustomer } from '@State/customer-actions';
import { getCurrentLocation } from '@State/selectors';
import { companyDefaultValues, CustomerType, formatOfficialIdNo, getCustomerErrorMessage, personDefaultValues, vehicleDefaultValues } from '@Utils/customer-util';
import { formatPhoneNumberE164 } from '@Utils/phone-util';
import CustomerInformationForm from '@Components/customers/customer-detail/overview/customer-information-form';
import ModalDialog from '@Components/dialogs/modal-dialog';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import { txt } from '@Utils/i18n-util';
import msg from './customer-header.msg';
import VehicleInformationForm from './customer-detail/overview/vehicle-information-form';
import CompanyInformationForm from './customer-detail/overview/company-information-form';

const AddCustomerModal = ({
  customerType = CustomerType.Person, person = null, vehicle = null, company = null,
  routeParams, onClose, onCreated
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const currentLocation = useSelector(state => getCurrentLocation(state, { routeParams }));

  const initialValies = useMemo(() => {
    switch (customerType) {
      case CustomerType.Vehicle:
        return { ...vehicleDefaultValues, ...vehicle };
      case CustomerType.Company:
        return { ...companyDefaultValues, ...company };
      case CustomerType.Person:
        return { ...personDefaultValues, ...person };
    }
  }, [customerType]);

  const onCreate = async (values) => {
    try {
      const customer = {
        ...values,
        customerType,
        locationId: currentLocation.locationId,
        officialIdType: values.officialIdNo ? values.officialIdType : null,
        officialIdNo: values.officialIdNo ? formatOfficialIdNo(values, customerType) : null,
        phoneNumber: values.phoneNumber ? formatPhoneNumberE164(values.phoneNumber) : null,
        otherPhoneNumber: values.otherPhoneNumber ? formatPhoneNumberE164(values.otherPhoneNumber) : null
      };
      const customerId = await dispatch(createCustomer(customer));
      onCreated({ ...customer, customerId });
    } catch (error) {
      setError(error);
    }
  };

  return (
    <ModalDialog
      contentSize="medium"
      title={txt(msg.addDialogTitle)}
      onClose={onClose}
    >
      {error && (
        <AlertWithIcon icon="fa fa-exclamation-circle" error>
          {getCustomerErrorMessage(customerType, error)}
        </AlertWithIcon>
      )}
      {customerType === CustomerType.Vehicle && (
        <VehicleInformationForm
          onClose={onClose}
          onSubmit={onCreate}
          initialValues={initialValies}
        />
      )}
      {customerType === CustomerType.Company && (
        <CompanyInformationForm
          onClose={onClose}
          onSubmit={onCreate}
          initialValues={initialValies}
        />
      )}
      {customerType === CustomerType.Person && (
        <CustomerInformationForm
          onClose={onClose}
          onSubmit={onCreate}
          initialValues={initialValies}
        />
      )}
    </ModalDialog>
  );
};

export default AddCustomerModal;
