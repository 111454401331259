import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getSectionUrl } from '@Utils/navigate';
import {
  addServiceResourceMapping, deleteServiceResourceMapping, fetchAllResourceMappings, fetchServices
} from '@State/services-actions';
import { CHANGE_RESOURCES, hasPermission } from '@Utils/permissions';
import { getPermissions } from '@State/selectors';
import ResourceServiceGroup from './resource-service-group';
import CustomServiceSettingsModal from '../../services/custom-service-settings-modal';

class ResourceServices extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    resourceId: PropTypes.number.isRequired,
    loadData: PropTypes.func.isRequired,
    filter: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      service: null,
      customServiceMapping: null,
      onlyShowMapped: true
    };
  }

  componentDidMount() {
    this.props.loadData().then(() => this.setState({ loading: false }));
  }

  selectService = (serviceId, active) => {
    if (active) {
      this.props.addServiceResourceMapping(serviceId, this.props.resourceId);
    } else {
      this.props.deleteServiceResourceMapping(serviceId, this.props.resourceId);
    }
  };

  showCustomMappingModal = (serviceId) => {
    const mappingId = `${this.props.resourceId}:${serviceId}`;
    const service = this.props.filteredServices.get(serviceId);
    const serviceMapping = this.props.serviceMappings.get(mappingId);
    this.setState({ service, customServiceMapping: serviceMapping.set('resource', this.props.resource) });
  };

  hideCustomMappingModal = (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    this.setState({ service: null, customServiceMapping: null });
  };

  render() {
    const { routeParams, permissions, resSrvCount } = this.props;
    const canChangeResource = hasPermission(permissions, CHANGE_RESOURCES);
    const { service, customServiceMapping, loading } = this.state;
    const servicesUrl = getSectionUrl(routeParams, 'admin', 'services');

    return (
      <div className="resource-services-container">
        { resSrvCount === 0 && !loading && (
          <div className="alert alert-danger" role="alert">
            OBS! Inga tjänster är valda för den här resursen. För att kunna bokas online behöver du välja vilka tjänster som ska kunna bokas.
          </div>
        )}
        <h4>Välj de tjänster som ska kunna bokas för den här resursen</h4>
        <div className="text-muted">
          <ul>
            {canChangeResource && (
            <li>
              Lägg till nya tjänster och ändra ordning genom att <Link to={servicesUrl}>Administrera kontots tjänster</Link>
            </li>
            )}
            <li>Ställ in egna värden om du exempelvis har andra priser på en tjänst än vad som är inställt för kontot</li>
          </ul>
        </div>
        <br />
        <br />
        {this.renderList()}
        {customServiceMapping && (
          <CustomServiceSettingsModal
            isAddonService={service.addon}
            showInBooking={service.webShowInBooking}
            serviceMapping={customServiceMapping}
            closeModal={this.hideCustomMappingModal}
          />
        )}
      </div>
    );
  }

  renderList() {
    const { filteredServiceGroups, serviceMappings, resourceId } = this.props;

    return filteredServiceGroups.map((group) => {
      return (
        <ResourceServiceGroup
          key={group.get('id')}
          groupName={group.get('name')}
          services={this.groupServices(group)}
          serviceMappings={serviceMappings}
          resourceId={resourceId}
          selectService={this.selectService}
          setCustomValues={this.showCustomMappingModal}
        />
      );
    });
  }

  groupServices(group) {
    const { filteredServices } = this.props;
    const serviceIds = group.get('serviceIds');
    return serviceIds.map(id => filteredServices.get(id)).filter(v => v);
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    servicesById, orderedServiceGroups, locationFeatures, resourcesById, resourceServiceMappingsByCombinedId
  } = state;
  const { filter, onlyShowMapped, routeParams } = ownProps;
  const { entityId: resourceId } = ownProps.routeParams;

  const resSrvCount = resourceServiceMappingsByCombinedId.count((value, key) => {
    return key.startsWith(resourceId);
  });

  let filteredServices = filter ? servicesById.filter((srv) => {
    return srv.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
  }) : servicesById;

  filteredServices = onlyShowMapped
    ? filteredServices.filter((srv) => {
      const mappingId = `${resourceId}:${srv.id}`;
      return resourceServiceMappingsByCombinedId.has(mappingId);
    })
    : filteredServices;

  const filteredServiceGroups = filter ? orderedServiceGroups.filter((group) => {
    let match = false;
    group.get('serviceIds').forEach((srvId) => {
      if (filteredServices.has(srvId)) {
        match = true;
      }
    });
    return match;
  }) : orderedServiceGroups;

  return {
    resourceId,
    resource: resourcesById.get(resourceId),
    routeParams,
    locationFeatures,
    filteredServices,
    filteredServiceGroups,
    serviceMappings: resourceServiceMappingsByCombinedId,
    resSrvCount,
    permissions: getPermissions(state, { routeParams })
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadData: () => {
      return Promise.all([
        dispatch(fetchServices()),
        dispatch(fetchAllResourceMappings())
      ]);
    },
    addServiceResourceMapping: (serviceId, resourceId) => {
      return dispatch(addServiceResourceMapping(serviceId, resourceId));
    },
    deleteServiceResourceMapping: (serviceId, resourceId) => {
      return dispatch(deleteServiceResourceMapping(serviceId, resourceId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourceServices);
