import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useCustomer from '@Components/customers/useCustomer';
import CustomerModalTabs from '@Components/customers/customer-modal/customer-modal-tabs';
import CustomerModalBody from '@Components/customers/customer-modal/customer-modal-body';
import CustomerDetailHeader from '@Components/customers/customer-detail/customer-detail-header';
import { openCustomerTab } from '@State/cf-actions';

const CustomerModalContent = ({ customer, onCloseForm, routeParams }) => {
  const dispatch = useDispatch();
  const { selectedCustomer, isLoading } = useCustomer();
  const cf = useSelector((state) => state.cf);
  const tab = cf.get('tab');
  const tabId = cf.get('tabId');

  const handlerSelectTab = (tab) => dispatch(openCustomerTab({ tab }));

  return (
    <div className="customer-modal-form">
      <CustomerDetailHeader
        modalView
        onCloseForm={onCloseForm}
        customer={selectedCustomer || customer}
      />
      <CustomerModalTabs
        customer={selectedCustomer || customer}
        selectedTab={tab}
        setSelectedTab={handlerSelectTab}
      />
      <CustomerModalBody
        routeParams={routeParams}
        selectedTab={tab}
        isLoading={isLoading}
        customerId={customer.id || customer.customerId}
        tabId={tabId}
        selectedCustomer={selectedCustomer}
      />
    </div>
  );
};

export default CustomerModalContent;
