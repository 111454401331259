import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import {
  fetchReports, fetchReport, fetchXReportSummary, sendReport
} from '@State/pos-actions';
import { isCashierAdmin } from '@Utils/pos-utils';
import { getTimeByFormat } from '@Utils/time-util';
import Loader from '@Components/ui/loader';
import Report from '@Components/pos/reports/report';
import ReportListItem from '@Components/pos/reports/report-list-item';
import { navigate, getSectionUrl } from '@Utils/navigate';
import { hasRunningPosUnit } from '@State/pos-selectors';
import { TitleEmptyDataStyle, BlockStyledPos } from '@Components/pos/style';
import { Text } from '@Components/ui/styled/main';
import DialogNotification from '@Components/dialogs/dialog-notification';

class Reports extends PureComponent {
  state = {
    loading: true,
    openedItems: [],
    xReport: null,
    sending: false,
    showSendConfirm: false
  };

  UNSAFE_componentWillReceiveProps({
    id, reports, posReport, hasRunningPosUnit
  }) {
    const canFetchReport = this.props.hasRunningPosUnit || id;
    const shouldFetchReport = posReport.isEmpty() || posReport.get('sequenceNo') !== id;

    if (canFetchReport && shouldFetchReport) {
      this.props.fetchReport(id);
    }
    if (this.props.hasRunningPosUnit && !hasRunningPosUnit) {
      this.setState({ xReport: null });
    }
    if (!posReport.isEmpty()) {
      const selectedItem = reports && reports.find(r => r.get('sequenceNumber') === this.props.id);
      if (selectedItem) {
        const openedItems = [...this.state.openedItems, getTimeByFormat(selectedItem.get('createdTs'))];
        this.setState({ openedItems });
      }
    }
  }

  componentDidMount() {
    const { hasRunningPosUnit, posUnit, posReport } = this.props;

    if (!isCashierAdmin(posUnit)) {
      this.setState({ loading: false });
      return;
    }

    if (hasRunningPosUnit) {
      this.props.fetchXReportSummary()
        .then((xReport) => this.setState({ xReport }));

      if (posReport) {
        this.props.fetchReport(this.props.id);
      }
    }
    this.props.fetchReports()
      .then(() => this.setState({ loading: false }));
  }

  itemClick = report => {
    navigate(getSectionUrl(this.props.routeParams, 'pos/reports', report.sequenceNumber || ''));
  };

  sendReport = () => {
    const { posReport, sendReport } = this.props;
    this.setState({ sending: true });
    return sendReport(posReport.get('sequenceNo'))
      .then(() => this.setState({ showSendConfirm: true, sending: false }))
      .catch(() => this.setState({ sending: false }));
  };

  hideSendConfirm = () => {
    this.setState({ showSendConfirm: false });
  };

  handleClickHeader = (group) => {
    const { openedItems } = this.state;
    let newItems = [];
    if (openedItems.includes(group)) {
      newItems = openedItems.filter((item) => item !== group);
    } else {
      newItems = [...openedItems, group];
    }
    this.setState({ openedItems: newItems });
  };

  getXReportListItem = () => {
    const { posUnit } = this.props;
    const { xReport } = this.state;

    if (!posUnit || !xReport) {
      return null;
    }
    return {
      sequenceNumber: 0,
      registerName: posUnit.get('vunitName'),
      openedTs: posUnit.get('stateSince'),
      netAmount: xReport.transactionTypeSummary.Sale?.total
    };
  };

  render() {
    const {
      loading, openedItems, sending, showSendConfirm
    } = this.state;
    const {
      hasRunningPosUnit, posUnit, reports, posReport, id, routeParams
    } = this.props;

    if (loading) {
      return (
        <div className="pos-reports">
          <Loader />
        </div>
      );
    }

    if (!isCashierAdmin(posUnit)) {
      return (
        <div className="pos">
          <BlockStyledPos>
            <h3>Behörighet saknas</h3>
            <Text muted>Du har inte behörighet att visa rapporter</Text>
          </BlockStyledPos>
        </div>
      );
    }

    const xReportListItem = this.getXReportListItem();
    const grouped = reports && reports.sortBy(r => r.get('sequenceNumber')).reverse()
      .groupBy(r => getTimeByFormat(r.get('createdTs')));

    if (!hasRunningPosUnit && !grouped.isEmpty() && (!id || (id && !posReport))) {
      const idSeq = grouped.first().first().get('sequenceNumber');
      return (
        <Redirect
          to={getSectionUrl(
            routeParams,
            'pos/reports',
            idSeq
          )}
        />
      );
    }

    return (
      <div className="pos-reports">
        <div className="columns-container">
          <div className="columns-sidebar hidden-print">
            <div className="columns-header">
              <h1>Dagrapporter</h1>
            </div>
            <div className="columns-list">
              {xReportListItem && (
                <>
                  <div className="columns-list-header statis">
                    <div>
                      <i className="fa fa-fw fa-chevron-down" />
                      <span>X-rapport</span>
                    </div>
                  </div>
                  <ReportListItem
                    itemClick={this.itemClick}
                    report={xReportListItem}
                    isSelected={!id}
                  />
                </>
              )}
              {grouped && grouped.size === 0 && !xReportListItem && (
                <TitleEmptyDataStyle>Det finns inga rapporter att visa</TitleEmptyDataStyle>
              )}
              {grouped && grouped.entrySeq().map(([group, results]) => {
                const isSelectedGroup = openedItems.includes(group);
                const classListIcon = isSelectedGroup ? 'fa fa-fw fa-chevron-down' : 'fa fa-fw fa-chevron-right';
                const onClick = () => this.handleClickHeader(group);

                return (
                  <Fragment key={group}>
                    <div className="columns-list-header" onClick={onClick}>
                      <div>
                        <i className={classListIcon} />
                        <span>
                          Z-rapporter {group}
                        </span>
                      </div>
                    </div>
                    {isSelectedGroup && results && results.map((report, index) => {
                      return report && (
                      <ReportListItem
                        key={index}
                        itemClick={this.itemClick}
                        report={report.toJS()}
                        isSelected={report.get('sequenceNumber') === id}
                      />
                      );
                    })}
                  </Fragment>
                );
              })}
            </div>
          </div>
          <div className="columns-content">
            <div className="columns-content-container width-large">
              <div className="columns-content-body with-padding">
                {!posReport.isEmpty() && (
                <Report
                  report={posReport}
                  sendReport={this.sendReport}
                  sending={sending}
                />
                )}
                {showSendConfirm && (
                  <DialogNotification title="Rapport skickad" onClose={this.hideSendConfirm}>
                    Rapporten har mejlats till kontoägaren.
                  </DialogNotification>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  id: parseInt(props.routeParams.id || '0'),
  reports: state.posReports,
  posReport: state.posReport,
  hasRunningPosUnit: hasRunningPosUnit(state),
  posUnit: state.pos.get('posUnit')
});

const mapDispatchToProps = dispatch => ({
  fetchReports: () => dispatch(fetchReports()),
  fetchXReportSummary: () => dispatch(fetchXReportSummary()),
  fetchReport: (id) => dispatch(fetchReport(id)),
  sendReport: (id) => dispatch(sendReport(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reports);
