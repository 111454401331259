import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { getSectionUrl, navigate } from '@Utils/navigate';
import { fetchGoogleBusinessLocations, saveGoogleBusinessPlaceId } from '@State/google-business-actions';
import DialogAlert from './dialog-alert';
import GoogleBusinessSelectPlace from './google-business-select-place';
import GoogleBusinessCompleted from './google-business-completed';

const GoogleAuth = ({ location, match }) => {
  const dispatch = useDispatch();
  const { status } = queryString.parse(location.search);

  const [loading, setLoading] = useState(false);
  const [businesses, setBusinesses] = useState(null);
  const [placeId, setPlaceId] = useState(null);

  useEffect(() => {
    if (status === 'success') {
      setLoading(true);
      dispatch(fetchGoogleBusinessLocations())
        .then(({ availableLocations }) => setBusinesses(availableLocations))
        .finally(() => setLoading(false));
    }
  }, []);

  const onSelect = (placeId) => {
    dispatch(saveGoogleBusinessPlaceId(placeId));
    setPlaceId(placeId);
  };

  const onClose = () => {
    navigate(getSectionUrl(match.params, 'admin', 'google-business'));
  };

  if (placeId) {
    return (
      <GoogleBusinessCompleted
        onClose={onClose}
      />
    );
  }

  return status === 'success' ? (
    <GoogleBusinessSelectPlace
      loading={loading}
      businesses={businesses}
      onSelect={onSelect}
      onClose={onClose}
    />
  ) : (
    <DialogAlert
      warning
      icon="fa fa-exclamation-triangle"
      text="Koppling till Google företagsprofil kunde inte aktiveras"
      onClose={onClose}
      closeButtonText="OK"
    />
  );
};

export default GoogleAuth;
