import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { is12HourFormat } from '@Utils/i18n-util';
import { getGridProps } from './grid-selectors';

export class RowHeaders extends Component {
  static propTypes = {
    pixelsPerRow: PropTypes.number.isRequired,
    rowsPerHour: PropTypes.number.isRequired,
    gridScrollHeight: PropTypes.number.isRequired,
    phoneMode: PropTypes.bool.isRequired
  };

  render() {
    const {
      pixelsPerRow, rowsPerHour, gridScrollHeight, phoneMode
    } = this.props;
    const rowHeight = pixelsPerRow * rowsPerHour;
    const labelStyle = {
      fontSize: phoneMode ? '10px' : '12px'
    };

    const rows = this.createRowHeaders();

    return (
      <div id="rowheaders" style={{ height: gridScrollHeight }}>
        {rows.map((row, i) => (
          <div
            id={`rhead${i + 1}`}
            key={`rhead${i + 1}`}
            className={`rhead rheadeven ${phoneMode && 'phone'}`}
            style={{ height: rowHeight, top: i * rowHeight }}
          >
            <div className="rheadtext" style={labelStyle}>{ row.label }</div>
          </div>
        ))}
      </div>
    );
  }

  createRowHeaders() {
    // I've hardcoded a day that doesn\t have daylight savings adjustment, so that we always render 24 rows 0 to 24.
    // Only reason we use moment here is to get the formatting right. AM/PM, 24h etc..
    //
    const startOfDay = moment('1976-07-26');
    const rowDefinitions = [];
    const format = is12HourFormat() ? 'h A' : 'HH:mm';

    for (let i = 0; i < 24; i++) {
      rowDefinitions.push({ label: startOfDay.format(format) });
      startOfDay.add(1, 'h');
    }
    return rowDefinitions;
  }
}

const mapStateToProps = (state, props) => {
  const { mainViewState } = state;
  const { pixelsPerRow, rowsPerHour, gridScrollHeight } = getGridProps(state, props);

  return {
    pixelsPerRow,
    gridScrollHeight,
    rowsPerHour,
    phoneMode: mainViewState.get('phoneMode')
  };
};

export default connect(mapStateToProps)(RowHeaders);
