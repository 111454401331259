import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import moment from 'moment';

export default class CurrentDateLabel extends Component {
  render() {
    const { viewDateStart } = this.props;

    return (
      <>
        <span className={this.classNames()}>{this.dateLabel()}</span>
        <span className="toolbar-week-label">
          v{viewDateStart.format('W')}
        </span>
      </>
    );
  }

  weekLabel() {
    const { phoneMode, tabletMode, viewDateStart } = this.props;
    const viewDateEnd = moment(viewDateStart).endOf('week');

    return phoneMode || tabletMode
      ? `${viewDateStart.format('D')} - ${viewDateEnd.format('D MMM -YY')}`
      : `${viewDateStart.format('D MMM')} - ${viewDateEnd.format('D MMM, YYYY')}`;
  }

  dayLabel() {
    const { viewDateStart } = this.props;

    return viewDateStart.format('ddd D MMM, YYYY');
  }

  dateLabel() {
    const { viewMode } = this.props;
    return viewMode === 'week' ? this.weekLabel() : this.dayLabel();
  }

  classNames() {
    let classes = 'toolbar-date-label';

    if (this.checkIfPast()) {
      classes += ' past';
    }

    return classes;
  }

  checkIfPast() {
    const { viewMode, viewDateStart } = this.props;

    const now = viewMode === 'week' ? moment().startOf('week') : moment().startOf('day');

    return moment(viewDateStart).isBefore(now);
  }
}

CurrentDateLabel.propTypes = {
  viewMode: PropTypes.oneOf(['week', 'day']).isRequired,
  phoneMode: PropTypes.bool.isRequired,
  tabletMode: PropTypes.bool.isRequired,
  viewDateStart: PropTypes.object.isRequired
};
