import { defineMessages } from 'react-intl';

export default defineMessages({
  btnDelete: {
    id: 'general.popoverDeleteButton.btnDelete',
    defaultMessage: 'Delete'
  },
  lblConfirmDelete: {
    id: 'general.popoverDeleteButton.lblConfirmDelete',
    defaultMessage: 'Confirm delete?'
  },
  btnCancel: {
    id: 'general.popoverDeleteButton.btnCancel',
    defaultMessage: 'Cancel'
  }
});
