import React from 'react';
import PropTypes from 'prop-types';
import NavTabsLink from '@Components/ui/nav-tabs-link';
import { txt } from '@Utils/i18n-util';
import msg from './schedules.msg';

const ScheduleSubNav = ({ routeParams }) => {
  const sections = [
    { navId: 'schedule', name: txt(msg.lblSchedules) },
    { navId: 'exception', name: txt(msg.lblExceptions) },
    { navId: 'holidays', name: txt(msg.lblHolidays) }
  ];

  return (
    <NavTabsLink
      subUrl="admin/schedules"
      routeParams={routeParams}
      sections={sections}
    />
  );
};

ScheduleSubNav.propTypes = {
  routeParams: PropTypes.object
};

export default ScheduleSubNav;
