import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';

import { TitleDate, ContentBlock } from '@Components/customers/customer-styles';
import CustomerBookingEvent from '@Components/customers/customer-detail/bookings/customer-booking-event';
import { sortEventByTimeAndReverse } from '@Utils/customer-util';
import { getTimeByFormat } from '@Utils/time-util';

const CustomerPastBookings = ({
  items = [], isSelectable, onSelectEvent, selectedEventId
}) => !isEmpty(items) && items.map(([date, groupedEvents], index) => (
  <div key={`${date}_${first(groupedEvents).id}_${index}`}>
    <TitleDate>{getTimeByFormat(date)}</TitleDate>
    <ContentBlock>
      {sortEventByTimeAndReverse(groupedEvents).map((event, index) => (
        <CustomerBookingEvent
          key={index}
          isSelectable={isSelectable}
          onSelectEvent={onSelectEvent}
          selectedEventId={selectedEventId}
          {...event}
        />
      ))}
    </ContentBlock>
  </div>
));

CustomerPastBookings.propTypes = {
  items: PropTypes.array,
  handlerOnClick: PropTypes.func,
  selectedEventId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default CustomerPastBookings;
