import axios from 'axios/index';
import {
  prefixUrl, axiosErrorHandler, axiosDefault, checkStatusAxios,
  prefixIcalUrl, axiosDelete, axiosPut, axiosPost, axiosGet
} from '@Utils/ajax-util';
import { apiUrl } from '@Utils/config';
import { prefixApiUrl } from '@Utils/url-util';

export const RECEIVE_USE_LOCATION_SCHEDULE = 'RECEIVE_USE_LOCATION_SCHEDULE';
export const RECEIVE_GROUPS_AND_RESOURCES = 'RECEIVE_GROUPS_AND_RESOURCES';

export const SORT_GROUPS = 'SORT_GROUPS';
export const ADD_GROUP = 'ADD_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const RENAME_GROUP = 'RENAME_GROUP';

export const ADD_RESOURCE = 'ADD_RESOURCE';
export const DELETE_RESOURCE = 'DELETE_RESOURCE';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';

export const GROUP_MOVED = 'GROUP_MOVED';
export const RESOURCE_MOVED = 'RESOURCE_MOVED';

function receiveGroupsAndResources(json) {
  return {
    type: RECEIVE_GROUPS_AND_RESOURCES,
    ...json
  };
}

export function fetchGroupsAndResources(orgCtxName = null, locCtxName = null) {
  const url = orgCtxName && locCtxName
    ? prefixApiUrl(`/locations/${orgCtxName}/${locCtxName}/resources/with-groups/`)
    : prefixUrl('/resources/with-groups/');

  return dispatch => dispatch(axiosGet(url, {
    onSuccess: ({ data }) => dispatch(receiveGroupsAndResources(data))
  }));
}

function groupRenamed(group) {
  return {
    type: RENAME_GROUP,
    ...group
  };
}

export function renameGroup(group) {
  const url = prefixUrl(`/resourcegroups/${group.id}`);
  return dispatch => dispatch(axiosPut(url, { name: group.name }, {
    onSuccess: () => dispatch(groupRenamed(group))
  }));
}

function groupDeleted(groupId) {
  return {
    type: DELETE_GROUP,
    groupId
  };
}

export function deleteGroup(groupId) {
  const url = prefixUrl(`/resourcegroups/${groupId}`);
  return dispatch => dispatch(axiosDelete(url, {
    onSuccess: () => dispatch(groupDeleted(groupId))
  }));
}

function groupAdded(group, prepend) {
  return {
    type: ADD_GROUP,
    group,
    prepend
  };
}

export function addResourceGroup(group) {
  const { prepend } = group;
  const url = prefixUrl('/resourcegroups/');
  return dispatch => dispatch(axiosPost(url, group, {
    onSuccess: ({ data }) => dispatch(groupAdded(data, prepend))
  }));
}

function groupsSorted(groups) {
  return {
    type: SORT_GROUPS,
    groups
  };
}

export function saveCopyToGroup(resourceId, groupId, settings) {
  const url = prefixUrl(`/resources/${resourceId}/copy-to-group/${groupId}`);
  return axiosPost(url, settings);
}
export function saveCopyToResource(resourceId, targetResourceId, settings) {
  const url = prefixUrl(`/resources/${resourceId}/copy-to-resource/${targetResourceId}`);
  return axiosPost(url, settings);
}

export function saveGroupOrder(sortedGroups) {
  const url = prefixUrl('/resources/sort');
  return dispatch => dispatch(axiosPost(url, sortedGroups, {
    onSuccess: () => dispatch(groupsSorted(sortedGroups))
  }));
}

function resourceDeleted(id) {
  return {
    type: DELETE_RESOURCE,
    id
  };
}

export function deleteResource(resourceId) {
  const url = prefixUrl(`/resources/${resourceId}/deleted`);
  return dispatch => dispatch(axiosPut(url, {}, {
    onSuccess: () => dispatch(resourceDeleted(resourceId)),
    errorDetails: (error) => {
      if (error.response.status === 400) {
        return {
          title: 'Framtida bokningar finns',
          message: 'Resursen kan inte tas bort eftersom det finns framtida bokningar för resursen. Flytta eller ta bort befintliga bokningar för att kunna ta bort resursen.'
        };
      }
    }
  }));
}

export function resourceUpdated(resource) {
  return {
    type: UPDATE_RESOURCE,
    resource
  };
}

function resourceAdded(resource, groupId, prepend) {
  return {
    type: ADD_RESOURCE,
    groupId,
    resource,
    prepend
  };
}

export function resourceUseLocationSchedule(useLocationSchedule) {
  return {
    type: RECEIVE_USE_LOCATION_SCHEDULE,
    useLocationSchedule
  };
}

function addResourceErrorDetails(error) {
  if (error.response.status === 400) {
    return {
      title: 'Uppgradering krävs',
      message: 'Kunde inte lägga till resurs. Max antal resurser har uppnåtts. För att lägga till fler behöver abonnemanget uppgraderas.'
    };
  }
}

export function addResource(resource) {
  const { groupId, prepend } = resource;
  const url = prefixUrl(`/resources/group/${groupId}`);
  return dispatch => dispatch(axiosPost(url, resource, {
    onSuccess: ({ data }) => {
      dispatch(resourceAdded(data, parseInt(groupId), prepend));
      return data;
    },
    errorDetails: addResourceErrorDetails
  }));
}

export function duplicateResource(resourceId, name) {
  const url = prefixUrl(`/resources/${resourceId}/duplicate`);

  return (dispatch, getState) => {
    const { resourcesById } = getState();
    const resource = resourcesById.get(resourceId);

    return dispatch(axiosPost(url, resource, {
      onSuccess: ({ data }) => {
        const { resourceId, groupId } = data;
        const newResource = { ...resource, id: resourceId, name };
        dispatch(resourceAdded(newResource, parseInt(groupId)));
        return newResource;
      },
      errorDetails: addResourceErrorDetails
    }));
  };
}

export function updateResource(id, resource) {
  const url = prefixUrl(`/resources/${id}`);
  return dispatch => dispatch(axiosPut(url, resource, {
    onSuccess: () => dispatch(resourceUpdated({ id, ...resource }))
  }));
}

export function moveGroup(moveAction) {
  return (dispatch) => {
    const url = prefixUrl('/resourcegroups/move');
    const config = axiosDefault();

    // Apply the change locally first (will revert the change if a network error occurs
    //
    dispatch({ type: GROUP_MOVED, moveAction });

    const { groupId, srcPos: srcIdx, destPos: dstIdx } = moveAction;
    const body = {
      groupId, srcIdx, dstIdx
    };

    return axios.put(url, body, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch((error) => {
        // Revert the change
        //
        const { groupId, srcPos, destPos } = moveAction;
        const m = {
          srcPos: destPos,
          destPos: srcPos,
          groupId
        };

        dispatch({ type: GROUP_MOVED, m });
        axiosErrorHandler(error, dispatch);
      });
  };
}

export function moveResource(moveAction) {
  return (dispatch) => {
    const url = prefixUrl('/resources/move');
    const config = axiosDefault();

    // Apply the change locally first - as required by the dnd component
    // (will revert the change if a network error occurs)
    //
    dispatch({ type: RESOURCE_MOVED, moveAction });

    const {
      srcGrpId, destGrpId: dstGrpId, srcPos: srcIdx, destPos: dstIdx, itemId
    } = moveAction;
    const body = {
      resourceId: itemId, srcGrpId, dstGrpId, srcIdx, dstIdx
    };

    return axios.put(url, body, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch((error) => {
        // Revert the change
        //
        const {
          srcGrpId, destGrpId, srcPos, destPos, itemId
        } = moveAction;

        const m = {
          srcGrpId: destGrpId,
          destGrpId: srcGrpId,
          srcPos: destPos,
          destPos: srcPos,
          resourceId: itemId
        };

        dispatch({ type: RESOURCE_MOVED, m });
        axiosErrorHandler(error, dispatch);
      });
  };
}

export function updateResourceAttribs(resourceId, attributes) {
  return (dispatch, getState) => {
    const url = prefixUrl(`/resources/${resourceId}/attribs`);
    const config = axiosDefault();
    const res = getState().resourcesById.get(parseInt(resourceId));

    return axios.put(url, attributes, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({ type: UPDATE_RESOURCE, resource: { ...res, attributes } });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function createIcalToken(resourceId) {
  return (dispatch) => {
    const url = prefixIcalUrl(`/tokens/resource/${resourceId}/`);
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function getIcalFeedUrl(token) {
  return `${apiUrl()}/api/icalendar/${token}/bookings.ics`;
}
