import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AppVersionMultiCustomer, getEmbedVersion, isEmbeddedInApp, isMinVersion } from '@Utils/embedded-util';
import DialogAlert from './dialog-alert';

class AppVersionTooLowAlert extends Component {
  render() {
    const { embeddedInApp, embedVersion } = this.props;
    const showVersionAlert = embeddedInApp
      && !isMinVersion(embedVersion, AppVersionMultiCustomer);

    return showVersionAlert ? (
      <DialogAlert
        warning
        icon="fa fa-exclamation-triangle"
        text={(
          <div>
            Du behöver uppdatera appen till senaste versionen
            för att kunna använda Cliento.<br /><br />
            Version som krävs: {AppVersionMultiCustomer}
          </div>
        )}
      />
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    embeddedInApp: isEmbeddedInApp(state),
    embedVersion: getEmbedVersion(state)
  };
};

export default connect(mapStateToProps)(AppVersionTooLowAlert);
