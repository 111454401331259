import React, { Component } from 'react';
import { connect } from 'react-redux';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { dismissOnboarding } from '@State/account-actions';
import { getShowFeaturesOnboarding } from '@State/selectors';
import FeaturesConfig from '../admin/features-config';

class FeaturesOnboardingModal extends Component {
  render() {
    const { showOnboarding, dismissOnboarding } = this.props;

    if (!showOnboarding) {
      return null;
    }

    return (
      <ModalDialog
        alignTop
        contentSize="xl"
        onClose={dismissOnboarding}
      >
        <h2 className="mt0">Välkommen till Cliento!</h2>
        <p className="lead">
          Välj tillval för att  anpassa Cliento till din verksamhet.
        </p>
        <FeaturesConfig modal />
        <p className="text-muted">
          Svårt att bestämma nu? Slå på eller av tillvalen senare under Admin &gt; Tillval.
        </p>
        <div className="text-center">
          <br />
          <button onClick={dismissOnboarding} className="btn-modern btn-xl">
            Börja använda Cliento <i className="fa fa-chevron-right" style={{ marginLeft: 10 }} />
          </button>
        </div>
      </ModalDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showOnboarding: getShowFeaturesOnboarding(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dismissOnboarding: () => {
      dispatch(dismissOnboarding());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeaturesOnboardingModal);
