import React, { useCallback } from 'react';
import sortBy from 'lodash/sortBy';
import { txt } from '@Utils/i18n-util';
import msg from './product.msg';

const GroupItemNav = ({ type, selectedType, label, onSelect, news }) => {
  return (
    <li className={selectedType === type ? 'is-active' : ''}>
      {news ? <span className="label-news">NY</span> : null}
      <a href="#" onClick={ev => onSelect(ev, type)}>{label}</a>
    </li>
  );
};

const ProductGroupNav = ({ onSelectType, defaultGroupType, selectedType, hideServices, hideProducts }) => {
  const onSelect = useCallback((ev, type) => {
    ev.target.blur();
    ev.preventDefault();
    onSelectType(type);
  }, []);

  const groupTypes = [{
    type: 'Products',
    label: txt(msg.lblProducts)
  }, {
    type: 'Services',
    label: txt(msg.lblServices)
  }, {
    type: 'Vouchers',
    label: txt(msg.lblVouchers)
  }];

  const orderedTypes = sortBy(groupTypes, ({ type }) => defaultGroupType !== type);
  const filteredTypes = orderedTypes.filter(({ type }) => {
    return (!hideServices || type !== 'Services')
      && (!hideProducts || type !== 'Products');
  });

  return (
    <div className="pos-segment">
      <ul>
        {filteredTypes.map(({ type, ...props }) => (
          <GroupItemNav
            key={type}
            type={type}
            {...props}
            onSelect={onSelect}
            selectedType={selectedType}
          />
        ))}
      </ul>
    </div>
  );
};

export default ProductGroupNav;
