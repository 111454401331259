import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import TextareaAutosize from 'react-textarea-autosize';
import { getPreviewText } from '@Utils/templates';
import TagsInfo from './tags-info';
import SmsLengthLabel from './sms-length-label';

const styleSms = { marginBottom: 5 };
const stylePreviewText = { marginTop: 2, marginBottom: 5 };

class SmsTemplateInput extends Component {
  state = {
    isEditing: false
  };

  render() {
    const { isEditing } = this.state;
    const { input: { name, value }, replaceValues } = this.props;
    const previewText = getPreviewText(value, replaceValues);

    return (
      <div className="row">
        <div className="col-sm-6">
          <div className="well well-sm">
            <Field name={name} component={this.textArea} />
            {isEditing && (
              <div>
                <TagsInfo time resource service location customer bookingLink />
                <a href="#" onClick={this.useDefaultSmsTemplate}>Använd standardtext</a>
              </div>
            )}
          </div>
        </div>
        <div className="col-sm-6">
          {isEditing && (
            <div>
              <div style={styleSms}>
                <strong>Förhandsgranskning:</strong>
                <br />
                <div className="well well-sm" style={stylePreviewText}>
                  <span className="text-wrap">{previewText}</span>
                </div>
                <SmsLengthLabel text={previewText} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  textArea = props => (
    <TextareaAutosize {...props.input} minRows={3} className="form-control" onFocus={this.startEditing} />
  );

  startEditing = (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    this.setState({ isEditing: true });
  };

  useDefaultSmsTemplate = (ev) => {
    ev.preventDefault();
    const { input: { onChange }, defaultTemplate } = this.props;

    onChange(defaultTemplate);
  };
}

SmsTemplateInput.propTypes = {
  defaultTemplate: PropTypes.string.isRequired,
  replaceValues: PropTypes.object.isRequired
};

export default SmsTemplateInput;
