import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getOrgWideCustomerDb } from '@State/selectors';
import { colors } from '@Components/ui/styled/variables';
import EventCard from '@Components/ui/event-card';
import { getSumByType, getReceiptTypeIcon } from '@Utils/pos-utils';
import CurrencyUtil from '@Utils/currency-util';
import { CustomerEventIcon, UpperText } from '@Components/customers/customer-styles';
import { txt } from '@Utils/i18n-util';
import msg from './customer-sales.msg';

const CustomerDetailSale = ({
  sale, isSelectedSale, handleSelect, isSelectable = false, children
}) => {
  const orgWideCustomerDb = useSelector(getOrgWideCustomerDb);
  const locationNames = useSelector(state => state.locationNames);

  const {
    items, id, status, paidAmount, saleReceiptType, locationId
  } = sale;
  const receiptTypeIcon = getReceiptTypeIcon(saleReceiptType);

  const countServices = getSumByType(items, 'Service', 'itemType');
  const countProducts = getSumByType(items, 'Product', 'itemType');
  const onSelect = () => handleSelect && handleSelect(id);

  const serviceText = txt(countServices === 1 ? msg.lblService : msg.lblServices, { count: countServices });
  const productText = txt(countProducts === 1 ? msg.lblProduct : msg.lblProducts, { count: countProducts });

  const contentText = [
    countServices > 0 ? serviceText : null,
    countProducts > 0 ? productText : null
  ].filter(text => text).join(', ');

  return (
    <EventCard
      icon={receiptTypeIcon}
      isSelectable={isSelectable}
      handler={onSelect}
      isSelected={isSelectedSale}
      borderColor={colors.customerSaleBg}
    >
      {status !== 'Paid' && <CustomerEventIcon className="fal fa-reply" />}
      <strong>Köp {CurrencyUtil.accountCurrency(paidAmount, 2)}</strong>
      {orgWideCustomerDb && (
        <div className="text-muted">
          <i className="far fa-location-dot mr1" />
          {locationNames.get(locationId)}
        </div>
      )}
      <div>{contentText}</div>
      <UpperText>{children}</UpperText>
    </EventCard>
  );
};

CustomerDetailSale.propTypes = {
  isSelectedSale: PropTypes.bool,
  handleSelect: PropTypes.func
};

export default CustomerDetailSale;
