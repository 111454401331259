import React from 'react';
import { navigateAsync } from '@Utils/navigate';

const Navigate = (href) => ev => {
  ev.preventDefault();
  navigateAsync(href);
};

const MobileNavItem = ({
  label, icon, href, onClick, routeParams
}) => {
  const { section } = routeParams;
  const active = href?.includes(section);
  const cls = active ? 'mobile-nav-item active' : 'mobile-nav-item';

  return (
    <li>
      <a className={cls} href="#" onClick={onClick || Navigate(href)}>
        {icon && <i className={icon} />}
        <span className="name">{label}</span>
      </a>
    </li>
  );
};
export default MobileNavItem;
