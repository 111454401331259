import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { google } from '@Utils/preference-keys';
import { fetchGoogleBusinessAuthUrl } from '@State/google-business-actions';
import GoogleBusinessInfo from '@Components/dialogs/google-business-info';

const GoogleBusiness = () => {
  const dispatch = useDispatch();
  const locationConfig = useSelector(state => state.locationConfig);
  const googleBusinessPlaceId = locationConfig.get(google.placeId);

  const handleConnect = useCallback(() => dispatch(fetchGoogleBusinessAuthUrl())
    .then(({ linkUrl }) => { window.location = linkUrl; }), []);

  if (!googleBusinessPlaceId) {
    return (
      <div className="subnav-container center">
        <div>
          <img src="https://cliento.com/images/features/feature-google-business.webp" height={250} />
          <p className="lead mt2 mb5">
            Koppla ihop din bokningssida på Cliento med din Google<br />
            företagsprofil och ranka högre vid sökningar
          </p>
          <button className="btn btn-modern btn-xl" onClick={handleConnect}>
            Koppla till Google-konto
            <i className="fa fa-chevron-right ml2" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="subnav-container">
      <h3 className="mb3">
        Google företagsprofil
      </h3>
      <GoogleBusinessInfo />
    </div>
  );
};

export default GoogleBusiness;
