import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFeatures, getRouteParams } from '@State/selectors';
import { logoutOperator } from '@State/pos-actions';
import { txt } from '@Utils/i18n-util';
import { getAutoLoginOperator, getPosTitle } from '@State/pos-selectors';
import PosHeaderNav from '@Components/pos/header/pos-header-nav';
import PosHeaderToolbar from '@Components/pos/header/pos-header-toolbar';
import PosDeviceStatus from '@Components/pos/header/pos-device-status';
import UserMenuButton from '@Components/nav/user-menu-button';
import Lock from '@Components/pos/header/pos-btn-lock';
import { HeaderLock, HeaderLockUser } from '../style';
import msg from './pos-header.msg';

const PosHeader = (props) => {
  const routeParams = useSelector(state => getRouteParams(state, props));

  const dispatch = useDispatch();
  const enablePos = useSelector(state => getFeatures(state).EnablePOS);
  const posOrgs = useSelector(({ posOrgs }) => posOrgs);
  const posUnit = useSelector(({ pos }) => pos.get('posUnit'));
  const operator = useSelector(({ pos }) => pos.get('operator'));
  const showContent = enablePos && posOrgs && !posOrgs.isEmpty();
  const autoLoginOperator = useSelector(getAutoLoginOperator);
  const title = useSelector(getPosTitle);

  const onLogoutOperator = () => dispatch(logoutOperator());

  const showLockPage = !showContent || !operator || !posUnit;

  if (showLockPage) {
    return (
      <HeaderLock>
        <HeaderLockUser>
          <UserMenuButton routeParams={routeParams} />
        </HeaderLockUser>
      </HeaderLock>
    );
  }

  return (
    <header className="section-header">
      {showContent && !autoLoginOperator && <Lock isUnlocked={!!operator} onClick={onLogoutOperator} />}
      <div className="section-header-title">
        <h1>{title}</h1>
        {showContent && (
          <h2>
            {operator
              ? txt(msg.lblCashier, { cashier: operator.opName })
              : txt(msg.lblLoggedOut)}
          </h2>
        )}
      </div>
      <div className="section-header-content">
        <PosHeaderNav routeParams={routeParams} />
        <PosHeaderToolbar routeParams={routeParams} />
      </div>
      <div className="section-header-right">
        <UserMenuButton routeParams={routeParams} />
        <PosDeviceStatus />
      </div>
    </header>
  );
};

export default PosHeader;
