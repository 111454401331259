import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getShowFeaturesOnboarding } from '@State/selectors';
import { setAccountInfoDismissed, shouldShowAccountInfo, smsSendLimitReached } from '@Utils/account';
import { getOrganisationsUrl } from '@Utils/navigate';
import { getBannerStyle, isEmbeddedOrWrapped } from '@Utils/embedded-util';

class AccountStatus extends Component {
  constructor(props) {
    super(props);

    this.state = this.makeInitialState(props);
  }

  makeInitialState(props) {
    return {
      showAccountInfo: shouldShowAccountInfo(props.trialStatus, props.trialUntil, props.accountStatus)
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      showAccountInfo: shouldShowAccountInfo(nextProps.trialStatus, nextProps.trialUntil, nextProps.accountStatus)
    });
  }

  render() {
    const { showAccountInfo } = this.state;
    const { showOnboarding, smsLimitReached, bannerStyle } = this.props;

    return showAccountInfo && !showOnboarding ? (
      <div className="alert-floating-container" style={bannerStyle}>
        <div className="alert-floating alert-info">
          {!smsLimitReached && (
            <a href="#" className="alert-close" onClick={this.close}><i className="fa fa-times" /></a>
          )}
          {this.renderContent()}
        </div>
      </div>
    ) : null;
  }

  renderContent() {
    const { trialStatus, trialUntil, accountStatus, smsLimitReached } = this.props;

    if (trialStatus === 'TrialExpired') {
      return this.renderTrialExpired();
    } if (trialStatus === 'Trial') {
      return this.renderTrial(trialUntil);
    } if (accountStatus === 'ActivePaymentRequired') {
      return this.renderPaymentRequired();
    } if (smsLimitReached) {
      return this.renderSmsLimitReached();
    }
    return null;
  }

  renderTrial(trialUntil) {
    const daysLeft = trialUntil.diff(moment(), 'days');
    const daysLeftText = daysLeft === 1 ? 'en dag' : `${daysLeft} dagar`;

    return (
      <p>
        Hej! Du har {daysLeftText} kvar av din provperiod. Du kan aktivera ditt konto när som helst under {this.renderOrganisationsLink()}.
      </p>
    );
  }

  renderTrialExpired() {
    return (
      <p>
        Din gratis provperiod är slut. Gå till {this.renderOrganisationsLink()} för att aktivera ditt konto.
      </p>
    );
  }

  renderPaymentRequired() {
    return (
      <p>
        Betalning saknas, vänligen kontrollera betalningsinformationen som är kopplad till kontot under {this.renderOrganisationsLink()}.
      </p>
    );
  }

  renderSmsLimitReached() {
    return (
      <p>
        <i className="fa fa-exclamation-triangle mr2" />
        Ditt konto är spärrat för SMS. Inga påminnelser eller bokningsbekräftelser kommer skickas.
        Kontakta support för åtgärd.
      </p>
    );
  }

  renderOrganisationsLink() {
    const { embeddedOrWrapped } = this.props;

    return embeddedOrWrapped
      ? 'Abonnemang'
      : <a href={getOrganisationsUrl(this.props.match.params)}>Abonnemang</a>;
  }

  close = (ev) => {
    ev.preventDefault();
    setAccountInfoDismissed();
    this.setState({ showAccountInfo: false });
  };
}

const mapStateToProps = (state) => {
  const { appState } = state;

  return {
    trialStatus: appState.get('trialStatus'),
    trialUntil: appState.get('trialUntil'),
    accountStatus: appState.get('accountStatus'),
    embeddedOrWrapped: isEmbeddedOrWrapped(state),
    smsLimitReached: smsSendLimitReached(appState.get('accountStatus')),
    showOnboarding: getShowFeaturesOnboarding(state),
    bannerStyle: getBannerStyle(state)
  };
};

export default connect(mapStateToProps)(AccountStatus);
