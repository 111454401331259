import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getReportParams } from '@Components/reports/reports-helpers';
import { downloadDeferredSalesReportExcel } from '@State/report-actions';
import Button from '@Components/ui/button';

const DeferredSalesReportToolbar = (props) => {
  const dispatch = useDispatch();
  const reportParams = useSelector(state => getReportParams(state, props));

  const handleDownloadExcel = useCallback((ev) => {
    ev.preventDefault();
    ev.target.blur();
    const { start, end } = reportParams;
    dispatch(downloadDeferredSalesReportExcel(start, end));
  }, [reportParams]);

  return (
    <Button onClick={handleDownloadExcel} gray small>Ladda ner</Button>
  );
};

export default DeferredSalesReportToolbar;
