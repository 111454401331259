import moment from 'moment';
import { createSelector } from 'reselect';
import { web } from '@Utils/preference-keys';

export const priceType = {
  price: 'price',
  priceEvening: 'priceEvening',
  priceHoliday: 'priceHoliday'
};

const getPriceConfig = createSelector(
  state => state.locationConfig,
  (locationConfig) => {
    return {
      redDays: locationConfig.get(web.holidayPriceOnPublicHoliday),
      saturdays: locationConfig.get(web.holidayPriceOnSaturday),
      sundays: locationConfig.get(web.holidayPriceOnSunday),
      eveningStart: locationConfig.get(web.eveningPriceStart)
    };
  }
);

const getCurrentHolidays = createSelector(
  state => state.schedulesByResource,
  (schedulesByResource) => {
    const { blocks } = schedulesByResource.first();
    return blocks
      ? blocks.filter(b => b.publicHoliday).map(b => b.day)
      : [];
  }
);

const getPriceType = (priceConfig, holidays, startTime) => {
  const { redDays, saturdays, sundays, eveningStart } = priceConfig;
  const date = startTime.format('YYYY-MM-DD');
  const eveningTime = eveningStart && moment(`${date}T${eveningStart}`);

  if (redDays && holidays.includes(date)) {
    return priceType.priceHoliday;
  }
  if (sundays && startTime.isoWeekday() === 7) {
    return priceType.priceHoliday;
  }
  if (saturdays && startTime.isoWeekday() === 6) {
    return priceType.priceHoliday;
  }
  if (eveningTime && startTime.isSameOrAfter(eveningTime)) {
    return priceType.priceEvening;
  }
  return priceType.price;
};

export const getPriceCategory = createSelector(
  getPriceConfig,
  getCurrentHolidays,
  (state, props) => state.bkf.get('startTime'),
  (priceConfig, holidays, startTime) => {
    return getPriceType(priceConfig, holidays, startTime);
  }
);

export const getNewPriceCategory = createSelector(
  getPriceConfig,
  getCurrentHolidays,
  (state, props) => props.startTime,
  (state, props) => state.bookingsById.get(props.bookingId),
  state => state.servicesById,
  (priceConfig, holidays, startTime, booking, servicesById) => {
    const useDynamicPricing = s => servicesById.get(s.id)?.useDynamicPricing;
    if (booking.services.every(s => !useDynamicPricing(s))) {
      return null;
    }

    const oldPriceType = getPriceType(priceConfig, holidays, booking.startTime);
    const newPriceType = getPriceType(priceConfig, holidays, startTime);

    return oldPriceType !== newPriceType
      ? newPriceType
      : null;
  }
);
