import React from 'react';
import ModalDialog from './modal-dialog';
import GoogleBusinessInfo from './google-business-info';

const GoogleBusinessCompleted = ({ onClose }) => {
  return (
    <ModalDialog
      contentSize="large"
      title="Ditt Google-konto är kopplat!"
      hideCloseButton
      closeOnClickOutside={false}
      onClose={onClose}
      buttons={onClose && [{
        gray: true,
        name: 'Stäng',
        onClick: onClose
      }]}
    >
      <GoogleBusinessInfo />
    </ModalDialog>
  );
};

export default GoogleBusinessCompleted;
