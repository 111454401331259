import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchGoogleBusinessInfo, revokeGoogleBusinessToken, updateGoogleBusinessInfo
} from '@State/google-business-actions';
import { company } from '@Utils/preference-keys';
import Loader from '@Components/ui/loader';
import ProgressButton from '@Components/ui/progress-button';
import Panel from '@Components/ui/styled/panel';

const GoogleBusinessInfo = () => {
  const dispatch = useDispatch();
  const locationConfig = useSelector(state => state.locationConfig);

  const [loading, setLoading] = useState(false);
  const [business, setBusiness] = useState(null);

  const {
    title, primaryPhone, webSiteUri, profileDescription,
    addressLine1, postalCode, locality
  } = business || {};

  useEffect(() => {
    setLoading(true);
    dispatch(fetchGoogleBusinessInfo())
      .then((response) => setBusiness(response))
      .finally(() => setLoading(false));
  }, []);

  const handleUpdate = () => {
    const data = {
      title: locationConfig.get(company.companyName) || title,
      description: profileDescription,
      phone: locationConfig.get(company.contactNumber) || primaryPhone,
      websiteUri: locationConfig.get(company.contactWebsite) || webSiteUri,
      address: {
        addressLine1: locationConfig.get(company.addressLine1) || addressLine1,
        postCode: locationConfig.get(company.postCode) || postalCode,
        area: locationConfig.get(company.area) || locality,
        country: 'SE'
      }
    };
    return dispatch(updateGoogleBusinessInfo(data));
  };

  const handleDisconnect = () => dispatch(revokeGoogleBusinessToken());

  return loading ? <Loader /> : (
    <Panel maxWidth="550px">
      <h4>{title}</h4>
      <p className="mb0"><i className="fa fa-home mr1" />{addressLine1}, {postalCode} {locality}</p>
      <p className="mb0"><i className="fa fa-phone mr1" />{primaryPhone}</p>
      <p><i className="fa fa-globe mr1" />{webSiteUri}</p>
      <p>{profileDescription}</p>
      <div className="mt2">
        <ProgressButton small gray marginTop marginRight onClick={handleUpdate}>Uppdatera info</ProgressButton>
        <ProgressButton small danger marginTop onClick={handleDisconnect}>Koppla från</ProgressButton>
      </div>
    </Panel>
  );
};

export default GoogleBusinessInfo;
