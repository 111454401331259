import React from 'react';
import { classes } from './utils';

const AlertWithIcon = ({
  success = false, warning = false, center = false, error = false, onClick = null,
  className = '', icon = null, children, button = null, small = false, multiline = false
}) => {
  const classNames = classes({
    alert: true,
    'alert-sm': small,
    'alert-success': success,
    'alert-warning': warning,
    'alert-info': !warning && !success && !error,
    'alert-with-icon': true,
    'alert-center': center,
    'alert-danger': error,
    'alert-clickable': onClick,
    [className]: true
  });
  return (
    <div className={classNames} onClick={onClick}>
      {icon && <i className={icon} />}
      <p>
        {children}
      </p>
      {button && (
        <div className={multiline ? 'alert-button-container multiline' : 'alert-button-container'}>
          {button}
        </div>
      )}
    </div>
  );
};

export default AlertWithIcon;
