import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSysAdmin } from '@State/selectors';
import { getPosOrg } from '@State/pos-selectors';
import { getCustomerDetails } from '@Utils/pos-utils';
import { downloadReceiptSie, resetReceiptCopyCount } from '@State/pos-actions';
import { Button } from '@Components/ui/styled/main';
import ProgressButton from '@Components/ui/progress-button';
import Popover from '@Components/ui/popover';
import ReceiptAccountingModal from './receipt-accounting-modal';
import ChangeCustomerModal from './change-customer-modal';

const ReceiptTools = ({ receipt, printReceipt, showSendReceipt, routeParams }) => {
  const dispatch = useDispatch();
  const posOrg = useSelector(getPosOrg);
  const isSysAdmin = useSelector(getSysAdmin);

  const [isPrinting, setIsPrinting] = useState(false);
  const [showTools, setShowTools] = useState(false);
  const [showAccounting, setShowAccounting] = useState(false);
  const [showChangeCustomer, setShowChangeCustomer] = useState(false);

  const hasReceipt = receipt && !receipt.isEmpty();
  const isEcom = receipt.get('vunitName') === 'Ecommerce';
  const isInvoice = receipt.get('receiptType')?.includes('Invoice');
  const canPrintReceipt = !isPrinting && hasReceipt && receipt.get('copyCount') === 0;
  const customerDetails = getCustomerDetails(receipt.get('customerName'), receipt.get('customerPno'));
  const isPosReceipt = !isEcom && !isInvoice;
  const shouldShowTools = hasReceipt && (isSysAdmin || customerDetails || isPosReceipt);

  const onHideTools = () => setShowTools(false);
  const onHideChangeCustomer = () => setShowChangeCustomer(false);
  const onShowChangeCustomer = () => {
    setShowChangeCustomer(true);
    onHideTools();
  };

  const onEmailReceipt = () => {
    showSendReceipt();
    onHideTools();
  };

  const onPrintReceipt = () => {
    setIsPrinting(true);
    return printReceipt()
      .then(() => setIsPrinting(false));
  };

  const onDownloadSie = () => {
    return dispatch(downloadReceiptSie(posOrg.get('id'), receipt.get('receiptId')))
      .then(onHideTools);
  };

  const onResetCount = () => {
    return dispatch(resetReceiptCopyCount(posOrg.get('id'), receipt.get('receiptId')))
      .then(onHideTools);
  };

  const onShowAccounting = () => {
    onHideTools();
    setShowAccounting(true);
  };

  const toolsPopoverBody = (
    <div className="Popover-dialog-sm button-wrapper">
      <div className="menu-button-wrapper">
        {isPosReceipt && (
          <>
            <Button small gray block onClick={onEmailReceipt} disabled={!canPrintReceipt}>Mejla kopia</Button>
            <Button small gray block onClick={onPrintReceipt} disabled={!canPrintReceipt}>Skriv ut kopia</Button>
          </>
        )}
        {customerDetails && <Button small gray block onClick={onShowChangeCustomer}>Byt kund</Button>}
        {isSysAdmin && (
          <>
            <Button small gray block onClick={onShowAccounting}>Visa bokföring</Button>
            <ProgressButton small gray block onClick={onDownloadSie}>Ladda ner SIE-fil</ProgressButton>
            {receipt.get('copyCount') > 0 && (
              <ProgressButton small gray block onClick={onResetCount}>Tillåt kopia igen</ProgressButton>
            )}
          </>
        )}
      </div>
    </div>
  );

  return shouldShowTools ? (
    <>
      <Popover
        preferPlace="below"
        isOpen={showTools}
        onOuterAction={onHideTools}
        body={toolsPopoverBody}
        className="Popover-big-radius"
      >
        <Button gray small marginLeft onClick={() => setShowTools(!showTools)}>
          Alternativ
          <i className="far fa-fw fa-chevron-down" />
        </Button>
      </Popover>
      {showAccounting && (
        <ReceiptAccountingModal
          posOrg={posOrg}
          receipt={receipt}
          onClose={() => setShowAccounting(false)}
        />
      )}
      {showChangeCustomer && <ChangeCustomerModal routeParams={routeParams} receipt={receipt} onClose={onHideChangeCustomer} />}
    </>
  ) : null;
};

export default ReceiptTools;
