import React, { PureComponent } from 'react';
import styled from 'styled-components';
import {
  BlockStyledPos, BlockButton, Circle, Status, FlexFull
} from '@Components/pos/style';
import { Column, Text } from '@Components/ui/styled/main';
import { colors } from '@Components/ui/styled/variables';
import { getPosUnitTitle, sortPosUnits } from '@Utils/pos-utils';
import { txt } from '@Utils/i18n-util';
import msg from './pos.msg';

export const Title = styled.h4`
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const GrayText = styled.div`
  color: ${colors.textMuted};
  text-align: left;

  span {
    margin-top: -2px;
  }
`;

class SelectPosUnit extends PureComponent {
  componentDidMount() {
    const { posUnits } = this.props;
    this.autoSelectPosUnit(posUnits);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { posUnits } = nextProps;

    if (this.props.posUnits.isEmpty() && !posUnits.isEmpty()) {
      this.autoSelectPosUnit(posUnits);
    }
  }

  autoSelectPosUnit = (posUnits) => {
    if (posUnits.size === 1) {
      this.props.onSelect(posUnits.first().toJS());
    } else if (!posUnits.isEmpty()) {
      const openHere = posUnits.find(p => p.get('state') === 'OpenCurrentDevice');

      // Auto select pos unit open on this device if all units are for the same company
      if (openHere && posUnits.every(p => p.get('posOrgId') === openHere.get('posOrgId'))) {
        this.props.onSelect(openHere.toJS());
      }
    }
  };

  getStatusBody = (status) => {
    switch (status) {
      case 'OpenNoCurrentDevice':
      case 'OpenCurrentDevice':
        return (
          <>
            {txt(msg.lblOpen)}
            <Circle bg={colors.success} />
          </>
        );
      case 'OpenOtherDevice':
        return (
          <>
            {txt(msg.lblOpenOtherDevice)}
            <Circle bg={colors.danger} />
          </>
        );
      case 'Closed':
        return (
          <>
            {txt(msg.lblClosed)}
            <Circle bg={colors.softGray80} />
          </>
        );
    }
  };

  render() {
    const { posUnits } = this.props;

    if (posUnits.isEmpty()) {
      return (
        <div className="pos">
          <BlockStyledPos>
            <h3>{txt(msg.lblUnauthorized)}</h3>
            <Text muted>{txt(msg.lblUnauthorizedText)}</Text>
          </BlockStyledPos>
        </div>
      );
    }

    return (
      <div className="pos">
        <BlockStyledPos>
          <h3>{txt(msg.lblSelectPosUnit)}</h3>
          <br />
          {posUnits.sort(sortPosUnits).map((posUnit, index) => {
            const state = posUnit.get('state');
            const isOnprem = posUnit.get('type').includes('Onprem');
            const title = getPosUnitTitle(posUnits, posUnit);

            const handleOnSelect = () => this.props.onSelect(posUnit.toJS());

            return (
              <BlockButton
                key={index}
                onClick={handleOnSelect}
              >
                <Column>
                  <FlexFull>
                    <Title>{title}</Title>
                    <Status>
                      <GrayText>
                        {isOnprem ? this.getStatusBody(state) : null}
                      </GrayText>
                    </Status>
                  </FlexFull>
                  <GrayText>{isOnprem ? posUnit.get('address') : posUnit.get('type')}</GrayText>
                </Column>
              </BlockButton>
            );
          })}
        </BlockStyledPos>
      </div>
    );
  }
}

export default SelectPosUnit;
