import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'customers.export.title',
    defaultMessage: 'Download customers'
  },
  btnDownload: {
    id: 'customers.export.btnDownload',
    defaultMessage: 'Download'
  },
  txtInfo: {
    id: 'customers.export.txtInfo',
    defaultMessage: 'Download a file with all customers, or customers for a specific resource.'
  },
  lblCustomers: {
    id: 'customers.export.lblCustomers',
    defaultMessage: 'Customers'
  },
  lblAllCustomers: {
    id: 'customers.export.lblAllCustomers',
    defaultMessage: 'All customers'
  },
  lblHistory: {
    id: 'customers.export.lblHistory',
    defaultMessage: 'History'
  },
  lblIncludeHistory: {
    id: 'customers.export.lblIncludeHistory',
    defaultMessage: 'Include logs and bookings'
  }
});
