import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { formatPhoneNumber, formatPhoneNumberE164 } from '@Utils/phone-util';
import { updateCustomerAddress, updateCustomerInfo } from '@State/customer-actions';
import { formatOfficialIdNo, getCustomerErrorMessage, getInitialOfficialIdType, isCustomerCompany, isCustomerPerson, isCustomerVehicle } from '@Utils/customer-util';
import CustomerInformationForm from '@Components/customers/customer-detail/overview/customer-information-form';
import ModalDialog from '@Components/dialogs/modal-dialog';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import { txt } from '@Utils/i18n-util';
import msg from './customer-edit-modal.msg';
import VehicleInformationForm from './vehicle-information-form';
import CompanyInformationForm from './company-information-form';

const CustomerEditModal = ({ customer, onClose }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('info');

  const {
    addressLine1, addressLine2, addressLine3, area, postCode, country,
    name, phoneNumber, otherPhoneNumber, email, officialIdNo, gender,
    customerType, officialIdType, id
  } = customer;

  const initialInformationData = {
    customerType,
    name,
    email,
    gender,
    officialIdNo,
    officialIdType: getInitialOfficialIdType(customerType, officialIdType),
    phoneNumber: formatPhoneNumber(phoneNumber),
    otherPhoneNumber: formatPhoneNumber(otherPhoneNumber)
  };

  const initialAddressData = {
    addressLine1,
    addressLine2,
    addressLine3,
    area,
    postCode,
    country
  };

  const initialData = { ...initialInformationData, ...initialAddressData };

  const onSubmitInfo = (newInfo) => {
    return dispatch(updateCustomerInfo(id, newInfo));
  };

  const onSubmitAddress = (newAddress) => {
    return dispatch(updateCustomerAddress(id, newAddress));
  };

  const handleSubmit = async (values) => {
    const newAddress = {
      addressLine1: values.addressLine1 || '',
      addressLine2: values.addressLine2,
      addressLine3: values.addressLine3,
      area: values.area || '',
      postCode: values.postCode || '',
      country: values.country || ''
    };
    const newInfo = {
      customerType: values.customerType,
      name: values.name,
      email: values.email,
      gender: values.gender,
      officialIdNo: values.officialIdNo,
      officialIdType: values.officialIdNo ? values.officialIdType : null,
      phoneNumber: values.phoneNumber,
      otherPhoneNumber: values.otherPhoneNumber
    };

    try {
      if (!isEqual(initialInformationData, newInfo)) {
        await onSubmitInfo({
          ...newInfo,
          officialIdNo: formatOfficialIdNo(newInfo, customerType),
          phoneNumber: newInfo.phoneNumber ? formatPhoneNumberE164(newInfo.phoneNumber) : null,
          otherPhoneNumber: newInfo.otherPhoneNumber ? formatPhoneNumberE164(newInfo.otherPhoneNumber) : null
        });
      }
      if (!isEqual(initialAddressData, newAddress)) {
        await onSubmitAddress(newAddress);
      }
      onClose();
    } catch (error) {
      setError(error);
    }
  };

  const handleTabClick = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab);
  };

  const getClassTab = (tab) => activeTab === tab ? 'tabs__item tabs__item--active' : 'tabs__item';

  return (
    <ModalDialog
      onClose={onClose}
    >
      {error && (
        <AlertWithIcon icon="fa fa-exclamation-circle" error>
          {getCustomerErrorMessage(customerType, error)}
        </AlertWithIcon>
      )}
      {isCustomerPerson(customer) && (
        <ul className="tabs__list modal-margins">
          <li className={getClassTab('info')}>
            <a href="#" onClick={e => handleTabClick(e, 'info')}>{txt(msg.customerDetails)}</a>
          </li>
          <li className={getClassTab('address')}>
            <a href="#" onClick={e => handleTabClick(e, 'address')}>{txt(msg.address)}</a>
          </li>
        </ul>
      )}
      {isCustomerVehicle(customer) && (
        <VehicleInformationForm
          onClose={onClose}
          onSubmit={handleSubmit}
          initialValues={initialData}
        />
      )}
      {isCustomerCompany(customer) && (
        <CompanyInformationForm
          onClose={onClose}
          onSubmit={handleSubmit}
          initialValues={initialData}
        />
      )}
      {isCustomerPerson(customer) && (
        <CustomerInformationForm
          showInfo={activeTab === 'info'}
          onClose={onClose}
          onSubmit={handleSubmit}
          initialValues={initialData}
        />
      )}
    </ModalDialog>
  );
};

export default CustomerEditModal;
