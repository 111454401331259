import React from 'react';
import { txt } from '@Utils/i18n-util';
import msg from './confirm-remove-customer.msg';

const ConfirmRemoveCustomer = ({ onCancel, onConfirm, progress }) => {
  return (
    <div className={progress ? 'disabled' : ''}>
      <p>
        <strong>{txt(msg.txtConfirmRemove)}</strong>
      </p>
      <br />
      <div className="text-right">
        <button type="button" className="btn btn-default" tabIndex={4} onClick={onCancel}>{txt(msg.btnCancel)}</button> &nbsp;
        <button type="submit" className="btn-save" tabIndex={3} onClick={onConfirm}>{progress ? txt(msg.btnRemoving) : txt(msg.btnConfirm)}</button>
      </div>
    </div>
  );
};

export default ConfirmRemoveCustomer;
