import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { replaceJsonPreferences } from '@State/preferences-actions';
import JsonEditor from '@Components/ui/jsoneditor/jsoneditor';
import Loader from '@Components/ui/loader';

const AdminConfigPrefs = () => {
  const dispatch = useDispatch();
  const jsonPreferences = useSelector(state => state.preferencesViewState.get('jsonPreferences'));
  if (!jsonPreferences) {
    return (<Loader />);
  }
  return (
    <JsonEditor
      title="Prefs"
      json={jsonPreferences}
      onSubmit={(values) => dispatch(replaceJsonPreferences(values))}
    />
  );
};

export default AdminConfigPrefs;
