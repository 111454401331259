import React from 'react';
import { HeaderSubText, TextEllipsis } from '@Components/ui/styled/main';
import ViewModeSwitcher from './viewmode-switcher';
import DateNavigator from './date-navigator';
import CalendarHeaderResourceTablet from './calendar-header-resource-tablet';
import CalendarHeaderUserBlock from './calendar-header-user-block';
import CalendarHeaderToolbarButtons from './calendar-header-toolbar-buttons';

const CalendarHeaderTable = ({
  dateLabel, routeParams, viewDateStartEnd, calendarTitle
}) => (
  <header className="section-header calendar-header ipad">
    <div className="section-header-content ui-btn-block">
      <TextEllipsis className="ui-mar-right-auto title-block">
        <DateNavigator routeParams={routeParams} viewDateStartEnd={viewDateStartEnd} />
        <HeaderSubText tabletMode>{dateLabel}</HeaderSubText>
      </TextEllipsis>
      <div className="ui-mar-right-auto">
        <CalendarHeaderResourceTablet calendarTitle={calendarTitle} routeParams={routeParams} />
        <ViewModeSwitcher tabletMode routeParams={routeParams} />
      </div>
      <div className="ui-mar-right-auto"><CalendarHeaderToolbarButtons routeParams={routeParams} /></div>
    </div>
    <CalendarHeaderUserBlock isTableMode routeParams={routeParams} />
  </header>
);

export default CalendarHeaderTable;
