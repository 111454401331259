import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Field, reduxForm } from 'redux-form';
import Popover from '@Components/ui/popover';
import useTimeout from '@Hooks/useTimeout';

import { createValidator, email, required } from '@Utils/validation';
import { checkUserName } from '@State/users-actions';
import TextInput from '@Components/inputs/text-input';
import ConfirmBlock from '@Components/ui/confirm-block';
import StaffRoleFormRow from '@Components/admin/staff-config/staff-role-form-row';

const UserExistsMessage = styled.div`
  padding-top: 18px;
  padding-bottom: 24px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
`;

const InputsDisableWrapper = styled.div`
  ${({ disabled }) => disabled && css`
    opacity: 0.5;
  `}
`;

const StaffConfigUserLoginForm = ({
  id, userNameChecked, isOpen, onSubmit, handleSubmit, onHidePopup, children, dispatch, ...formState
}) => {
  const { validationPassed = false, registered = false } = userNameChecked || {};
  const usersById = useSelector(state => state.usersById);
  const emails = usersById.valueSeq().map((user) => user.get('username'));

  const isEmailExist = () => userName && emails.includes(userName);

  const getErrors = () => {
    if (userName === '') return false;
    if (isEmailExist()) {
      return 'Denna email existerar redan';
    }
    return !validationPassed ? 'Ogiltig e-postadress' : undefined;
  };

  const getMeta = () => ({
    error: getErrors(),
    touched: userName !== ''
  });

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [meta, setMeta] = useState(getMeta());

  const handleChangePassword = useCallback(
    (event, newValue) => setPassword(newValue),
    []
  );
  const handleChangeName = useCallback(
    (event, newValue) => setUserName(newValue),
    []
  );

  useTimeout({
    handler: () => dispatch(checkUserName(userName, id)),
    depends: userName,
    checkerFunction: () => userName !== ''
  });

  const onConfirm = useCallback(() => {
    formState.validate();
    if (formState.valid) {
      handleSubmit();
    }
  }, []);

  const getDisableState = () => meta.error || !(registered && validationPassed || password && validationPassed);

  useEffect(() => {
    if (userName !== '') {
      setMeta(getMeta());
    }
  }, [userName, validationPassed, registered]);

  useEffect(() => {
    return () => {
      if (!isOpen) {
        setMeta('');
        setUserName('');
      }
    };
  }, [isOpen]);

  const renderBody = (
    <div className="Popover-dialog-sm staff-popup-form">
      <form onSubmit={handleSubmit}>
        <ConfirmBlock
          disableConfirm={getDisableState()}
          confirmText="Lägg till"
          onCancel={onHidePopup}
          onConfirm={onConfirm}
        >
          <Field
            name="username"
            label="Användarnamn (e-post)"
            component={TextInput}
            meta={meta}
            onChange={handleChangeName}
          />
          <InputsDisableWrapper disabled={!userName}>
            {!registered
              ? (
                <Field
                  name="password"
                  label="Lösenord"
                  disabled={!userName}
                  component={TextInput}
                  className="form-control-password"
                  onChange={handleChangePassword}
                />
              )
              : <UserExistsMessage>Användaren finns redan, lösenord behöver inte anges.</UserExistsMessage>}
            <StaffRoleFormRow disabled={!userName} />
          </InputsDisableWrapper>
        </ConfirmBlock>
      </form>
    </div>
  );

  return (
    <Popover
      isOpen={isOpen}
      className="Popover-big-radius"
      preferPlace="below"
      onOuterAction={onHidePopup}
      body={renderBody}
    >
      {children}
    </Popover>
  );
};

StaffConfigUserLoginForm.propTypes = {
  isOpen: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onHidePopup: PropTypes.func
};

const validator = createValidator({
  userPassword: [required],
  username: [required, email],
  userRole: [required]
});

export default reduxForm({
  form: 'staff-config-user-login',
  validate: validator,
  enableReinitialize: true
})(StaffConfigUserLoginForm);
