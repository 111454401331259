import { defineMessages } from 'react-intl';

export default defineMessages({
  lblHeaderTitle: {
    id: 'staff.header.lblHeaderTitle',
    defaultMessage: 'Staff Journal'
  },
  btnShowJournal: {
    id: 'staff.header.btnShowJournal',
    defaultMessage: 'Show journal'
  },
  btnPrint: {
    id: 'staff.header.btnPrint',
    defaultMessage: 'Print'
  },
  lblListTitle: {
    id: 'staff.list.lblListTitle',
    defaultMessage: 'Staff'
  },
  txtStaffConfig: {
    id: 'staff.list.txtStaffConfig',
    defaultMessage: 'Add or change staff?'
  },
  btnStaffConfig: {
    id: 'staff.list.btnStaffConfig',
    defaultMessage: 'Go to Staff'
  }
});
