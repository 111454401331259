import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchSchedules, fetchResourceSchedules, toggleResourceLocationSchedule, fetchScheduleExceptions
} from '@State/schedule-actions';
import { getResourcePreferences } from '@State/preferences-actions';
import ScheduleExceptions from '@Components/schedule/schedule-exceptions';
import ScheduleHolidays from '@Components/schedule/schedule-holidays';
import Schedule from '@Components/schedule/schedule';
import { classes } from '@Components/ui/utils';
import Button from '@Components/ui/button';
import Loader from '@Components/ui/loader';
import { txt } from '@Utils/i18n-util';
import msg from './schedules.msg';

const styleImageExceptionsEmpty = { width: '500px' };

const ScheduleContent = ({ routeParams }) => {
  const dispatch = useDispatch();
  const { entityId: resourceId } = routeParams;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    if (!isNaN(resourceId)) {
      Promise.all([
        dispatch(fetchResourceSchedules(resourceId)),
        dispatch(fetchScheduleExceptions(resourceId)),
        dispatch(getResourcePreferences(resourceId))
      ]).then(() => setIsLoading(false));
    } else {
      dispatch(fetchSchedules())
        .then(() => setIsLoading(false));
    }
  }, [resourceId]);

  const locationScheduleEnabled = useSelector(state => state.resourcesById.some(r => r.useLocationSchedule));
  const useLocationSchedule = useSelector(state => state.adminSchedules.get('useLocationSchedule'));
  const schedules = useSelector(state => state.adminSchedules.get('schedules'));
  const exceptions = useSelector(state => state.adminSchedules.get('exceptions'));
  const showSchedule = (!resourceId && locationScheduleEnabled) || (resourceId && !useLocationSchedule);

  const toggleSchedule = () => {
    dispatch(toggleResourceLocationSchedule(resourceId, showSchedule));
  };

  const classNames = classes({
    'schedule-content': true,
    'schedule-bg': !showSchedule
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Switch>
      <Route
        exact
        path="/:org/:loc/:section(admin)/schedules/:id/exception"
        render={() => (
          <div className="schedule-content">
            {!exceptions || exceptions && exceptions.length === 0
              ? (
                <div className="golden-content">
                  <div className="gray-text">
                    {txt(msg.txtNoExceptions1)}<br />
                    {txt(msg.txtNoExceptions2)}
                  </div>
                  <img style={styleImageExceptionsEmpty} src="/exceptions-empty.png" />
                </div>
              )
              : <ScheduleExceptions resourceId={resourceId} exceptions={exceptions} />}
          </div>
        )}
      />
      <Route
        exact
        path="/:org/:loc/:section(admin)/schedules/:id/schedule"
        render={() => (
          <div className={classNames}>
            {showSchedule
              ? <Schedule schedules={schedules} resourceId={resourceId} />
              : (
                <div className="golden-content">
                  <div className="gray-text">
                    {txt(msg.txtSharedSchedule1)}<br />
                    {txt(msg.txtSharedSchedule2)}
                  </div>
                  <Button small primary onClick={toggleSchedule}>
                    {txt(msg.btnUseIndividualSchedule)}
                  </Button>
                </div>
              )}
          </div>
        )}
      />
      <Route
        exact
        path="/:org/:loc/:section(admin)/schedules/:id/holidays"
        render={() => (
          <ScheduleHolidays resourceId={resourceId} />
        )}
      />
      <Route
        exact
        path="/:org/:loc/:section(admin)/schedules/"
        render={() => (
          <div className={classNames}>
            {showSchedule
              ? <Schedule schedules={schedules} />
              : (
                <div className="golden-content">
                  <div className="gray-text">
                    {txt(msg.txtAllIndividual1)}<br /><br />
                    {txt(msg.txtAllIndividual2)}
                  </div>
                </div>
              )}
          </div>
        )}
      />
    </Switch>
  );
};

export default ScheduleContent;
