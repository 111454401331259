import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { openDrawer } from '@State/pos-actions';
import TB from '@Components/ui/tb-button';
import CenterBlocks from '@Components/ui/styled/center-blocks';
import { marginButtonStyle, labelStyle, iconStyle } from '@Components/pos/style';
import DialogPosClose from '@Components/pos/dialogs/dialog-pos-close';
import DialogPosFixSale from '@Components/pos/dialogs/dialog-pos-fix-sale';
import DialogPosDepositWithdraw from '@Components/pos/dialogs/dialog-pos-deposit-withdraw';
import { getCashEnabled, getShowOpenDrawer, hasPrinterError } from '@State/pos-selectors';
import { isPosUnitClosed, isPosUnitOpenCurrentDevice } from '@Utils/pos-utils';
import { getSysAdmin } from '@State/selectors';
import { txt } from '@Utils/i18n-util';
import msg from './pos-header.msg';

const TBStyled = (props) => (
  <TB
    iconStyle={iconStyle}
    buttonStyle={marginButtonStyle}
    labelStyle={labelStyle}
    {...props}
  />
);

const PosHeaderToolbar = ({ routeParams }) => {
  const isSysAdmin = useSelector(getSysAdmin);
  const posUnit = useSelector(state => state.pos.get('posUnit'));
  const isOpenCurrentDevice = isPosUnitOpenCurrentDevice(posUnit);
  const printerError = useSelector(hasPrinterError);
  const cashEnabled = useSelector(state => getCashEnabled(state));
  const showOpenDrawer = useSelector(state => getShowOpenDrawer(state));

  const [isShowCloseDialog, setIsShowCloseDialog] = useState(false);
  const hideCloseDialog = () => setIsShowCloseDialog(false);
  const showCloseDialog = () => setIsShowCloseDialog(true);

  const [isShowFixDialog, setIsShowFixDialog] = useState(false);
  const hideFixDialog = () => setIsShowFixDialog(false);
  const showFixDialog = () => setIsShowFixDialog(true);

  const [isShowDepositWithdrawDialog, setIsShowDepositWithdrawDialog] = useState(false);
  const hideDepositWithdrawDialog = () => setIsShowDepositWithdrawDialog(false);
  const showDepositWithdrawDialog = () => setIsShowDepositWithdrawDialog(true);

  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const onOpenDrawer = () => {
    setIsDrawerOpen(true);
    dispatch(openDrawer());
    setTimeout(() => setIsDrawerOpen(false), 5000);
  };

  const showFixReceipt = isSysAdmin && posUnit && !isPosUnitClosed(posUnit);
  const drawerDisabled = isDrawerOpen || printerError;

  return (
    <>
      <CenterBlocks flex={1}>
        {isOpenCurrentDevice && (
          <>
            {cashEnabled && <TBStyled label={txt(msg.btnCashInOut)} icon="fa fa-coins" onClick={showDepositWithdrawDialog} />}
            {showOpenDrawer && <TBStyled label={txt(msg.btnOpenDrawer)} icon="fa fa-inbox" onClick={onOpenDrawer} disabled={drawerDisabled} />}
            <TBStyled label={txt(msg.btnClose)} icon="fa fa-power-off" onClick={showCloseDialog} />
          </>
        )}
        {showFixReceipt && <TBStyled label={txt(msg.btnFixReceipt)} icon="fa fa-file-check" onClick={showFixDialog} />}
      </CenterBlocks>
      {isShowCloseDialog && <DialogPosClose routeParams={routeParams} onClose={hideCloseDialog} />}
      {isShowFixDialog && <DialogPosFixSale routeParams={routeParams} onClose={hideFixDialog} />}
      {isShowDepositWithdrawDialog && <DialogPosDepositWithdraw onClose={hideDepositWithdrawDialog} />}
    </>
  );
};

export default PosHeaderToolbar;
