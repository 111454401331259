import React, { useCallback } from 'react';
import Header, { Toolbar } from '@Components/nav/header';
import { getSectionUrl, navigate } from '@Utils/navigate';
import { txt } from '@Utils/i18n-util';
import msg from './staff.msg';

const onPrint = (ev) => {
  ev.preventDefault();
  ev.target.blur();
  window.print();
};

const StaffHeader = ({ match }) => {
  const routeParams = match.params;
  const showReport = routeParams.id === 'report';

  const onShowUsers = useCallback(() => {
    navigate(getSectionUrl(routeParams, 'staff'));
  }, []);
  const onShowReport = useCallback(() => {
    navigate(getSectionUrl(routeParams, 'staff', 'report'));
  }, []);

  return (
    <Header
      title={txt(msg.lblHeaderTitle)}
      className="thin-header with-sidebar"
      onBackButtonClick={onShowUsers}
      showBackButton={showReport}
      routeParams={routeParams}
    >
      <Toolbar>
        {showReport
          ? <button type="button" className="btn btn-cta-secondary" onClick={onPrint}>{txt(msg.btnPrint)}</button>
          : <button type="button" className="btn btn-cta" onClick={onShowReport}>{txt(msg.btnShowJournal)}</button>}
      </Toolbar>
    </Header>
  );
};

export default StaffHeader;
