import { defineMessages } from 'react-intl';

export default defineMessages({
  txtConfirmRemove: {
    id: 'calendar.bkf.removeCustomer.txtConfirmRemove',
    defaultMessage: 'Remove customer from booking?'
  },
  btnCancel: {
    id: 'calendar.bkf.removeCustomer.btnCancel',
    defaultMessage: 'Cancel'
  },
  btnConfirm: {
    id: 'calendar.bkf.removeCustomer.btnConfirm',
    defaultMessage: 'Remove'
  },
  btnRemoving: {
    id: 'calendar.bkf.removeCustomer.btnRemoving',
    defaultMessage: 'Removing...'
  }
});
