import React, { Component } from 'react';
import { connect } from 'react-redux';
import { company } from '@Utils/preference-keys';
import {
  getJsonPreferences, mergeJsonPreferences, getInitialValues, updateContactDetails
} from '@State/preferences-actions';
import CompanyInfoForm from './company-info-form';

class CompanyInfo extends Component {
  componentDidMount() {
    this.props.getCompanyInfo();
  }

  onSubmit = (values) => {
    return this.props.saveCompanyInfo(values, this.props.enableMicrosite);
  };

  render() {
    const { initialValues, enableMicrosite, locationConfig } = this.props;

    return (
      <div className="subnav-container">
        <CompanyInfoForm
          locationConfig={locationConfig}
          enableMicrosite={enableMicrosite}
          initialValues={initialValues}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locationConfig: state.locationConfig,
    enableMicrosite: state.locationFeatures.get('EnableMicrosite'),
    initialValues: getInitialValues(state, company)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyInfo: () => {
      dispatch(getJsonPreferences());
    },
    saveCompanyInfo: (values, enableMicrosite) => {
      return enableMicrosite
        ? dispatch(updateContactDetails(values))
        : dispatch(mergeJsonPreferences(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfo);
