import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getAdminNavSections } from '@Components/admin/nav-selectors';
import { getRouteParams } from '@State/selectors';

import { Toolbar } from '@Components/nav/header';
import ServiceActionsButtons from '@Components/services/service-actions-buttons';
import ResourceActionButtons from '@Components/resources/resource-action-buttons';
import ProductActionButtons from '@Components/inventory/products/product-action-buttons';
import VoucherTemplateActionButtons from '@Components/admin/voucher-templates/voucher-template-action-buttons';
import StockTakingToolbar from '@Components/inventory/stock-taking/stock-taking-toolbar';
import DeliveryToolbar from '@Components/inventory/delivery/delivery-toolbar';
import PosToolbar from '@Components/admin/pos-config/pos-toolbar';
import StaffConfigToolbar from '@Components/admin/staff-config/staff-config-toolbar';
import UserMenuButton from '@Components/nav/user-menu-button';

class AdminHeader extends PureComponent {
  render() {
    const { routeParams } = this.props;
    return (
      <header className="section-header thin-header with-subnav">
        <div className="section-header-title">
          <h1>Admin</h1>
        </div>
        <Toolbar>
          {this.renderToolbar(routeParams)}
        </Toolbar>
        <div className="section-header-right">
          <UserMenuButton routeParams={routeParams} />
        </div>
      </header>
    );
  }

  renderToolbar(routeParams) {
    const { match, hasPosOrgs } = this.props;
    const id = match?.params?.id;

    switch (routeParams.subsection) {
      case 'services':
      case 'addon-services':
        return <ServiceActionsButtons id={id} routeParams={routeParams} />;
      case 'resources':
        return <ResourceActionButtons id={id} routeParams={routeParams} />;
      case 'products':
        return hasPosOrgs && <ProductActionButtons routeParams={routeParams} />;
      case 'voucher-templates':
        return hasPosOrgs && <VoucherTemplateActionButtons routeParams={routeParams} />;
      case 'stock-taking':
        return hasPosOrgs && <StockTakingToolbar routeParams={routeParams} />;
      case 'delivery':
        return hasPosOrgs && <DeliveryToolbar routeParams={routeParams} />;
      case 'users':
        return <StaffConfigToolbar routeParams={routeParams} />;
      case 'pos':
        return hasPosOrgs && <PosToolbar id={id} routeParams={routeParams} />;
      default:
        return null;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    match: ownProps.match,
    hasPosOrgs: !state.posOrgs.isEmpty(),
    routeParams: getRouteParams(state, ownProps),
    sections: getAdminNavSections(state, ownProps)
  };
};

export default connect(mapStateToProps)(AdminHeader);
