import moment from 'moment';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUpdatedSchedule, scheduleToTimeRestrictions, timeRestrictionsToSchedule } from '@Utils/schedule-utils';
import { updateServiceTimeRestrictions } from '@State/services-actions';
import ScheduleDay from '@Components/schedule/schedule-day';
import LabelSwitchInput from '@Components/ui/label-switch-input';
import Panel from '@Components/ui/styled/panel';

const ServiceSchedule = ({ serviceId }) => {
  const dispatch = useDispatch();
  const service = useSelector((state) => state.servicesById.get(serviceId));
  const { useTimeRestriction, timeRestrictions } = service || {};

  const serviceSchedule = useMemo(() => {
    return timeRestrictions
      ? timeRestrictionsToSchedule(timeRestrictions)
      : { blocks: [] };
  }, [service]);

  const days = useMemo(() => {
    return Array.from({ length: 7 }, (x, i) => {
      const { blocks } = serviceSchedule || {};
      const isoWeekday = moment().weekday(i).isoWeekday();

      return {
        week: 1,
        day: isoWeekday,
        blocks: blocks?.filter((block) => block.day === isoWeekday) || []
      };
    });
  }, [service]);

  const onToggleTimeRestrictions = () => {
    return onSaveTimeRestrictions(!useTimeRestriction, serviceSchedule);
  };

  const onSaveDay = (update) => {
    const schedule = getUpdatedSchedule(serviceSchedule, update);
    return onSaveTimeRestrictions(true, schedule);
  };

  const onCloseDay = (day, week) => {
    const { blocks } = serviceSchedule;
    return onSaveTimeRestrictions(true, {
      ...serviceSchedule,
      blocks: blocks.filter((b) => b.week === week && b.day === day ? null : b)
    });
  };

  const onSaveTimeRestrictions = (active, schedule) => {
    const restrictions = scheduleToTimeRestrictions(schedule);
    return dispatch(updateServiceTimeRestrictions(serviceId, active, restrictions));
  };

  return (
    <div className="with-padding">
      <Panel>
        <LabelSwitchInput
          noBorder
          id={`schedule-enabled-${serviceId}`}
          label="Aktivera schema för tjänsten"
          isOn={useTimeRestriction}
          handleToggle={onToggleTimeRestrictions}
          description="Använd ett särskilt schema för att ange när tjänsten är tillgänglig för bokning."
        />
      </Panel>
      {useTimeRestriction && (
        <div className="schedule-table">
          {days.map((day) => (
            <ScheduleDay
              compact
              key={day.day}
              data={day}
              onSave={onSaveDay}
              onDelete={onCloseDay}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ServiceSchedule;
