import React from 'react';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import DatePickerCalendarInput from '@Components/ui/date-picker/date-picker-calendar-input';
import PanelRadio from '@Components/inputs/panel-radio';
import Row from '@Components/ui/styled/row';
import TimePickerInput from '@Components/inputs/time-picker-input';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import { txt } from '@Utils/i18n-util';
import msg from './campaign-form.msg';

const CampaignSchedule = () => {
  const { watch, setValue, register } = useFormContext();
  const [sendAtTime, sendDate, sendTime] = watch(['sendAtTime', 'sendDate', 'sendTime']);
  const deliveryTime = moment(`${sendDate?.format('YYYY-MM-DD')}T${sendTime}`);

  return (
    <>
      <h4>{txt(msg.lblScheduleCampaign)}</h4>
      <PanelRadio
        value="now"
        label={txt(msg.lblSendNow)}
        input={{ ...register('sendAtTime') }}
      />
      <PanelRadio
        value="later"
        label={txt(msg.lblSchedule)}
        input={{ ...register('sendAtTime') }}
      >
        <Row>
          <div className="mr2">
            <DatePickerCalendarInput
              date={moment(sendDate)}
              minDate={moment().toDate()}
              onChange={date => setValue('sendDate', moment(date))}
            />
          </div>
          <div>
            <TimePickerInput
              time={sendTime}
              onChange={(ev, time) => setValue('sendTime', time)}
            />
          </div>
        </Row>
      </PanelRadio>
      {sendAtTime === 'later' && deliveryTime.isSameOrBefore(moment()) && (
        <AlertWithIcon warning icon="fa fa-exclamation-triangle">
          {txt(msg.txtTimePassed)}
        </AlertWithIcon>
      )}
    </>
  );
};

export default CampaignSchedule;
