import React from 'react';
import { useDispatch } from 'react-redux';
import { bkfModals } from '@State/bkf/constants';
import { toggleBKFModal } from '@State/bkf/actions';

const BookingCustomerNotes = ({ customer }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(toggleBKFModal(bkfModals.notes, true));
  };

  if (!customer?.customerNote) {
    return null;
  }

  return (
    <div className="booking-form-block notes" onClick={onClick}>
      <h4>
        <i className="far fa-info-circle mr1" />
        Meddelande från kund
      </h4>
      <div className="text-wrap">
        {customer.customerNote}
      </div>
    </div>
  );
};

export default BookingCustomerNotes;
