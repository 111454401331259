import Loader from '@Components/ui/loader';
import React, { lazy, Suspense } from 'react';

const RichTextEditorField = lazy(() => import('./rich-text-editor-field'));

const RichTextEditor = (props) => (
  <Suspense fallback={<Loader />}>
    <RichTextEditorField {...props} />
  </Suspense>
);

export default RichTextEditor;
