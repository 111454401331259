import styled from 'styled-components';
import DDModern from '@Components/ui/drop-down/drop-down-modern';
import { Panel } from '@Components/ui/styled/main';
import { colors } from '@Components/ui/styled/variables';

export const DDModernStyled = styled(DDModern)`
  max-width: 250px;
`;

export const ListStyle = styled.div`
`;

export const GrayText = styled(Panel)`
  min-width: 340px;
  height: 100%;
  margin-right: 20px;
`;

export const Label = styled.div`
  font-weight: 500;
  color: ${colors.textMuted};
`;

export const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 20px;
   > div {
    display: flex;
   }
`;

export const SwitchGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 0 50px;
`;

export const SwitchStyle = styled.div`
  width: 700px;
  margin-top: 25px;
`;
