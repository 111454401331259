import React from 'react';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';

import { classes } from '@Components/ui/utils';
import DatePickerCalendarInput from '@Components/ui/date-picker/date-picker-calendar-input';

const HookFormInput = ({
  id,
  label = null,
  unitLabel = null,
  placeholder = null,
  helpText = null,
  width = null,
  registerOptions = {},
  options = [],
  type = 'text',
  initialValue = null,
  disabled = false,
  className = null,
  onBlur = null,
  rows = null
}) => {
  const { register, setValue, watch, formState: { errors } } = useFormContext();
  const error = errors[id];
  const input = register(id, { ...registerOptions });
  const value = watch(id);

  const isSelect = type === 'select';
  const isDate = type === 'date';
  const isCheckbox = type === 'checkbox';
  const isTextarea = type === 'textarea';
  const isInput = !isSelect && !isDate && !isCheckbox && !isTextarea;

  const classNames = classes({
    'form-group': true,
    'has-error': error,
    [className]: className
  });

  return (
    <div className={classNames}>
      {label && !isCheckbox && (
        <label className="control-label" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="form-control-container" style={{ width }}>
        {unitLabel && (
          <label className="form-control-label">{unitLabel}</label>
        )}
        {isSelect && (
          <div className="select-container">
            <select
              disabled={disabled}
              className="form-control"
              defaultValue={initialValue}
              aria-invalid={error ? 'true' : 'false'}
              placeholder={placeholder}
              {...input}
            >
              {options?.map((opt) => (
                <option key={opt.value} value={opt.value} style={opt.value === '' ? { display: 'none' } : null}>
                  {opt.title}
                </option>
              ))}
            </select>
          </div>
        )}
        {isDate && (
          <DatePickerCalendarInput
            date={value ? moment(value) : null}
            onChange={date => setValue(id, date ? moment(date).format('YYYY-MM-DD') : null)}
          />
        )}
        {isTextarea && (
          <textarea
            rows={rows ?? 4}
            disabled={disabled}
            className="form-control"
            placeholder={placeholder}
            defaultValue={initialValue}
            {...input}
          />
        )}
        {isInput && (
          <input
            disabled={disabled}
            className="form-control"
            type={type}
            step={type === 'number' ? 'any' : null}
            placeholder={placeholder}
            defaultValue={initialValue}
            aria-invalid={error ? 'true' : 'false'}
            {...input}
            onBlur={onBlur}
          />
        )}
        {isCheckbox && (
          <div className={disabled ? 'checkbox disabled' : 'checkbox'}>
            <label>
              <input
                type="checkbox"
                disabled={disabled}
                {...input}
              />
              {label}
            </label>
          </div>
        )}
        {error && <span className="help-block">{error.message}</span>}
        {helpText && (
          <span className="form-control-description">{helpText}</span>
        )}
      </div>
    </div>
  );
};

export default HookFormInput;
