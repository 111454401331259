import React, { Component } from 'react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import CurrencyUtil from '@Utils/currency-util';
import { getSectionUrl } from '@Utils/navigate';
import { shorten } from '@Components/campaign/campaign-helpers';
import { fetchGroupsAndResources } from '@State/resource-actions';
import { fetchServices } from '@State/services-actions';
import { fetchVouchers, createVoucher, updateVoucher, voidVoucher } from '@State/voucher-actions';
import FilterContainer from '@Components/ui/filter-container';
import Button from '@Components/ui/button';
import ConfirmPopover from '@Components/ui/confirm-popover';
import NavTabsLink from '@Components/ui/nav-tabs-link';
import VoucherModal from './voucher-modal';

const tabSections = [
  { navId: 'active', name: 'Aktiva' },
  { navId: 'other', name: 'Ej aktiva' }
];

class Vouchers extends Component {
  state = {
    showConfirmVoid: null,
    showCreateVoucher: false,
    showEditVoucher: false
  };

  showCreateVoucher = () => this.setState({ showCreateVoucher: true });

  showEditVoucher = (voucher) => {
    this.setState({ showEditVoucher: voucher });
  };

  hideConfirmVoid = () => this.setState({ showConfirmVoid: null });

  createVoucher = (voucher) => {
    return this.props.createVoucher(voucher)
      .then(() => this.props.fetchVouchers());
  };

  updateVoucher = (voucher) => {
    return this.props.updateVoucher(voucher)
      .then(() => this.props.fetchVouchers());
  };

  hideForm = () => {
    this.setState({
      showCreateVoucher: false,
      showEditVoucher: false
    });
  };

  showConfirmVoid = (id) => {
    this.setState({ showConfirmVoid: id });
  };

  handleVoidVoucher = (id) => {
    this.hideConfirmVoid();
    return this.props.voidVoucher(id)
      .then(() => this.props.fetchVouchers());
  };

  componentDidMount() {
    this.props.fetchVouchers();

    if (this.props.resourcesById.isEmpty()) {
      this.props.fetchGroupsAndResources();
    }
    if (this.props.servicesById.isEmpty()) {
      this.props.fetchServices();
    }
  }

  componentDidUpdate(prevProps) {
    const tabChanged = prevProps.routeParams.tab !== this.props.routeParams.tab;
    const vouchersChanged = prevProps.activeVouchers !== this.props.activeVouchers;

    if (tabChanged || vouchersChanged) {
      ReactTooltip.rebuild();
    }
  }

  getRuleHeading = (rule) => {
    switch (rule) {
      case 'allowedServiceIds':
        return 'Tjänster';
      case 'allowedResourceIds':
        return 'Resurser';
      default:
        return rule;
    }
  };

  getRuleValue = (key, value) => {
    const { servicesById, resourcesById } = this.props;
    switch (key) {
      case 'allowedServiceIds':
        return value.map(id => servicesById.get(id)?.name).join(', ');
      case 'allowedResourceIds':
        return value.map(id => resourcesById.get(id)?.name).join(', ');
      default:
        return value;
    }
  };

  getRulesTooltip = (rules) => {
    return Object.entries(rules)
      .filter(([key, value]) => value)
      .map(([key, value]) => `${this.getRuleHeading(key)}: ${shorten(this.getRuleValue(key, value), 100)}`)
      .join('<br />');
  };

  render() {
    const { showCreateVoucher, showEditVoucher, showConfirmVoid } = this.state;
    const {
      routeParams, activeVouchers, nonActiveVouchers, match
    } = this.props;

    const showActiveVouchers = match.params.tab !== 'other';
    const vouchers = showActiveVouchers ? activeVouchers : nonActiveVouchers;

    if (!match.params.tab) {
      return (
        <Redirect to={getSectionUrl(routeParams, 'admin/vouchers', 'active')} />
      );
    }

    return (
      <div className="columns-container">
        <div className="columns-content">
          <div className="columns-content-container width-large">
            <div className="columns-content-body with-padding">
              <FilterContainer>
                <div className="form-group">
                  <Button small primary onClick={this.showCreateVoucher}>Lägg till rabattkod</Button>
                </div>
              </FilterContainer>
              <NavTabsLink
                withoutId
                bottomMargin
                subUrl="admin/vouchers"
                routeParams={routeParams}
                sections={tabSections}
              />
              <ReactTooltip id="voucher-list" effect="solid" place="top" className="voucher-tooltip" multiline />
              <table className="table table-striped select-text">
                <thead>
                  <tr>
                    <th>Rabattkod</th>
                    <th>Giltig från</th>
                    <th>Giltig t.o.m.</th>
                    <th>Värde</th>
                    <th>Använd</th>
                    <th>Regler</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {vouchers && vouchers.map(voucher => {
                    const {
                      id, code, discountType, amountValue, pctValue, redeems, maxRedeems,
                      noRedeemBeforeTs, noRedeemAfterTs, voucherStatus, voucherRules
                    } = voucher.toJS();
                    const showEdit = voucherStatus !== 'Void';
                    const hasRules = Object.values(voucherRules || {}).some(Boolean);

                    return (
                      <tr key={id}>
                        <td>{code}</td>
                        <td>{moment(noRedeemBeforeTs).format('L')}</td>
                        <td>{moment(noRedeemAfterTs).subtract(1, 'd').format('L')}</td>
                        <td>{discountType === 'Percentage'
                          ? `${pctValue}%`
                          : CurrencyUtil.accountCurrency(amountValue, 0)}
                        </td>
                        <td>{redeems} / {maxRedeems}</td>
                        <td>
                          {hasRules
                            ? <i className="fa fa-info-circle text-info" data-tip={this.getRulesTooltip(voucherRules)} data-for="voucher-list" />
                            : '-'}
                        </td>
                        <td className="text-right">
                          {showEdit && (
                            <>
                              <Button tiny gray onClick={() => this.showEditVoucher(voucher.toJS())}>Ändra</Button>
                              <ConfirmPopover
                                text={`Makulera rabattkod med kod ${code}?`}
                                confirmText="Makulera"
                                onClose={this.hideConfirmVoid}
                                onConfirm={() => this.handleVoidVoucher(id)}
                                isOpen={showConfirmVoid === id}
                              >
                                <Button tiny gray marginLeft onClick={() => this.showConfirmVoid(id)}>Makulera</Button>
                              </ConfirmPopover>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {showCreateVoucher && (
                <VoucherModal
                  onSubmit={this.createVoucher}
                  onClose={this.hideForm}
                />
              )}
              {showEditVoucher && (
                <VoucherModal
                  voucher={showEditVoucher}
                  onSubmit={this.updateVoucher}
                  onClose={this.hideForm}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { vouchers, resourcesById, servicesById } = state;

  return {
    routeParams: props.match.params,
    activeVouchers: vouchers.get('activeVouchers'),
    nonActiveVouchers: vouchers.get('nonActiveVouchers'),
    activeCount: vouchers.get('activeCount'),
    remainingValue: vouchers.get('remainingValue'),
    resourcesById,
    servicesById
  };
};

const mapDispatchToProps = dispatch => ({
  fetchVouchers: () => dispatch(fetchVouchers()),
  fetchServices: () => dispatch(fetchServices()),
  fetchGroupsAndResources: () => dispatch(fetchGroupsAndResources()),
  createVoucher: voucher => dispatch(createVoucher(voucher)),
  updateVoucher: voucher => dispatch(updateVoucher(voucher)),
  voidVoucher: id => dispatch(voidVoucher(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Vouchers);
