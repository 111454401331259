import React, { useState } from 'react';
import ScheduleCreateModal from '@Components/schedule/schedule-create-modal';
import { createSchedule } from '@State/schedule-actions';
import { useDispatch, useSelector } from 'react-redux';
import { txt } from '@Utils/i18n-util';
import msg from './schedules.msg';

const ScheduleCreateNewButton = ({ id }) => {
  const dispatch = useDispatch();
  const useLocationSchedule = useSelector(state => state.adminSchedules.get('useLocationSchedule'));

  const [isShowModalCreate, setIsShowModalCreate] = useState(false);
  const handlerHideForm = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setIsShowModalCreate(false);
  };

  const handlerShowModal = () => setIsShowModalCreate(true);
  const handleCreateSchedule = (data) => dispatch(createSchedule({ ...data }, id))
    .then(() => setIsShowModalCreate(false));

  return (
    <>
      {!useLocationSchedule && (
      <div className="schedule-add-new-item">
        <button type="button" className="btn btn-default" onClick={handlerShowModal}>
          <i className="far fa-calendar-alt" /> {txt(msg.btnAddSchedule)}
        </button>
      </div>
      )}
      {isShowModalCreate && <ScheduleCreateModal onClose={handlerHideForm} onSubmit={handleCreateSchedule} />}
    </>
  );
};

export default ScheduleCreateNewButton;
