import React, { Component } from 'react';
import { connect } from 'react-redux';
import { classes } from '@Components/ui/utils';
import { getResourceIcon } from '@Utils/resource-util';
import { getAdminResourceGroups, getFilteredResources } from '@Utils/filter-selectors';
import { getScheduleConfigUrl, navigate } from '@Utils/navigate';
import { toggleResourcesCollapsed } from '@State/admin-view-reducer';
import SortableList from '@Components/sortable-list/sortable-list';
import { txt } from '@Utils/i18n-util';
import msg from './schedules.msg';

class ScheduleResourceList extends Component {
  render() {
    const {
      filteredGroups, filteredResources, routeParams, onToggleCollapse, collapsedIds
    } = this.props;

    const className = classes({
      'columns-list-item': true,
      selected: !routeParams.id
    });

    return (
      <>
        <div className="columns-list-header-container">
          <div className="columns-list-header">
            <div><i className="fa fa-fw fa-chevron-down" /><span>{txt(msg.lblMainSchedule)}</span></div>
          </div>
          <div className={className} onClick={this.onMainClick}>
            <i className="fa-solid fa-users" /><span className="list-item-header">{txt(msg.lblSharedSchedule)}</span>
          </div>
        </div>
        <SortableList
          onChangeCollapsedIds={onToggleCollapse}
          collapsedIds={collapsedIds}
          groups={filteredGroups}
          groupItemSelector="resourceIds"
          items={filteredResources}
          itemContent={this.itemContent}
          selectedId={parseInt(routeParams.id)}
          onItemClick={this.onItemClick}
          hideGroupMenu
        />
      </>
    );
  }

  onMainClick = () => {
    navigate(getScheduleConfigUrl(this.props.routeParams));
  };

  onItemClick = item => {
    const { routeParams } = this.props;
    navigate(getScheduleConfigUrl(routeParams, item.id));
  };

  itemContent = item => {
    const { name, resourceType, useLocationSchedule } = item;

    return (
      <div>
        <div className="list-item-sub-header">
          {useLocationSchedule ? '' : txt(msg.lblIndividual)}
        </div>
        <div className="list-item-header">
          <i className={getResourceIcon(resourceType)} />
          {name}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  const { adminViewState } = state;

  return {
    collapsedIds: adminViewState.get('resourcesCollapsedIds'),
    filteredResources: getFilteredResources(state, ownProps),
    filteredGroups: getAdminResourceGroups(state, ownProps)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onToggleCollapse: (collapsedIds) => {
      return dispatch(toggleResourcesCollapsed(collapsedIds));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleResourceList);
