import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Panel } from '@Components/ui/styled/main';
import { SwitchGrid } from '@Components/admin/json-prefs/admin-config-style';
import LabelSwitchInput from '@Components/ui/label-switch-input';
import { setLocationFeature } from '@State/features-actions';

const AdminFeaturesConfigurations = () => {
  const dispatch = useDispatch();
  const features = useSelector(state => state.adminConfigState.get('features'));

  const toggleFeature = (key) => {
    return dispatch(setLocationFeature(key, !features.get(key)));
  };

  return features ? (
    <Panel>
      <SwitchGrid>
        {features.keySeq().sortBy().map((key) => {
          return (
            <LabelSwitchInput
              noBorder
              label={key}
              id={key}
              key={key}
              isOn={features.get(key)}
              handleToggle={() => toggleFeature(key)}
            />
          );
        })}
      </SwitchGrid>
    </Panel>
  ) : null;
};

export default AdminFeaturesConfigurations;
