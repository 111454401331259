import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyUtil from '@Utils/currency-util';
import {
  maxLength, convertToFloat, isValidInput, convertToString
} from '@Utils/numpad-util';
import { fetchAvailablePosUnits } from '@State/pos-actions';
import { getCashEnabled } from '@State/pos-selectors';
import { openPosUnit } from '@State/pos-config-actions';
import ModalDialog from '@Components/dialogs/modal-dialog';
import DialogLoader from '@Components/dialogs/dialog-loader';
import Numpad from '@Components/inputs/numpad';
import CalcDisplay from '@Components/ui/calc-display';
import { txt } from '@Utils/i18n-util';
import msg from './dialog-pos.msg';

const SubText = styled.div`
  height: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
`;

const DialogPosOpen = ({ onClose }) => {
  const dispatch = useDispatch();

  const posUnit = useSelector(state => state.pos.get('posUnit'));
  const isLoading = useSelector(state => state.pos.get('progress'));
  const cashEnabled = useSelector(state => getCashEnabled(state));

  const [isValid, setIsValid] = useState(true);
  const [cashValue, setCashValue] = useState(convertToString(posUnit.get('cashBalance')));

  useEffect(() => {
    if (!cashEnabled) {
      dispatch(openPosUnit(posUnit.get('vunitId'), 0));
    } else {
      dispatch(fetchAvailablePosUnits());
    }
  }, []);

  useEffect(() => {
    if (cashEnabled) {
      setCashValue(convertToString(posUnit.get('cashBalance')));
    }
  }, [posUnit]);

  const onClickOpen = (ev) => {
    ev && ev.preventDefault();
    dispatch(openPosUnit(posUnit.get('vunitId'), convertToFloat(cashValue || 0)));
  };

  const handleChangeNumber = value => {
    if (isValidInput(value, maxLength)) {
      setIsValid(CurrencyUtil.isValidCashAmount(value));
      setCashValue(value);
    }
  };

  if (isLoading || !cashEnabled) {
    return <DialogLoader title={txt(msg.lblOpeningPos)} />;
  }

  return (
    <ModalDialog
      title={txt(msg.lblCashAmount)}
      onClose={onClose}
      buttons={[
        {
          name: txt(msg.btnOpenPos),
          primary: true,
          onClick: onClickOpen,
          disabled: !isValid
        }
      ]}
    >
      <CalcDisplay align="center">
        {CurrencyUtil.accountCurrency(convertToFloat(cashValue), 2)}
      </CalcDisplay>
      <SubText>{txt(msg.txtCashAmount)}</SubText>
      <Numpad
        onChange={handleChangeNumber}
        onEnter={isValid ? onClickOpen : null}
        input={cashValue}
      />
    </ModalDialog>
  );
};

export default DialogPosOpen;
