import React from 'react';
import { useSelector } from 'react-redux';

const BookingReview = () => {
  const reviews = useSelector(state => state.bkf.get('reviews'));
  const { reviewPostedTs, stars } = reviews?.length > 0 ? reviews[0] : {};

  if (!reviewPostedTs) {
    return null;
  }

  return (
    <div className="booking-form-block one-line cancelled">
      <h4>
        {[1, 2, 3, 4, 5].map((star) => {
          const icon = stars >= star
            ? 'fa fa-star-sharp icon-star-filled'
            : 'fa fa-star-sharp icon-star-empty';
          return (
            <i key={star} className={icon} />
          );
        })}
      </h4>
    </div>
  );
};

export default BookingReview;
