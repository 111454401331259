import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class PasswordPolicy extends Component {
  render() {
    const { passwordPolicy, validationError, isValidating } = this.props;
    const hasPolicy = passwordPolicy && Object.values(passwordPolicy).some(v => v);

    return hasPolicy ? (
      <div>
        <p style={{ marginBottom: 5 }}><strong>Lösenordet måste bestå av</strong></p>
        {Object.keys(passwordPolicy).map((key) => {
          const isValid = !validationError || validationError.indexOf(key) === -1;
          const errorClass = isValidating ? 'text-danger' : 'text-muted';
          const errorIcon = isValidating ? 'fa fa-fw fa-times-circle' : 'fa fa-fw fa-circle-o';
          return passwordPolicy[key] > 0 ? (
            <div key={key} className={isValid ? 'text-success' : errorClass}>
              <i className={isValid ? 'fa fa-fw fa-check-circle' : errorIcon} style={{ marginRight: 5 }} />
              {this.getPolicyDescription(key)}
            </div>
          ) : null;
        })}
        <br />
      </div>
    ) : null;
  }

  getPolicyDescription = (key) => {
    const { passwordPolicy } = this.props;
    const value = passwordPolicy[key];

    if (key == 'minLength') {
      return `minst ${value} tecken`;
    }
    if (key == 'caps') {
      return `minst ${value} stora bokstäver`;
    }
    if (key == 'digits') {
      return `minst ${value} siffror`;
    }
    if (key == 'specials') {
      return `minst ${value} specialtecken`;
    }
  };
}

PasswordPolicy.propTypes = {
  passwordPolicy: PropTypes.object,
  validationError: PropTypes.array,
  isValidating: PropTypes.bool
};
